/** @format */

import { FC } from 'react';

interface Props {
  label: string;
}
export const UIDateLabel: FC<Props> = ({ label }): JSX.Element => {
  return (
    <div className='date-label'>{label}</div>
  );
};
