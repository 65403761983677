/** @format */

import { FC } from 'react';
import { ButtonExport } from '../../../shared/ButtonExport';
import { InfoBox } from '../../../shared/InfoBox';

import iconCollapse from '../../../assets/icons/outline/collapse.svg';
import iconAdd from '../../../assets/icons/admin/add.svg';
import iconTypeIds from '../../../assets/icons/admin/typeids.svg';

interface Props {
  label: string;
  url?: string;
  customerId?: string;
  infoMessage: string;
  collapsed: boolean;
  expandable?: boolean;
  onClick?: () => void;
  onAdd?: () => void;
  onTypeIds?: () => void;
  children?: JSX.Element;
}

export const UITabTitle: FC<Props> = ({
  label,
  url = '',
  customerId = '',
  infoMessage = '',
  onClick = null,
  onAdd = null,
  onTypeIds = null,
  collapsed,
  children,
  expandable = true,
}): JSX.Element => {
  return (
    <div className="head"
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <h2>
        {label}
        {infoMessage.length > 0 && <InfoBox message={infoMessage} />}
        {children}
      </h2>
      {url && url.length > 0 && <ButtonExport url={url} customerId={customerId || '0'} />}
      {expandable && <img className='expand' src={iconCollapse} width={'16'} height={'16'} style={{ transform: collapsed ? 'rotate(180deg)' : 'rotate(0deg)' }} />}
      {(onAdd !== null) && <img className='add addrebate' alt="Add" src={iconAdd} width={'16'} height={'16'} onClick={onAdd} />}
      {(onTypeIds !== null) && <img className='typeids' alt="Type IDs" src={iconTypeIds} width={'16'} height={'16'} onClick={onTypeIds} />}
    </div>
  );
};
