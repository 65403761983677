import { DateBox, Validator } from 'devextreme-react';
import { RequiredRule } from 'devextreme-react/form';
import { FC } from 'react';

export enum DateSelectionType {
    date,
    time,
    datetime,
}

export enum DateSelectionPickerType {
    rollers,
    calendar,
}

interface SelectionProps {
    id?: string | undefined,
    name?: string | undefined,
    value?: any,
    pickerType: DateSelectionPickerType,
    selectionType: DateSelectionType,
    required?: boolean,
    onChangedSelection: (selection?: any) => void,
}

export const DateSelection: FC<SelectionProps> = ({
    id,
    name,
    value,
    pickerType = DateSelectionPickerType.calendar,
    selectionType = DateSelectionType.date,
    required = false,
    onChangedSelection = () => { },
}): JSX.Element => {
    // const now = new Date();

    function getSelectionType() {
        switch (selectionType) {
            case DateSelectionType.date:
                return 'date';
            case DateSelectionType.time:
                return 'time';
        }
    }
    function getPickerType() {
        switch (pickerType) {
            case DateSelectionPickerType.calendar:
                return 'calendar';
            case DateSelectionPickerType.rollers:
                return 'rollers';
        }
    }
    
    return (
        <div>
            <DateBox
                id={id}
                name={name}
                value={value}
                pickerType={getPickerType()}
                type={getSelectionType()}
                onValueChanged={onChangedSelection}>
                <Validator>
                    {required ? <RequiredRule message='Field is required' /> : <div />}
                </Validator>
            </DateBox>
        </div>
    );
};