/** @format */

import { FC } from 'react';
import styled from '@emotion/styled';
import { mapColors } from '../../../../theme/mapColors';
import { percentageFormat } from '../../../../utils/numberUtils';

const Th = styled.th({
  minWidth: '222px',
  height: '25px',

  color: '#8792B1',
  border: `1px solid ${mapColors.backgrounds.borderPrimary}`,
  borderCollapse: 'collapse',
  borderRadius: '10px',
  textAlign: 'start',
  fontSize: '12px',
  fontWeight: '600',
  paddingLeft: '20px',
});

const Table = styled.table({
  alignSelf: 'center',
  overflow: 'hidden',
  width: '100%',
  height: 'auto',
  marginBottom: '20px',
  borderCollapse: 'collapse',
  borderRadius: '10px',
  borderStyle: 'hidden',
  boxShadow: `0 0 0 1px ${mapColors.backgrounds.borderPrimary}`,
});

const Td = styled.td({
  height: '25px',
  minWidth: '222px',
  textAlign: 'start',
  paddingLeft: '30px',
  fontSize: '12px',
  border: `1px solid ${mapColors.backgrounds.borderPrimary}`,
  fontWeight: '600',
});
const TdL1 = styled.td({
  height: '25px',
  minWidth: '222px',
  textAlign: 'start',
  paddingLeft: '10px',
  fontSize: '12px',
  border: `1px solid ${mapColors.backgrounds.borderPrimary}`,
  fontWeight: '600',
});

interface Props {
  list: Array<any>;
}

export const TableGPN: FC<Props> = ({ list }): JSX.Element => {
  const getLevels = (group: number) => {
    const level = list
      .filter(item => {
        return item.level === 2 && item.group === group;
      })
      .map((el, index) => (
        <tr key={`level_${group}_${index}`}>
          <Td>{el.name}</Td>
          <Td>{el.units}</Td>
          <Td style={{ textAlign: 'right', paddingRight: '10px' }}>{el.perc_mix}%</Td>
        </tr>
      ));
    return level;
  };

  const table = list
    .filter(item => {
      return item.level === 1;
    })
    .map((el, index) => (
      <>
        <tr key={`table_${el.group}_${index}`}>
          <TdL1>{el.name}</TdL1>
          <Td>{el.units}</Td>
          <Td style={{ textAlign: 'right', paddingRight: '10px' }}>{percentageFormat(el.perc_mix, false)}</Td>
        </tr>
        {getLevels(el.group)}
      </>
    ));

  return (
    <Table>
      <thead>
        <tr>
          <Th style={{ display: 'flex', alignItems: 'center', border: '0px' }}>Manufactures</Th>
          <Th>Units</Th>
          <Th style={{ textAlign: 'right', paddingRight: '10px' }}>% Mix</Th>
        </tr>
      </thead>
      <tbody>{table}</tbody>
    </Table>
  );
};
