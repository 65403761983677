/** @format */

import { FC } from 'react';
import { ActiveBrand, BrandTrackingItem } from '../services/rebate/api';
import { MESSAGE_NOT_AVAILABLE_ABBREV } from '../constants/general';
import { DataGrid } from 'devextreme-react';
import { Column, Pager, Paging } from 'devextreme-react/data-grid';
import { ColumnCellTemplateData } from 'devextreme/ui/data_grid';
import { UIIcon } from './Icon';
import okIcon from '../assets/images/v2/ok.svg';
// import { Column } from 'devextreme-react/data-grid';

interface Props {
    brands: Array<ActiveBrand>;
    brandTrackingData: Array<BrandTrackingItem>;
    totalActiveBrands: number | null;
    opportunityBrands: number | null;
}
export const RebateViewActiveBrands: FC<Props> = ({
    brands,
    brandTrackingData,
    totalActiveBrands,
    opportunityBrands,
}): JSX.Element => {

    const brand1 = brands.length > 0 ? brands[0] : null;
    const brand2 = brands.length > 1 ? brands[1] : null;
    const brand3 = brands.length > 2 ? brands[2] : null;

    /*
    let brandPercentage = ((totalActiveBrands ?? 0.0) * 100) / 9;
    if (brandPercentage < 0.0) brandPercentage = 0.0;
    if (brandPercentage > 100.0) brandPercentage = 100.0;
    */
    
    let brandPercentage = 0.0;
    const brandCount = totalActiveBrands ?? 0;
    if (brandCount <= 0) {
        // DO NOTHING
    } else if (brandCount === 1) {
        brandPercentage = 8.33;
    } else if (brandCount === 2) {
        brandPercentage = 16.66;
    } else if (brandCount === 3) {
        brandPercentage = 25;
    } else if (brandCount === 4) {
        brandPercentage = 33.33;
    } else if (brandCount === 5) {
        brandPercentage = 41.33;
    } else if (brandCount === 6) {
        brandPercentage = 50;
    } else if (brandCount === 7) {
        brandPercentage = 66.66;
    } else if (brandCount === 8) {
        brandPercentage = 83.33;
    } else {
        brandPercentage = 100.0;
    }


    const brandTrackingDataBrands: Array<string> = [];
    if (brandTrackingData.length > 0) {
        brandTrackingData.forEach(customer => {
            (customer.brands ?? []).forEach(brand => {
                if (!brandTrackingDataBrands.includes(brand.brand)) {
                    brandTrackingDataBrands.push(brand.brand);
                }
            });
        });
    }
    brandTrackingDataBrands.sort(function (a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
    });

    const brandTrackingTableData: Map<any, any>[] = [];

    if (brandTrackingData.length > 0) {
        brandTrackingData.forEach(customer => {
            const customerData = new Map();
            customerData.set('customer_code', customer.customer_code);
            customerData.set('number_of_brands', customer.number_of_brands);
            brandTrackingDataBrands.forEach(brand => {
                let brandData = null;
                customer.brands.forEach(customerBrand => {
                    if (
                        brand ===
                        customerBrand.brand) {
                        brandData = customerBrand.units_from_target;
                    }
                });
                customerData.set(brand, brandData);
            });
            brandTrackingTableData.push(customerData);
        });
    }

    /*
    const brandTrackingTableDataColumns = [];
    brandTrackingTableDataColumns.push(
        {
            key: 'customer_code',
            dataField: 'customer_code',
            caption: 'azz',
            alignment: 'left',
            width: 150,
            fixed: true,
            fixedPosition: 'left',
        });
    
    brandTrackingTableDataColumns.push(<Column
        key={'number_of_brands'}
        dataField={'number_of_brands'}
        caption={'Number of brands'}
        alignment={'center'}
        fixed={false}
    />);

    brandTrackingDataBrands.forEach(element => {
        if (element) {
            brandTrackingTableDataColumns.push(<Column
                key={element.toLowerCase().replace(' ', '_')}
                dataField={element.toLowerCase().replace(' ', '_')}
                caption={element}
                alignment={'center'}
            />);
        }
    });
    */

    const brandTrackingTableDataRows = brandTrackingData.map((item) => {
        const temp: any = {};
        temp['customer_code'] = item.customer_code;
        temp['number_of_brands'] = item.number_of_brands;
        brandTrackingDataBrands.forEach(brand => {
            let brandData = null;
            item.brands.forEach(customerBrand => {
                if (brand === customerBrand.brand) {
                    
                    brandData = customerBrand.units_from_target;
                }
            });
            temp[brand.toLowerCase().replace(' ', '_').replace('.', '_')] = brandData;
        });
        return temp;
    });

    /*
    function brandTrackingTableDataRowsToCells(item: any) {
        const output = [];
        if (item) {
            for (const subitem in item) {
                let cssClass = '';
                if (subitem === 'number_of_brands') cssClass = 'number_of_brands';
                else if (subitem === 'customer_code') cssClass = 'customer_code';
                else if (!item[subitem]) cssClass = 'brand null';
                else if (parseInt(item[subitem], 10) > 0) cssClass = 'brand red';
                else if (parseInt(item[subitem], 10) <= 0) cssClass = 'brand green';
                output.push(<td className={cssClass}>{item[subitem]}</td>);
            }
        }
        return output;
    }
    */

    const cellPrepared = (data: any) => {
        if (data) {
            if (data.rowType === 'header') {
                if (data.columnIndex > 1) {
                    data.cellElement.classList.add('brand-header');
                } else {
                    data.cellElement.classList.add('info-header');
                }
            }

            if (data.rowType === 'data') {
                if (data.columnIndex > 1) {

                    if (data) {
                        if (data.displayValue === null) {
                            // DO NOTHING
                        } else if (data.displayValue <= 0) {
                            data.cellElement.classList.add('green-cell');
                        } else if (data.displayValue > 0) {
                            data.cellElement.classList.add('red-cell');
                        } else {
                            // DO NOTHING
                        }
                    }
                }
            }
        }
    };

    const cellPreparedPrintable = (data: any) => {
        if (data) {
            if (data.rowType === 'header') {
                if (data.columnIndex > 1) {
                    data.cellElement.classList.add('brand-header');
                } else {
                    data.cellElement.classList.add('info-header');
                }
            }

            if (data.rowType === 'data') {
                if (data.columnIndex > 1) {

                    if (data) {
                        if (data.displayValue === null) {
                            // DO NOTHING
                        } else if (data.displayValue <= 0) {
                            data.cellElement.classList.add('green-cell');
                        } else if (data.displayValue > 0) {
                            data.cellElement.classList.add('red-cell');
                        } else {
                            // DO NOTHING
                        }
                    }
                }
            }
        }
    };

    const trackingCellRenderer = (data: ColumnCellTemplateData) => {
        if (data) {
            if (data.displayValue === null || data.displayValue === undefined) {
                return '-';
            } else if (data.displayValue <= 0) {
                return <UIIcon name={'Logo'} srcIcon={okIcon} width={'18'} height={'18'} className="ok" />;
            } else if (data.displayValue > 0) {
                return data.displayValue;
            } else {
                return '';
            }
        }
    };

    return <>
        <div className="active-brands">
            <div className='row opportunity'>
                <div className='active'>
                    <div className='value'>{totalActiveBrands ?? MESSAGE_NOT_AVAILABLE_ABBREV}</div>
                    <div className='label'>Active Brands</div>
                </div>
                <div className='brands'>
                    <div className='value'>{opportunityBrands ?? MESSAGE_NOT_AVAILABLE_ABBREV}</div>
                    <div className='label'>Opportunity Brands</div>
                </div>
            </div>

            <button className='brand-tracking-button' data-bs-toggle="modal" data-bs-target="#brand-tracking-backdrop">Click here for brand tracking</button>

            <div className='printable'>

                <div className="legend-items">
                    <div className="legend-item">
                        <div className="legend-color active" />
                        <div className="legend-title">The brand is active</div>
                    </div>
                    <div className="legend-item">
                        <div className="legend-color from" />
                        <div className="legend-title">Units from active</div>
                    </div>
                </div>

                <DataGrid
                    allowColumnReordering={false}
                    rowAlternationEnabled={false}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={false}
                    allowColumnResizing={false}
                    columnAutoWidth={true}
                    keyExpr="customer_code"
                    className='brand-tracking-table'
                    onCellPrepared={cellPreparedPrintable}
                    dataSource={brandTrackingTableDataRows}>
                    <Column
                        key={'customer_code'}
                        dataField={'customer_code'}
                        caption={''}
                        alignment={'left'}
                        width={150}
                        fixed={true}
                        fixedPosition={'left'}
                        allowSorting={false} />,
                    <Column
                        key={'number_of_brands'}
                        dataField={'number_of_brands'}
                        caption={'# of Active Brands'}
                        alignment={'center'}
                        allowSorting={false}
                        width={80}/>
                    {brandTrackingDataBrands.map((item) =>
                        <Column
                            key={item.toLowerCase().replace(' ', '_').replace('.', '_')}
                            dataField={item.toLowerCase().replace(' ', '_').replace('.', '_')}
                            caption={item}
                            alignment={'center'}
                            cellRender={(e) => trackingCellRenderer(e)}
                            allowSorting={false} />,
                    )}
                    <Pager
                        visible={false}
                        displayMode={'compact'}
                        showPageSizeSelector={false}
                        showInfo={true}
                        showNavigationButtons={true}
                    />
                    <Paging defaultPageSize={100000} />
                </DataGrid>
            </div>
            <div className="modal fade modal-xl" id="brand-tracking-backdrop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-labelledby="brand-tracking-backdrop-label"
                aria-hidden="true">
                <div className="modal-dialog modal-xl" style={{ width: '90%' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="brand-tracking-backdrop-label">Brand tracking</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">

                            <div className="legend-items">
                                <div className="legend-item">
                                    <div className="legend-color active" />
                                    <div className="legend-title">The brand is active</div>
                                </div>
                                <div className="legend-item">
                                    <div className="legend-color from" />
                                    <div className="legend-title">Units from active</div>
                                </div>
                            </div>

                            <DataGrid
                                allowColumnReordering={false}
                                rowAlternationEnabled={false}
                                showBorders={true}
                                showColumnLines={true}
                                showRowLines={false}
                                allowColumnResizing={false}
                                columnAutoWidth={true}
                                keyExpr="customer_code"
                                className='brand-tracking-table'
                                onCellPrepared={cellPrepared}
                                dataSource={brandTrackingTableDataRows}>
                                <Column
                                    key={'customer_code'}
                                    dataField={'customer_code'}
                                    caption={''}
                                    alignment={'left'}
                                    width={150}
                                    fixed={true}
                                    fixedPosition={'left'}
                                    allowSorting={false} />,
                                <Column
                                    key={'number_of_brands'}
                                    dataField={'number_of_brands'}
                                    caption={'# of Active Brands'}
                                    width={160}
                                    alignment={'center'}
                                    allowSorting={false} />
                                {brandTrackingDataBrands.map((item) =>
                                    <Column
                                        key={item.toLowerCase().replace(' ', '_').replace('.', '_')}
                                        dataField={item.toLowerCase().replace(' ', '_').replace('.', '_')}
                                        caption={item}
                                        alignment={'center'}
                                        width={brandTrackingDataBrands.length > 4 ? 180 : undefined}
                                        cellRender={(e) => trackingCellRenderer(e)}
                                        allowSorting={false} />,
                                )}
                                <Pager
                                    visible={false}
                                    displayMode={'compact'}
                                    showPageSizeSelector={false}
                                    showInfo={true}
                                    showNavigationButtons={true}
                                />
                                <Paging defaultPageSize={100000} />
                            </DataGrid>
                        </div>
                    </div>
                </div>
            </div>


            {/*
            <div className="row active gx-1">
                <div className="col brand left">{(brand1?.totalActiveBrand ?? '').length > 0 && <div className="tip">{brand1?.totalActiveBrand ?? ''}</div>}</div>
                <div className="col brand center">{(brand2?.totalActiveBrand ?? '').length > 0 && <div className="tip">{brand2?.totalActiveBrand ?? ''}</div>}</div>
                <div className="col brand right">{(brand3?.totalActiveBrand ?? '').length > 0 && <div className="tip">{brand3?.totalActiveBrand ?? ''}</div>}</div>
            </div>
            */}
            <div className="row brands gx-1">
                <div className="col brand left"><div className={brand1?.active ? '' : 'selected'}>{brand1?.percent ?? ''} %</div></div>
                <div className="col brand center"><div className={brand2?.active ? '' : 'selected'}>{brand2?.percent ?? ''} %</div></div>
                <div className="col brand right"><div className={brand3?.active ? '' : 'selected'}>{brand3?.percent ?? ''} %</div></div>
            </div>
            <div className="row labels g-2">
                <div className="col label left"><div>{brand1?.label ?? ''}</div></div>
                <div className="col label center"><div>{brand2?.label ?? ''}</div></div>
                <div className="col label right"><div>{brand3?.label ?? ''}</div></div>
            </div>
            <div className="row chart">
                <div className='tier-chart'>
                    <>
                        <div className='tier-chart-item current'>
                            <div className='tier-current-bar tier-bar' style={{ width: brandPercentage + '%' }}><div className='tier-indicator' /></div>
                        </div>
                        <div key={'tier_bar_1'} className='tier-chart-item target'>
                            <div className='tier-target-bar tier-bar' style={{ width: '100%' }} />
                        </div>
                        {brandPercentage > 0.0 && <div className='tier-chart-tooltip' style={{ left: 'calc(' + brandPercentage + '% - 58px)' }}>
                            <div className='value'>
                                {brand1?.totalActiveBrand ?? ''}
                                {brand2?.totalActiveBrand ?? ''}
                                {brand3?.totalActiveBrand ?? ''}
                            </div>
                        </div>}
                    </>
                </div>
            </div>
        </div>
    </>;
};