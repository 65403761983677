/** @format */

import { FC } from 'react';
import { ColumnItem } from '../services/rebate/api';
import { integerFormat, numberFormat, percentageFormat } from '../utils/numberUtils';

type GenericTableRebateProps = {
  items: Array<ColumnItem>;
};

export const GenericTableRebate: FC<GenericTableRebateProps> = ({ items }): JSX.Element => {

  function formatValue(item: ColumnItem) {
    if (item !== null && item.value !== null && item.value !== undefined) {
      if (item.value_type.toLowerCase() === 'string') return item.value;
      if (item.value_type.toLowerCase() === 'string_highlight') return item.value;
      if (item.value_type.toLowerCase() === 'unit') return integerFormat(parseFloat(item.value));
      if (item.value_type.toLowerCase() === 'amount') {
        // const tempValue = Math.abs(parseFloat(item.value));
        // if (tempValue < 1) return numberFormatRaw(parseFloat(item.value));
        return numberFormat(parseFloat(item.value));
      }
      if (item.value_type.toLowerCase() === 'percent') return percentageFormat(parseFloat(item.value) * 100.0);
    }
    return 'N/A';
  }

  function formatValueClass(item: ColumnItem) { 
    if (item !== null && item.value !== null && item.value !== undefined) {
      if (item.value_type.toLowerCase() === 'string_highlight') return 'highlight';
    }
    return '';
  }

  const table = items.map((el, index) => (
    <tr key={`${el.title}-${index}`}>
      <th>{el.title}</th>
      <td className={formatValueClass(el)}>{formatValue(el)}</td>
    </tr>
  ));

  return (
    <table className='simple-table rebate'>
      <tbody>{table}</tbody>
    </table>
  );
};
