/** @format */

import { mapColors } from '../theme/mapColors';

export const utilityPaletteChartRebates = [
  mapColors.theme.greenClear[0],
  mapColors.theme.orange,
  mapColors.theme.blueLight[0],
  mapColors.theme.blue,
  mapColors.theme.lilac,
  mapColors.theme.yellow[0],
];
