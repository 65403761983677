/** @format */

import { FC, useState } from 'react';

import { UITabTitle } from '../partials/TabTitle';
import { CardPortfolio } from './partials/CardPortfolio';
import { CardBulletChart } from './partials/CardBulletChart';
import { mapColors } from '../../../theme/mapColors';
// import { CardPieChart } from './partials/CardPieChart';
import iconStats from '../../../assets/icons/outline/Stats.svg';
import iconSlice from '../../../assets/icons/outline/Slice.svg';
import iconStatsUp from '../../../assets/icons/outline/StatsUp.svg';
import { decimalFormat, numberFormat, percentageFormat } from '../../../utils/numberUtils';
import { BOX_TITLE_DATA_INFO, CYTD, MESSAGE_NOT_AVAILABLE_ABBREV, YTD } from '../../../constants/general';
import { Expandable } from '../../../shared/Expandable';

type DataInfoProps = {
  customer: any;
};

export const DataInfo: FC<DataInfoProps> = ({ customer = null }): JSX.Element => {
  // const customer = useAppSelector(selectorCustomer);

  const totalPortfolioCY = customer ? customer.customer_data.total_portfolio_cy : null;
  const totalPortfolioDeltaCY = customer ? customer.customer_data.total_portfolio_delta_cy_py * 100.0 : null;

  // const returnRateCY = customer ? 100.0 * customer.customer_data.return_rate_cy : null;
  // const returnRateDeltaCY = customer ? customer.customer_data.return_rate_delta_cy_py : null;

  const salesCY = customer ? customer.customer_data.sales_cy : null;
  const salesDeltaCY = customer ? customer.customer_data.sales_delta_cy_py * 100.0 : null;

  // const totalPotentialSavings = customer ? customer.customer_data.total_potential_savings : null;
  // const totalAchievedSavings = customer ? customer.customer_data.total_achieved_savings : null; 

  const levelStatusValue = customer ? customer.customer_data.level_status_value ?? 0 : 0;
  const levelStatusValue2 = customer ? customer.customer_data.customer_status_level.level_status_value ?? 0 : 0;
  let levelValue = levelStatusValue2 - levelStatusValue;
  if (levelValue === 1) levelValue = 0;

  const levelRating = customer ? customer.customer_data.level_status_rating : null;

  const percentageAchiviedValue = customer ? customer.customer_data.total_achieved_saving_percentage : 0;

  const levelString = customer ? customer.customer_data.customer_status_level.level_status_description : '';
  const trendingLevelString = customer ? customer.customer_data.customer_trending_status_level : '';

  const needed = customer.customer_data.level_status_value ?? 0;
  // const currentId = customer ? customer.customer_data.level_status_id : 0;
  const oldId = customer ? customer.customer_data.customer_status_level.level_status_id : 0;

  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div id="portfolio-rating" className="data-panel">
      <UITabTitle
        label={BOX_TITLE_DATA_INFO}
        infoMessage={''}
        onClick={() => setIsExpanded(!isExpanded)}
        collapsed={isExpanded}
      />
      <Expandable expanded={isExpanded} direction={'row'}>
        <div className='box'>
          <div className='left'>
            <div className='box-row'>
              <CardPortfolio
                showScaling={true}
                showInfo={true}
                tooltipMessage={'Net Invoiced $ ' + CYTD + ' (All Brands)'}
                infoMessage={''}
                isScaling={totalPortfolioDeltaCY ? totalPortfolioDeltaCY > 0 : false}
                srcIcon={iconStats}
                value={totalPortfolioCY ? numberFormat(totalPortfolioCY) : MESSAGE_NOT_AVAILABLE_ABBREV}
                delta={totalPortfolioDeltaCY ? percentageFormat(totalPortfolioDeltaCY) : MESSAGE_NOT_AVAILABLE_ABBREV}
                info={'Total Portfolio Sales (' + YTD + ')'}
                iconColor={mapColors.theme.blueLight[0]}
              />
              <CardPortfolio
                showScaling={true}
                showInfo={true}
                tooltipMessage={'Net Invoiced Units ' + CYTD + ' (All Brands)'}
                infoMessage={''}
                isScaling={salesDeltaCY ? salesDeltaCY > 0 : false}
                srcIcon={iconStatsUp}
                value={salesCY ? decimalFormat(salesCY, true) : MESSAGE_NOT_AVAILABLE_ABBREV}
                delta={salesDeltaCY ? percentageFormat(salesDeltaCY) : MESSAGE_NOT_AVAILABLE_ABBREV}
                info={'Total Sales Units (' + YTD + ')'}
                iconColor={mapColors.theme.greenClear[0]}
              />
              <CardPortfolio
                showScaling={false}
                showInfo={false}
                tooltipMessage={'Return Units/Gross Invoiced Units'}
                infoMessage={'Total Achieved Savings include earned Rebates, Commercial, Prompt Pay, Promotional, and Free Freight discounts'}
                isLast
                isScaling={false}
                srcIcon={iconSlice}
                // value={returnRateCY ? percentageFormat(Math.round(returnRateCY), true, 0, '0%') : '0%'}
                value={numberFormat(customer?.new_luxottica_portfolio_savings?.current_year.total ?? 0.0)}
                delta={percentageAchiviedValue ? percentageFormat(percentageAchiviedValue, true, 0, '0%') + ' of total portfolio savings' : MESSAGE_NOT_AVAILABLE_ABBREV}
                info={'Total Savings'}
                iconColor={mapColors.theme.orange}
                isNeeded={false}
                neededLabel={''}
                valueColor={'inherit'}
              />
            </div>

            <CardBulletChart
              needed={needed}
              value={levelValue}
              level={levelString}
              trending_level={trendingLevelString}
              rating={levelRating}
              total={totalPortfolioCY}
              oldId={oldId}
            />
          </div>
          {/*
          <div className='right'>
            <CardPieChart
              tooltipMessage={'Total Achieved Savings/Gross Sales'}
              potentialValue={totalPotentialSavings ?? -1}
              achievedValue={totalAchievedSavings ?? -1}
              percentageAchievedValue={percentageAchiviedValue ?? -1}
              infoMessage={
                'Total Achieved Savings include earned Rebates, Commercial, Prompt Pay, Promotional, and Free Freight discounts'
              }
            />
            </div>*/}
        </div>
      </Expandable>
    </div>
  );
};
