/** @format */

import { FC } from 'react';

export const Legend: FC = (): JSX.Element => {
  return (
    <div className='legend-box'>
      <h4>Year over year</h4>
      <div className='legend-items'>
        <div className='legend-item'>
          <div className='legend-color py' />
          <div className='legend-title'>PY (Previous Year)</div>
        </div>
        <div className='legend-item'>
          <div className='legend-color cy' />
          <div className='legend-title'>CY (Current Year)</div>
        </div>
      </div>
    </div>
  );
};
