/** @format */

import { FC, useState } from 'react';
import styled from '@emotion/styled';

import { mapColors } from '../../../theme/mapColors';
import { UITabTitle } from '../partials/TabTitle';
import { StandardBarChart } from '../../../shared/StandardBarChart';
import { TablePromptPay } from './partials/TablePromptPay';
import { BOX_TITLE_REBATES, BOX_TITLE_TOTAL_PORTFOLIO_REBATE_2 } from '../../../constants/general';
import { Expandable } from '../../../shared/Expandable';

//#region ::: PARTIALs

type ChartBoxProps = {
  hasData: boolean;
};

const ChartBox = styled.div<ChartBoxProps>(props => ({
  width: props.hasData ? '800px' : '100%',
  height: '307px',
}));

const Title = styled.p({
  fontSize: '16px',
  color: `${mapColors.fonts.fontPrimary}`,
  fontWeight: 'bold',
  display: 'flex',
  alignSelf: 'start',
  margin: '20px',
});

type ContainerPromptProps = {
  hasData: boolean;
};

const ContainerPrompt = styled.div<ContainerPromptProps>(props => ({
  display: props.hasData ? 'flex' : 'none',
  flexDirection: 'column',
  alignItems: 'start',
  width: '447px',
  height: '307px',
  backgroundColor: 'white',
  borderLeft: `1px solid ${mapColors.backgrounds.borderPrimary}`,
  borderRadius: '10px',
  borderTopLeftRadius: '0',
  borderBottomLeftRadius: '0',
}));

const TitlePrompt = styled.p({
  fontWeight: 'bold',
  fontSize: '16px',
  marginLeft: '20px',
});

//#endregion
type RebatesProps = {
  customer: any;
};

export const Rebates: FC<RebatesProps> = ({ customer = null }): JSX.Element => {
  const hasData = true;
  /*
    customer?.rebate_info?.prompt_pay?.ytd &&
    customer?.rebate_info?.prompt_pay?.qtd &&
    customer?.rebate_info?.prompt_pay?.mtd; */

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div id="rebates" className="data-panel rebates">
      <UITabTitle
        label={BOX_TITLE_REBATES}
        infoMessage={'For more information on individual earned rebates reach out to your Sales Consultant'}
        onClick={() => setIsExpanded(!isExpanded)}
        collapsed={isExpanded}
      />
      <Expandable expanded={isExpanded}>
        <div className='box'>
          <ChartBox hasData={hasData}>
            <Title>{BOX_TITLE_TOTAL_PORTFOLIO_REBATE_2}</Title>
            <StandardBarChart hasData={hasData} customer={customer} />
          </ChartBox>

          <ContainerPrompt hasData={hasData}>
            <TitlePrompt>Prompt Pay</TitlePrompt>
            <TablePromptPay customer={customer} />
          </ContainerPrompt>
        </div>
      </Expandable>
    </div>
  );
};

export const RebatesAsContent: FC<RebatesProps> = ({ customer = null }): JSX.Element => {
  const hasData = true;
  return (
    <>
      <div className='left-box' style={{ width: hasData ? '800px' : '100%' }}>
        <h3>Portfolio Rebate</h3>
        <StandardBarChart hasData={hasData} customer={customer} />
      </div>

      <div className='right-box' style={{ display: hasData ? 'flex' : 'none' }}>
        <h3>Prompt Pay</h3>
        <TablePromptPay customer={customer} />
      </div>
    </>
  );
};
