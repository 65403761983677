/** @format */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface State {
  session?: { first_name: string; id: number; jwt_token: string; last_name: string; refresh_token: string };
}

type Reducers = {
  login: (state: State, action: PayloadAction<any>) => State;
  logout: (state: State) => State;
};

export const sliceAuth = createSlice<State, Reducers>({
  name: 'auth',
  initialState: {},
  reducers: {
    login(state, action) {
      return { ...state, session: action.payload };
    },
    logout(state) {
      return { ...state, session: undefined };
    },
  },
});
