/** @format */

import { FC, useState } from 'react';
import { UIIcon } from './Icon';

import srcInfo from '../assets/icons/outline/InfoBlue.svg';

type InfoBoxProps = {
  message: string;
  mode?: string;
};

export const InfoBox: FC<InfoBoxProps> = ({ message, mode = 'normal' }): JSX.Element => {
  const [isTooltip, setIsTooltip] = useState(false);
  return (
    <div className='hover' onMouseLeave={() => setIsTooltip(false)} onMouseOver={() => setIsTooltip(true)}>
      <UIIcon name={'Info'} srcIcon={srcInfo} width={'12'} height={'12'} className="" />
      {isTooltip && <div className='hovertip' style={{ marginRight: mode === 'left' ? '140px' : '0' }}>{message}</div>}
    </div>
  );
};
