/** @format */

import { FC, useState } from 'react';
import { UITabTitle } from '../partials/TabTitle';
import { BOX_TITLE_VSDATA } from '../../../constants/general';
import { Expandable } from '../../../shared/Expandable';
import { BrandTrackingItem, Performances, SectionItem } from '../../../services/rebate/api';
import { RebateTitle } from '../../../shared/RebateTitle';
import { UIDateLabel } from '../../../shared/DateLabel';
import { GenericTableRebate } from '../../../shared/GenericTableRebate';
import { RebateViewBars } from '../../../shared/RebateViewBars';
import { RebateViewActiveBrands } from '../../../shared/RebateViewActiveBrands';

type VSDataProps = {
  customer: any;
  performances?: Performances | null;
  sections?: Array<SectionItem> | null;
  brandTrackingData?: Array<BrandTrackingItem> | null;
};

export const VSData: FC<VSDataProps> = ({ customer = null, performances = null, sections = null, brandTrackingData = null }): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(false);

  const tierData = performances?.sections.find((item) => item.section_name === 'Vision Source');

  /*
  function getTotalBox(data: Category) {
    if (data !== null && data?.type === 2 && data?.active_brands !== null && data?.bars !== null && data?.bars.length > 0) {
      return <div className='total-box'>
        <div className='total-title'>{data?.bars[0].rebate_header_label?.toUpperCase()}</div>
        <div className='total-value'>{numberFormat(data?.bars[0].rebate_header_value ?? 0.0)}</div>
      </div>;
    } else if (data !== null && data?.type === 1 && data?.bars !== null && data?.bars.length > 0) {
      if (data?.bars.length === 2) {
        if ((data?.bars[0].rebate_header_value ?? 0.0) > (data?.bars[1].rebate_header_value ?? 0.0)) {
          return <div className='total-box'>
            <div className='total-title'>{data?.bars[0].rebate_header_label?.toUpperCase()}</div>
            <div className='total-value'>{numberFormat(data?.bars[0].rebate_header_value ?? 0.0)}</div>
          </div>;
        } else {
          return <div className='total-box'>
            <div className='total-title'>{data?.bars[1].rebate_header_label?.toUpperCase()}</div>
            <div className='total-value'>{numberFormat(data?.bars[1].rebate_header_value ?? 0.0)}</div>
          </div>;
        }
      } else {
        return <div className='total-box'>
          <div className='total-title'>TOTAL TRENDING REBATE</div>
          <div className='total-value'>{numberFormat(0.0)}</div>
        </div>;
      }
    } else {
      return <RebateMaxValueViewSimple tiers={data?.rebate?.tiers ?? []} />;
    }
  }
  */

  const tierLayout = tierData?.types.map((element) => {
    const quarterTierData = element?.categories.filter((item) => item.period === 'Quarter') ?? [];
    const annualTierData = element?.categories.filter((item) => item.period === 'Annual') ?? [];
    const semesterTierData = element?.categories.filter((item) => item.period === 'Semester') ?? [];

    return (<>
      {quarterTierData !== null && quarterTierData.length > 0 && <div className='quarter'>
        <h3 className='rebate-title' style={{ borderTop: 'none' }}><RebateTitle data={quarterTierData} />
        </h3>
        <UIDateLabel
          label={
            customer
              ? customer?.years.qtr_range + ', ' + customer?.years.current_year
              : ''
          }
        />

        {/*getTotalBox(quarterTierData[0])*/}

        {quarterTierData[0]?.type === 1 &&
          <>
            <RebateViewBars
              bars={quarterTierData[0]?.bars ?? []} />
          </>
        }

        {quarterTierData[0] !== null && quarterTierData[0]?.type === 2 && quarterTierData[0]?.active_brands !== null &&
          <>
            <RebateViewActiveBrands
              brands={quarterTierData[0]?.active_brands ?? []}
              brandTrackingData={brandTrackingData ?? []}
              totalActiveBrands={quarterTierData[0]?.total_active_brands}
              opportunityBrands={quarterTierData[0]?.opportunity_brands} />
          </>
        }

        <div className='row nogutter' style={{ padding: '16px 0', marginBottom: '10px' }}>
          <div className='row horizontal'>
            <div className='col-md-6'>
              <GenericTableRebate items={quarterTierData[0]?.left_columns ?? []} />
              <div className='message'>* All rebate payouts are based on net purchases excluding Ray-Ban Sun, Ray-Ban Meta, Oakley Sun, Costa Sun, Chanel and Brunello Cucinelli</div>
            </div>
            <div className='col-md-6 vertical'>
              <GenericTableRebate items={quarterTierData[0]?.right_columns ?? []} />
            </div>
          </div>
          <div className='separator' />
        </div>
      </div>}

      {semesterTierData !== null && semesterTierData.length > 0 && <div className='semester'>
        <div className='row nogutter'>
          <h3 className='rebate-title'><RebateTitle data={semesterTierData} /></h3>
          <UIDateLabel
            label={
              customer
                ? customer?.years.sem_range + ', ' + customer?.years.current_year
                : ''
            }
          />

          {/*getTotalBox(semesterTierData[0])*/}

          {semesterTierData[0]?.type === 1 &&
            <>
              <RebateViewBars
                bars={semesterTierData[0]?.bars ?? []} />
            </>
          }

          {semesterTierData[0] !== null && semesterTierData[0]?.type === 2 && semesterTierData[0]?.active_brands !== null &&
            <>
              <RebateViewActiveBrands
                brands={semesterTierData[0]?.active_brands ?? []}
                brandTrackingData={brandTrackingData ?? []}
                totalActiveBrands={semesterTierData[0]?.total_active_brands}
                opportunityBrands={semesterTierData[0]?.opportunity_brands} />
            </>
          }
        </div>


        <div className='row nogutter semester' style={{ padding: '16px 0', marginBottom: '10px' }}>
          <div className='row horizontal'>
            <div className='col-md-6'>
              <GenericTableRebate items={semesterTierData[0]?.left_columns ?? []} />
            </div>
            <div className='col-md-6 vertical'>
              <GenericTableRebate items={semesterTierData[0]?.right_columns ?? []} />
            </div>
          </div>
        </div>
      </div>
      }

      {annualTierData !== null && annualTierData.length > 0 && <div className='annual'>
        <div className='row nogutter'>
          <h3 className='rebate-title'><RebateTitle data={annualTierData} /></h3>
          <UIDateLabel
            label={
              customer
                ? customer?.total_yearly_rebate?.yearly_dates + ', ' + customer?.total_yearly_rebate?.year
                : ''
            }
          />

          {/*getTotalBox(annualTierData[0])*/}

          {annualTierData[0]?.type === 1 &&
            <>
              <RebateViewBars
                bars={annualTierData[0]?.bars ?? []} />
            </>
          }

          {annualTierData[0] !== null && annualTierData[0]?.type === 2 && annualTierData[0]?.active_brands !== null &&
            <>
              <RebateViewActiveBrands
                brands={annualTierData[0]?.active_brands ?? []}
                brandTrackingData={brandTrackingData ?? []}
                totalActiveBrands={annualTierData[0]?.total_active_brands}
                opportunityBrands={annualTierData[0]?.opportunity_brands} />
            </>
          }
        </div>


        <div className='row nogutter annual' style={{ padding: '16px 0', marginBottom: '10px' }}>
          <div className='row horizontal'>
            <div className='col-md-6'>
              <GenericTableRebate items={annualTierData[0]?.left_columns ?? []} />
            </div>
            <div className='col-md-6 vertical'>
              <GenericTableRebate items={annualTierData[0]?.right_columns ?? []} />
              <div className='message'>* All rebate payouts are based on net purchases excluding Ray-Ban Sun, Ray-Ban Meta, Oakley Sun, Costa Sun, Chanel and Brunello Cucinelli</div>
            </div>
          </div>
          <div className='separator' />
        </div>
      </div>
      }
    </>);
  });

  return (
    <div id="vsdata" className='data-panel'>
      <UITabTitle
        label={BOX_TITLE_VSDATA}
        infoMessage="Vision Source Elite Program is exclusive to Vision Source members only, excluded from the Luxottica Rewards Program. For more information reach out to your Sales Consultant"
        onClick={() => setIsExpanded(!isExpanded)}
        collapsed={isExpanded}
      />
      <Expandable expanded={isExpanded} boxed={false}>
        {/*<div className='data-container container-fluid'>*/}
        <>
          {tierLayout}

          <div className='row nogutter' style={{ paddingBottom: '16px' }}>
            {sections?.map((section) => {
              return <div key={section.section} className='col-12' style={{ paddingRight: '16px', paddingLeft: '8px' }}>
                <div style={{ alignSelf: 'left' }}>
                  <h3>{section.section}</h3>
                  <GenericTableRebate items={section.section_children ?? []} />
                </div>
              </div>;
            })}
          </div>
          {/*</div>*/}
        </>
      </Expandable>
    </div >
  );
};
