/** @format */

import { FC } from 'react';
import { Category } from '../services/rebate/api';

interface Props {
    data: Category[]
}
export const RebateTitle: FC<Props> = ({
    data,
}): JSX.Element => {

    let name = '';
    // let period = '';

    if (data !== null && data !== undefined && data.length > 0) {
        name = data[0].rebate_name ?? '';
        // period = data[0].period ?? ''; // rebate.id_period === 0 ? 'Annual' : 'Quarterly';
    }

    // return <>{`${name} | ${period}`.trim()}</>;
    return <>{`${name}`.trim()}</>;
};