/** @format */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TCustomer {
  customer_id: number;
  customer_name: string;
  customer_code: string;
  alliance_code: string;
  alliance_name: string;
  customer_type_id: 1 | 2;
  customer_type_description: string;
  common_owner_code: string;
  main_account_code: string;
  address: string;
  state: string;
  email: string;
}

interface State {
  itemsCount: number;
  pageSize: number;
  results?: Array<TCustomer>;
  searchResult?: string;
}

type Reducers = {
  storeItemsCount: (state: State, action: PayloadAction<number>) => State;
  storePageSize: (state: State, action: PayloadAction<number>) => State;
  storeResults: (state: State, action: PayloadAction<Array<TCustomer>>) => State;
  storeInputSearch: (state: State, action: PayloadAction<string>) => State;
  deleteResults: (state: State) => State;
};

export const sliceResults = createSlice<State, Reducers>({
  name: 'results',
  initialState: {
    itemsCount: 0,
    pageSize: 0,
  },
  reducers: {
    storeItemsCount(state, action) {
      return { ...state, itemsCount: action.payload };
    },
    storePageSize(state, action) {
      return { ...state, pageSize: action.payload };
    },
    storeResults(state, action) {
      return { ...state, results: action.payload };
    },
    storeInputSearch(state, action) {
      return { ...state, searchResult: action.payload };
    },
    deleteResults() {
      return {
        itemsCount: 0,
        pageSize: 0,
      };
    },
  },
});
