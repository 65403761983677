import { Switch } from 'devextreme-react';
import { FC, useEffect, useState } from 'react';
import './DynamicMultiselectionStyle.scss';

interface DynamicMultiselectionProps {
    id: string;
    data: any;
    required?: boolean;
    defaultValues?:string[];
    onChangedSelection: (selection?: any) => void;
}

export const DynamicMultiselection: FC<DynamicMultiselectionProps> = ({
    data = null,
    id,
    defaultValues,
    onChangedSelection,
}): JSX.Element => {
    const [currentCategory, setCurrentCategory] = useState<string>();
    const [selectedBrandCodes, setSelectedBrandCodes] = useState<string[]>(defaultValues ?? []);
     
    useEffect(() => {
        if (defaultValues !== undefined) {
            setSelectedBrandCodes(defaultValues);

        }
    }, [defaultValues]);
    useEffect(() => {
        setCurrentCategory(data.map((object: any) => {
            return object.category;
        })[0]);

    }, [data]);

    function loadBrandCategories() {
        const categories = data.map((object: any) => {
            return object.category;
        });

        return categories.map(((cat: any) => <div className={`list--item ${currentCategory === cat ? 'list--item__selected' : ''}`}
            key={cat} onClick={() => {
                setCurrentCategory(cat);
            }} >
            {cat}
        </div>));
    }

    function loadBrandCodes() {
        if (currentCategory === undefined || currentCategory === null) {
            return [];
        }
        const brands = data.find((object: any) => object.category === currentCategory).brands;
        return brands.map(((brand: string) =>
            <div className={`collection--item ${selectedBrandCodes.some((object) => object === brand) ? 'collection--item__selected' : 'collection--item__unselected'}`}
                key={brand} onClick={() => {
                    if (selectedBrandCodes.some((object) => object === brand) === false) {
                        let tmpList = selectedBrandCodes.slice(0);
                        const element = tmpList?.find((item: string) => item === brand);
                        if (element !== null && element !== undefined) {
                            tmpList = tmpList?.filter((item: string) => item !== brand);
                        } else {
                            tmpList?.push(brand);
                        }
                        setSelectedBrandCodes(tmpList);
                        onChangedSelection(tmpList);
                    }
                }} >
                {brand}
            </div>),
        );
    }
    function selectAllBrandCategories(value: any) {
        let tmpList = selectedBrandCodes.slice(0);
        if (data === undefined || data === null) {
            return [];
        }
        data.forEach((element : any) => {
            if (value) {
                element.brands.forEach((element: string) => {
                    const item = tmpList?.find((item: string) => item === element);
                    if (item === null || item === undefined) {
                        tmpList?.push(element);
                    }
                });
            } else {
                element.brands.forEach((element: string) => {
                    const value = tmpList?.find((item: string) => item === element);
                    tmpList = tmpList?.filter((item: string) => item !== value);
                });
            }
        });
        setSelectedBrandCodes(tmpList);
        onChangedSelection(tmpList);
    }
    function checkSelectAllCategoriesCondition() {
        if (data === undefined || data === null) {
            return false;
        }
        let condition = true;
        data.forEach((element : any) => {
            element.brands.forEach((element: string) => {
                const item = selectedBrandCodes.find((item: string) => item === element);
                if (item === null || item === undefined) {
                    condition = false;
                }
            });
        });
        return condition;
    }
    function selectAllCategoryBrandCodes(value: any) {
        if (currentCategory === undefined || currentCategory === null) {
            return [];
        }

        const brands = data.find((object: any) => object.category === currentCategory).brands;
        
        let tmpList = selectedBrandCodes.slice(0);

        if (value) {
            brands.forEach((element: string) => {
                const item = tmpList?.find((item: string) => item === element);
                if (item === null || item === undefined) {
                    tmpList?.push(element);
                }
            });
        } else {
            brands.forEach((element: string) => {
                const value = tmpList?.find((item: string) => item === element);
                tmpList = tmpList?.filter((item: string) => item !== value);
            });
        }

        setSelectedBrandCodes(tmpList);
        onChangedSelection(tmpList);
    }

    function getSelectedBrandCodes() {
        const all: any[] = [];
        if (data.length === 0) {
            return;
        } 

        selectedBrandCodes.forEach((code: string) => {
            const _temp = data.find((object: any) => {
                return object.brands.some((item: string) => item === code);
            });
            
            if (_temp && _temp.category) {
                all.push({
                    category: _temp.category,
                    brand: code,
                });
            }
        });
        const result: any[] = [];
        const allCategories = Array.from(new Set(all.map(item => item.category)));
        allCategories.forEach((_cat: string) => {
            const filtered = all.filter((obj: any) => obj.category === _cat);
            result.push({
                category: _cat,
                brands: filtered.map((_item: any) => _item.brand),
            });
        });
        return result.map((item) =>
            <div className='multiselection--selections-box' key={item.category}>
                <div className='list--item'>{item.category}</div>
                <div className='collection hide-scrollbar'>
                    {item.brands.map((brand: string) =>
                        <div className='collection--item collection--item__categorized' key={brand} onClick={() => {
                            let tmpList = selectedBrandCodes.slice(0);
                            tmpList = tmpList?.filter((item: string) => item !== brand);
                            setSelectedBrandCodes(tmpList);
                            onChangedSelection(tmpList);
                        }} >
                            {brand}
                        </div>)}
                </div>

            </div>,
        );
    }

    function checkSelectAllCondition() {
        if (currentCategory === undefined || currentCategory === null) {
            return false;
        }
        const brands = data.find((object: any) => object.category === currentCategory).brands;
        let condition = true;
        brands.forEach((element: string) => {
            const item = selectedBrandCodes.find((item: string) => item === element);
            if (item === null || item === undefined) {
                condition = false;
            }
        });
        return condition;
    }

    return (
        <div id={id} className='multiselection hide-scrollbar'>
            <div className='multiselection--list box'>
                <label className='multiselection--title'> Brand Category</label>
                <div className='list hide-scrollbar'>
                    {loadBrandCategories()}
                  
                </div>
                <div className='multiselection--chips--bottom'>
                    <div className='multiselection--chips--text'>Select all</div>
                    <Switch
                        switchedOffText=''
                        switchedOnText=''
                        value = {checkSelectAllCategoriesCondition()}
                        onValueChange={(value) => selectAllBrandCategories(value)} />
                </div>
            </div>

            <div className='multiselection--chips box'>
                <label className='multiselection--title'> Brand Code</label>
                <div className='multiselection--chips-content hide-scrollbar'>
                    <div className='collection hide-scrollbar'>
                        {loadBrandCodes()}
                    </div>
                </div>
                <div className='multiselection--chips--bottom'>
                    <div className='multiselection--chips--text'>Select all</div>
                    <Switch
                        switchedOffText=''
                        switchedOnText=''
                        value={checkSelectAllCondition()}
                        onValueChange={(value) => {
                            selectAllCategoryBrandCodes(value);
                        }} />
                </div>
            </div>

            <div className='multiselection--selections box'>
                <label className='multiselection--title'> Selected Brands </label>
                <div className='multiselection--selections-content hide-scrollbar'>
                    {getSelectedBrandCodes()}
                </div>
            </div>
        </div>
    );
};
