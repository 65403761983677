/** @format */

import { FC, useState, useEffect } from 'react';
import { Chart, Series, Size, Legend, ArgumentAxis, ValueAxis, CommonAxisSettings, CommonSeriesSettings, Label } from 'devextreme-react/chart';
import { mapColors } from '../../../../theme/mapColors';
import { BrandStandardResponse } from './Performance_graph_service';
import { Selection, SelectionMode } from '../../../../shared/Selection';

interface LearnMoreProps {
    values: BrandStandardResponse[]
}

export const LearnMore: FC<LearnMoreProps> = ({ values = [] }): JSX.Element => {
    // See here: https://getbootstrap.com/docs/5.0/components/modal/
    values.forEach((element) => { 
        if (element.customer_code === 'All') {
            element.customer_code = 'All Customers';
        }
    });

    const [key, setKey] = useState<string>('');
    const [views, setViews] = useState<JSX.Element[]>([]);
    const [printableViews, setPrintableViews] = useState<JSX.Element[]>([]);

    function checkValue(val: number | null) {
        return val === undefined || val === null ? 'N/A' : val;
    }

    function _setUpChart(brandResponse: BrandStandardResponse) {
        const brandViews: JSX.Element[] = [];
        for (const value in brandResponse.brand_standard_list) {
            brandViews.push(<div key={brandResponse.brand_standard_list[value].brand_description}>
                <div className='learn_more--row'>
                    <div className='learn_more--content'>

                        <div className='learn_more--content-left'>
                            {brandResponse.brand_standard_list[value].brand_image !== null &&
                                <img src={'/brands/' + brandResponse.brand_standard_list[value].brand_image ?? ''} className='brandImage' />}
                            {brandResponse.brand_standard_list[value].brand_image === null &&
                                <img src={'/brands/NoImage.png'} className='brandImage' />}
                            <div className='central'>
                                <div className='title'>{brandResponse.brand_standard_list[value].brand_description}</div>
                                {/* <div className='label'>{ brandResponse.brand_standard_list[value].brand_description }</div> */}
                            </div>
                        </div>

                        <Chart id="chart" className='side_chart_learn_more' dataSource={
                            [
                                {
                                    day: 'unit',
                                    current: brandResponse.brand_standard_list[value].unit_current_year,
                                    previuos: brandResponse.brand_standard_list[value].unit_previous_year,
                                    dashboard: brandResponse.brand_standard_list[value].brand_standard,
                                },
                            ]
                        } rotated={true} width='80%' barGroupPadding={0.4}
                        >

                            <ArgumentAxis grid={{ color: mapColors.backgrounds.borderTertiary, visible: false }} visible={false} label={{ font: { color: mapColors.fonts.fontPrimary, weight: '600', size: '14px' }, visible: false }} />
                            <ValueAxis maxValueMargin={0.1} grid={
                                {
                                    color: mapColors.backgrounds.borderTertiary,
                                    visible: false,
                                }}
                                visible={false}
                                label={{
                                    font: { color: mapColors.fonts.fontPrimary, weight: '600', size: '14px' },
                                    visible: false,
                                }} />
                            <CommonAxisSettings
                                tick={{ visible: false }}
                                color={mapColors.backgrounds.borderSecondary}
                            />
                            <Size
                                height={114}
                            />
                            <Legend visible={false} />
                            
                            <Series
                                valueField="dashboard"
                                name={key}
                                color='#7888a5'
                                showInLegend={false}
                                cornerRadius={7.5}>
                                <Label
                                    visible={true}
                                    backgroundColor={'#ffffff'}
                                    font={{ color: '#031434', weight: 600, size: '12px' }}
                                    border={{ visible: true, width: 1, color: '#7888a5' }}
                                    format={{ type: 'fixedPoint', precision: 0 }}
                                    horizontalOffset={-18} />
                            </Series>
                            <Series
                                valueField="current"
                                name={key}
                                color={'#091f5a'}
                                showInLegend={false}
                                cornerRadius={7.5}>
                                <Label
                                    visible={true}
                                    backgroundColor={'#ffffff'}
                                    font={{ color: '#031434', weight: 600, size: '12px' }}
                                    border={{ visible: true, width: 1, color: '#091f5a' }}
                                    format={{ type: 'fixedPoint', precision: 0 }}
                                    horizontalOffset={-18} />
                            </Series>
                            <Series
                                valueField="previuos"
                                name={key}
                                color={'#bdcbfc'}
                                showInLegend={false}
                                cornerRadius={7.5}>
                                <Label
                                    visible={true}
                                    backgroundColor={'#ffffff'}
                                    font={{ color: '#031434', weight: 600, size: '12px' }}
                                    border={{ visible: true, width: 1, color: '#bdcbfc' }}
                                    format={{ type: 'fixedPoint', precision: 0 }}
                                    horizontalOffset={-18} />
                            </Series>
                            

                            <CommonSeriesSettings argumentField="day"
                                type="bar"
                                hoverMode="allArgumentPoints"
                                selectionMode="allArgumentPoints"
                                barWidth={15} />
                        </Chart>

                        <div className='counters'>
                            <div className='value'>{checkValue(brandResponse.brand_standard_list[value].unit_previous_year)} U</div>
                            <div className='value' style={{ marginBottom: '0px' }}>{checkValue(brandResponse.brand_standard_list[value].unit_current_year)} U</div>
                            <div className='value'>{checkValue(brandResponse.brand_standard_list[value].brand_standard)} U</div>
                        </div>
                    </div>
                </div>
            </div>);
        }
        return brandViews;
    }

    function _onChangeSelect(code: string) {
        setKey(code);
    }

    function compare(a: any, b: any) {
        if (a.customer_code === 'All Customers') {
            return 1;
        }
        if (b.customer_code === 'All Customers') {
            return 1;
        }
        if (a.customer_code < b.customer_code) {
            return -1;
        }
        if (a.customer_code > b.customer_code) {
            return 1;
        }
        return 0;
    }

    useEffect(() => {
        const selectedBrands = values.filter((customer => {
            return customer.customer_code === key;
        }));

        if (selectedBrands.length > 0) {
            setViews(_setUpChart(selectedBrands[0]));
        }
    }, [key]);

    useEffect(() => {
        if (values.length > 0) {
            values = values.sort(compare);
            const tempKey = values[0].customer_code;
            const tempSelectedBrands = values.filter((customer => {
                return customer.customer_code === tempKey;
            }));
            if (tempSelectedBrands.length > 0) {
                setPrintableViews(_setUpChart(tempSelectedBrands[0]));
            }
        }
    }, [values]);

    function _setData() {
        if (values.length > 0) {
            values = values.sort(compare);
            setKey(values[0].customer_code);
        }
    }

    return (
        <>
            <button className='performance__bottom_content--button' data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => _setData()}>Learn more</button>

            <div className="printable">
                <div className='learn_more--header'>
                    <div className="float-right">
                        {/*
                        <Selection<string>
                            id="_customer_"
                            name="_customer_"
                            disabled={false}
                            map={values.map((e) => {
                                return { code: e.customer_code };
                            })}
                            placeholder='Filter by customer...'
                            displayExpr='code'
                            valueExpr='code'
                            selectionMode={SelectionMode.single}
                            values={[key]}
                            onChangedSelection={(selection) => {
                                _onChangeSelect(selection !== undefined ? selection : 'All Customers');
                            }} />*/}
                    </div>
                    <div className='learn_more--legend'>
                        <div className="square" style={{ backgroundColor: '#bdcbfc' }} />
                        <div className='label'>Previous Year</div>
                        <div className="square" style={{ backgroundColor: '#091f5a' }} />
                        <div className='label'>Current Year</div>
                        <div className="square" style={{ backgroundColor: '#7888a5' }} />
                        <div className='label'>Brand Standard</div>
                    </div>
                </div>
                {printableViews}
            </div>

            <div className="modal fade modal-xl" id="staticBackdrop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-xl" style={{ width: '90%' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Brand Performance</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <div className='learn_more--header'>
                                <div className="float-right">
                                    <Selection<string>
                                        id="_customer_"
                                        name="_customer_"
                                        componentKey="_customer_"
                                        disabled={false}
                                        map={values.map((e) => {
                                            return { code: e.customer_code };
                                        })}
                                        placeholder='Filter by customer...'
                                        displayExpr='code'
                                        valueExpr='code'
                                        selectionMode={SelectionMode.single}
                                        values={[key]}
                                        onChangedSelection={(selection) => {
                                            _onChangeSelect(selection !== undefined ? selection : 'All Customers');
                                        }} />
                                </div>
                                <div className='learn_more--legend'>
                                    <div className="square" style={{ backgroundColor: '#bdcbfc' }} />
                                    <div className='label'>Previous Year</div>
                                    <div className="square" style={{ backgroundColor: '#091f5a' }} />
                                    <div className='label'>Current Year</div>
                                    <div className="square" style={{ backgroundColor: '#7888a5' }} />
                                    <div className='label'>Brand Standard</div>
                                </div>
                            </div>
                            {views}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
