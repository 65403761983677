/** @format */

import axios from 'axios';

export const apiCustomer = ({ customerId, isInternal }: { customerId: number; isInternal: boolean }) => {
  const bodyParameters = { customer_id: customerId, is_internal_user: isInternal };
  /* const dispatch = useDispatch(); */

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('jwtToken')}` },
  };
  return axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/masterdata`, bodyParameters, config);
};

export const apiCustomerRole = ({ customerId, token }: { customerId: number; token: string }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/users/${customerId}`, config);
};
