/** @format */

import { FC /* , useEffect, useState */, useEffect } from 'react';
import styled from '@emotion/styled';

import { SearchInput } from '../../shared/SearchInput';
import { UIIcon } from '../../shared/Icon';

//import srcLogo from '../../assets/icons/outline/Logo.svg';

import srcLogo from '../../assets/images/logo.png';

import { ROUTE_DASHBOARD } from '../../constants/general';
import { useNavigate } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
import { MainHeader } from '../../shared/MainHeader';

//#region ::: PARTIALS

export const Navbar = styled.div({
  display: 'grid',
  alignItems: 'center',
  position: 'fixed',
  justifyContent: 'end',
  height: '80px',
  width: '100%',
  zIndex: '999999999',
});

//#endregion

export const PageSearch: FC = (): JSX.Element => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [id, setId] = useState<any>(localStorage.getItem('id'));
  // const initialStateRole = JSON.parse(localStorage.getItem('role') as string);
  // const [/* role, */ setRole] = useState(initialStateRole);

  useEffect(() => {
    document.title = 'Search | Rewards Hub';
  }, []);

  function checkCustomer() {
    const currentCustomerId = localStorage.getItem('customerId');
    const currentRole = localStorage.getItem('role');
    if (currentCustomerId !== null && currentRole !== null && currentCustomerId !== 'null' && currentRole !== 'null')
      return true;
    return false;
  }

  useEffect(() => {
    const updateInfoElement = document.getElementById('update-info');
    if (updateInfoElement) updateInfoElement.style.display = 'none';

    if (checkCustomer()) {
      const currentCustomerId = localStorage.getItem('customerId');
      const currentCustomerTypeId = localStorage.getItem('typeId');
      navigate(`${ROUTE_DASHBOARD}/${currentCustomerId}/${currentCustomerTypeId}`, { replace: true });
    }
  }, []);

  return (
    <>
      <MainHeader
        showUser={true}
        enableLeftMenu={false}
        showNavigationBar={false}
        showPdfExportButton={false}
        showSearchBar={false}
        showSearchButton={false}
        customer={null} />

      <section className="main">
        <div className="container search">
          <Fade /* transitionDuration={500} */ >
            <UIIcon name={'Logo'} srcIcon={srcLogo} width={''} height={''} className="logo" />
          </Fade>

          <Fade /*transitionDuration={500} delay={500} */>
            <div className="claim-intro">
              The Brands your patients want, <span>with a profitability opportunity your practice needs!</span>
            </div>
          </Fade>
          
          <Fade /* transitionDuration={500} delay={1000} */>
            <div className="claim">Start Earning Today!</div>
          </Fade>

          <div className='box'>
            <SearchInput isSerp={true} />
          </div>
        </div>
      </section>
    </>
  );
};
