/** @format */

import { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { mapColors } from '../../../theme/mapColors';
import { UITabTitle } from '../partials/TabTitle';
import { DoubleBulletBar } from '../../../shared/DoubleBulletBar';

import { LabelGPN } from './partials/Label';
import { TableGPN } from './partials/TableGPN';
import { LegendGPN } from './partials/LegendGPN';
import { BOX_TITLE_GPN_SALES } from '../../../constants/general';
import { Expandable } from '../../../shared/Expandable';

//#region ::: PARTIALs

/*
const Box = styled.div({
  width: '100%',
  height: 'auto',
  backgroundColor: mapColors.backgrounds.borderTertiary,
  borderBottomLeftRadius: '10px',
  borderBottomRightRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  border: `1px solid ${mapColors.backgrounds.borderPrimary}`,
  borderCollapse: 'collapse',
});
*/

const Content = styled.div({
  width: '97%',
  border: `1px solid ${mapColors.backgrounds.borderPrimary}`,
  height: 'auto',
  backgroundColor: 'white',
  margin: '16px',
  display: 'flex',
  alignItems: 'start',
  borderRadius: '10px',
});

const Hr = styled.hr({
  margin: '10px',
  display: 'flex',
  width: '350px',
  alignSelf: 'center',
  border: `1px solid ${mapColors.backgrounds.borderPrimary}`,
});

const ChartBox = styled.div({
  width: '400px',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
});

const Title = styled.p({
  fontSize: '16px',
  color: `${mapColors.fonts.fontPrimary}`,
  fontWeight: '600',
  display: 'flex',
  alignSelf: 'start',
  margin: '20px',
  marginBottom: '6px',
});

const ContainerPrompt = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  width: 'auto',
  height: 'auto',
  backgroundColor: 'white',
  borderLeft: `1px solid ${mapColors.backgrounds.borderPrimary}`,
  borderRadius: '10px',
  borderTopLeftRadius: '0',
  borderBottomLeftRadius: '0',
});

const Row = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '16px',
});

//#endregion
type GPNSalesProps = {
  customer: any;
};

export const GPNSales: FC<GPNSalesProps> = ({ customer = null }): JSX.Element => {
  const [filteredManufacturers, setFilteredManufacturers] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);

  const monthLabels = [
    '',
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const periods = customer ? customer?.gpn_info.gpn_manufactures_periods : [];
  const months = periods.map((el: any, index: number) => (
    <option key={index} value={el.month + '-' + el.year}>
      {monthLabels[el.month] + ' ' + el.year}
    </option>
  ));

  const gpnManufactures = customer ? customer?.gpn_info.gpn_manufactures : [];

  const data = gpnManufactures
    .filter((item: any) => {
      return item.month === periods[0].month && item.year === periods[0].year;
    })
    .map((a: any) => ({
      name: a.name,
      units: a.unit,
      perc_mix: a.perc_mix,
      level: a.level,
      group: a.group_id,
    }));

  useEffect(() => {
    setFilteredManufacturers(data);
  }, [customer]);

  const handleMonthChange = (event: any) => {
    const selectedMonth = event.target.value.split('-')[0];
    const selectedYear = event.target.value.split('-')[1];
    const temp = gpnManufactures
      .filter((item: any) => {
        return item.month === parseInt(selectedMonth, 10) && item.year === parseInt(selectedYear, 10);
      })
      .map((a: any) => ({
        name: a.name,
        units: a.unit,
        perc_mix: a.perc_mix,
        level: a.level,
        group: a.group_id,
      }));
    setFilteredManufacturers(temp);
  };

  return (
    <div id="gpn-sales" className="data-panel">
      <UITabTitle
        label={BOX_TITLE_GPN_SALES}
        infoMessage={''}
        onClick={() => setIsExpanded(!isExpanded)}
        collapsed={isExpanded}
      />
      <Expandable expanded={isExpanded}>
        <Content>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                height: '430px',
                // borderBottom: `1px solid ${mapColors.backgrounds.borderPrimary}`,
              }}
            >
              <ChartBox>
                <Title>Years to date</Title>
                <LabelGPN name={'Sales'} />
                <DoubleBulletBar
                  label="Sales"
                  decimal={false}
                  py={customer ? customer.gpn_info?.gpn_sales_units[0]?.gpn_sales : 0}
                  cy={customer ? customer.gpn_info?.gpn_sales_units[1]?.gpn_sales : 0}
                />
                <LegendGPN />
                <Hr />
                <LabelGPN name={'Units'} />
                <DoubleBulletBar
                  label="Units"
                  decimal={true}
                  py={customer ? customer.gpn_info?.gpn_sales_units[0]?.gpn_units : 0}
                  cy={customer ? customer.gpn_info?.gpn_sales_units[1]?.gpn_units : 0}
                />
                <LegendGPN />
              </ChartBox>

              <ContainerPrompt>
                <Row>
                  <Title>Manufactures</Title>
                  <select
                    onChange={handleMonthChange}
                    style={{
                      height: '24px',
                      width: '130px',
                      border: `1px solid ${mapColors.backgrounds.borderPrimary}`,
                      borderRadius: '8px',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      marginTop: '10px',
                      color: mapColors.fonts.fontTertiary,
                    }}
                  >
                    {months}
                  </select>
                </Row>
                <div style={{ marginLeft: '23px', overflowY: 'scroll' }}>
                  <TableGPN list={filteredManufacturers} />
                </div>
              </ContainerPrompt>
            </div>

            {/* <TableFrameShare /> */}
          </div>
        </Content>
      </Expandable>
    </div>
  );
};
