
import { FC, useState } from 'react';
import './Performance_graph.scss';


interface PerformanceFilterProps  {
    values: string[]
    didPressButtonIndex: (index: number) => void
}


export const PerformanceFilter: FC<PerformanceFilterProps> = ({
    values = [],
    didPressButtonIndex = () => {},
}): JSX.Element => {
    const [selectedIndex, setselectedIndex] = useState(0);

    const buttons = [];
    if (values !== null) {
        for (let i = 0; i < values.length; i++) {
            buttons.push(
                <div onClick={() => {
                    setselectedIndex(i);
                    didPressButtonIndex(i);
                }}
                key={i.toString()}
                className={`filter--button ${selectedIndex === i ? 'filter--button--selected' : '' }`}>
                    <span className={`filter--button--text ${selectedIndex === i ? 'filter--button--text--selected' : '' }`} key={i}>{values[i]}</span>
                </div>,
            );
        }
    }
    return (
        <div className='filter'>
            { buttons }
        </div>
    );
};
        

