import { NumberBox, TextBox, Validator } from 'devextreme-react';
import { NumericRule } from 'devextreme-react/data-grid';
import { EmailRule, RequiredRule } from 'devextreme-react/validator';
//import { TextBoxType } from 'devextreme/ui/text_box';
import { FC } from 'react';


interface TextAreaInputProps {
    id?: string | undefined;
    name?: string | undefined;
    defaultValue?: any;
    placeholder?: string;
    type?: 'text' | 'email' | 'password' | 'search' | 'tel' | 'url' | undefined;
    required?: boolean;
    onlyNumbers?: boolean;
    format?: string;
    min?: number,
    max?: number,
    isValid?: boolean,
    disable?: boolean,
    onChangedSelection: (value: any) => void,
}

export const TextAreaInput: FC<TextAreaInputProps> = ({
    id,
    name,
    defaultValue = null,
    placeholder = '',
    required = false,
    type = 'text',
    format = '#',
    onlyNumbers = false,
    min = 0,
    max = undefined,
    isValid = true,
    disable = false,
    onChangedSelection = () => { },
}): JSX.Element => {

    function addValidators() {
        return <Validator>
            {required ? <RequiredRule message='Field is required' /> : <div />}
            {type === 'email' ? <EmailRule message='Email is invalid' /> : <div />}
            {onlyNumbers ? <NumericRule message='only numbers' /> : <div />}
        </Validator>;
    }

    function buildTexBox() {
        return <TextBox
            validationErrors={['required!']}
            id={id}
            name={name}
            disabled = {disable}
            value={defaultValue ?? ''}
            mode={type}
            placeholder={placeholder}
            valueChangeEvent="input"
            isValid={isValid}
            onValueChange={onChangedSelection}
        >
            {addValidators()}
        </TextBox>;
    }

    function buildNumberBox() {
        return <NumberBox
            id={id}
            name={name}
            min={min}
            max={max}
            disabled = {disable}
            value={defaultValue ?? 0}
            format={format}
            placeholder={placeholder}
            valueChangeEvent="input"
            onValueChange={(value : number) => {
                return onChangedSelection(value);
            } }
        >
            {addValidators()}
        </NumberBox>;
    }
    return (onlyNumbers ? buildNumberBox() : buildTexBox());

};
