/** @format */

import { FC, useState } from 'react';
import { UITabTitle } from '../partials/TabTitle';
import { UIDateLabel } from '../../../shared/DateLabel';
import { BOX_TITLE_TOTAL_PORTFOLIO_REBATE, CQTD, CSTD, CYTD, NOTE_YTD_RETURN_RATE } from '../../../constants/general';
import { Expandable } from '../../../shared/Expandable';
import { Performances } from '../../../services/rebate/api';
import { RebateTitle } from '../../../shared/RebateTitle';
import { GenericTableRebate } from '../../../shared/GenericTableRebate';
import { RebateMaxValueViewNonVS } from '../../../shared/RebateMaxValueViewNonVS';
import { RebateViewNonVS } from '../../../shared/RebateViewNonVS';

//#endregion

type TotalPortFolioRebateProps = {
  customer: any;
  performances: Performances | null,
  dateInTitle?: boolean;
};

export const TotalPortFolioRebate: FC<TotalPortFolioRebateProps> = ({
  customer = null,
  performances = null,
  dateInTitle = false,
}): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(false);
  // const oldId = customer ? customer.customer_data.customer_status_level.level_status_id : 0;

  const tierData = performances?.sections.find((item) => item.section_name === 'Luxottica Rewards Rebates');
  const annualTierData = tierData?.types[0].categories.filter((item) => item.period === 'Annual') ?? [];
  const quarterTierData = tierData?.types[0].categories.filter((item) => item.period === 'Quarterly') ?? [];
  const semesterTierData = tierData?.types[0].categories.filter((item) => item.period === 'Semester') ?? [];

  function semesterMessage() {
    return semesterTierData[0]?.rebate?.message ?? '';
  }

  function quarterMessage() {
    return quarterTierData[0]?.rebate?.message ?? '';
  }

  function annualMessage() {
    return annualTierData[0]?.rebate?.message ?? '';
  }
  
  return (
    <div id="total-portfolio-rebates" className='data-panel rebates'>
      <UITabTitle
        label={BOX_TITLE_TOTAL_PORTFOLIO_REBATE}
        infoMessage={'Total Portfolio performance and Rebate includes all Luxottica Brands'}
        onClick={() => setIsExpanded(!isExpanded)}
        collapsed={isExpanded}
      >
        {dateInTitle ? (
          <UIDateLabel
            label={
              customer ? customer?.total_yearly_rebate?.yearly_dates + ', ' + customer?.total_yearly_rebate?.year : ''
            }
          />
        ) : (
          <></>
        )}
      </UITabTitle>
      <Expandable expanded={isExpanded}>
        <div className='box'>
          {quarterTierData !== null && quarterTierData.length > 0 && <>
            {quarterMessage() !== '' && <div className={'catch-up-message'}>{quarterMessage()}</div>}

            <h3><RebateTitle data={quarterTierData} /></h3>

            {!dateInTitle && (
              
                <UIDateLabel
                  label={
                    customer
                      ? customer?.years.qtr_range + ', ' + customer?.years.current_year
                      : ''
                  }
                />
              
            )}

            <RebateMaxValueViewNonVS tiers={quarterTierData[0]?.rebate?.tiers ?? []} />

            <RebateViewNonVS
              tiers={quarterTierData[0]?.rebate?.tiers ?? []}
              label={CQTD}
              aup={customer?.total_yearly_rebate.cytd_aup ?? 0} />

            <div className='row horizontal'>
              <div className='col-md-6'>
                <GenericTableRebate items={quarterTierData[0]?.left_columns ?? []} />
              </div>
              <div className='col-md-6 vertical'>
                <GenericTableRebate items={quarterTierData[0]?.right_columns ?? []} />
                <div className='message'>{NOTE_YTD_RETURN_RATE}</div>
              </div>
            </div>

            <div className='separator' />
          </>}

          {semesterTierData !== null && semesterTierData.length > 0 && <>
            {semesterMessage() !== '' && <div className={'catch-up-message'}>{semesterMessage()}</div>}
            <h3><RebateTitle data={semesterTierData} /></h3>

            {!dateInTitle && (
              
                <UIDateLabel
                  label={
                    customer
                      ? customer?.years.sem_range + ', ' + customer?.years.current_year
                      : ''
                  }
                />
              
            )}

            <RebateMaxValueViewNonVS tiers={semesterTierData[0]?.rebate?.tiers ?? []} />

            <RebateViewNonVS
              tiers={semesterTierData[0]?.rebate?.tiers ?? []}
              label={CSTD}
              aup={customer?.total_yearly_rebate.cytd_aup ?? 0} />

            <div className='row horizontal'>
              <div className='col-md-6'>
                <GenericTableRebate items={semesterTierData[0]?.left_columns ?? []} />
              </div>
              <div className='col-md-6 vertical'>
                <GenericTableRebate items={semesterTierData[0]?.right_columns ?? []} />
                <div className='message'>{NOTE_YTD_RETURN_RATE}</div>
              </div>
            </div>

            <div className='separator' />
          </>}

          {annualTierData !== null && annualTierData.length > 0 &&
            <>
            {annualMessage() !== '' && <div className={'catch-up-message'}>{annualMessage()}</div>}
              <h3><RebateTitle data={annualTierData} /></h3>

              {!dateInTitle && (
                
                  <UIDateLabel
                    label={
                      customer
                        ? customer?.total_yearly_rebate?.yearly_dates + ', ' + customer?.total_yearly_rebate?.year
                        : ''
                    }
                  />
                
              )}

              <RebateMaxValueViewNonVS tiers={annualTierData[0]?.rebate?.tiers ?? []} />

              {annualTierData !== null && <>
                <RebateViewNonVS
                  tiers={annualTierData[0]?.rebate?.tiers ?? []}
                  label={CYTD}
                  aup={customer?.total_yearly_rebate.cytd_aup ?? 0} />
              </>}

              <div className='row horizontal'>
                <div className='col-md-6'>
                  <GenericTableRebate items={annualTierData[0]?.left_columns ?? []} />
                </div>
                <div className='col-md-6 vertical'>
                  <GenericTableRebate items={annualTierData[0]?.right_columns ?? []} />
                  <div className='message'>{NOTE_YTD_RETURN_RATE}</div>
                </div>
              </div>
            </>
          }

          <div className='separator' />
        </div>
      </Expandable>
    </div >
  );
};
