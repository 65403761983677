/** @format */

import { FC } from 'react';

interface Props {
  id?: string | undefined;
  onClick: () => void;
  text: string;
  target: string;
  disabled: boolean;
}

export const AddButtonDialog: FC<Props> = ({ id, onClick, text, target, disabled }): JSX.Element => {
  return (
    <div className='add'>
      <button
        id={id}
        type="button"
        className={'add ' + (disabled ? 'disabled' : '')}
        data-bs-toggle="modal"
        data-bs-target={target}
        onClick={() => onClick()}>
        {text}
      </button>
    </div>
  );
};
