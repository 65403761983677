/** @format */

export const mapColors = {
  backgrounds: {
    body: '#F2F5FC',
    bgTable: '#06152b',
    bgDisabled: '#F1F4F9',
    bgIcons: '#DEE6F7',
    borderPrimary: '#DCE0EB',
    borderSecondary: '#ECEFF6',
    borderTertiary: '#F8FAFF',
  },
  fonts: {
    fontPrimary: '#2E2F32',
    fontSecondary: '#06152b',
    fontTertiary: '#8792B1',
  },
  theme: {
    primary: [' #0074FF', '#99c7ff', '#CCE3FF', '#EEF6FF'],
    danger: ['#EA3A3D', '#F49698', '#F8C1C2', '#FCE5E5'],
    success: ['#1AD598', '#6EEDC4', '#CFF9EB', '#E8FDF6'],
    warning: ['#F9B959', '#FBD090', '#FDE3BD', '#FEF1DE'],
    yellow: ['#FFEA9D', '#FFF2C4', '#FFF7D8', '#FFFBEB'],
    redPale: ['#FF546C', '#FFD1D8'],
    greenClear: ['#4FDDD4', '#DCF8F6'],
    blueLight: ['#00ECFF', ' #BBFAFF'],
    blue: '#004699',
    violet: '#6665DD',
    lilac: '#C760E0',
    orange: '#FF715B',
  },
};
