/** @format */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface State {
  brand?: {
    brand_id: number;
    brand_name: string;
    brand_yearly_growth_missing: number;
    brand_yearly_growth_sale: number;
    discount: boolean;
    brand_growth_target: [
      {
        box_label: string;
        brand_id: number;
        customer_id: number;
        missing: number;
        quarter: number;
        sale: number;
        year: number;
      },
    ];
    brand_quarter_rebates: [
      {
        brand_id: number;
        cusomer_id: number;
        cy_quarter_td: number;
        estimated_units_from_target: number;
        from_target: number;
        growth_target: number;
        potential_rebate: string;
        py_quarter: number;
        py_quarter_td: number;
        quarter: number;
        quarter_dates: string;
        quarter_td_growth: number;
        quarter_td_return_rate: number;
        quarter_td_return_rate_needed: number;
        quarter_td_ytd_growth: number;
        quarterly_rebate_eligible: string;
        rebate: number;
        target: number;
        total_potential_rebate: number;
        trending_rebate: number;
        year: number;
      },
    ];
    brand_sales: [
      {
        brand_id: number;
        cusomer_id: number;
        quarter: number;
        sales: number;
        year: number;
      },
      {
        brand_id: number;
        cusomer_id: number;
        quarter: number;
        sales: number;
        year: number;
      },
      {
        brand_id: number;
        cusomer_id: number;
        quarter: number;
        sales: number;
        year: number;
      },
      {
        brand_id: number;
        cusomer_id: number;
        quarter: number;
        sales: number;
        year: number;
      },
      {
        brand_id: number;
        cusomer_id: number;
        quarter: number;
        sales: number;
        year: number;
      },
      {
        brand_id: number;
        cusomer_id: number;
        quarter: number;
        sales: number;
        year: number;
      },
      {
        brand_id: number;
        cusomer_id: number;
        quarter: number;
        sales: number;
        year: number;
      },
      {
        brand_id: number;
        cusomer_id: number;
        quarter: number;
        sales: number;
        year: number;
      },
      {
        brand_id: number;
        cusomer_id: number;
        quarter: number;
        sales: number;
        year: number;
      },
    ];
    brand_sales_aup: [
      {
        AUP: number;
        brand_id: number;
        cusomer_id: number;
      },
    ];
    brand_lens_club_info: [
      {
        box_label1: string;
        box_label2: string;
        box_label3: string;
        box_perc1: number;
        box_perc2: number;
        box_perc3: number;
        box_value1: string;
        box_value2: string;
        box_value3: string;
        brand_id: number;
        cusomer_id: number;
      },
    ];
    brand_yearly_rebates: [
      {
        annual_rebate_status: string;
        brand_id: number;
        cusomer_id: number;
        cytd: number;
        cytd_aup: number;
        cytd_growth: number;
        estimated_units_from_target: number;
        fpy: string;
        from_target: number;
        growth_target: number;
        potential_target: number;
        pytd: number;
        rebate: number;
        target: number;
        total_potential_rebate: number;
        potential_rebate: string;
        trending_rebate: number;
        year: number;
        yearly_dates: string;
        ytd_return_rate: number;
        ytd_return_rate_need: number;
        ytd_units: number;
      },
    ];
  };
}

type Reducers = {
  createBrand: (state: State, action: PayloadAction<any>) => State;
  deleteBrand: (state: State) => State;
};

export const sliceBrand = createSlice<State, Reducers>({
  name: 'brand',
  initialState: {},
  reducers: {
    createBrand(state, action) {
      return { ...state, brand: action.payload };
    },
    deleteBrand() {
      return {};
    },
  },
});
