/** @format */

import axios from 'axios';

export const apiBrandDetails = ({
  customerId,
  brandId,
  allianceCode,
  currentYear,
  previousYear,
}: {
  customerId: number;
  brandId: number;
  allianceCode?: string;
  currentYear?: number;
  previousYear?: number;
}) => {
  const bodyParameters = {
    customer_id: customerId,
    brand_id: brandId,
    alliance_code: allianceCode,
    current_year: currentYear,
    previous_year: previousYear,
  };
  /* const dispatch = useDispatch(); */
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('jwtToken')}` },
  };
  return axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/brand/details`, bodyParameters, config);
};
