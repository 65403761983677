/** @format */

import { useEffect, useState } from 'react';
import { TRACKING_CHECK } from '../constants/general';
import { appInsights } from './AppInsights';

function CookieBanner() {
    const [showBanner, setShowBanner] = useState(false);
    const isCustomer = localStorage.getItem('customerId') !== null && localStorage.getItem('customerId') !== 'null';
    const userRole = localStorage.getItem('role');
    
    function enableTracking() {
        const userId = localStorage.getItem('id');
        const luxId = localStorage.getItem('luxId');
        appInsights.context.user.clearAuthenticatedUserContext();
        appInsights.context.user.setAuthenticatedUserContext(`${luxId} - ${userRole}`, userId ?? '', false);
    }

    function disableTracking() {
        appInsights.context.user.clearAuthenticatedUserContext();
        appInsights.context.user.setAuthenticatedUserContext(`${userRole}`, '', false);
    }

    const acceptCookies = () => {
        const currentTimestamp = Date.now();
        localStorage.setItem('tracking', currentTimestamp.toString());
        enableTracking();
        setShowBanner(false);
    };

    const declineCookies = () => {
        const currentTimestamp = -1 * Date.now();
        localStorage.setItem('tracking', currentTimestamp.toString());
        disableTracking();
        setShowBanner(false);
    };
    
    useEffect(() => {
        if (isCustomer) {
            disableTracking();
            const lastTracking = localStorage.getItem('tracking');
            if (lastTracking !== null) {
                const timestamp = parseInt(lastTracking, 10);
                const ask = (Date.now() - TRACKING_CHECK) > Math.abs(timestamp);
                if (ask) {
                    setShowBanner(true);
                } else {
                    if (timestamp > 0) {
                        enableTracking();
                    } else {
                        disableTracking();
                    }
                }
            } else {
                setShowBanner(true);
            }
        } else {
            setShowBanner(false);
            enableTracking();
        }
    }, []);

    if (
        location.pathname !== '/' &&
        location.pathname !== '/login') {
        return (
            <div>
                {showBanner && ( // new
                    <div id="cookie-banner" className="container-fluid">
                        We use necessary cookies and similar technologies to ensure our site works efficiently. We&#x27;d also like to set optional cookies and similar technologies for analytics to help us improve our activities. If you click &#x22;refuse&#x22;, we will activate only the necessary cookies that enable you to browse and use the website. Visit our <a href='https://media-my.essilorluxottica.com/cms/caas/v1/media/3006526/data/download/6cfd3739a0030ad41919d721b6a42153.pdf' target='_blank' rel="noreferrer">cookie policy</a> to learn more.
                        <div className='buttons'>
                            <button type="button" onClick={declineCookies}>
                                Refuse
                            </button>
                            <button type="button" className='accept' onClick={acceptCookies}>
                                Accept
                            </button>
                        </div>
                    </div>
                )
                }
            </div >
        );
    } else {
        return <></>;
    }
}

export default CookieBanner;