/** @format */

import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import download from 'downloadjs';
import { UIIcon } from './Icon';

import srcExcel from '../assets/icons/solid/Excel.svg';
import { apiRefresh } from '../services/auth/api';
import { loaderSlice } from '../services/loader/slice';
import { useAppDispatch } from '../hooks/redux';
import { ROUTE_HOME } from '../constants/general';
import { clearStorage } from '../utils/localStorageUtils';

interface Props {
  customerId: string;
  url: string;
}

export const ButtonExport: FC<Props> = ({ url, customerId }): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  function downloadExport(event: any, exportUrl: string, exportCustomerId: string, retry = true) {
    event.stopPropagation();

    const token = localStorage.getItem('jwtToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const customerID = parseInt(exportCustomerId, 10);

    let filename = '';
    let mimeType = '';

    dispatch(loaderSlice.actions.startLoading());

    fetch(exportUrl, {
      method: 'POST',
      body: JSON.stringify({
        customer_id: customerID,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(function (resp) {
        if (resp.status === 200) {
          if (resp.headers.has('content-type')) {
            mimeType = resp.headers.get('content-type') ?? '';
          }
          if (resp.headers.has('content-disposition')) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(resp.headers.get('content-disposition') ?? '');
            if (matches !== null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '');
            }
          }
          return resp.blob();
        } else if (resp.status === 401 && refreshToken) {
          apiRefresh({ refreshToken })
            .then(response => {
              if (response.data?.data?.jwt_token && response.data?.data?.refresh_token) {
                localStorage.setItem('jwtToken', response.data.data.jwt_token);
                localStorage.setItem('refreshToken', response.data.data.refresh_token);
                if (retry) {
                  downloadExport(event, url, customerId, false);
                }
              }
            })
            .catch(() => {
              clearStorage();
              // loginWithRedirect();
              navigate(`${ROUTE_HOME}`, { replace: true });
            });
        }
        return null;
      })
      .then(function (blob) {
        dispatch(loaderSlice.actions.stopLoading());
        if (blob !== null) {
          if (filename !== '' && mimeType !== '') {
            return download(blob, filename, mimeType);
          }
          return download(blob);
        }
      });
  }

  return (
    <button className="export" onClick={event => downloadExport(event, url, customerId)}>
      <UIIcon name={'excel'} srcIcon={srcExcel} width={'20'} height={'20'} className="" />
      <span>Export XLS</span>
    </button>
  );
};
