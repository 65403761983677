/** @format */

import { FC } from 'react';
import { ScalingInfo } from '../../../../../../shared/ScalingInfo';
import { numberFormat, percentageFormat } from '../../../../../../utils/numberUtils';
import { CY, PY } from '../../../../../../constants/general';

type GridProps = {
  brand: any;
};

export const Grid: FC<GridProps> = ({ brand = null }): JSX.Element => {
  let customers: any[] = [];
  if (brand?.brand_sales) {
    customers = [
      {
        title: 'PY',
        tableTitle: PY,
        Q1: 'Q1 ' + numberFormat(brand?.brand_sales[0]?.sales),
        Q2: 'Q2 ' + numberFormat(brand?.brand_sales[1]?.sales),
        Q3: 'Q3 ' + numberFormat(brand?.brand_sales[2]?.sales),
        Q4: 'Q4 ' + numberFormat(brand?.brand_sales[3]?.sales),
      },
      {
        title: 'CY',
        tableTitle: CY,
        Q1: 'Q1 ' + numberFormat(brand?.brand_sales[4]?.sales),
        Q2: 'Q2 ' + numberFormat(brand?.brand_sales[5]?.sales),
        Q3: 'Q3 ' + numberFormat(brand?.brand_sales[6]?.sales),
        Q4: 'Q4 ' + numberFormat(brand?.brand_sales[7]?.sales),
      },
    ];
  }

  const diff1 = brand?.brand_sales ? brand?.brand_sales[0]?.sales - brand?.brand_sales[4]?.sales < 0 : false;
  const diff2 = brand?.brand_sales ? brand?.brand_sales[1]?.sales - brand?.brand_sales[5]?.sales < 0 : false;
  const diff3 = brand?.brand_sales ? brand?.brand_sales[2]?.sales - brand?.brand_sales[6]?.sales < 0 : false;
  const diff4 = brand?.brand_sales ? brand?.brand_sales[3]?.sales - brand?.brand_sales[7]?.sales < 0 : false;

  const diff1Value = Math.abs(
    brand?.brand_sales[0]?.sales === 0 && brand?.brand_sales[4]?.sales === 0
      ? 0
      : brand?.brand_sales
        ? brand?.brand_sales[0]?.sales === 0
          ? 100
          : ((brand?.brand_sales[0]?.sales - brand?.brand_sales[4]?.sales) * 100.0) / brand?.brand_sales[0]?.sales
        : 0,
  );
  const diff2Value = Math.abs(
    brand?.brand_sales[1]?.sales === 0 && brand?.brand_sales[5]?.sales === 0
      ? 0
      : brand?.brand_sales
        ? brand?.brand_sales[1]?.sales === 0
          ? 100
          : ((brand?.brand_sales[1]?.sales - brand?.brand_sales[5]?.sales) * 100.0) / brand?.brand_sales[1]?.sales
        : 0,
  );
  const diff3Value = Math.abs(
    brand?.brand_sales[2]?.sales === 0 && brand?.brand_sales[6]?.sales === 0
      ? 0
      : brand?.brand_sales
        ? brand?.brand_sales[2]?.sales === 0
          ? 100
          : ((brand?.brand_sales[2]?.sales - brand?.brand_sales[6]?.sales) * 100.0) / brand?.brand_sales[2]?.sales
        : 0,
  );
  const diff4Value = Math.abs(
    brand?.brand_sales[3]?.sales === 0 && brand?.brand_sales[7]?.sales === 0
      ? 0
      : brand?.brand_sales
        ? brand?.brand_sales[3]?.sales === 0
          ? 100
          : ((brand?.brand_sales[3]?.sales - brand?.brand_sales[7]?.sales) * 100.0) / brand?.brand_sales[3]?.sales
        : 0,
  );

  const table = customers.map((el, index) => (
    <tr key={`${el.title}-${index}`}>
      <th>{el.tableTitle}</th>
      <td>{el.Q1}</td>
      <td>{el.Q2}</td>
      <td>{el.Q3}</td>
      <td>{el.Q4}</td>
    </tr>
  ));

  return (
    <table className='simple-table' style={{ width: '96%', margin: '24px 2%' }}>
      <tbody>
        {brand?.brand_sales && brand?.brand_sales.length > 0 ? (
          <>
            {table}
            <tr>
              <th>Difference</th>
              <td>
                <div className='scaling-box'>
                  <ScalingInfo isScaling={diff1} isInfo={false} value={percentageFormat(diff1Value, true, 1)} />
                </div>
              </td>
              <td>
                <div className='scaling-box'>
                  <ScalingInfo isScaling={diff2} isInfo={false} value={percentageFormat(diff2Value, true, 1)} />
                </div>
              </td>
              <td>
                <div className='scaling-box'>
                  <ScalingInfo isScaling={diff3} isInfo={false} value={percentageFormat(diff3Value, true, 1)} />
                </div>
              </td>
              <td>
                <div className='scaling-box'>
                  <ScalingInfo isScaling={diff4} isInfo={false} value={percentageFormat(diff4Value, true, 1)} />
                </div>
              </td>
            </tr>
          </>
        ) : (
          <></>
        )}
      </tbody>
    </table>
  );
};
