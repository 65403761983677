/** @format */

import { FC, ReactNode } from 'react';
import { TierInfo } from '../services/admin/api';
import { numberFormat } from '../utils/numberUtils';
import { CYTD } from '../constants/general';

interface Props {
    bars: Array<TierInfo>;
}
export const RebateViewBars: FC<Props> = ({
    bars,
}): JSX.Element => {

    function getTarget(bar: TierInfo): number {
        return bar.growth_target ?? 0.0;
    }

    function getActual(bar: TierInfo): number {
        return bar.target_value ?? 0.0;
    }

    function getPercentage(bar: TierInfo): number {
        let output = getTarget(bar) !== 0.0 ? getActual(bar) * 100.0 / getTarget(bar) : 0.0;
        if (output > 100.0) output = 100.0;
        return output;
    }

    function getDifference(bar: TierInfo): number {
        return getTarget(bar) - getActual(bar);
    }

    function generateBars(bar: TierInfo, index: number) {
        let check = 0.0;
        let temp = Array<ReactNode>();
        if (getPercentage(bar) >= check) {
            check = getPercentage(bar);
            temp = [<div key={'tier_bar_' + index} className='tier-chart-item target'>
                <div className="target-box">
                    <div className="target" style={{ left: 'calc(' + check + '% - ' + (getActual(bar) <= 0.0 ? '54' : '60') + 'px)' }}>
                    <div className="value rightline">{numberFormat(getActual(bar))}</div></div></div>
                    <div className='tier-target-bar tier-bar' style={{ width: '100%' }} />
                    <div className={check > 90 ? 'tier-bar-label-up upper' : 'tier-bar-label-up'}>{numberFormat(getTarget(bar))}</div>
                    <div className='tier-bar-label-down'>{numberFormat(getDifference(bar) <= 0.0 ? 0.0 : getDifference(bar))}&nbsp;to reach target</div>
            </div>];
        }
        return temp;
    }

    const barLayout = (bars ?? []).map((bar, index) => (
        <>
            <h3>
                    {index === 0 && <>Overall Portfolio Growth</>}
                    {index === 1 && <>Ray-Ban & Oakley</>}
                </h3>
            <div className='tier-chart vsdata'>
                <>
                    <div className='tier-chart-item current'>

                        <div className='tier-current-bar tier-bar' style={{ width: getPercentage(bar) + '%' }}><div className='tier-indicator' /></div>
                        <div className='tier-bar-label'>
                            {index === 0 && <>{CYTD}</>}
                            {index === 1 && <>{CYTD} Ray-Ban & Oakley</>}
                            &nbsp;{numberFormat(getActual(bar))}</div>
                    </div>
                    {generateBars(bar, index)}
                </>
            </div>
        </>
    ));

    return bars !== null && bars !== undefined && bars.length > 0 ?
        <>
            <div className='tier-chart-box'>
                <div className='tier-chart-container'>
                    {barLayout}
                </div>
            </div>
        </> : <></>;
};