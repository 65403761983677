/** @format */
//import { FC } from 'react';
//import { TypeIdFormModel, TypeIdLevelItem } from '../../../services/admin/api';

import { FC, useState } from 'react';
import { BackActionButton } from '../partials/BackActionButton';
import { SaveButton } from '../partials/SaveButton';
import './TypeIdForm.scss';
import { TypeIdCreateRequestModel, TypeIdFullModel, TypeIdGroup } from '../../../models/TypeIdCreateRequestModel';
import { CheckBox, TextBox } from 'devextreme-react';
import { loaderSlice } from '../../../services/loader/slice';
import { clearStorage } from '../../../utils/localStorageUtils';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ROUTE_ADMIN_REBATES, ROUTE_HOME } from '../../../constants/general';
import { apiAdminTypeIDSave } from '../../../services/admin/api';
import { apiRefresh } from '../../../services/auth/api';
import { GenericModal } from '../partials/GenericModal';
import EventEmitter from 'events';


interface TypeIdFormProps {
  onChangePage: (selection: number) => void;
  request: TypeIdCreateRequestModel | undefined;
  eventEmitter: EventEmitter;
}

export const TypeIdForm: FC<TypeIdFormProps> = ({
  onChangePage,
  request,
  eventEmitter,
}): JSX.Element => {

  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalBody, setModalBody] = useState<string>('');
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const closeModal = () => {
    setIsOpenModal(false);
  };

  const openModalState = () => {
    setIsOpenModal(true);
  };

  function _isValid(item: string | undefined | null) {
    return item !== undefined && item !== null && item !== '';
  }

  function _saveTypeId(retry = true) {
    let canGo = true;
    if (request !== undefined) {
      request.type_ids.forEach((item: TypeIdFullModel) => {
        if (    !_isValid(item.in_group1) 
            ||  !_isValid(item.in_group2)
            ||  !_isValid(item.in_group3)
            ||  !_isValid(item.db_gr1)
            ||  !_isValid(item.db_gr2)
            ||  !_isValid(item.db_gr3)
            ||  !_isValid(item.type_id)
            ) {
              canGo = false;
            }
      });

      request.groups.forEach((item: TypeIdGroup) => {
        if (    !_isValid(item.spk_group) 
            ||  !_isValid(item.spk_group_id)
            ) {
              canGo = false;
            }
      });
    }

    if (request !== undefined && canGo) {
      dispatch(loaderSlice.actions.startLoading());
      apiAdminTypeIDSave({ data: request }).then(response => {
        console.log(response);
        if (response.data.data === true) {
          eventEmitter.emit('SuccesfullSaved');
          onChangePage(1);
        }
        dispatch(loaderSlice.actions.stopLoading());
      }).catch(error => {
        const refreshToken = localStorage.getItem('refreshToken');
        if (error.response && error.response.status && error.response.status === 401 && refreshToken) {
          apiRefresh({ refreshToken })
            .then(response => {
              if (response.data?.data?.jwt_token && response.data?.data?.refresh_token) {
                localStorage.setItem('jwtToken', response.data.data.jwt_token);
                localStorage.setItem('refreshToken', response.data.data.refresh_token);
                if (retry) {
                  _saveTypeId(false);
                }
              }
            })
            .catch(() => {
              clearStorage();
              navigate(`${ROUTE_HOME}`, { replace: true });
            });
        } else if (error.response && error.response.status && error.response.status === 500) {
          navigate(`${ROUTE_ADMIN_REBATES}`, { replace: true });
        } else {
          clearStorage();
          navigate(`${ROUTE_HOME}`, { replace: true });
        }
      });
    } else {
      const title = 'All fields must be compiled.';
      const body = 'Plesa compile all empty fileds to save Group and Type Id.';
      setModalTitle(title);
      setModalBody(body);
      openModalState();
    }
    
  }

  function _updateSpkGroup(group: TypeIdGroup, value: string) {
    group.spk_group = value;  
    switch (group.spk_level) {
      case 1: {
        group.spk_group = value;
        if (request !== undefined) {
          request.type_ids.forEach((item: TypeIdFullModel, index) => {
            item.db_gr1 = value;
            const id = 'in_group_1_' + index + '_' + item.type_id;
            const element = document.getElementById(id);
            if (element !== null) {
              console.log(element);
            }
          });
        }
        break;
      }

      case 2: {
        group.spk_group = value;
        if (request !== undefined) {
          request.type_ids.forEach((item: TypeIdFullModel, index) => {
            item.db_gr2 = value;
            const id = 'db_gr2_' + index + '_' + item.type_id;
            const element = document.getElementsByName(id);
            if (element.length > 0) {
              console.log(element);
              element[0].setAttribute('value', value);
            }
          });
        }
        break;
      }

      case 3: {
        group.spk_group = value;
        if (request !== undefined) {
          request.type_ids.forEach((item: TypeIdFullModel, index) => {
            item.db_gr3 = value;
            const id = 'db_gr3_' + index + '_' + item.type_id;
            const element = document.getElementById(id);
            if (element !== null) {
              console.log(element);
            }
          });
        }
        break;
      }
    }
  }

  function _buildGroupInput(group: TypeIdGroup) {
    return <div key={group.spk_level} className='content_block'>
      <div className='content_block__title'>{'Livello ' + group.spk_level}</div>
      <div className='content_block__fields'>
      <div className='text_input'>
        <label className='text_input__title'>Spk Group Id</label>
        <div className='text_input__content'>
          <TextBox
            id={'group_id_' + group.spk_level}
            name={'group_id_' + group.spk_level}
            placeholder=''
            defaultValue={group.spk_group_id ?? undefined}
            disabled={true}
            onValueChange={(value) => { group.spk_group_id = value; }} />
        </div>
      </div>
      <div className='text_input'>
        <label className='text_input__title'>Spk Group</label>
        <div className='text_input__content'>
          <TextBox
            id={'group_' + group.spk_level}
            name={'group_' + group.spk_level}
            placeholder=''
            defaultValue={''}
            disabled={false}
            onValueChange={(value) => {  _updateSpkGroup(group, value); }} />
        </div>
      </div>
      <div className='text_input'>
        <label className='text_input__title'>Is Brand</label>
        <div className='text_input'>
          <CheckBox
            id={'is_brand_' + group.spk_level}
            name={'is_brand_' + group.spk_level}
            onValueChange={(value) => { group.is_brand = value === true ? 'X' : ''; }}
            defaultValue={false}
          />
        </div>
      </div>
    </div></div>;
  }

  function _buildTypeIdInput(typeId: TypeIdFullModel, index: number) {
    return <div key={index} className='content_block'>
      <div className='content_block__title'>{'Type Id ' + typeId.type_id}</div>
      <div className='content_block__fields'>
      <div className='text_input'>
        <label className='text_input__title'>Type Id</label>
        <div className='text_input__content'>
          <TextBox
            id={'type_id_' + index + '_' + typeId.type_id}
            name={'type_id_' + index + '_' + typeId.type_id}
            placeholder=''
            defaultValue={typeId.type_id}
            disabled={true}
            onValueChange={(value) => { typeId.type_id = value.toString(); }} />
        </div>
      </div>
      <div className='text_input'>
        <label className='text_input__title'>In Group 1</label>
        <div className='text_input__content'>
          <TextBox
            id={'in_group_1_' + index + '_'  + typeId.type_id}
            name={'in_group_1_' + index + '_'  + typeId.type_id}
            placeholder=''
            defaultValue={typeId.in_group1}
            disabled={typeId.in_group1 !== ''}
            onValueChange={(value) => { typeId.in_group1 = value.toString(); }} />
        </div>
      </div>
      <div className='text_input'>
        <label className='text_input__title'>In Group 2</label>
        <div className='text_input__content'>
          <TextBox
            id={'in_group_2_' + index + '_'  + typeId.type_id}
            name={'in_group_2_' + index + '_'  + typeId.type_id}
            placeholder=''
            defaultValue={typeId.in_group2}
            disabled={typeId.in_group2 !== ''}
            onValueChange={(value) => { typeId.in_group2 = value.toString(); }} />
        </div>
      </div>
      <div className='text_input'>
        <label className='text_input__title'>In Group 3</label>
        <div className='text_input__content'>
          <TextBox
            id={'in_group_3_' + index + '_'  + typeId.type_id}
            name={'in_group_3_' + index + '_'  + typeId.type_id}
            placeholder=''
            defaultValue={typeId.in_group3}
            disabled={typeId.in_group3 !== ''}
            onValueChange={(value) => { typeId.in_group3 = value.toString(); }} />
        </div>
      </div>
      <div className='text_input'>
        <label className='text_input__title'>Db Gr2</label>
        <div className='text_input__content'>
          <TextBox
            id={'db_gr2_' + index + '_'  + typeId.type_id}
            name={'db_gr2_' + index + '_'  + typeId.type_id}
            placeholder=''
            defaultValue={typeId.db_gr2}
            disabled={true}
            onValueChange={(value) => { typeId.db_gr2 = value.toString(); }} />
        </div>
      </div>
      <div className='text_input'>
        <label className='text_input__title'>Db Gr3</label>
        <div className='text_input__content'>
          <TextBox
            id={'db_gr3_' + index + '_'  + typeId.type_id}
            name={'db_gr3_' + index + '_'  + typeId.type_id}
            placeholder=''
            defaultValue={typeId.db_gr3}
            disabled={true}
            onValueChange={(value) => { typeId.db_gr3 = value.toString(); }} />
        </div>
      </div>
    </div></div>;
  }

  function _buildGroupsElements(req : TypeIdCreateRequestModel | undefined) {
    console.log(req?.groups);
    const res: JSX.Element[] = [];
    if (req !== undefined) {
      req.groups.forEach((item: TypeIdGroup) => {
        res.push(_buildGroupInput(item)); 
      });
    }
    return res;
  }

  function _buildTypeIdssElements(req : TypeIdCreateRequestModel  | undefined) {
    console.log(req?.type_ids);
    const res: JSX.Element[] = [];
    if (req !== undefined) {
      req.type_ids.forEach((item: TypeIdFullModel, index) => {
        res.push(_buildTypeIdInput(item, index)); 
      });
    }
    return res;
  }

  
  return (
    <div className='composition__container'>
      <div className='bordered_box'>
          {_buildGroupsElements(request)}
      </div>
      <div className='bordered_box'>
          {_buildTypeIdssElements(request)}
      </div>
      <div className='flex-spacebetween type_id_container__footer' >
        <BackActionButton enabled={true}
          text='Back'
          onClick={() => onChangePage(1)} />

        <SaveButton
          enabled={true}
          text='Save'
          onClick={() => {
            _saveTypeId(true);
          }} />
      </div>

      <GenericModal
        isOpenModal={isOpenModal}
        onClick={closeModal}
        title={modalTitle}
        body={modalBody}
      />
    </div>
  );
};