/** @format */

import { FC } from 'react';

interface Props {
  label: string;
  value: string;
  srcIcon: string;
}

export const UIDetail: FC<Props> = ({ label, srcIcon }): JSX.Element => {
  return (
    <>
      <img alt="icon" src={srcIcon} />
      <span>{label}</span>
    </>
  );
};