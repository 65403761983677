/** @format */

import { FC, useEffect, useState } from 'react';
import { UIIcon } from '../../../../shared/Icon';

import iconSuccess from '../../../../assets/icons/solid/AlertSuccess.svg';
import iconDanger from '../../../../assets/icons/solid/AlertDanger.svg';
import iconWarning from '../../../../assets/icons/solid/AlertWarning.svg';
import iconInfo from '../../../../assets/icons/solid/AlertInfo.svg';

interface Props {
  variant: string;
}

export const IconSolid: FC<Props> = ({ variant }): JSX.Element => {
  const [myVariant, setMyVariant] = useState<string>(iconDanger);

  useEffect(() => {
    switch (variant) {
      case 'Success':
        setMyVariant(iconSuccess);
        break;
      case 'Danger':
        setMyVariant(iconDanger);
        break;
      case 'Warning':
        setMyVariant(iconWarning);
        break;
      case 'Info':
        setMyVariant(iconInfo);
        break;
    }
  }, [variant]);

  return (
    <div className='icon'>
      <UIIcon
        name={'icon'}
        srcIcon={myVariant}
        width={myVariant === iconDanger ? '30' : '24'}
        height={myVariant === iconDanger ? '30' : '24'}
        className=""
      />
    </div>
  );
};
