import { FC, useEffect } from 'react';
import { mapColors } from '../../../../theme/mapColors';
import './Performance_graph.scss';

import Chart, {
  Series,
  CommonSeriesSettings,
  ValueAxis,
  Tooltip,
  Legend,
  CommonAxisSettings,
  ArgumentAxis,
  Label,
  Format,
} from 'devextreme-react/chart';
import { numberFormat } from '../../../../utils/numberUtils';

export default function TooltipTemplate(info: any) {
  return (
    <div className="performance_graph__tooltip">
      <div className='performance_graph__tooltip--title'>{info.point.data.key}</div>
      <div className='performance_graph__tooltip--row'>
        <label className='performance_graph__tooltip--key dark_blue'>Current year</label>
        <label className='performance_graph__tooltip--value dark'>{/*info.point.data.prefix*/}{numberFormat(info.point.data.cy)}</label>
      </div>
      <div className='performance_graph__tooltip--row'>
        <label className='performance_graph__tooltip--key light_blue'>Previous year</label>
        <label className='performance_graph__tooltip--value dark'>{/*info.point.data.prefix*/}{numberFormat(info.point.data.py)}</label>
      </div>
      <hr className="solid" />
      <div className='performance_graph__tooltip--row'>
        <label className='performance_graph__tooltip--key light'>Change</label>
        <label className='performance_graph__tooltip--value red'>{/*info.point.data.prefix*/}{numberFormat(info.point.data.cy - info.point.data.py)}</label>
      </div>
    </div>
  );
}

interface GraphProps {
  data: any;
  prefix?: string | null,
  updateLayout: boolean | null;
}
export const PerformanceGraph: FC<GraphProps> = ({ data = null, prefix = null, updateLayout = true }): JSX.Element => {

  let chart: Chart;

  useEffect(() => {
    if (chart) {
      chart.instance.render();
    }
  }, [updateLayout]);

  return (
    <Chart
      ref={(ref: any) => { chart = ref; }}
      dataSource={data}
      className={'main_chart'}>
      
       <CommonSeriesSettings
        cornerRadius={4}
        barWidth={54}
        argumentField="key"
        type="bar"
        hoverMode="allArgumentPoints"
        selectionMode="allArgumentPoints">
        
        <Label visible={true} showForZeroValues={false}
          customizeText={(e: any) => `${prefix === null ? '' : ` ${prefix}`} ${e.valueText}`}
          font={{ color: '#333333', weight: 600, size: '10px' }}
          backgroundColor={'#FFFFFF'}
          border={{ color: '#DDDDDD', width: 1, dashStyle: 'solid', visible: true }}>
          <Format type="fixedPoint" precision={0} />
        </Label>
        
      </CommonSeriesSettings>

      <Series valueField="py" color={'#bdcbfc'} />
      <Series valueField="cy" color={'#091f5a'} />

      <Tooltip
        enabled={true}
        format="millions"
        contentRender={TooltipTemplate}
      />

      <ArgumentAxis
        label={{ font: { color: mapColors.fonts.fontPrimary, weight: '600', size: '12px' } }} />
      
      <CommonAxisSettings
        tick={{ visible: false }}
        grid={{ color: mapColors.backgrounds.borderSecondary }}
        color={mapColors.backgrounds.borderSecondary}
      />

      <ValueAxis
        visible={true}
        label={{
          font: { color: mapColors.fonts.fontTertiary, weight: '600', size: '8px' },
          customizeText: (e: any) => `${prefix === null ? '' : ` ${prefix}`} ${e.valueText}`,
        }}
      />

      <Legend visible={false} horizontalAlignment="center" />

    </Chart>
  );


};

