/** @format */

import { FC } from 'react';
import { CQTD, CSTD, CYTD, NOTE_BRAND_QTD_RETURN_RATE, NOTE_BRAND_YTD_RETURN_RATE } from '../../../../../constants/general';
import { Performances } from '../../../../../services/rebate/api';
import { UIDateLabel } from '../../../../../shared/DateLabel';
import { RebateTitle } from '../../../../../shared/RebateTitle';
import { GenericTableRebate } from '../../../../../shared/GenericTableRebate';
import { RebateMaxValueViewNonVS } from '../../../../../shared/RebateMaxValueViewNonVS';
import { RebateViewNonVS } from '../../../../../shared/RebateViewNonVS';

interface Props {
  customer: any;
  brand: any;
  performances: Performances | null;
}

export const BrandRebate: FC<Props> = ({ brand = null, performances, customer = null }): JSX.Element => {
  // const quarterRebates = brand?.brand_quarter_rebates[0];
  // const yearlyRebates = brand?.brand_yearly_rebates[0];

  const performancesData = performances?.sections.find((item) => item.section_name === 'Rewards Brand Pillar Performance');

  let tempBrandName = brand.brand_name.toLowerCase();
  if (tempBrandName.includes('oakley')) tempBrandName = 'oakley';
  if (tempBrandName.includes('ray')) tempBrandName = 'ray-ban';

  const annualData = performancesData?.types[0].categories.filter((item) => item.period === 'Annual' && item.category_name.toLowerCase().includes(tempBrandName)) ?? [];
  const quarterData = performancesData?.types[0].categories.filter((item) => item.period === 'Quarterly' && item.category_name.toLowerCase().includes(tempBrandName)) ?? [];
  const semesterData = performancesData?.types[0].categories.filter((item) => item.period === 'Semester' && item.category_name.toLowerCase().includes(tempBrandName)) ?? [];


  function semesterMessage() {
    return semesterData[0]?.rebate?.message ?? '';
  }

  function quarterMessage() {
    return quarterData[0]?.rebate?.message ?? '';
  }

  function annualMessage() {
    return annualData[0]?.rebate?.message ?? '';
  }

  function semesterSection() {
    return (semesterData !== null && semesterData.length > 0) ? <>
      <RebateMaxValueViewNonVS tiers={semesterData[0]?.rebate?.tiers ?? []} />
      <RebateViewNonVS
        tiers={semesterData[0]?.rebate?.tiers ?? []}
        aup={brand?.brand_yearly_rebates[0]?.cytd_aup ?? 0}
        label={CSTD}
      />
    </> : <></>;
  }

  function quarterSection() {
    return (quarterData !== null && quarterData.length > 0) ? <>
      <RebateMaxValueViewNonVS tiers={quarterData[0]?.rebate?.tiers ?? []} />
      <RebateViewNonVS
        tiers={quarterData[0]?.rebate?.tiers ?? []}
        aup={brand?.brand_yearly_rebates[0]?.cytd_aup ?? 0}
        label={CQTD}
      />
    </> : <></>;
  }

  function annualSection() {
    return (annualData !== null && annualData.length > 0) ? <>
      <RebateMaxValueViewNonVS tiers={annualData[0]?.rebate?.tiers ?? []} />
      <RebateViewNonVS
        tiers={annualData[0]?.rebate?.tiers ?? []}
        aup={brand?.brand_yearly_rebates[0]?.cytd_aup ?? 0}
        label={CYTD}
      />
    </> : <></>;
  }



  return (
    <>
      {quarterData !== null && quarterData.length > 0 && <>
        <div className='brand-rebate-box quarter'>
          {quarterMessage() !== '' && <div className={'catch-up-message'}>{quarterMessage()}</div>}
          <div className='title-box'>
            <div className='title'>
              {/*'Quarterly ' + brand?.brand_name + ' Growth Rebate'*/}
              <RebateTitle data={quarterData} />

              <UIDateLabel
                label={
                  customer
                    ? customer?.years.qtr_range + ', ' + customer?.years.current_year
                    : ''
                }
              />
            </div>
          </div>

          {quarterSection()}

          <div className='brand-data-box'>
            <div className="row">
              <div className="col">
                {/*<TableQuarterlyRebateSingleBrand brand={brand} tiers={quarterData[0]?.rebate?.tiers ?? []} />*/}
                <GenericTableRebate items={quarterData[0].left_columns ?? []} />
              </div>
              <div className='col' style={{ flexDirection: 'column' }}>
                {/*<TableReturnRateSingleBrand brand={brand} isQuarterly={true} />*/}
                <GenericTableRebate items={quarterData[0].right_columns ?? []} />
                <div className='message'>{NOTE_BRAND_QTD_RETURN_RATE}</div>
                {/*<PotentialRebate isQuarterly={true} value={quarterRebates?.total_potential_rebate} />*/}
                {/*
                <PotentialRebateTier
                  tiers={quarterData[0]?.rebate?.tiers ?? []}
                  py={brand?.brand_quarter_rebates[0]?.py_quarter_td ?? 0}
                  cy={brand?.brand_quarter_rebates[0]?.cy_quarter_td ?? 0} />
                */}
              </div>
            </div>
          </div>
        </div>
      </>}

      {semesterData !== null && semesterData.length > 0 && <>

        <div className='brand-rebate-box semester'>
          {semesterMessage() !== '' && <div className={'catch-up-message'}>{semesterMessage()}</div>}
          <div className='title-box'>
            <div className='title'>
              <RebateTitle data={semesterData} />

              <UIDateLabel
                label={
                  customer
                    ? customer?.years.sem_range + ', ' + customer?.years.current_year
                    : ''
                }
              />
            </div>
          </div>

          {semesterSection()}

          <div className='brand-data-box'>
            <div className="row">
              <div className="col">
                <GenericTableRebate items={semesterData[0].left_columns ?? []} />
              </div>
              <div className='col' style={{ flexDirection: 'column' }}>
                <GenericTableRebate items={semesterData[0].right_columns ?? []} />
                <div className='message'>{NOTE_BRAND_QTD_RETURN_RATE}</div>
              </div>
            </div>
          </div>
        </div>
      </>}

      {annualData !== null && annualData.length > 0 && <>



        <div className='brand-rebate-box'>
          {annualMessage() !== '' && <div className={'catch-up-message'}>{annualMessage()}</div>}
          <div className='title-box'>
            <div className='title'>
              {/*brand?.brand_name + ' Annual Rebate'*/}
              <RebateTitle data={annualData} />
              <UIDateLabel
                label={
                  customer
                    ? customer?.total_yearly_rebate?.yearly_dates + ', ' + customer?.total_yearly_rebate?.year
                    : ''
                }
              />
            </div>
          </div>

          {annualSection()}

          <div className='brand-data-box'>
            <div className="row">
              <div className="col">
                {/*<TableAnnualRebateSingleBrand brand={brand} />*/}
                <GenericTableRebate items={annualData[0].left_columns ?? []} />
              </div>
              <div className='col' style={{ flexDirection: 'column' }}>
                {/*<TableReturnRateSingleBrand brand={brand} isQuarterly={false} />*/}
                <GenericTableRebate items={annualData[0].right_columns ?? []} />
                <div className='message'>{NOTE_BRAND_YTD_RETURN_RATE}</div>
                {/*<PotentialRebate isQuarterly={false} value={yearlyRebates?.total_potential_rebate} />*/}
                {/*
                <PotentialRebateTier tiers={annualData[0]?.rebate?.tiers ?? []}
                  py={brand?.brand_yearly_rebates[0]?.pytd ?? 0}
                  cy={brand?.brand_yearly_rebates[0]?.cytd ?? 0} />
                */}
              </div>
            </div>
          </div>
        </div>
      </>}
    </>
  );
};
