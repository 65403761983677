/** @format */

import { FC, useEffect, useState } from 'react';
import { UITabTitle } from '../partials/TabTitle';
import { BOX_TITLE_CONTACTS, EXPORT_CONTACTS } from '../../../constants/general';
import DataGrid, {
  Column,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  Sorting,
} from 'devextreme-react/data-grid';
import { useParams } from 'react-router-dom';
import { Expandable } from '../../../shared/Expandable';
import { NoData } from '../../../shared/NoData';

type ContactProps = {
  customer: any;
  opened?: boolean;
  printMode: boolean;
};

export const Contact: FC<ContactProps> = ({ customer = null, opened = false, printMode = false }): JSX.Element => {
  const { customerId } = useParams();
  const [isExpanded, setIsExpanded] = useState(opened);
  const data = customer && customer.contacts ? customer.contacts : [];

  useEffect(() => {
    setIsExpanded(opened);
  }, [opened]);

  return (
    <div id="contacts" className="data-panel">
      <UITabTitle
        infoMessage={''}
        label={BOX_TITLE_CONTACTS}
        url={data.length > 0 ? EXPORT_CONTACTS : ''}
        customerId={customerId || '0'}
        onClick={() => setIsExpanded(!isExpanded)}
        collapsed={isExpanded}
      />
      <Expandable expanded={isExpanded}>
        {data.length > 0 ? (
          <DataGrid
            dataSource={data}
            allowColumnReordering={true}
            rowAlternationEnabled={true}
            showBorders={true}
            showColumnLines={false}
            showRowLines={false}
            allowColumnResizing={true}
            columnAutoWidth={true}
            columnMinWidth={200}
          // onContentReady={this.onContentReady}
          >
            <Sorting mode="multiple" />
            <ColumnFixing enabled={false} />
            <HeaderFilter visible={false} />
            <FilterRow visible={false} />

            <Column
              dataField="customer_code_child"
              caption="CUSTOMER ID"
              alignment="left"
            >
              <HeaderFilter allowSearch={true} />
            </Column>
            <Column dataField="name" caption="NAME" alignment="left">
              <HeaderFilter allowSearch={true} />
            </Column>
            <Column
              dataField="description"
              caption="DESCRIPTION"
              alignment="left"
            >
              <HeaderFilter allowSearch={true} />
            </Column>
            <Column dataField="phone" caption="PHONE" alignment="left">
              <HeaderFilter allowSearch={true} />
            </Column>
            <Column dataField="email" caption="EMAIL" alignment="left">
              <HeaderFilter allowSearch={true} />
            </Column>

            <Pager
              visible={printMode ? false : true}
              // allowedPageSizes={false}
              displayMode={'compact'}
              showPageSizeSelector={false}
              showInfo={true}
              showNavigationButtons={true}
            />
            <Paging defaultPageSize={printMode ? 100000 : 10} />
          </DataGrid>
        ) : (
          <NoData />
        )}
      </Expandable>
    </div>
  );
};
