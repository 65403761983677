/** @format */

import { UIIcon } from './Icon';

import emptyStateImage from '../assets/images/empty.svg';

//#endregion
export const EmptyState = () => {
  return (
    <UIIcon name={'target'} srcIcon={emptyStateImage} className="" width={''} height={''} />
  );
};

export default EmptyState;
