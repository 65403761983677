/** @format */

import { FC } from 'react';

interface Props {
  name: string;
  srcIcon: string;
  width: string;
  height: string;
  className: string;
}
export const UIIcon: FC<Props> = ({ name, srcIcon, width, height, className }): JSX.Element => {
  return <img alt={name} src={srcIcon} width={width} height={height} className={className} />;
};

export const TierIcon: FC<Props> = ({ name, srcIcon, width, height, className }): JSX.Element => {
  return <img alt={name} src={srcIcon} width={width} height={height} className={className} />;
};
