/** @format */

import { FC } from 'react';

interface Props {
  onClick: () => void;
  text: string;
  enabled: boolean;
}

export const BackActionButton: FC<Props> = ({ onClick, text, enabled }): JSX.Element => {
  return (
    <div className={ enabled ? 'back_action_button' : 'back_action_button disabled'}>
      <a onClick={() => onClick()}>{text}</a>
    </div>
  );
};
