/** @format */

import { FC } from 'react';

interface Props {
  label: string;
  isVisible: boolean;
  isTopPointing?: boolean;
  isSearch?: boolean;
}
export const UITooltip: FC<Props> = ({ label, isVisible, isTopPointing = false, isSearch = false }): JSX.Element => {

  function getClassName() {
    let output = 'tooltip-container';
    if (isTopPointing) output += 'top-pointing';
    if (isSearch) output += 'search';
    return output;
  }

  return (
    <>
      {label.length > 0 && isVisible && (
        <div className={getClassName()}>
          {label}
        </div>
      )}
    </>
  );
};
