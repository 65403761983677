/** @format */

import axios from 'axios';
import { RebateData, TierInfo } from '../admin/api';

export interface TypeID {
  description: string;
  type_id: string;
  value: string | null;
}

export interface VSTypeID {
  annual_vision_source: Array<TypeID>;
  quarterly_vision_source: Array<TypeID>;
}

export interface Performances {
  sections: Section[];
}

export interface Section {
  section_name: string;
  types: Type[];
}

export interface Type {
  type_name: string;
  categories: Category[];
}

export interface ColumnItem {
  title: string;
  value: string;
  value_type: string;
}

export interface SectionItem {
  section: string;
  section_children: Array<ColumnItem>;
}

export interface ActiveBrand {
  percent?: number,
  label?: string,
  active?: boolean,
  totalActiveBrand?: string
}

export interface BrandTrackingItem {
  customer_code: string,
  number_of_brands: number;
  brands: Array<BrandTrackingItemBrand>;
}

export interface BrandTrackingItemBrand {
  brand: string;
  units_from_target: number;
}

export interface Category {
  category_name: string;
  period: string;
  bars: Array<TierInfo>;
  rebate: RebateData;
  rebate_name: string;
  left_columns?: Array<ColumnItem>;
  right_columns?: Array<ColumnItem>;
  active_brands?: Array<ActiveBrand>;
  type: number | null;
  total_active_brands: number | null;
  opportunity_brands: number | null;
}

export const apiRebateFromCustomer = ({ customerCode }: { customerCode: string; }) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('jwtToken')}` },
  };
  return axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/rebate/getfromcustomer?customer_code=${customerCode}`, config);
};

export const apiTypeIDFromCustomer = ({ customerCode }: { customerCode: string; }) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('jwtToken')}` },
  };
  return axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/rebate/gettypeidfromcustomer?code=${customerCode}`, config);
};

export const apiVisionSourceRebateData = ({ customerCode }: { customerCode: string; }) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('jwtToken')}` },
  };
  return axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/rebate/getvisionsourcerebatedata?code=${customerCode}`, config);
};

export const apiVisionSourceSections = ({ customerCode }: { customerCode: string; }) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('jwtToken')}` },
  };
  return axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/rebate/getvisionsourcesections?code=${customerCode}`, config);
};


export const apiRebatesPotential = ({ customerCode }: { customerCode: string; }) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('jwtToken')}` },
  };
  return axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/rebate/get-potential-rebates?customer_code=${customerCode}`, config);
};