/** @format */

import { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { CardList } from './partials/CardList';
// import { mapColors } from '../../theme/mapColors';

//import srcLogo from '../../assets/icons/outline/LogoNavbar.svg';
import { useAppDispatch } from '../../hooks/redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { apiPagedResults } from '../../services/search/api';
import { loaderSlice } from '../../services/loader/slice';
import { apiRefresh } from '../../services/auth/api';
import { ROUTE_DASHBOARD, ROUTE_HOME } from '../../constants/general';
// import headerBg from '../../assets/images/navbarbg.png';
import { clearStorage } from '../../utils/localStorageUtils';
import { MainHeader } from '../../shared/MainHeader';
//#region ::: PARTIALS

/*
export const NavbarSerp = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '80px',
  width: '100%',
  zIndex: 30000,
  backgroundColor: 'white',
  backgroundImage: 'url(' + headerBg + ')',
  backgroundAttachment: 'fixed',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  boxShadow: '0px 2px 12px 2px rgba(0,0,0,0.1)',
});
*/

export const Logo = styled.p({
  marginLeft: '30px',
});

/*
const Navbar = styled.div({
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  zIndex: '999999999',
});
*/

//#endregion

export const PageSerp: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  /*
  const results = useAppSelector(selectorResults);
  const itemsCount = useAppSelector(selectorItemsCount);
  const pageSize = useAppSelector(selectorPageSize);
  */

  const [results, setResults] = useState(null);
  const [itemsCount, setItemsCount] = useState<number>(-1);
  const [pageSize, setPageSize] = useState<number>(-1);
  const [isSearching, setIsSearching] = useState(true);
  const refreshToken = localStorage.getItem('refreshToken');

  const totalPages = Math.floor((itemsCount + pageSize - 1) / pageSize);
  const isResultLabel = !results;

  const [searchParams, setSearchParams] = useSearchParams();
  const _currentSearch = searchParams.get('search');
  const _currentPage = searchParams.get('page');
  let currentPage = 1;

  if (_currentPage !== null) {
    currentPage = parseInt(_currentPage, 10);
  }

  const previousPage = (event: any) => {
    event.preventDefault();
    if (_currentSearch) {
      setSearchParams(
        {
          search: _currentSearch,
          page: (currentPage - 1).toString(),
        },
        { replace: true },
      );
    }
  };

  const nextPage = (event: any) => {
    event.preventDefault();
    if (_currentSearch) {
      setSearchParams(
        {
          search: _currentSearch,
          page: (currentPage + 1).toString(),
        },
        { replace: true },
      );
    }
  };

  function searchCustomer(retry = true) {
    if (_currentSearch !== null && _currentSearch.length > 2) {
      setIsSearching(true);
      dispatch(loaderSlice.actions.startLoading());
      apiPagedResults({ inputSearch: _currentSearch, pageNumber: currentPage })
        .then(response => {
          setItemsCount(parseInt(response.data.count, 10));
          setPageSize(parseInt(response.data.error, 10));
          setResults(response.data.data);
          dispatch(loaderSlice.actions.stopLoading());
          setIsSearching(false);
        })
        .catch(error => {
          if (error.response && error.response.status && error.response.status === 401 && refreshToken) {
            apiRefresh({ refreshToken })
              .then(response => {
                if (response.data?.data?.jwt_token && response.data?.data?.refresh_token) {
                  localStorage.setItem('jwtToken', response.data.data.jwt_token);
                  localStorage.setItem('refreshToken', response.data.data.refresh_token);
                  if (retry) {
                    searchCustomer(false);
                  }
                }
              })
              .catch(() => {
                clearStorage();
                // loginWithRedirect();
                navigate(`${ROUTE_HOME}`, { replace: true });
              });
          } else {
            clearStorage();
            // loginWithRedirect();
            navigate(`${ROUTE_HOME}`, { replace: true });
          }
        });
    }
  }

  function checkCustomer() {
    const currentCustomerId = localStorage.getItem('customerId');
    const currentRole = localStorage.getItem('role');
    if (currentCustomerId !== null && currentRole !== null && currentCustomerId !== 'null' && currentRole !== 'null')
      return true;
    return false;
  }

  const search = () => {
    if (checkCustomer()) {
      const currentCustomerId = localStorage.getItem('customerId');
      const currentCustomerTypeId = localStorage.getItem('typeId');
      navigate(`${ROUTE_DASHBOARD}/${currentCustomerId}/${currentCustomerTypeId}`, { replace: true });
    } else {
      searchCustomer();
    }
  };

  useEffect(() => {
    const updateInfoElement = document.getElementById('update-info');
    if (updateInfoElement) updateInfoElement.style.display = 'none';

    document.title = `Search Results for "${_currentSearch}" - Page ${_currentPage ?? 1} | Rewards Hub`;
    window.scrollTo(0, 0);
    search();
  }, [_currentSearch, _currentPage]);

  return (
    <>
      <MainHeader
        enableLeftMenu={false}
        showNavigationBar={false}
        showPdfExportButton={false}
        showSearchBar={true}
        showSearchButton={false}
        showUser={true}
        customer={null} />
      
      <section className="main">
        <div className="container serp">
          {!isResultLabel && (
            <div id='results-count'>
              {!isSearching && (
                <>
                  Results for &quot;{_currentSearch}&quot;: {itemsCount} - Page {currentPage} of {totalPages}
                </>
              )}
            </div>
          )}
          {!isSearching && (
            <>
              <CardList results={results} />

              <div id="pager" className={totalPages > 1 ? 'show' : 'hide'}>
                <button onClick={previousPage} className={currentPage > 1 ? 'enabled' : ''}>
                  &laquo; Previous
                </button>
                <button onClick={nextPage} className={currentPage < totalPages ? 'enabled' : ''}>
                  Next &raquo;
                </button>
              </div>

            </>
          )}
        </div>
      </section>
    </>
  );
};
