/** @format */
import packageJSON from '../../package.json';

export const APP_NAME = packageJSON.name;
export const APP_VERSION = '2.0.0';
export const APP_COPYRIGHT = 'EssilorLuxottica';

export const BRANDS_STRIPE_LINE_1 =
  'POLO | RALPH LAUREN | RALPH EYEWEAR | COACH | MICHAEL KORS | BROOKS BROTHERS | ARMANI EXCHANGE | EMPORIO ARMANI | OLIVER PEOPLES | ALAIN MIKLI | STARCK EYES';
export const BRANDS_STRIPE_LINE_2 =
  'TORY BURCH | BURBERRY | VERSACE | PRADA EYEWEAR | PRADA LINEA ROSSA | TIFFANY & CO. | DOLCE & GABBANA | GIORGIO ARMANI | CHANEL | MIU MIU EYEWEAR | BVLGARI';

export const ROUTE_LOGIN = '/login';
export const ROUTE_HOME = '/';
export const ROUTE_SERP = '/serp';
export const ROUTE_DASHBOARD = '/dashboard';
export const ROUTE_SEARCH = '/search';
export const ROUTE_NO_PERMISSION = '/unauthorized';
export const ROUTE_COOKIE_POLICY = '/cookie-policy';
export const ROUTE_ADMIN = '/admin';
export const ROUTE_ADMIN_REBATES = '/admin/rebates';
export const ROUTE_ADMIN_REBATES_EDIT = '/admin/rebates/edit';
export const ROUTE_ADMIN_TYPE_IDS_ADD = '/admin/typeids/add';

export const EXPORT_DOORS = process.env.REACT_APP_API_BASE_URL + '/api/v1/doors_export';
export const EXPORT_CONTACTS = process.env.REACT_APP_API_BASE_URL + '/api/v1/contacts_export';
export const EXPORT_CREDIT_STATUS = process.env.REACT_APP_API_BASE_URL + '/api/v1/creditstatus_export';
export const EXPORT_EL360 = process.env.REACT_APP_API_BASE_URL + '/api/v1/el360_export';

export const MESSAGE_SESSION_EXPIRED = '';
export const MESSAGE_NOT_AVAILABLE_DATA = 'No available data';
export const MESSAGE_NOT_AVAILABLE_ABBREV = 'N/A';

//export const HEADER_BACKGROUND_COLOR = 'linear-gradient(90deg, #4599FF 0%, #0051B4 100%)';
export const HEADER_BACKGROUND_COLOR = ' #FFFFFF';

export const BOX_TITLE_GENERAL_INFO = 'Account Information';
export const BOX_TITLE_DATA_INFO = 'Total Portfolio Summary';
export const BOX_TITLE_BRAND = 'Luxottica Rewards Brand Pillar Rebates';
export const BOX_TITLE_TOTAL_PORTFOLIO_REBATE = 'Luxottica Rewards Portfolio Rebates';
export const BOX_TITLE_TOTAL_PORTFOLIO_REBATE_2 = 'Total Rebates';
export const BOX_TITLE_TOTAL_PROFITABILITY = 'Luxottica Savings';
export const BOX_TITLE_REBATES = 'Luxottica Portfolio Rebates';
export const BOX_TITLE_EL360 = 'EL360 Benefits';
export const BOX_TITLE_DOORS = 'Doors';
export const BOX_TITLE_CONTACTS = 'Customer Contact';
export const BOX_TITLE_REBATES_2 = 'Rebates';
export const BOX_TITLE_VSDATA_FOOTER = 'Performance';
export const BOX_TITLE_VSDATA = 'Vision Source Elite Vendor Rebates';
export const BOX_TITLE_GPN_SALES = 'GPN Luxottica Frame Share Compliance';
export const BOX_TITLE_CREDIT_STATUS = 'Credit Status';

export const BOX_TITLE_BRAND_PERFORMANCE = 'Brand Performance';

export const NOTE_YTD_RETURN_RATE = '* Return rate must be 15% or less for annual portfolio rebate qualification';
export const NOTE_FREE_SHIPPING_SAVES = '* Free Freight Savings excludes Oliver Peoples, B2B & Free Freight Promos';
export const NOTE_BRAND_QTD_RETURN_RATE =
  '* Return rate must be 15% or less for quarterly brand category rebate qualification';
export const NOTE_BRAND_YTD_RETURN_RATE =
  '* Return rate must be 15% or less for annual brand category rebate qualification';

export const TOOLTIP_YTD_RR_U_Needed = '';

export const YTD = 'Year to Date';
export const MTD = 'Month to Date';
export const QTD = 'Quarter to Date';
export const RR = 'Return Rate';
export const CY = 'Current Year';
export const PY = 'Previous Year';
export const PYTD = 'Previous Year to Date';
export const CYTD = 'Current Year to Date';
export const CQTD = 'Current Quarter to Date';
export const CSTD = 'Current Semester to Date';
export const FPY = 'Full Previous Year';
export const FCY = 'Full Current Year';
export const AUP = 'Average Unit Price';
export const LY = 'Last Year';
export const FY = 'Full Year';

export const TABLE_CURRENCY_FORMAT = { style: 'currency', currency: 'USD', useGrouping: true, maximumFractionDigits: 0, minimumFractionDigits: 0 };
export const TABLE_PERCENTAGE_FORMAT = "#0.#' %'";
export const TABLE_NUMBER_FORMAT = ',##0';

export const EL360_URL = 'https://www.essilorpro.com/programs-services/essilorluxottica360/';

export const ADMIN_ROLE = 'Admin';

export const TRACKING_CHECK = 3600000 * 24 * 30; // 30 days

export const LUXOTTICA_DARK_BLUE = '#004992';
export const LUXOTTICA_LIGHT_BLUE = '#8ec5e2';