/** @format */

import { useState } from 'react';
import { TypeIdNewLevelPage } from './TypeIdNewLevelPage';
import { TypeIdForm } from './TypeIdForm';
import './TypeIdMainView.scss';
import { TypeIdCreateRequestModel } from '../../../models/TypeIdCreateRequestModel';
import EventEmitter from 'events';

export enum NextButtonStyle {
    save,
    next,
    refresh,
}

export const TypeIdMainView = (): JSX.Element => {
    
    const eventEmitter = new EventEmitter();
    
    const [page, setPage] = useState<number>(1);
    const [request, setRequest] = useState<TypeIdCreateRequestModel>();
    
    function _setRequestVal(val : TypeIdCreateRequestModel) {
        const f = JSON.parse(JSON.stringify(val));
        setRequest(f);
    }

    function _setAndResetPage(page: number) {
        setPage(page);
    }

    

    return (
        <div className='type_id_container'>
            <div className={ page === 1 ? 'type_id_container__content' : 'type_id_container__content hidden'} >
             <TypeIdNewLevelPage 
                onChangePage={(page) => setPage(page)} 
                onSetRequest={(request) => _setRequestVal(request)} 
                eventEmitter= {eventEmitter}
                /> 
            </div>
            <div className={ page === 2 ? 'type_id_container__content' : 'type_id_container__content hidden'} >
             { page === 2 &&  <TypeIdForm 
             onChangePage={(page) => _setAndResetPage(page)} 
             request= {request}
             eventEmitter= {eventEmitter}
             /> }
            </div>
        </div>
    );
};
