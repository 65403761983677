/** @format */

import { FC } from 'react';
import srcActiveIcon from '../../../../assets/images/v2/el360.png';

interface Props {
  el360_status: string;
}

export const InfoEL360: FC<Props> = ({ el360_status }): JSX.Element => {
  if (el360_status === '1' || el360_status === '2')
    return (
      <div className='info-box el360 status_1'>
         <img src={srcActiveIcon} />
        {/*<div className='text'>{'EL360'}</div>*/}
        {/*<div className='icon'>*/}
        {/*<UIIcon name={'Active'} srcIcon={srcActiveIcon} width={'20'} height={'20'} className="" />*/}
        {/*</div>*/}
      
      </div>
    );
  // else if (status === '2')
  //   return (
  //     <div className='el360 status_2'>
  //       <div className='icon'>
  //         <UIIcon name={'Pending'} srcIcon={srcPendingIcon} width={'20'} height={'20'} className="" />
  //       </div>
  //       <div className='text'>{'EL360'}</div>
  //     </div>
  //   );
  // else
  //   return (
  //     <div className='info-box el360'>
  //       <div className='text'>{'EL360'}</div>
  //       <div className='icon'>
  //         <UIIcon name={'Inactive'} srcIcon={srcBlockIcon} width={'20'} height={'20'} className="" />
  //       </div>
  //     </div>
  //   );
  return <></>;
};
