/** @format */

import { FC, ReactNode } from 'react';
import { TierInfo, TierItem } from '../services/admin/api';
import { numberFormat } from '../utils/numberUtils';
import { TierTableViewNonVS } from './TierTableViewNonVS';

interface Props {
    tiers: Array<TierItem>;
    label: string,
    aup: number,
}
export const RebateViewNonVS: FC<Props> = ({
    tiers,
    aup,
    label,
}): JSX.Element => {

    let maxGrowthTarget = 0;
    let maxGrowthTargetIndex = -1;

    function getGrowthTarget(tier: TierInfo) {
        return tier.growth_target ?? 0.0;
    }

    function getTargetActual(tier: TierInfo | undefined): number {
        if (tier)
            return parseFloat(tier.target_actual ?? '0.0');
        return 0.0;
    }

    function getMaxTargetActual(): number {
        if (maxGrowthTargetIndex >= 0)
            return getTargetActual(tiers[maxGrowthTargetIndex].tier);
        return 0.0;
    }

    (tiers ?? []).forEach((item: TierItem, index: number) => {
        if (item.tier !== null && item.tier !== undefined) {
            const temp = getGrowthTarget(item.tier);
            if (temp > maxGrowthTarget) {
                maxGrowthTarget = temp;
                maxGrowthTargetIndex = index;
            }
        }
    });

    if (maxGrowthTargetIndex >= 0 && tiers.length > maxGrowthTarget) {
        const temp = getTargetActual(tiers[maxGrowthTargetIndex].tier);
        if (temp > maxGrowthTarget) maxGrowthTarget = temp;
    }

    function getCurrentBar() {
        const actual = getMaxTargetActual() ?? 0;
        if (actual <= maxGrowthTarget) {
            return (getMaxTargetActual() ?? 0) * 100.0 / maxGrowthTarget;
        }
        return 100.0;
    }

    function generateBars() {
        let check = 0.0;
        let temp = Array<ReactNode>();
        (tiers ?? []).forEach((item: TierItem, i: number) => {
            const tempTarget = item.tier !== null && item.tier !== undefined ? getGrowthTarget(item.tier) : 0.0;
            const tempBarPercentage = maxGrowthTarget !== 0.0 ? tempTarget * 100.0 / maxGrowthTarget : 0.0;
            const tempTargetDiff = tempTarget - (item.tier !== null && item.tier !== undefined ? getTargetActual(item.tier) : 0.0);

            if (tempBarPercentage >= check) {
                check = tempBarPercentage;
                temp = [<div key={'tier_bar_' + i} className='tier-chart-item target'>
                    <div className="target-box"><div className="target" style={{ left: 'calc(' + getCurrentBar() + '% - 60px)' }}><div className="value rightline">{numberFormat(getMaxTargetActual())}</div></div></div>
                    <div className='tier-target-bar tier-bar'
                        style={{ width: tempTargetDiff < 0 ? '100%' : (tempBarPercentage + '%') }}>{/*<div className='tier-indicator' />*/}</div>
                    <div className={  getCurrentBar() > 90 ? 'tier-bar-label-up upper' : 'tier-bar-label-up'}>{numberFormat(tempTarget)}</div>
                    <div className='tier-bar-label-down'>{numberFormat(tempTargetDiff <= 0.0 ? 0.0 : tempTargetDiff)}&nbsp;to reach target</div>
                    {/*           
                    {false && <div className={
                                'tier-bar-label' :
                                'tier-bar-label left'
                        } style={{
                            width:
                                tempTargetDiff < 0 ? 'calc(100% - 12px)' :
                                    (tempBarPercentage > 15 ?
                                        'calc(' + tempBarPercentage + '% - 12px)' :
                                        tempBarPercentage + '%'),
                        }}><b>{numberFormat(tempTargetDiff <= 0.0 ? 0.0 : tempTargetDiff)}</b>&nbsp;to&nbsp;<b>Tier {i + 1}</b> - {numberFormat(tempTarget)}</div>}*/}
                </div>];
                // temp.push(<div key={'tier_bar_' + i} className='tier-chart-item'>
                // <div className='tier-target-bar tier-bar' style={{ width: tempBarPercentage + '%' }}><div className='tier-indicator' /></div>
                // <div className='tier-bar-label'>{numberFormat(tempTargetDiff)} to <b>Tier {i + 1}</b> - {numberFormat(tempTarget)}</div>
                // </div>);
            }
        });
        return temp;
    }

    return tiers !== null && tiers !== undefined && tiers.length > 0 ?
        <div className='tier-chart-box'>
            <div className='tier-chart-container'>
                <div className='tier-chart'>
                    <>
                        <div className='tier-chart-item current'>
                            <div className='tier-current-bar tier-bar' style={{ width: getCurrentBar() + '%' }}><div className='tier-indicator' /></div>
                            <div className='tier-bar-label'>{label}&nbsp;{numberFormat(getMaxTargetActual())}</div>
                        </div>
                        {generateBars()}
                    </>
                </div>
            </div>
            
            <TierTableViewNonVS
                tiers={tiers ?? []}
                aup={aup}
            />
        </div> : <></>;
};