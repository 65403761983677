/** @format */

import { MESSAGE_NOT_AVAILABLE_ABBREV } from '../constants/general';

export const numberFormat = (value: number | null, notAvailableString = MESSAGE_NOT_AVAILABLE_ABBREV) => {
  if (value !== null && value !== undefined) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }).format(Math.round(value));
  }
  return notAvailableString;
};

export const numberFormatRaw = (value: number | null, notAvailableString = MESSAGE_NOT_AVAILABLE_ABBREV) => {
  if (value !== null && value !== undefined) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(value);
  }
  return notAvailableString;
};

export const percentageFormat = (
  value: number | null,
  enableNotAvailable = true,
  fixedDigits = 1,
  notAvailableString = MESSAGE_NOT_AVAILABLE_ABBREV,
) => {
  if (value !== null && value !== undefined) {
    return (Math.round(Number(value) * 100) / 100).toFixed(fixedDigits) + ' %';
  } else if (enableNotAvailable) {
    return notAvailableString;
  }
  return '';
};

export const decimalFormat = (
  value: any | null,
  applyFormat = true,
  fixedDigits = 0,
  roundTo = 1,
  notAvailableString = MESSAGE_NOT_AVAILABLE_ABBREV,
) => {
  if (value !== null && value !== undefined) {
    if (applyFormat)
      return (Math.round(Number(value) * (10 ^ roundTo)) / (10 ^ roundTo))
        .toFixed(fixedDigits)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return (Math.round(Number(value) * (10 ^ roundTo)) / (10 ^ roundTo)).toFixed(fixedDigits);
  }
  return notAvailableString;
};

export const integerFormat = (value: any | null, notAvailableString = MESSAGE_NOT_AVAILABLE_ABBREV) => {
  if (value !== null && value !== undefined) {
    return (Math.round(Number(value) * 10) / 10)
      .toFixed(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return notAvailableString;
};

export const genericFormat = (value: any) => {
  if (value && value.toString().length > 0) {
    return value;
  }
  return MESSAGE_NOT_AVAILABLE_ABBREV;
};
