/** @format */

import { FC } from 'react';

interface ExpandableProps {
  expanded: boolean;
  boxed?: boolean;
  direction?: string;
  children: JSX.Element;
}

export const Expandable: FC<ExpandableProps> = ({ boxed = false, expanded = false, direction = 'column', children }): JSX.Element => {
  let cssClass = 'expandable';
  if (boxed) cssClass += ' boxed';
  if (expanded) cssClass += ' expanded';
  if (direction === 'column') cssClass += ' vertical';
  else cssClass += ' horizontal';
  
  return (
    <div className={ cssClass }>
      {children}
    </div>
  );
};
