/** @format */

import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './utils/createStore';

import './assets/rh.scss';
import './assets/print.scss';
import { FooterContent } from './shared/FooterContent';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
      {/* <Footer /> */}
      <FooterContent />
    </BrowserRouter>
  </Provider>,
);

reportWebVitals();
