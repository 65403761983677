/** @format */

import { decimalFormat, numberFormat, percentageFormat } from '../utils/numberUtils';

export function TooltipNumberTemplate({ argument, value }: { argument: string; value: number }) {
  return (
    <div>
      <p style={{ fontSize: '14px', fontWeight: '500', color: '#031434', padding: 0, margin: 0, textAlign: 'center' }}>
        {numberFormat(value)}
      </p>
      {false && <p style={{ fontSize: '10px', color: '#8792B1', margin: 0 }}>{argument}</p>}
    </div>
  );
}

export function TooltipPercentageTemplate({ argument, value }: { argument: string; value: number }) {
  return (
    <div>
      <p style={{ fontSize: '14px', color: 'white', fontWeight: 600, margin: 0 }}>
        {percentageFormat(value)}
      </p>
      <p style={{ fontSize: '10px', color: '#8792B1', margin: 0 }}>{argument}</p>
    </div>
  );
}

export function TooltipDecimalTemplate({ argument, value }: { argument: string; value: number }) {
  return (
    <div>
      <p style={{ fontSize: '14px', color: 'white', fontWeight: 600, margin: 0 }}>
        {decimalFormat(value)}
      </p>
      <p style={{ fontSize: '10px', color: '#8792B1', margin: 0 }}>{argument}</p>
    </div>
  );
}
