import { FC, useEffect } from 'react';
import { mapColors } from '../../../../theme/mapColors';
import {
  Chart,
  Series,
  Label,
  CommonSeriesSettings,
  ValueAxis,
  Legend,
  CommonAxisSettings,
  ArgumentAxis,
  Format,
} from 'devextreme-react/chart';
import './Performance_graph.scss';

interface GraphProps {
  id: string;
  data: any;
  prefix?: string | null,
  updateLayout: boolean;
}
export const PerformanceSideGraph: FC<GraphProps> = ({ id, data = null, updateLayout = false, prefix = null }): JSX.Element => {

  let chart: any;

  useEffect(() => {
    if (chart) {
      chart.instance.render();

    }
  }, [updateLayout]);

  return (
    <Chart
      id={id}
      className='side_chart'
      ref={(ref: any) => { chart = ref; }}
      negativesAsZeroes={false}
      rotated={true}
      dataSource={data}>

      <CommonSeriesSettings
        barWidth={30}
        barPadding={0}
        cornerRadius={4}
        argumentField="key"
        type="bar"
        hoverMode="allArgumentPoints"
        selectionMode="allArgumentPoints"
      >
        <Label visible={true} showForZeroValues={false}
          customizeText={(e: any) => `${prefix === null ? '' : ` ${prefix}`} ${e.valueText}`}
          font={{ color: '#333333', weight: 600, size: '10px' }}
          backgroundColor={'#FFFFFF'}
          border={{ color: '#DDDDDD', width: 1, dashStyle: 'solid', visible: true }}
        >
          <Format type="fixedPoint" precision={0} />
        </Label>
      </CommonSeriesSettings>

      <CommonAxisSettings
        tick={{ visible: false }}
        color={mapColors.backgrounds.borderSecondary}
      />

      <ValueAxis
        grid={{ visible: false }}
        label={{
          font:
            { color: mapColors.fonts.fontTertiary, weight: '600', size: '8px' },
          visible: true,
          customizeText: (e: any) => `${prefix === null ? '' : ` ${prefix}`} ${e.valueText}`,
        }}
      />

      <ArgumentAxis
        grid={{
          color: mapColors.backgrounds.borderTertiary,
          visible: false,
        }}
        visible={false}
        label={{ font: { color: mapColors.fonts.fontPrimary, weight: '600', size: '14px' }, visible: false }} />

      <Series valueField="cy" color={'#091f5a'} />
      <Series valueField="py" color={'#bdcbfc'} />

      <Legend visible={false} horizontalAlignment="center" />

    </Chart>
  );
};
