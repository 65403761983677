/** @format */

import { FC } from 'react';

interface Props {
  color: string;
}
export const IconOutline: FC<Props> = ({ color }): JSX.Element => {
  return (
    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 34 34">
      <g id="invisible_box" data-name="invisible box">
        <rect id="Rettangolo_4068" data-name="Rettangolo 4068" width="12" height="12" fill="none" />
      </g>
      <g id="icons_Q2" data-name="icons Q2" transform="translate(7.084 7.055)">
        <path
          id="Tracciato_1092"
          data-name="Tracciato 1092"
          d="M21.9,19.9l7.508-7.437a1.487,1.487,0,0,0,.142-1.913,1.346,1.346,0,0,0-2.125-.142l-7.508,7.508-7.508-7.508a1.346,1.346,0,0,0-2.125.142,1.488,1.488,0,0,0,.142,1.913L17.934,19.9l-7.508,7.438a1.487,1.487,0,0,0-.142,1.913,1.346,1.346,0,0,0,2.125.142l7.508-7.508L27.425,29.4a1.346,1.346,0,0,0,2.125-.142,1.487,1.487,0,0,0-.142-1.913Z"
          transform="translate(-10.001 -9.959)"
          fill={color}
        />
      </g>
    </svg>
  );
};
