/** @format */
import { FC } from 'react';
import { Header } from '../../shared/Header';

export const CookiePolicy: FC = (): JSX.Element => {

    return (
        <>
            <Header />
            <section className="main">
                <div className="container cookie-policy">
                    <h2>Cookie Policy</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pellentesque maximus lorem a dictum. Nullam et felis ut mauris suscipit hendrerit. Praesent gravida, nunc ornare blandit sagittis, justo tortor facilisis metus, a pharetra justo neque vitae quam. Sed euismod sollicitudin diam sit amet aliquam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras commodo mauris non est viverra, sit amet malesuada felis dignissim. Praesent gravida lobortis quam, id rhoncus nisl fringilla ut. Sed eget ipsum magna.</p>
                    <p>Aenean eget nibh feugiat, lobortis sapien at, tempor orci. Aliquam erat volutpat. Proin commodo congue tellus eget euismod. Vestibulum tempor posuere malesuada. Sed vel enim consequat, viverra purus vel, mollis tortor. Aliquam consectetur quam in suscipit laoreet. Duis tempor hendrerit orci. Vivamus euismod felis et bibendum accumsan. Duis non dui bibendum, molestie nibh ac, tincidunt sapien. Proin ultricies metus id lectus volutpat, eu blandit libero mollis. Aenean erat mauris, vehicula a commodo vel, lobortis sed lacus. Nunc vestibulum purus vitae nibh tincidunt maximus quis in ipsum. Phasellus turpis nunc, congue eget felis ac, aliquam blandit purus. Aliquam quis vestibulum ex, a sodales turpis. Sed rutrum augue sit amet ipsum tincidunt, in blandit leo tristique. Nam egestas libero in fermentum accumsan.</p>
                    <p>Cras luctus blandit ligula non commodo. Vestibulum dapibus eleifend nulla at congue. Integer non tellus diam. Donec eu consequat justo. Cras congue posuere nunc, id rhoncus ex vulputate sed. Curabitur purus risus, sollicitudin et placerat sed, ullamcorper ut enim. Sed ac ligula magna. Vivamus vitae imperdiet elit. Donec ac augue placerat, mollis ante id, elementum ante. Aenean at ornare sapien.</p>
                    <p>Quisque non pretium magna, aliquet commodo sapien. Maecenas orci turpis, efficitur eu risus eu, tristique faucibus lorem. In porttitor sed magna ut aliquet. Nulla in tempus justo, at sagittis libero. Ut interdum tincidunt mauris eget porta. Phasellus pretium, massa eu lacinia fermentum, arcu nibh semper ante, eu maximus lacus elit vel quam. Quisque rutrum, sapien non pharetra semper, risus eros hendrerit sapien, at aliquet urna lacus non ante. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Fusce ut tempor orci. Sed eu libero magna.</p>
                    <p>Cras et lacus libero. Quisque urna nibh, auctor sed dolor eu, convallis pulvinar neque. Aenean enim justo, lobortis eget ultrices in, congue eu mauris. Etiam auctor neque mauris, vitae sodales leo pellentesque sed. Donec varius mauris sapien, in egestas turpis semper eu. Etiam gravida sodales odio eget viverra. Quisque placerat, dolor pretium consequat suscipit, sapien sem sollicitudin nulla, vel faucibus nulla ex quis nunc. Vestibulum eu venenatis turpis. Nunc in massa ornare, ultrices risus eget, aliquet sem. Morbi augue nisl, bibendum eget vestibulum ut, consectetur ut odio. Fusce finibus dignissim massa, vel finibus erat accumsan et. Vivamus et felis facilisis, fermentum justo dignissim, pellentesque erat. In at pharetra lectus. Morbi pretium urna ante, at sollicitudin ante facilisis quis.</p>
                </div>
            </section>
        </>
    );
};