/** @format */

import { FC, useState } from 'react';
import { numberFormat } from '../utils/numberUtils';

import iconSilver from '../assets/images/v2/silver.svg';
import iconGold from '../assets/images/v2/gold.svg';
import iconPlatinum from '../assets/images/v2/platinum.svg';
import iconDiamond from '../assets/images/v2/diamond.svg';
import iconMultiDiamond from '../assets/images/v2/multidiamond.svg';

/*
import iconSilverBig from '../assets/icons/solid/Silver-big.svg';
import iconGoldBig from '../assets/icons/solid/Gold-big.svg';
import iconPlatinumBig from '../assets/icons/solid/Platinum-big.svg';
import iconDiamondBig from '../assets/icons/solid/Diamond-big.svg';
import iconMultiDiamondBig from '../assets/icons/solid/MultiDiamond-big.svg';
*/

interface Props {
  value: number;
  needed: number;
  total: number;
}
export const ChartBullet: FC<Props> = ({ value, needed, total }): JSX.Element => {
  if (value > 0) value = value + 1; // WORKAROUND OF THE CENTURY!
  let bigIconCheck = '';

  function fixValuePercentage(val: number) {
    const tempValue = needed > 0 ? val : total;
    if (tempValue <= 0) {
      return 0.0;
    } else if (tempValue <= 15000) {
      bigIconCheck = 'silver';
      return (((tempValue * 100) / 15000) * 25) / 100;
    } else if (tempValue <= 40000) {
      bigIconCheck = 'gold';
      return 25 + ((((tempValue - 15000) * 100) / (40000 - 15000)) * 25) / 100;
    } else if (tempValue <= 80000) {
      bigIconCheck = 'platinum';
      return 50 + ((((tempValue - 40000) * 100) / (80000 - 40000)) * 25) / 100;
    } else if (tempValue <= 150000) {
      bigIconCheck = 'diamond';
      return 75 + ((((tempValue - 80000) * 100) / (150000 - 80000)) * 25) / 100;
    } else {
      bigIconCheck = 'multi';
      return 100.0;
    }
    /* else if (tempValue <= 160000) {
      bigIconCheck = 'multi';
      return 80 + ((((tempValue - 120000) * 100) / (160000 - 120000)) * 20) / 100;
    } */
  }

  function valueCssPosition(val: number) {
    if (val < 7) return 'value leftline';
    else if (val > 93) return 'value rightline';
    else return 'value';
  }

  const fixedValuePercentage = fixValuePercentage(value);
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    
      <div className='chart-bullet-box' onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
        <div className='status'>
          <div className='status-item'>
            <img src={iconSilver} className={bigIconCheck === 'silver' ? 'big' : ''} width="60" height="60" />
            <div className='name'>Silver</div>
            <div className='goal'>$ 0</div>
          </div>
          <div className='status-item'>
            <img src={iconGold} className={bigIconCheck === 'gold' ? 'big' : ''} width="60" height="60" />
            <div className='name'>Gold</div>
            <div className='goal'>$ 15K</div>
          </div>
          <div className='status-item'>
            <img src={iconPlatinum} className={bigIconCheck === 'platinum' ? 'big' : ''} width="60" height="60" />
            <div className='name'>Platinum</div>
            <div className='goal'>$ 40K</div>
          </div>
          <div className='status-item'>
            <img src={iconDiamond} className={bigIconCheck === 'diamond' ? 'big' : ''} width="60" height="60" />
            <div className='name'>Diamond</div>
            <div className='goal'>$ 80K</div>
          </div>
          <div className='status-item'>
            <img src={iconMultiDiamond} className={bigIconCheck === 'multi' ? 'big' : ''} width="60" height="60" />
            <div className='name'>Diamond Plus</div>
            <div className='goal'>$ 150K</div>
          </div>
        </div>
        <div className='bars'>
          <div className='main-bar' />
          <div className='progress-bar' style={{ width: fixedValuePercentage + '%' }} />
          {showTooltip && <div className='bar-tooltip'>Tier Progress over current calendar year</div>}
        </div>
        {/*
      <div className='goals-box'>
        <div className='goals'>
          <div className='goal'><div className='tick' /><span>$ 0</span></div>
          <div className='goal'><div className='tick' /><span>$ 15K</span></div>
          <div className='goal'><div className='tick' /><span>$ 40K</span></div>
          <div className='goal'><div className='tick' /><span>$ 80K</span></div>
          <div className='goal'><div className='tick' /><span>$ 150K</span></div>
        </div>
  </div>*/}
        <div className='target-box'>
          <div className='target' style={{ left: 'calc(' + fixedValuePercentage + '% - 100px)' }}>
            {/*<div className='line' />*/}
            <div className={valueCssPosition(fixedValuePercentage)}>{needed > 0 ? numberFormat(value) : numberFormat(total)}</div>
          </div>
        </div>
        <div className='progress-box'>
          <div className='progress' style={{ left: fixedValuePercentage + '%' }}>
            <div className='indicator' />
          </div>
        </div>
      </div>
  );
};
