/** @format */

import { FC, useState } from 'react';
import { ADMIN_ROLE, ROUTE_ADMIN_REBATES, ROUTE_HOME } from '../constants/general';
import { UIIcon } from './Icon';
import logo from '../assets/images/v2/logo.png';
import search from '../assets/images/v2/search.svg';
import close from '../assets/images/v2/close.svg';
import export_pdf from '../assets/images/v2/export.svg';
import avatar from '../assets/images/v2/avatar.svg';
import menu from '../assets/images/v2/menu.svg';
import { SearchInput } from './SearchInput';
import { useNavigate } from 'react-router-dom';
import { apiRevoke } from '../services/auth/api';
import { jwtToken } from '../services/token';
import { SectionNavigator } from './SectionNavigator';

export interface MainHeaderProps {
  enableLeftMenu: boolean;
  showSearchBar: boolean;
  showNavigationBar: boolean;
  showSearchButton: boolean;
  showPdfExportButton: boolean;
  showUser: boolean;
  customer: any | null;
}

export const MainHeader: FC<MainHeaderProps> = ({ enableLeftMenu, showSearchBar, showNavigationBar, showSearchButton, showPdfExportButton, showUser, customer }): JSX.Element => {
  const navigate = useNavigate();
  const [leftMenuVisible, setLeftMenuVisible] = useState(false);
  const [optionsMenuVisible, setOptionsMenuVisible] = useState<boolean>(false);
  const [searchBarVisible, setSearchBarVisible] = useState(showSearchBar);
  const userRole = localStorage.getItem('role');
  const name = localStorage.getItem('firstName');
  const lastName = localStorage.getItem('lastName');
  const refreshToken = localStorage.getItem('refreshToken');
  const isAdmin = (userRole === ADMIN_ROLE);

  const onShowLeftMenu = () => {
    setLeftMenuVisible(true);
  };

  const onHideLeftMenu = () => {
    setLeftMenuVisible(false);
  };

  const onClickOpen = () => setOptionsMenuVisible(!optionsMenuVisible);
  const onClickSearch = () => setSearchBarVisible(!searchBarVisible);

  const onClickRebate = () => {
    navigate(`${ROUTE_ADMIN_REBATES}`, { replace: true });
  };

  const onClickLogout = () => {
    if (jwtToken !== undefined && refreshToken) {
      apiRevoke({ refreshToken })
        .then(() => {
          // DO NOTHING
        })
        .catch(() => {
          // DO NOTHING
        })
        .finally(function () {
          localStorage.clear();
          location.replace(process.env.REACT_APP_URL_LOGOUT_NAM ? process.env.REACT_APP_URL_LOGOUT_NAM : '');
        });
    }
  };

  const isXR = (customer?.customer_data?.alliance_code ?? '').toLowerCase().startsWith('xr');
  const customerCode = customer?.customer_data?.customer_code ?? '';
  const level = customer?.customer_data?.customer_status_level?.level_status_description ?? '';

  return (
    <>
      <header id="main-header">
        <div className='logo'>
          {enableLeftMenu &&
            <a onClick={() => onShowLeftMenu()}><div className='circle-button'><img src={menu} className='menu' /></div></a>}
          <a href={`${ROUTE_HOME}`}>
            <UIIcon name={''} srcIcon={logo} width={''} height={''} className="rh" />
          </a>
        </div>
        <div className='search'>
          {searchBarVisible && <SearchInput isSerp={true} />}
          {!searchBarVisible && showNavigationBar && <SectionNavigator leftMode={false} isXR={isXR} customerCode={customerCode} level={level} onLinkClick={() => { }} />}
        </div>
        <div className='actions'>
          {showSearchButton &&
            <a onClick={onClickSearch}>
              {!searchBarVisible && <div className='circle-button'><img src={search} className='search' /></div>}
              {searchBarVisible && <div className='circle-button'><img src={close} className='close' /></div>}
            </a>}
          {showPdfExportButton &&
            <a data-bs-toggle="modal" data-bs-target="#pdf-export-options"><div className='circle-button'><img src={export_pdf} className='export' /></div></a>
          }
          {showUser &&
            <a onClick={onClickOpen}>
              <div className='user-info'>
                <div className='circle-button'>
                  <img src={avatar} />
                </div>
                <div className='details'>
                  <div className='name'>{name} {lastName}</div>
                  <div className='role'>{userRole}</div>
                </div>
              </div>
            </a>}
          {optionsMenuVisible && (
            <div className='bubble'>
              {isAdmin &&
                <>
                  <button className='rebate' onClick={onClickRebate}>
                    <div className='icon' />
                    Rebate tool
                  </button>
                </>
              }
              <button className='logout' onClick={onClickLogout}>
                <div className='icon' />
                Logout
              </button>
            </div>)}
        </div >
      </header>

      <div id="left-menu" className={leftMenuVisible ? 'opened' : 'closed'}>
        <div className='head'>
          <a href={`${ROUTE_HOME}`}>
            <UIIcon name={''} srcIcon={logo} width={''} height={''} className="rh" />
          </a>
        </div>
        {showNavigationBar && <SectionNavigator leftMode={true} isXR={isXR} customerCode={customerCode} level={level} onLinkClick={() => { setLeftMenuVisible(false); }} />}
        <a onClick={() => onHideLeftMenu()}><div className='circle-button'><img src={menu} className='close' /></div></a>
      </div>
    </>
  );
};
