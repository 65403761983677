import { FC, useEffect, useState } from 'react';
import './CellBoxContainer.scss';

import '../assets/rh.scss';
import iconEdit from '../assets/icons/admin/edit.svg';
import iconDelete from '../assets/icons/admin/delete.svg';
import iconCollapse from '../assets/icons/outline/collapse.svg';

interface CellBoxContainerProps {
    onEdit: () => void;
    onCancel: () => void;
    title: string;
    content: JSX.Element;
}

export const CellBoxContainer: FC<CellBoxContainerProps> = ({
    title = '',
    content = null,
    onEdit = () => { },
    onCancel = () => { },
}): JSX.Element => {
    const [isClose, setIsClose] = useState<boolean>(false);

    useEffect(() => {
    }, []);

    function shrink() {
        setIsClose(!isClose);
    }

    function _buildHeader() {
        return <div className='cellbox--header'>
            <div className='cellbox--headertitle'>{title}</div>
            <div className='cellbox--headerbuttons'>
                <div className='cellbox--button'>
                    <img alt="Delete" src={iconEdit} width={'16'} height={'16'} onClick={onEdit} />
                </div>
                <div className='cellbox--button'>
                    <img alt="Delete" src={iconDelete} width={'16'} height={'16'} onClick={onCancel} />
                </div>
                <img className='cellbox--expand'
                    onClick={shrink}
                    src={iconCollapse}
                    width={'16'}
                    height={'16'}
                    style={{ transform: isClose ? 'rotate(180deg)' : 'rotate(0deg)' }} />
            </div>
        </div>;
    }

    function _buildContent() {
        if (content !== null || content !== undefined) {
            return <div className='cellbox--content' style={{ height: isClose ? '0px' : 'auto' }}>{content}</div>;
        }
        return <div className='cellbox--content' />;
    }

    return (
        <div className='cellbox'>
            {_buildHeader()}
            {_buildContent()}
        </div>
    );
};
