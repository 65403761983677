/** @format */
import { DataGrid } from 'devextreme-react';
import { Column, ColumnFixing, FilterRow, HeaderFilter, Pager, Paging, Sorting } from 'devextreme-react/data-grid';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { apiAdminRebateDelete, apiAdminRebateList } from '../../../services/admin/api';
import { apiRefresh } from '../../../services/auth/api';
import { ROUTE_ADMIN_REBATES_EDIT, ROUTE_ADMIN_TYPE_IDS_ADD } from '../../../constants/general';
import { Expandable } from '../../../shared/Expandable';
import { NoData } from '../../../shared/NoData';
import { UITabTitle } from '../../Dashboard/partials/TabTitle';

import iconEdit from '../../../assets/icons/admin/edit.svg';
import iconDelete from '../../../assets/icons/admin/delete.svg';
import { useDispatch } from 'react-redux';
import { loaderSlice } from '../../../services/loader/slice';
import './rebates-styles.scss';
import { InfoTooltip } from '../../../shared/InfoTooltip';
import { clearStorage } from '../../../utils/localStorageUtils';
import { MainHeader } from '../../../shared/MainHeader';

export const AdminRebates: FC = (): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { customerId } = useParams();
    const [isExpanded] = useState(true);
    const [hasData, setHasData] = useState(false);
    const [data, setData] = useState([]);

    const [confirmTitle, setConfirmTitle] = useState('');
    const [confirmMessage, setConfirmMessage] = useState('');
    const [confirmId, setConfirmId] = useState(0);
    const [confirmOperation, setConfirmOperation] = useState('');

    function _loadData(retry = true) {

        dispatch(loaderSlice.actions.startLoading());

        apiAdminRebateList().then(response => {
            const tempData = response && response.data ? response.data.data : [];
            if (tempData.length > 0) {
                setData(tempData);
                setHasData(true);
            }
            dispatch(loaderSlice.actions.stopLoading());
        }).catch(error => {
            const refreshToken = localStorage.getItem('refreshToken');
            if (error.response && error.response.status && error.response.status === 401 && refreshToken) {
                apiRefresh({ refreshToken })
                    .then(response => {
                        if (response.data?.data?.jwt_token && response.data?.data?.refresh_token) {
                            localStorage.setItem('jwtToken', response.data.data.jwt_token);
                            localStorage.setItem('refreshToken', response.data.data.refresh_token);
                            if (retry) {
                                _loadData(true);
                            }
                        }
                    })
                    .catch(() => {
                        clearStorage();
                    });
            } else {
                clearStorage();
            }
        });
    }

    function _deleteRebate(retry = true) {
        dispatch(loaderSlice.actions.startLoading());
        apiAdminRebateDelete({ id: confirmId }).then(response => {
            const tempData = response && response.data ? response.data.data : [];
            if (tempData.length > 0) {
                setData(tempData);
                setHasData(true);
            }
            dispatch(loaderSlice.actions.stopLoading());
            _loadData();
        }).catch(error => {
            const refreshToken = localStorage.getItem('refreshToken');
            if (error.response && error.response.status && error.response.status === 401 && refreshToken) {
                apiRefresh({ refreshToken })
                    .then(response => {
                        if (response.data?.data?.jwt_token && response.data?.data?.refresh_token) {
                            localStorage.setItem('jwtToken', response.data.data.jwt_token);
                            localStorage.setItem('refreshToken', response.data.data.refresh_token);
                            if (retry) {
                                _deleteRebate(true);
                            }
                        }
                    })
                    .catch(() => {
                        clearStorage();
                    });
            } else {
                clearStorage();
            }
        });
    }

    useEffect(() => {
        _loadData();
    }, []);

    function onTypeIds() {
        navigate(ROUTE_ADMIN_TYPE_IDS_ADD, { replace: true });
    }

    function onAdd() {
        navigate(ROUTE_ADMIN_REBATES_EDIT, { replace: true });
    }

    function onEdit(id: number) {
        navigate(ROUTE_ADMIN_REBATES_EDIT + '?rebateId=' + id, { replace: true });
    }

    function RebateEditButton(cell: any) {
        return (
            <img className='edit' alt="Edit" src={iconEdit} width={'16'} height={'16'} onClick={() => onEdit(cell.data.id_rebate)} />
        );
    }

    function RebateDeleteButton(cell: any) {
        return (
            <img className='delete' alt="Delete" src={iconDelete} width={'16'} height={'16'} data-bs-toggle="modal" data-bs-target="#confirmDialog" onClick={() => {
                setConfirmTitle('Delete Rebate?');
                setConfirmMessage(`Are you sure you want to permanently delete the rebate '${cell.data.name}'?`);
                setConfirmId(cell.data.id_rebate);
                setConfirmOperation('delete');
            }} />
        );
    }
    const headerCellTemplate = (data: any) => {
        return <div className='table_header'>{data.column.name}<InfoTooltip targetId={data.column.name} text={data.column.name} /></div>;
    };
    const renderStatusCell = (cell: any) => {
        let className = '';
        switch (cell.data.status) {
            case 'Approved': {
                className = 'approved';
            } break;
            case 'Draft': {
                className = 'draft';
            } break;
            default: {
                className = '';
            } break;
        }
        return <div className={className}>{cell.data.status}</div>;
    };

    /*
    function cellPrepared(e: any) {
        if (e.rowType === 'data') {
            if (e.column.dataField === 'status') {
                let className = 'draft';
                switch (e.data.status) {
                    case 'Approved': {
                        className = 'approved';
                    } break;
                    default: {
                        className = 'draft';
                    } break;
                }
                e.cellElement.classList.add(className);
            }
        }
    }
    */

    return (
        <>
            {/*<Header isSearch={true} />*/}
            <MainHeader
                showUser={false}
                enableLeftMenu={false}
                showNavigationBar={false}
                showPdfExportButton={false}
                showSearchBar={false}
                showSearchButton={false}
                customer={null} />

            <section className="main">
                <div className="container admin admin_rebates">
                    <div className="data-panel">
                        <UITabTitle
                            label={'Rebates'}
                            infoMessage={''}
                            collapsed={isExpanded}
                            expandable={false}
                            url={''}
                            customerId={customerId || '0'}
                            onAdd={() => onAdd()}
                            onTypeIds={() => onTypeIds()}
                        />
                        <Expandable expanded={true} boxed={false}>
                            {hasData ?
                                <DataGrid
                                    dataSource={data}
                                    allowColumnReordering={true}
                                    rowAlternationEnabled={true}
                                    showBorders={true}
                                    showColumnLines={false}
                                    showRowLines={false}
                                    allowColumnResizing={true}
                                    columnAutoWidth={true}
                                    columnMinWidth={40}

                                    // onCellPrepared={cellPrepared}
                                    repaintChangesOnly={true}
                                // onContentReady={this.onContentReady}
                                >
                                    <Sorting mode="multiple" />
                                    <ColumnFixing enabled={false} />
                                    <HeaderFilter visible={false} />
                                    <FilterRow visible={false} /* applyFilter={this.state.currentFilter} */ />

                                    {/*
                                    <Column dataField="id_rebate" caption="#" alignment="center">
                                        <HeaderFilter allowSearch={false} />
                                    </Column>
                                    */}
                                    <Column dataField="rebate_code" caption="#" alignment="left">
                                        <HeaderFilter allowSearch={false} />
                                    </Column>
                                    <Column dataField="name" caption="NAME" alignment="left" headerCellRender={headerCellTemplate}>
                                        <HeaderFilter allowSearch={true} />
                                    </Column>
                                    <Column dataField="type" caption="TYPE of REBATE" alignment="left" headerCellRender={headerCellTemplate}>
                                        <HeaderFilter allowSearch={true} />
                                    </Column>
                                    <Column dataField="section" caption="SECTION" alignment="left" headerCellRender={headerCellTemplate}>
                                        <HeaderFilter allowSearch={true} />
                                    </Column>
                                    <Column dataField="sales" caption="SALES ORG" alignment="left" headerCellRender={headerCellTemplate}>
                                        <HeaderFilter allowSearch={true} />
                                    </Column>
                                    <Column dataField="status" width={90} caption="STATUS" alignment="left" cellRender={renderStatusCell} headerCellRender={headerCellTemplate}>
                                        <HeaderFilter allowSearch={true} />
                                    </Column>
                                    <Column dataField="dates" caption="DATES (Valid to - from)" alignment="left" headerCellRender={headerCellTemplate}>
                                        <HeaderFilter allowSearch={false} />
                                    </Column>
                                    <Column dataField="period" caption="REBATE PERIOD" alignment="left" headerCellRender={headerCellTemplate}>
                                        <HeaderFilter allowSearch={true} />
                                    </Column>

                                    <Column alignment="center" width={50} fixed={true} fixedPosition="right" cellRender={RebateEditButton} allowReordering={false} />
                                    <Column alignment="center" width={50} fixed={true} fixedPosition="right" cellRender={RebateDeleteButton} allowReordering={false} />

                                    <Pager
                                        visible={true}
                                        // allowedPageSizes={false}
                                        displayMode={'compact'}
                                        showPageSizeSelector={false}
                                        showInfo={true}
                                        showNavigationButtons={true}
                                    />

                                    <Paging defaultPageSize={20} />
                                </DataGrid>
                                :
                                <NoData />
                            }
                        </Expandable>
                    </div>
                </div>
            </section>

            <div className="modal fade" id="confirmDialog" tabIndex={-1} aria-labelledby="confirmDialogLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="confirmDialogLabel">{confirmTitle}</h5>
                            <button id="confirmDialogCloseButton" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <div className='container'>
                                <div className='row'>
                                    <div className='col'>
                                        {confirmMessage}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={() => {
                                document.getElementById('confirmDialogCloseButton')?.click();
                                if (confirmOperation === 'delete') {
                                    _deleteRebate();
                                } else if (confirmOperation === 'edit') {
                                    // DO NOTHING
                                } else {
                                    // DO NOTHING
                                }
                            }}>
                                {confirmOperation === 'delete' && 'Confirm'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

