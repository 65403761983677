import { FC, useState } from 'react';
import './CellBoxContainer.scss';

import '../assets/rh.scss';

import { Tooltip } from 'devextreme-react';
import { UIIcon } from './Icon';
import srcInfo from '../assets/icons/outline/InfoBlue.svg';

interface InfoTooltipProps {
    text: string;
    targetId: string;
  }

export const InfoTooltip: FC<InfoTooltipProps> = ({
    text = '',
    targetId = '',
}): JSX.Element => {
  const [visible, setVisible] = useState<boolean>(false);
  
    function _buildTooltip() {
        return <div id={'tooltip_' + targetId} className='info_tooltip'>
          <div className='hover' 
           onMouseLeave={ () => setVisible(false) }
           onMouseOver={ () => setVisible(true) }>
           <UIIcon name={'Info'} srcIcon={srcInfo} width={'12'} height={'12'} className="" />
          </div>
           <Tooltip
            target={'#tooltip_' + targetId}
            visible={visible}
            /*hideOnOutsideClick={false}*/>
            <div>{text}</div>
          </Tooltip>
        </div>;
    }
    return (_buildTooltip());
};
