/** @format */

import { FC, useState } from 'react';
import { UITabTitle } from '../partials/TabTitle';
import { Expandable } from '../../../shared/Expandable';
import { GenericTableRebate } from '../../../shared/GenericTableRebate';
import { numberFormat, percentageFormat } from '../../../utils/numberUtils';
import { BOX_TITLE_TOTAL_PROFITABILITY } from '../../../constants/general';

type SavingsPropos = {
  customer: any;
};

export const Savings: FC<SavingsPropos> = ({ customer = null }): JSX.Element => {

  const [currentYear, setCurrentYear] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div id="savings" className="data-panel savings">
      <UITabTitle
        label={BOX_TITLE_TOTAL_PROFITABILITY}
        infoMessage={'Free shipping savings exclude overseas shipments from Alain Mikli, Brunello Cucinelli, Oliver Peoples, Persol, and Stark.  Commercial discounts and free shipping values are not shown for purchases made through Frame Dream, Focus on Frames, and PERC Advantage.'}
        onClick={() => setIsExpanded(!isExpanded)}
        collapsed={isExpanded}
      >

        <div className="selector" style={isExpanded ? {} : { display: 'none ' }}>
          <div className={!currentYear ? 'previous-year selected' : 'previous-year'} onClick={(event) => { event.stopPropagation(); if (currentYear && isExpanded) setCurrentYear(false); }}>Previous year</div>
          <div className={currentYear ? 'current-year selected' : 'current-year'} onClick={(event) => { event.stopPropagation(); if (!currentYear && isExpanded) setCurrentYear(true); }}>Current year</div>
        </div>

      </UITabTitle>
      <Expandable expanded={isExpanded}>
        <div className='box'>
          <div className='savings'>
            <GenericTableRebate items={
              currentYear ?
                customer?.new_luxottica_portfolio_savings?.current_year.items ?? [] :
                customer?.new_luxottica_portfolio_savings?.previous_year.items ?? []} />
          </div>
          <div className='total'>
            <div className='value'>
              {currentYear ?
                numberFormat(customer?.new_luxottica_portfolio_savings?.current_year.total) :
                numberFormat(customer?.new_luxottica_portfolio_savings?.previous_year.total)}
            </div>
            <div className='label'>Total savings</div>
            <div className='percentage'>
              {(currentYear ?
                percentageFormat(customer?.new_luxottica_portfolio_savings?.current_year?.total_percentage ?? 0.0) :
                percentageFormat(customer?.new_luxottica_portfolio_savings?.previous_year?.total_percentage ?? 0.0)) + ' of total portfolio gross sales'}
            </div>

          </div>
        </div>
      </Expandable>
    </div>
  );
};