/** @format */

import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { apiLogin, apiRefresh } from '../services/auth/api';
import { sliceAuth } from '../services/auth/slice';
//import { ROUTE_DASHBOARD, ROUTE_LOGIN, ROUTE_NO_PERMISSION, ROUTE_SEARCH } from '../constants/general';
import { ROUTE_DASHBOARD, ROUTE_NO_PERMISSION, ROUTE_SEARCH } from '../constants/general';
import { useAuth0 } from '@auth0/auth0-react';
// import { useAppSelector } from '../hooks/redux';
// import { selectorCustomerId } from '../@ducks/auth/selectors';
import { apiCustomerRole } from '../services/customer/api';
// import { sliceCustomer } from '../@ducks/customer/slice';
import { loaderSlice } from '../services/loader/slice';
import { clearStorage } from '../utils/localStorageUtils';

export const PageHome: FC = (): JSX.Element | null => {
  const { loginWithRedirect } = useAuth0();

  // const [token, setToken] = useState<string>();
  const userId = localStorage.getItem('id'); // useAppSelector(selectorCustomerId);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const [searchParams] = useSearchParams();
  const authCode = searchParams.get('code');
  // const debugMode = searchParams.get('debug');

  const token = localStorage.getItem('jwtToken');
  const refreshToken = localStorage.getItem('refreshToken');

  useEffect(() => {
    const updateInfoElement = document.getElementById('update-info');
    if (updateInfoElement) updateInfoElement.style.display = 'none';

    if (!token) {
      if (authCode) {
        dispatch(loaderSlice.actions.startLoading());
        apiLogin(authCode)
          .then(response => {
            localStorage.setItem('jwtToken', response.data.data.jwt_token);
            localStorage.setItem('id', response.data.data.id);
            localStorage.setItem('firstName', response.data.data.first_name);
            localStorage.setItem('lastName', response.data.data.last_name);
            localStorage.setItem('refreshToken', response.data.data.refresh_token);
            localStorage.setItem('luxId', response.data.data.lux_id);
            localStorage.setItem('role', response.data.data.user_role?.user_role_description);
            dispatch(sliceAuth.actions.login(response.data.data));
            dispatch(loaderSlice.actions.stopLoading());
            if (localStorage.getItem('jwtToken')) {
              location.reload();
            } else {
              navigate(ROUTE_NO_PERMISSION, { replace: true });
            }
          })
          .catch(() => {
            navigate(ROUTE_NO_PERMISSION, { replace: true });
          });
      } else {
        clearStorage();
        //navigate(ROUTE_LOGIN, { replace: true });
        loginWithRedirect();
      }
    } else {
      if (userId !== null && userId !== 'null') {
        dispatch(loaderSlice.actions.startLoading());
        apiCustomerRole({ customerId: parseInt(userId, 10), token })
          .then(response => {
            localStorage.setItem('role', response.data.data.user_role?.user_role_description);
            localStorage.setItem('customerId', response.data.data.customer_id);
            localStorage.setItem('typeId', response.data.data.user_type_id);

            // dispatch(sliceCustomer.actions.createCustomerRole(response.data.data));

            const customer_id = localStorage.getItem('customerId');
            const customer_type_id = localStorage.getItem('typeId');

            dispatch(loaderSlice.actions.stopLoading());

            if (customer_id !== null && customer_id !== 'null') {
              navigate(`${ROUTE_DASHBOARD}/${customer_id}/${customer_type_id}`, { replace: true });
            } else {
              navigate(`${ROUTE_SEARCH}`, { replace: true });
            }
          })
          .catch(error => {
            if (error.response && error.response.status && error.response.status === 401 && refreshToken) {
              apiRefresh({ refreshToken })
                .then(response => {
                  if (response.data?.data?.jwt_token && response.data?.data?.refresh_token) {
                    localStorage.setItem('jwtToken', response.data.data.jwt_token);
                    localStorage.setItem('refreshToken', response.data.data.refresh_token);
                    if (localStorage.getItem('jwtToken')) {
                      location.reload();
                    }
                  }
                })
                .catch(() => {
                  clearStorage();
                  //navigate(ROUTE_LOGIN, { replace: true });
                  loginWithRedirect();
                });
            } else {
              clearStorage();
              //navigate(ROUTE_LOGIN, { replace: true });
              loginWithRedirect();
            }
          });
      } else {
        clearStorage();
        //navigate(ROUTE_LOGIN, { replace: true });
        loginWithRedirect();
      }
    }
  }, []);

  return <div />;
};
