/** @format */

import { createSlice } from '@reduxjs/toolkit';

export const loaderSlice = createSlice({
  name: 'loader',
  initialState: {
    isLoading: false,
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    stopLoading: state => {
      state.isLoading = false;
    },
  },
});

export const { startLoading, stopLoading } = loaderSlice.actions;

/*
const loaderStore = configureStore({
  reducer: loaderSlice.reducer,
});

// Can still subscribe to the store
// loaderStore.subscribe(() => console.log(loaderStore.getState()));
*/
