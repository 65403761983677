/** @format */

import { FC } from 'react';
import styled from '@emotion/styled';

import { UIIcon } from '../../../../shared/Icon';
import iconCY from '../../../../assets/icons/solid/CYSquare.svg';
import iconPY from '../../../../assets/icons/solid/PYSquare.svg';

//#region :::

const Container = styled.div({
  margin: '0 20px',
  display: 'flex',
  width: '700px',
  justifyContent: 'start',
});

const LegendBox = styled.div({
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  marginRight: '15px',
});

const LegendTitle = styled.p({
  fontSize: '10px',
  fontWeight: '600',
  color: '#8792B1',
  marginLeft: '5px',
});

//#endregion

export const LegendGPN: FC = (): JSX.Element => {
  return (
    <Container>
      <LegendBox>
        <UIIcon name={'light-blue'} srcIcon={iconPY} width={'10'} height={'10'} className="" />
        <LegendTitle>Previous Year</LegendTitle>
      </LegendBox>
      <LegendBox>
        <UIIcon name={'blue'} srcIcon={iconCY} width={'10'} height={'10'} className="" />
        <LegendTitle>Current Year</LegendTitle>
      </LegendBox>
    </Container>
  );
};
