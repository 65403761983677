/** @format */

import { FC, useEffect, useState } from 'react';
import { UITabTitle } from '../partials/TabTitle';
import { BrandSales } from './partials/BrandSales';
// import { TitleBar } from './partials/BrandSales/partials/TitleBar';
import { BrandRebate } from './partials/BrandRebate';
import { apiBrandDetails } from '../../../services/brand/api';
import { apiRefresh } from '../../../services/auth/api';
import { useNavigate } from 'react-router-dom';
import { BOX_TITLE_BRAND, ROUTE_HOME } from '../../../constants/general';
import { loaderSlice } from '../../../services/loader/slice';
import { useDispatch } from 'react-redux';
import { Expandable } from '../../../shared/Expandable';
import { NoData } from '../../../shared/NoData';
import { Performances } from '../../../services/rebate/api';
import { clearStorage } from '../../../utils/localStorageUtils';
import { Target } from '../../../shared/Target';

type BrandPrintableProps = {
  customer: any;
  isXR: boolean;
  performances: Performances | null;
  brandId?: number;
};

export type BrandData = {
  id: number;
  data: any;
};

export const BrandPrintable: FC<BrandPrintableProps> = ({ customer = null, isXR, performances, brandId = 0 }): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customerId = customer?.customer_data?.customer_id || null;

  const [isExpanded, setIsExpanded] = useState(false);
  const [brands, setBrands] = useState<Array<BrandData>>([]);
  const [brandName, setBrandName] = useState('');
  const [brandDiscount, setBrandDiscount] = useState(false);

  function findBrand(id: number) {
    return brands.findIndex(elem => elem.id === id);
  }

  function getCustomerBrandDiscount(brand_id: number | undefined) {
    customer?.customer_data?.brands?.forEach((brand: any) => {
      if (brand.brand_id === brand_id) {
        setBrandDiscount(brand.discount ?? false);
        return;
      }
    });
  }

  function loadData(loadBrandId: number, retry = true) {
    if (customer && loadBrandId && customerId) {
      dispatch(loaderSlice.actions.startLoading());
      apiBrandDetails({
        customerId: customerId,
        brandId: loadBrandId,
        currentYear: customer?.years.current_year,
        previousYear: customer?.years.previous_year,
      })
        .then(response => {

          // Fix rayban brand name
          if (response.data.data.brand_name.toUpperCase() === 'RAYBAN' ||
            response.data.data.brand_name.toUpperCase() === 'RAY BAN' ||
            response.data.data.brand_name.toUpperCase() === 'RAY-BAN')
            response.data.data.brand_name = 'RAY-BAN';

          if (response.data.data.brand_name.toUpperCase() === 'OAKLEY')
            response.data.data.brand_name = 'OAKLEY & COSTA';

          let temp = findBrand(loadBrandId);
          if (temp > 0) {
            // DO NOTHING
          } else {
            brands.push({ id: brandId, data: null });
            temp = findBrand(loadBrandId);
          }
          brands[temp].data = response.data.data;
          
          setBrandName(brands[temp].data.brand_name);
          setBrands(brands);
          getCustomerBrandDiscount(loadBrandId);

          dispatch(loaderSlice.actions.stopLoading());
        })
        .catch(error => {
          const refreshToken = localStorage.getItem('refreshToken');
          if (error.response && error.response.status && error.response.status === 401 && refreshToken) {
            apiRefresh({ refreshToken })
              .then(response => {
                if (response.data?.data?.jwt_token && response.data?.data?.refresh_token) {
                  localStorage.setItem('jwtToken', response.data.data.jwt_token);
                  localStorage.setItem('refreshToken', response.data.data.refresh_token);
                  if (retry) {
                    loadData(loadBrandId, false);
                  }
                }
              })
              .catch(() => {
                clearStorage();
                navigate(`${ROUTE_HOME}`, { replace: true });
              });
          } else {
            clearStorage();
            navigate(`${ROUTE_HOME}`, { replace: true });
          }
        });
    }
  }

  useEffect(() => {
    loadData(brandId);
    setIsExpanded(true);
  }, []);

  // Load brands for selection
  const tempBrands = customer?.customer_data.brands || [];
  const brandsArray = Array<any>();
  tempBrands.forEach((brand: any) => {
    if (brand.brand_name.toLowerCase() !== 'costa')
      brandsArray.push(brand);
  });

  function priority(brandName: string) {
    if (brandName.toLowerCase().includes('costa')) return 10;
    if (brandName.toLowerCase().includes('oakley')) return 100;
    if (brandName.toLowerCase().includes('ray ban')) return 1000;
    if (brandName.toLowerCase().includes('rayban')) return 1000;
    if (brandName.toLowerCase().includes('ray-ban')) return 1000;
    return 10000000;
  }

  function dataPriority(hasData: boolean) {
    if (hasData) return 10000;
    return -1;
  }

  // brands.sort((a: any, b: any) => (priority(a.brand_name) <= priority(b.brand_name) ? (a.brand_name < b.brand_name ? 1 : -1) : -1));
  brandsArray.sort((a: any, b: any) => (a.brand_name > b.brand_name ? 1 : -1));
  brandsArray.sort((a: any, b: any) => (priority(a.brand_name) < priority(b.brand_name) ? 1 : -1));
  brandsArray.sort((a: any, b: any) => (dataPriority(a.is_data_available) < dataPriority(b.is_data_available) ? -1 : 1));
  brandsArray.reverse();

  return (
    <div id='brand-data' className="data-panel" data-brand={brandId}>
      <UITabTitle
        label={BOX_TITLE_BRAND}
        infoMessage={'Quarterly brand performance, quarterly and annual brand rebate information'}
        onClick={() => setIsExpanded(!isExpanded)}
        collapsed={isExpanded}
      />

      <Expandable expanded={true}>
        <>
          <div className='actions'>
            <div className='brand-selection'>{brandName}</div>
            {brands.length > 0 && (
              brands.map((brand: any) => {
                const hasData = brand?.data?.brand_sales?.length > 0;
                const isDiscount = brandDiscount;
                return hasData && isDiscount && <Target key={'brand_target_' + brand.brand_id} />;
              }))}
          </div>
          {brands.length > 0 && <div className='separator' />}
          {brands.length > 0 && (
            brands.map((brand: any) => {
              const hasData = brand?.data?.brand_sales?.length > 0;
              return (
                <div className="box-selection" key={`brand_${brand.data.brand_id}`}>
                  <div style={{ width: '100%' }}>
                    {/*<TitleBar isDiscount={isDiscount} label={brand?.data.brand_name || ''} */}
                    {hasData ? (
                      <>
                        <div className='data-row'>
                          <BrandSales brand={brand.data} />
                        </div>
                        {!isXR ? (
                          <div className='rebate-box-separator' style={{ margin: '0 16px' }}>
                            <BrandRebate brand={brand.data} performances={performances} customer={customer} />
                          </div>
                        ) : (
                          <div style={{ height: '16px' }} />
                        )}
                      </>
                    ) : (
                      <NoData />
                    )}
                  </div>
                </div>
              );
            })
          )}
        </>
      </Expandable>
    </div>
  );
};
