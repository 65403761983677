/** @format */

import { ChangeEvent, FC, useState } from 'react';
import styled from '@emotion/styled';

import { UIIcon } from './Icon';

import searchIcon from '../assets/icons/outline/SearchWhite.svg';
import searchIconBlue from '../assets/icons/outline/Search.svg';
import { sliceResults } from '../services/search/slice';
import { useAppDispatch } from '../hooks/redux';
import { UITooltip } from './Tooltip';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTE_SERP } from '../constants/general';
// import { ROUTE_SERP } from '../constants/general';

//#region ::: PARTIALS

const Submit = styled.button({
  width: '60px',
  height: '60px',
  background: 'white',
  borderRadius: '50px',
  boxShadow: '0px 10px 30px -15px #000000',
  border: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '17px',
  cursor: 'pointer',
});

const SubmitBox = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});

//#endregion

export interface Props {
  isNavigate?: boolean;
  isSerp?: boolean;
}

export const SearchInput: FC<Props> = ({ isSerp = false /* , isNavigate = false  */ }): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isTooltip, setIsTooltip] = useState(false);
  const [searchParams] = useSearchParams();
  const _currentSearch = searchParams.get('search');
  const [searchInput, setInputResult] = useState<string>(_currentSearch ? decodeURIComponent(_currentSearch) : '');

  const searchCustomer = () => {
    dispatch(sliceResults.actions.storeResults([]));
    if (searchInput) {
      const params = {
        search: searchInput.trim(),
        // 'page': 1
      };

      navigate(
        {
          pathname: ROUTE_SERP,
          search: `?${createSearchParams(params)}`,
        },
        {
          replace: true,
        },
      );
    }
  };

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setInputResult(e.target.value);
    setIsTooltip(false);
  };

  const onFormSubmit = (e: any) => {
    e.preventDefault();
    if (searchInput.length > 2) {
      dispatch(sliceResults.actions.storeInputSearch(searchInput));
      searchCustomer();
    } else {
      setIsTooltip(true);
    }
  };

  function getTooltipBoxClassName() {
    let output = 'tooltip-box';
    if (isSerp) output += ' serp';
    return output;
  }

  return (
    <div className='search-box'>
      <form style={{ display: isSerp ? 'grid' : '' }} onSubmit={onFormSubmit}>
        <input
          style={{ top: isSerp ? 0 : '', margin: isSerp ? '' : '60px 0 80px 0' }}
          onFocus={() => setIsTooltip(false)}
          value={searchInput}
          onChange={onChangeSearch}
          /*placeholder="Customer search"*/
        />

        {isSerp ? (
          <button>
            <UIIcon name={''} srcIcon={searchIcon} width={'16'} height={'16'} className="" />
          </button>
        ) : (
          <SubmitBox>
            <Submit>
              <UIIcon name={'search-icon'} srcIcon={searchIconBlue} width={'30'} height={'30'} className="" />
            </Submit>
          </SubmitBox>
        )}
      </form>
      <div className={getTooltipBoxClassName()}>
        <UITooltip isSearch isTopPointing={isSerp} label={'Enter at least 3 characters'} isVisible={isTooltip} />
      </div>
    </div>
  );
};
