/** @format */

import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { TVariant, utilityGetColorByVariant } from '../../../utils/getColorByVariant';
import { IconOutline } from './partials/IconOutline';
import { IconSolid } from './partials/IconSolid';

interface Props {
  variant: TVariant;
  iconSrc?: string;
  customer: any;
}

export const Alert: FC<Props> = ({ variant, customer = null }): JSX.Element => {
  const notice: string = customer ? customer?.customer_data.customer_notice : '';

  const [isAlert, setIsAlert] = useState(false);
  const [isDetail, setIsDetail] = useState(false);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const alertText = notice;

  const outlineColor = utilityGetColorByVariant(variant).color;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsAlert(notice !== null && notice.length > 0);
    setIsDetail(notice !== null && notice.length > 80);
  }, [customer]);

  useLayoutEffect(() => {
    const { current } = ref;
    if (current) {
      {
        const width = current.offsetWidth;
        if (width >= 685) setIsDetail(true);
      }
    }
  }, []);

  const onClickClose = () => {
    setIsAlert(false);
    setIsDetail(false);
    setIsDetailOpen(false);
  };

  const onClickDetail = () => {
    setIsDetailOpen(!isDetailOpen);
    setIsDetail(!isDetail);
  };

  return (
    <div className='alert'>
      {isAlert && (
        <>
          <div className='content' style={{ animation: isAlert ? 'fadein 1.5s' : 'fadeout 1.5s', height: isDetailOpen ? 'auto' : '50px', backgroundColor: utilityGetColorByVariant(variant).background }}>
            <IconSolid variant={variant} />
            <div className='text' style={{ whiteSpace: isDetailOpen ? 'normal' : 'nowrap', overflow: isDetailOpen ? 'visible' : 'hidden' }}>
              <p className='announce' style={{ color: utilityGetColorByVariant(variant).color }} ref={ref}>{alertText}</p>
              {isDetailOpen && <button className='read-less' onClick={onClickDetail}>Read less</button>}
            </div>

            {isDetail && (
              <>
                <p className='announce' style={{
                  marginLeft: '10px',
                  marginTop: '12px',
                  color: utilityGetColorByVariant(variant).color,
                }}>...</p>
                <div className='buttons'>
                  <div className='read-more' style={{ backgroundColor: utilityGetColorByVariant(variant).color }} onClick={onClickDetail}>
                    <p>Read more</p>
                  </div>
                </div>
              </>
            )}
            <button className='close' onClick={onClickClose}>
              <IconOutline color={outlineColor} />
            </button>
          </div>
        </>
      )}
    </div>
  );
};
