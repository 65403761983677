/** @format */

import { FC, useEffect, useState } from 'react';
// import styled from '@emotion/styled';

import { mapColors } from '../../../theme/mapColors';
import { UITabTitle } from '../partials/TabTitle';
import { CardEL360 } from './CardEL360';

import iconDevice from '../../../assets/icons/outline/DeviceBlue.svg';
import iconHome from '../../../assets/icons/outline/HomeOrange.svg';
// import iconGift from '../../../assets/icons/outline/GiftPurple.svg';
import iconPC from '../../../assets/icons/outline/PC.svg';
import iconBlock from '../../../assets/icons/solid/Block.svg';
import iconHourglass from '../../../assets/icons/solid/HourglassYellow.svg';
import iconVerified from '../../../assets/icons/solid/VerifiedGreen.svg';
import { BOX_TITLE_EL360, EXPORT_EL360, TABLE_CURRENCY_FORMAT, TABLE_NUMBER_FORMAT, TABLE_PERCENTAGE_FORMAT } from '../../../constants/general';
import { Expandable } from '../../../shared/Expandable';
import DataGrid, {
  Column,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  Sorting,
} from 'devextreme-react/data-grid';
import { NoData } from '../../../shared/NoData';
import { apiEL360Compliance } from '../../../services/el360/api';
import { apiRefresh } from '../../../services/auth/api';
import { useParams } from 'react-router-dom';
import { percentageFormat } from '../../../utils/numberUtils';
import { clearStorage } from '../../../utils/localStorageUtils';

//#region ::: PARTIALs

/*
const Box = styled.div({
  width: '100%',
  height: 'auto',
  backgroundColor: mapColors.backgrounds.borderTertiary,
  borderBottomLeftRadius: '10px',
  borderBottomRightRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  border: `1px solid ${mapColors.backgrounds.borderPrimary}`,
  borderCollapse: 'collapse',
});
*/

//#endregion

type EL360Props = {
  customer: any;
  printMode: boolean;
};

export const EL360: FC<EL360Props> = ({ customer = null, printMode = false }): JSX.Element => {
  const { customerId } = useParams();
  const [isExpanded, setIsExpanded] = useState(false);
  const [hasData, setHasData] = useState(false);
  const [data, setData] = useState([]);

  const customerCode = customer?.customer_data?.customer_code || null;
  const commonOwnerCode = customer ? customer?.customer_data.common_owner_code : '';
  const isCommonOwner = commonOwnerCode !== null && commonOwnerCode.length > 0;

  let statusCodeDescriptionDigitalScreen =
    customer?.customer_data.digital_screens_status.digital_screens_status_description;
  const statusCodeDigitalScreen = customer?.customer_data.digital_screens_status.status_code;
  // const linkDigitalScreen = customer?.customer_data.digital_screens_status.digital_screens_status_link;
  const iconDigitalScreen =
    statusCodeDigitalScreen === '1' ? iconVerified : statusCodeDigitalScreen === '2' ? iconHourglass : iconBlock;

  let statusCodeDescriptionSmartShopper = customer?.customer_data.smartshopper_status.smartshopper_status_description;
  const statusCodeSmartShopper = customer?.customer_data.smartshopper_status.status_code;
  // const linkSmartShopper = customer?.customer_data.smartshopper_status.smartshopper_status_link;
  const iconSmartShopper =
    statusCodeSmartShopper === '1' ? iconVerified : statusCodeSmartShopper === '2' ? iconHourglass : iconBlock;

  let statusCodeDescriptionPromoBox = customer?.customer_data.promobox_status.promobox_status_description;
  // const statusCodePromoBox = customer?.customer_data.promobox_status.status_code;
  // const linkPromoBox = customer?.customer_data.promobox_status.promobox_status_link;
  // const iconPromoBox = statusCodePromoBox === '1' ? iconVerified : statusCodePromoBox === '2' ? iconHourglass : iconBlock;

  let statusCodeDescriptionSmartBook =
    customer?.customer_data.smartbook_status.smartbook_status_description ??
    customer?.customer_data.smartbook_status.smartbook_status_description;
  const statusCodeSmartBook = customer?.customer_data.smartbook_status.status_code;
  // const linkSmartBook = customer?.customer_data.smartbook_status.smartbook_status_link;
  const iconSmartBook =
    statusCodeSmartBook === '1' ? iconVerified : statusCodeSmartBook === '2' ? iconHourglass : iconBlock;

  if (statusCodeDescriptionDigitalScreen.length <= 0) statusCodeDescriptionDigitalScreen = 'Not Active';
  if (statusCodeDescriptionSmartShopper.length <= 0) statusCodeDescriptionSmartShopper = 'Not Active';
  if (statusCodeDescriptionPromoBox.length <= 0) statusCodeDescriptionPromoBox = 'Not Active';
  if (statusCodeDescriptionSmartBook.length <= 0) statusCodeDescriptionSmartBook = 'Not Active';

  function _loadData(code: string, isCommonOwner: boolean, retry = true) {
    if (code) {
      apiEL360Compliance({
        code: code,
        isCommonOwner: isCommonOwner,
      }).then(response => {
        const tempData = response && response.data ? response.data.data : [];
        if (tempData.length > 0) {
          setData(tempData);
          setHasData(true);
        }
      }).catch(error => {
        const refreshToken = localStorage.getItem('refreshToken');
        if (error.response && error.response.status && error.response.status === 401 && refreshToken) {
          apiRefresh({ refreshToken })
            .then(response => {
              if (response.data?.data?.jwt_token && response.data?.data?.refresh_token) {
                localStorage.setItem('jwtToken', response.data.data.jwt_token);
                localStorage.setItem('refreshToken', response.data.data.refresh_token);
                if (retry) {
                  _loadData(code, isCommonOwner, true);
                }
              }
            })
            .catch(() => {
              clearStorage();
            });
        } else {
          clearStorage();
        }
      });
    }
  }

  useEffect(() => {
    _loadData(customerCode, isCommonOwner, true);
  }, [customerCode]);

  function gpnSowPreprocess(cellInfo: { value: string; }) {
    if (cellInfo.value !== null && cellInfo.value !== undefined
      && cellInfo.value.length > 0
      && cellInfo.value.toLowerCase() !== 'nan')
      return percentageFormat(parseFloat(cellInfo.value) * 100.0);
    return '-';
  }
  
  return (
    <div id="el360panel" className="data-panel">
      <UITabTitle
        label={BOX_TITLE_EL360}
        infoMessage={''}
        onClick={() => setIsExpanded(!isExpanded)}
        collapsed={isExpanded}
      />
      <Expandable expanded={isExpanded} direction={'column'} boxed={true}>
        <>
          <div className="row items">
            <CardEL360
              title={'Digital Screens'}
              statusDescription={statusCodeDescriptionDigitalScreen}
              srcIconTop={iconDevice}
              srcIConBottom={iconDigitalScreen}
              labelColor={mapColors.theme.primary[0]}
            // link={linkDigitalScreen || ''}
            />
            <CardEL360
              title={'Smart Shopper'}
              statusDescription={statusCodeDescriptionSmartShopper}
              srcIconTop={iconHome}
              srcIConBottom={iconSmartShopper}
              labelColor={mapColors.theme.orange}
            // link={linkSmartShopper || ''}
            />
            {/*
            <CardEL360
              title={'PromoBoxx'}
              statusDescription={statusCodeDescriptionPromoBox}
              srcIconTop={iconGift}
              srcIConBottom={iconPromoBox}
              labelColor={mapColors.theme.lilac}
            // link={linkPromoBox || ''}
            />*/}
            <CardEL360
              title={'Smart Book'}
              statusDescription={statusCodeDescriptionSmartBook}
              srcIconTop={iconPC}
              srcIConBottom={iconSmartBook}
              labelColor={mapColors.theme.blue}
            // link={linkSmartBook || ''}
            />
          </div>
          <div className='divider' />
          <div className="data-panel">
            <UITabTitle
              label={'Data'}
              infoMessage={''}
              collapsed={isExpanded}
              expandable={false}
              url={hasData ? EXPORT_EL360 : ''}
              customerId={customerId || '0'}
            />
            <Expandable expanded={true} boxed={true}>
              {hasData ?
                <DataGrid
                  dataSource={data}
                  allowColumnReordering={true}
                  rowAlternationEnabled={true}
                  showBorders={true}
                  showColumnLines={false}
                  showRowLines={false}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  columnMinWidth={200}
                  repaintChangesOnly={true}
                // onContentReady={this.onContentReady}
                >
                  <Sorting mode="multiple" />
                  <ColumnFixing enabled={false} />
                  <HeaderFilter visible={false} />
                  <FilterRow visible={false} /* applyFilter={this.state.currentFilter} */ />

                  <Column dataField="main_account_code" caption="CO/MAIN ACCOUNT CODE" alignment="left">
                    <HeaderFilter allowSearch={true} />
                  </Column>
                  <Column dataField="customer_code" caption="CUSTOMER CODE" alignment="left">
                    <HeaderFilter allowSearch={true} />
                  </Column>
                  <Column dataField="expert_parent_id" caption="EXPERT PARENT ID" alignment="left">
                    <HeaderFilter allowSearch={true} />
                  </Column>
                  <Column dataField="previous_quarter_compliance" caption="PREVIOUS QUARTER COMPLIANCE" alignment="left">
                    <HeaderFilter allowSearch={true} />
                  </Column>
                  <Column dataField="current_quarter_complicance" caption="CURRENT QUARTER COMPLIANCE" alignment="left">
                    <HeaderFilter allowSearch={true} />
                  </Column>
                  <Column
                    dataField="gpn_sow"
                    dataType="number"
                    caption="GPN SOW"
                    alignment="left"
                    format={TABLE_PERCENTAGE_FORMAT}
                    customizeText={gpnSowPreprocess}>
                    <HeaderFilter allowSearch={true} />
                  </Column>
                  <Column
                    dataField="parent_door_count"
                    dataType="number"
                    format={TABLE_NUMBER_FORMAT}
                    caption="PARENT DOOR COUNT"
                    alignment="left">
                    <HeaderFilter allowSearch={true} />
                  </Column>
                  <Column
                    dataField="active_brand_count"
                    dataType="number"
                    format={TABLE_NUMBER_FORMAT}
                    caption="ACTIVE BRAND"
                    alignment="left">
                    <HeaderFilter allowSearch={true} />
                  </Column>
                  <Column
                    dataField="sales_door"
                    dataType="number"
                    caption="$ SALES/DOORS"
                    alignment="left"
                    format={TABLE_CURRENCY_FORMAT}>
                    <HeaderFilter allowSearch={true} />
                  </Column>

                  <Pager
                    
                    visible={printMode ? false : true}
                    // allowedPageSizes={false}
                    displayMode={'compact'}
                    showPageSizeSelector={false}
                    showInfo={true}
                    showNavigationButtons={true}
                  />
                  <Paging defaultPageSize={printMode ? 100000 : 10} />
                </DataGrid>
                :
                <NoData />
              }
            </Expandable>
          </div>
        </>
      </Expandable>
    </div>
  );
};
