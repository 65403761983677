/** @format */

import { FC } from 'react';

import {
  Chart,
  Series,
  CommonSeriesSettings,
  ValueAxis,
  CommonAxisSettings,
  ArgumentAxis,
} from 'devextreme-react/chart';
import { mapColors } from '../theme/mapColors';
import { Legend, Tooltip } from 'devextreme-react/pie-chart';
import { TooltipNumberTemplate } from './TooltipTemplate';

//#region :::

/*
const ChartLabel = styled.div({
  width: '50px',
  backgroundColor: 'white',
  justifyContent: 'center',
  display: 'flex',
  fontWeight: 'bold',
  fontSize: '8px',
  height: '14px',
  position: 'absolute',
  marginTop: '138px',
  alignItems: 'center',
  borderRadius: '2px',
});

const QLabel = styled.p({
  fontWeight: 'bold',
  fontSize: '10px',
  marginRight: '4px',
  marginBottom: '0px',
});

const YLabel = styled.p({
  fontWeight: 'bold',
  fontSize: '10px',
  color: mapColors.fonts.fontTertiary,
  marginBottom: '0px',
});
*/
//#endregion

const customizeLabel = () => {
  return {
    visible: false,
    backgroundColor: 'white',
  };
};

type SideBySideChartProps = {
  brand: any;
};

export const SideBySideChart: FC<SideBySideChartProps> = ({ brand = null }): JSX.Element => {
  const grossProductData = [
    {
      state: 'Q1',
      py: brand?.brand_sales[0]?.sales || 0,
      cy: brand?.brand_sales[4]?.sales || 0,
    },
    {
      state: 'Q2',
      py: brand?.brand_sales[1]?.sales || 0,
      cy: brand?.brand_sales[5]?.sales || 0,
    },
    {
      state: 'Q3',
      py: brand?.brand_sales[2]?.sales || 0,
      cy: brand?.brand_sales[6]?.sales || 0,
    },
    {
      state: 'Q4',
      py: brand?.brand_sales[3]?.sales || 0,
      cy: brand?.brand_sales[7]?.sales || 0,
    },
  ];

  return (
    <div className='sales-chart-box'>
      {/*
      <ChartLabel style={{ marginLeft: '57px' }}>
        <QLabel>Q1</QLabel>
        <YLabel>PY</YLabel>
      </ChartLabel>
      <ChartLabel style={{ marginLeft: '113px' }}>
        <QLabel>Q1</QLabel>
        <YLabel>CY</YLabel>
      </ChartLabel>
      <ChartLabel style={{ marginLeft: '217px' }}>
        <QLabel>Q2</QLabel>
        <YLabel>PY</YLabel>
      </ChartLabel>
      <ChartLabel style={{ marginLeft: '273px' }}>
        <QLabel>Q2</QLabel>
        <YLabel>CY</YLabel>
      </ChartLabel>
      <ChartLabel style={{ marginLeft: '377px' }}>
        <QLabel>Q3</QLabel>
        <YLabel>PY</YLabel>
      </ChartLabel>
      <ChartLabel style={{ marginLeft: '433px' }}>
        <QLabel>Q3</QLabel>
        <YLabel>CY</YLabel>
      </ChartLabel>
      <ChartLabel style={{ marginLeft: '537px' }}>
        <QLabel>Q4</QLabel>
        <YLabel>PY</YLabel>
      </ChartLabel>
      <ChartLabel style={{ marginLeft: '593px' }}>
        <QLabel>Q4</QLabel>
        <YLabel>CY</YLabel>
      </ChartLabel>
      */}
      
      <Chart style={{ height: '160px', paddingLeft: '110px' }} customizeLabel={customizeLabel} dataSource={grossProductData}>
        <CommonSeriesSettings
          cornerRadius={4}
          barWidth={90}
          argumentField="state"
          label={{ visible: true }}
          type="bar"
        />

        <CommonAxisSettings
          tick={{ visible: false }}
          grid={{ color: mapColors.backgrounds.borderSecondary }}
          color={mapColors.backgrounds.borderSecondary}
        />

        <ValueAxis
          visible={false}
          label={{ font: { color: mapColors.fonts.fontTertiary, weight: '600', size: '8px' } }}
        />

        <ArgumentAxis label={{ visible: false }} />
        <Legend visible={false} />

        <Tooltip
          enabled={true}
          cornerRadius={8}
          color="#fff"
          interactive={false}
          border={{ color: '#031434' }}
          paddingTopBottom={0}
          contentRender={TooltipNumberTemplate} />

        <Series valueField="py" color='#bdcbfc' />
        <Series valueField="cy" color='#091f5a' />
      </Chart>
    </div>
  );
};
