/** @format */

import { FC } from 'react';
import { SideBySideChart } from '../../../../../shared/SideBySideChart';
import { Legend } from './partials/Legend';
// import { LabelPrice } from './partials/LabelPrice';
import { Grid } from './partials/Grid';

type BrandSalesProps = {
  brand: any;
};

export const BrandSales: FC<BrandSalesProps> = ({ brand = null }): JSX.Element => {
  return (
    <>
      <div className='chart-box'>
        <div style={{ width: '100%' }}>
          <Legend />
          {/*<LabelPrice brand={brand} />*/}
          <SideBySideChart brand={brand} />
          <Grid brand={brand} />
        </div>
      </div>
    </>
  );
};
