/** @format */

import { FC } from 'react';
import { MESSAGE_NOT_AVAILABLE_DATA } from '../constants/general';

export const NoData: FC = (): JSX.Element => {
  return (
    <div className="no-data">{MESSAGE_NOT_AVAILABLE_DATA}</div>
  );
};

export const NoDataRewardsRebates: FC = (): JSX.Element => {
  return (
    <div className="no-data">Luxottica Rewards Portfolio rebates is only available to Diamond Plus accounts. Contact your sales consultant for more information.</div>
  );
};
