
import axios from 'axios';

export const apiPerformances = ({
    code,
    isCommonOwner,
}: {
    code: string
    isCommonOwner: boolean
}) => {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwtToken')}` },
      };
      return axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/brand/performance?code=${code}&isCommonOwner=${isCommonOwner ? 'true' : 'false'}`, config );
    };

  /*
performance?code=000012345786&isCommonOwner=true
https://lr-tetra-be-d02.azurewebsites.net/api/v1/brand/*/

export const apiPerformancesStandard = ({
  code,
}: {
  code: number
}) => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('jwtToken')}` },

    };
    return axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/brand/standard?CustomerId=${code}`, config );
  };

