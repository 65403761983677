/** @format */

import { FC } from 'react';
import { TierInfo, TierItem } from '../services/admin/api';
import { numberFormat } from '../utils/numberUtils';

export type RebateMaxValueViewNonVSProps = {
    tiers: Array<TierItem>;
};

export const RebateMaxValueViewNonVS: FC<RebateMaxValueViewNonVSProps> = ({ tiers }): JSX.Element => {

    let maxPotentialRebate = 0.0;
    let maxTrendingRebate = 0.0;

    function getValueFromTarget(tier: TierInfo) {
        return tier.from_target ?? 0.0;
    }

    function getPotentialRebate(tier: TierInfo) {
        if (getValueFromTarget(tier) > 0.0) {
            return tier.trending_rebate ?? 0.0;
        }
        return 0.0;
    }

    function getTrendingRebate(tier: TierInfo) {
        if (getValueFromTarget(tier) <= 0.0) {
            return tier.trending_rebate ?? 0.0;
        }
        return 0.0;
    }

    tiers.forEach((item: any) => {
        const potentialRebate = getPotentialRebate(item.tier);
        const trendingRebate = getTrendingRebate(item.tier);
        if (potentialRebate > maxPotentialRebate) maxPotentialRebate = potentialRebate;
        if (trendingRebate > maxTrendingRebate) maxTrendingRebate = trendingRebate;
    });

    return <div className={'total-box'}>
        <div className='total-value'>
            {maxPotentialRebate > 0 && maxPotentialRebate >= maxTrendingRebate ? <>{numberFormat(maxPotentialRebate)}</> : <>{numberFormat(maxTrendingRebate)}</>}
        </div>
        <div className='total-title'>
            {maxPotentialRebate > 0 && maxPotentialRebate >= maxTrendingRebate ? <>Total potential rebate</> : <>Total trending rebate</>}
        </div>
    </div>;
};