export interface TypeIduildingModel {
    id : number,
    spk_group : string,
    spk_group_id : string,
    spk_is_brand : string,
    spk_level : string,
    in_group : string,
    checked : boolean,
    selected : boolean
}

export class SelectionElements {
    id  = -1;
    
    childs: SelectionChilds[] = [];
}

export class SelectionChilds {
    id  = -1;
    
    childs: number[] = [];
}

export class CustomLevel {

    constructor(label: string, active: boolean) {
        this.label = label;
        this.active = active;
    }

    label  = '';

    active  = false;
}

export class InputCheck {

    constructor(level: number, status: boolean, statusColor: string, value: string, errorMessage: string) {
        this.level = level;
        this.status = status;
        this.statusColor = statusColor;
        this.value = value;
        this.errorMessage = errorMessage;
    }

    level  = -1;

    status  = false;

    statusColor = '';

    errorMessage = '';

    value : string | undefined = undefined;
}