/** @format */

import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_ADMIN_REBATES } from '../../../constants/general';

export const BackButton: FC = (): JSX.Element => {
  const navigate = useNavigate();

  function onBack() {
    navigate(ROUTE_ADMIN_REBATES, { replace: true });
  }

  return (
    <div className="back">
      <a onClick={() => onBack()}>Back to rebates</a>
    </div>
  );
};
