/** @format */

import { FC } from 'react';
import { TierInfo, TierItem } from '../services/admin/api';
import { decimalFormat, numberFormat, percentageFormat } from '../utils/numberUtils';

const labels = [
  {
    title: '',
    Q1: 'Target %',
    Q2: 'Growth Target $',
    Q3: '$ From Target',
    Q4: 'Est. Units from Target',
    Q5: 'Rebate %',
    Q6: 'Potential Rebate*',
    Q7: 'Trending Rebate*',
  },
];

type TierTableViewNonVSProps = {
  tiers: Array<TierItem>;
  aup: number;
};


export const TierTableViewNonVS: FC<TierTableViewNonVSProps> = ({ tiers, aup }): JSX.Element => {

  function compareTiersPerc(a:TierItem, b:TierItem) {
    if (a.tier === null || a.tier === undefined) return -1;
    if (a.tier.target_perc === null || a.tier.target_perc === undefined) return -1;
    if (b.tier === null || b.tier === undefined) return 1;
    if (b.tier.target_perc === null || b.tier.target_perc === undefined) return 1;
    if (a.tier.target_perc < b.tier.target_perc) return 1;
    return -1;
  }
  const mytiers = tiers.sort((a, b) => compareTiersPerc(a, b));
  
  let maxPotentialRebate = 0.0;
  let maxTrendingRebate = 0.0;
  
  function getGrowthTarget(tier: TierInfo) {
    return tier.growth_target ?? 0.0;
  }

  function getValueFromTarget(tier: TierInfo) {
    return tier.from_target ?? 0.0;
  }

  function getEstimatedUnitsFromTarget(tier: TierInfo) {
    // Est Units from Target	= From target / Current Year to date average unit price
    const tempAup = Math.round(aup);
    return getValueFromTarget(tier) / tempAup;
  }

  function getPotentialRebate(tier: TierInfo) {
    if (getValueFromTarget(tier) > 0.0) {
      return tier.trending_rebate ?? 0.0;
    }
    return 0.0;
    /*
    // Potential Rebate	= Growth target $ * Rebate %
    if (getValueFromTarget(tier) <= 0) return 0;
    return getGrowthTarget(tier) * (tier.rebate_target_perc ? tier.rebate_target_perc / 100 : 0);
    */
  }

  function getTrendingRebate(tier: TierInfo) {
    if (getValueFromTarget(tier) === 0.0) {
      return tier.trending_rebate ?? 0.0;
    }
    return 0.0;
    
    // return tier.trending_rebate ?? 0.0;
    /*
    // Trending Rebate	= CY QTD * Rebate %
    if (getValueFromTarget(tier) <= 0) {
      const trendingRebate = parseFloat(tier.target_actual ?? '0') * (tier.rebate_target_perc ? tier.rebate_target_perc / 100 : 0);
      if (trendingRebate < maxTrendingRebate) return 0;
      return trendingRebate;
    }
    return 0;
    */
  }

  tiers.forEach((item: any) => {
    const potentialRebate = getPotentialRebate(item.tier);
    const trendingRebate = getTrendingRebate(item.tier);
    if (potentialRebate > maxPotentialRebate) maxPotentialRebate = potentialRebate;
    if (trendingRebate > maxTrendingRebate) maxTrendingRebate = trendingRebate;
  });

  return (
    <table className='tier mb-16'>
      <thead>
        <tr>
          <td>{labels[0].title}</td>
          {/*<td className='gray'>{labels[0].Q1}</td>*/}
          <td className='gray'>{labels[0].Q2}</td>
          <td className='gray'>{labels[0].Q3}</td>
          <td className='gray'>{labels[0].Q4}</td>
          <td className='gray'>{labels[0].Q5}</td>
          <td className='gray'>{labels[0].Q6}</td>
          <td className='gray'>{labels[0].Q7}</td>
        </tr>
      </thead>
      {mytiers !== null && mytiers !== undefined ? (
        <tbody>
          {mytiers.map((item: any, i: number) => {
            return <tr key={'tier_table_view_row_' + i}>
              <td>{'TARGET'}</td>
              {/*
              <td>
                {item.tier.target_perc !== null && item.tier.target_perc !== undefined && item.tier.target_perc > 0 ? percentageFormat(item.tier.target_perc) : '-'}
              </td>
              */}
              <td>
                {numberFormat(getGrowthTarget(item.tier))}
              </td>
              <td>
                {numberFormat(getValueFromTarget(item.tier))}
              </td>
              <td>
                {isNaN(getEstimatedUnitsFromTarget(item.tier)) ? 'N/A' : decimalFormat(getEstimatedUnitsFromTarget(item.tier))}
              </td>
              <td>
                {percentageFormat(item.tier.rebate_target_perc)}
              </td>
              <td>
                {numberFormat(getPotentialRebate(item.tier))}
              </td>
              <td>
                {numberFormat(getTrendingRebate(item.tier))}
              </td>
            </tr>;
          })}
        </tbody>
      ) : null}
    </table>
  );
};
