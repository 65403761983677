/** @format */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface State {
  customer?: {
    contacts: [
      {
        contact_id: number;
        description: string;
        customer_id: number;
        name: string;
        phone: number;
        email: string;
      },
    ];
    doors: [
      {
        customer_id: number;
        door_id: number;
        name: string;
        address: string;
        city: string;
        zip_code: string;
        email: string;
        phone: string;
        country_code: string;
        sales_org: string;
        brand_code: string;
        partner2: string;
        partner_funcation: string;
        main_account: string;
        buying_group_code: string;
        co_number: string;
        alliance_code: string;
        alliance7: string;
        central_ord_block: string;
        lm_id: string;
        vs_member_id: string;
        gmt_rating: string;
      },
    ];
    credit_status: [
      {
        customer_id: number;
        acct: number;
        customer_name: string;
        group_name: string;
        co_code: number;
        credit_limit: number;
        net_balance: number;
        credit_tolerance: number;
        paynow: null;
        '30_days_past_due': number;
        '60_days_past_due': number;
        '90_days_past_due': number;
        '120_days_past_due': number;
        return_credit: number;
        payments: number;
        last_payment_date: string;
        sales_value: string;
        risk: string;
      },
    ];
    vs_info: {
      vs_data_footer: [
        {
          brand: string;
          customer_id: number;
          division: string;
          email: string;
          growth: number;
          rep: string;
          vs_data_footer_id: number;
        },
      ];
      vs_annual_performance: {
        annual_growth_t1: string;
        annual_growth_t1_from: string;
        annual_growth_t2: string;
        annual_growth_t2_from: string;
        customer_id: number;
        pyfy: string;
        pytd: string;
        total_units_cy: string;
        total_units_growth_qtd: string;
        total_units_growth_ytd: string;
        total_units_py: string;
        total_units_pyqtd: string;
        total_units_pytd: string;
        total_units_qtd: string;
        ytd: string;
        ytd_aup: string;
        ytd_growth: string;
      };
      vs_growth_rebate: [
        {
          customer_id: number;
          cyqtid: string;
          lyqtd: string;
          pfq: string;
          qtd: string;
          quarterly_growth: string;
          target1: string;
          target1_from: string;
          target1_potential: string;
          target2: string;
          target2_from: string;
          target2_potential: string;
        },
      ];
      vs_portfolio_rebate: [
        {
          active_door_count: string;
          average_brand_count: string;
          customer_id: number;
          potential_brand_count: number;
          qtd_portfolio: string;
          total_quarterly: string;
        },
      ];
      vs_program_elite: [
        {
          customer_id: number;
          qtd_dollar_authentics: string;
          qtd_dollar_elite: string;
          qtd_perc_complete: string;
          qtd_perc_elite: string;
        },
      ];
    };
    luxottica_portfolio_savings: {
      free_shipping_savings_tip: string;
      commercial_discount_in_percentage: number;
      customer_id: number;
      free_frame: number;
      free_frame_value: number;
      free_shipping_savings: number;
      promotional_discount_in_dollars: number;
      promotional_discount_in_percentage: number;
    };
    rebate_info: {
      reward_rebates: [
        {
          customer_id: number;
          brand_category: string;
          year: number;
          reward_rebate: number;
        },
      ];
      prompt_pay: {
        customer_id: number;
        ytd: number;
        qtd: number;
        mtd: number;
      };
    };
    customer_data: {
      payers: [
        {
          country: string;
          currency: string;
          customer_id: number;
          customer_rating: string;
          language: string;
          payer_code: string;
          payer_id: number;
          payer_name: string;
          reporting_scope: string;
          alliance_name: string;
        },
      ];
      email: string;
      customer_name: string;
      address: string;
      state: string;
      active_doors_number: number;
      contacts_number: number;
      common_owner_code: string;
      alliance_code: string;
      el360_status: {
        el_360_status_description: string;
        el_360_status_id: number;
      };
      total_portfolio_cy: string | number;
      return_rate_cy: string | number;
      sales_cy: string | number;
      return_rate_delta_cy_py: string;
      total_portfolio_delta_cy_py: string;
      sales_delta_cy_py: string;
      customer_status_level: {
        level_status_value: number;
      };
      total_potential_savings: number;
      total_achieved_savings: number;
      digital_screens_status: {
        digital_screens_status_id: number;
        digital_screens_status_description: string;
        digital_screens_status_link: string;
      };
      smartshopper_status: {
        smartshopper_status_id: number;
        smartshopper_status_description: string;
        smartshopper_status_link: string;
      };
      promobox_status: {
        promobox_status_id: number;
        promobox_status_description: string;
        promobox_status_link: string;
      };
      smartbook_status: {
        smartbook_status_id: number;
        smartbook_status_description: string;
        smartbook_status_link: string;
      };
      brands: [
        {
          brand_id: number;
          brand_name: string;
          discount: boolean;
          brand_sales: [];
          brand_sales_aup: [];
          brand_lens_club_info: [];
          brand_growth_target: [];
          brand_quarter_rebates: [];
          brand_yearly_rebates: [];
        },
      ];
    };
    total_yearly_rebate: {
      customer_id: number;
      year: number;
      yearly_dates: string;
      fpy: number;
      pytd: number;
      cytd: number;
      cytd_growth: number;
      cytd_aup: number;
      annual_rebate_status: string;
      ytd_return_rate: number;
      ytd_return_rate_needed: number;
      potential_rebate: number;
      rebate_details: [
        {
          customer_id: number;
          year: number;
          tier: number;
          target_percentage: number;
          target: number;
          from: number;
          estimated_units: number;
          rebate: number;
          potential_rebate: number;
          trending: number;
        },
        {
          customer_id: number;
          year: number;
          tier: number;
          target_percentage: number;
          target: number;
          from: number;
          estimated_units: number;
          rebate: number;
          potential_rebate: number;
          trending: number;
        },
      ];
    };
    gpn_info: {
      gpn_sales_units: [
        {
          customer_id: number;
          gpn_sales: number;
          gpn_units: number;
          yarn: number;
        },
        {
          customer_id: number;
          gpn_sales: number;
          gpn_units: number;
          yarn: number;
        },
      ];
      gpn_frames: [
        {
          customer_id: number;
          gpn_frame_value: number;
          quarter: number;
          year: number;
        },
      ];
      gpn_manufactures: [
        {
          name: string;
          customer_id: number;
          group_id: number;
          level: number;
          month: number;
          unit: number;
          year: number;
          perc_mix: number;
        },
      ];
      gpn_manufactures_periods: [
        {
          month: number;
          year: number;
        },
      ];
    };
    master_data: {
      customer_id: number;
      code: string;
      alliance_code: string;
      alliance_name: string;
      customer_name: string;
      address: string;
      state: string;
      country: string;
      email: string;
      payer: string;
      payer_name: string;
      customer_rating: string;
      customer_type_id: number;
      reporting_scope: string;
      language: string;
      currency: string;
      active_doors_number: number;
      contacts_number: number;
      el_360_status_id: number;
      digital_screens_status_id: number;
      smartshopper_status_id: number;
      promobox_status_id: number;
      smartbook_status_id: number;
      level_status_id: number;
      total_portfolio_cy: string;
      total_portfolio_delta_cy_py: string;
      return_rate_cy: string;
      return_rate_delta_cy_py: string;
      sales_cy: string;
      sales_delta_cy_py: string;
      total_potential_savings: number;
      total_achieved_savings: number;
      customer_type: {
        customer_type_id: number;
        customer_type_description: number;
      };
      el360_status: {
        el_360_status_id: number;
        el_360_status_description: string;
      };
      customer_status_level: {
        level_status_id: number;
        level_status_description: string;
        level_status_value: number;
      };

      luxottica_portfolio_savings: {
        customer_id: number;
        commercial_discount_in_dollars: number;
        commercial_discount_in_percentage: number;
        promotional_discount_in_dollars: number;
        promotional_discount_in_percentage: number;
        free_frame: number;
        free_frame_value: number;
        free_shipping_savings: number;
        free_shipping_savings_tip: string;
      };
    };
    years: {
      current_year: number;
      previous_year: number;
      data_updated_on: string;
      data_qtr: number;
      qtr_range: string;
      sem_range: string;
      fiscal_year: string;
    };
  };
  customerRole?: {
    user_id: number;
    lux_id: string;
    active: boolean;
    name: string;
    surname: string;
    email: null | string;
    customer_id: null | number;
    user_type_id: number;
    user_role_id: number;
    sap_id: number | null;
    user_role: {
      user_role_id: number;
      user_role_description: string;
      users: [];
    };
    user_type: {
      user_type_id: number;
      user_type_description: string;
      users: [];
    };
  };
}

type Reducers = {
  createCustomer: (state: State, action: PayloadAction<any>) => State;
  deleteCustomer: (state: State) => State;
  createCustomerRole: (state: State, action: PayloadAction<any>) => State;
};

export const sliceCustomer = createSlice<State, Reducers>({
  name: 'customer',
  initialState: {},
  reducers: {
    createCustomer(state, action) {
      return { customer: action.payload };
    },
    deleteCustomer() {
      return {};
    },
    createCustomerRole(state, action) {
      return { customerRole: action.payload };
    },
  },
});
