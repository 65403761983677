import { FC } from 'react';

interface PerformanceLegendProps  {
    elements: { [id:string]: string; }
}
export const PerformanceLegend: FC<PerformanceLegendProps> = ({ elements = null }): JSX.Element => {
    const views: JSX.Element[] = [];
    if (elements !== null) {
        Object.keys(elements).map((key) => {
            views.push(
                <div className={'performance_legend--item'} key={key} >
                    <div className='performance_legend--item-square' style={{ background : elements[key] }}> </div>
                    <label className='performance_legend--item-text'>{key}</label>
                </div>,
            );
    });
    }
    return (
        <div className='performance_legend'>
            { views }
        </div>
    );
};