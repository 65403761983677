/** @format */

import { FC, memo } from 'react';
/* import styled from '@emotion/styled'; */
import {
  Chart,
  SeriesTemplate,
  CommonSeriesSettings,
  Legend,
  CommonAxisSettings,
  Label,
  ArgumentAxis,
  Connector,
} from 'devextreme-react/chart';
import { mapColors } from '../theme/mapColors';
import { utilityPaletteChartRebates } from '../utils/getPaletteRebatesChart';
import { Tooltip } from 'devextreme-react/pie-chart';
import { TooltipNumberTemplate } from './TooltipTemplate';
import { numberFormat } from '../utils/numberUtils';
import { NoData } from './NoData';

/* const ChartLabel = styled.div({
  width: '55px',
  backgroundColor: 'white',
  justifyContent: 'center',
  display: 'flex',
  fontWeight: 'bold',
  fontSize: '8px',
  marginTop: '161.3px',
  height: '15px',
  position: 'absolute',
  alignItems: 'center',
  borderRadius: '2px',
}); */

type StandardBarChartProps = {
  customer: any;
  hasData: boolean;
};

function customizeLabel(arg: any) {
  return numberFormat(arg.value);
}

export const StandardBarChart: FC<StandardBarChartProps> = memo(({ customer = null, hasData }): JSX.Element => {
  const rewardRebates = customer && customer.rebate_info ? customer.rebate_info.reward_rebates : [];

  const dataSource: { label: string; number: number }[] = [];
  rewardRebates.map((el: any) => {
    dataSource.push({ label: el.brand_category, number: el.reward_rebate });
  });
  /*   dataSource.push({ 'label': 'test4', 'number': 450 });
  dataSource.push({ 'label': 'test5', 'number': 450 });
  dataSource.push({ 'label': 'test6', 'number': 450 });
  dataSource.push({ 'label': 'test7', 'number': 450 }); */

  return rewardRebates && rewardRebates.length > 0 ? (
    <>
      {/* <ChartLabel style={{ marginLeft: '92px' }}>$ {dataSource[0].number}</ChartLabel>
      <ChartLabel style={{ marginLeft: '212px' }}>$ {dataSource[1].number}</ChartLabel>
      <ChartLabel style={{ marginLeft: '332px' }}>$ {dataSource[2].number}</ChartLabel>
      <ChartLabel style={{ marginLeft: '451px' }}>$ {dataSource[3].number}</ChartLabel>
      <ChartLabel style={{ marginLeft: '571px' }}>$ {dataSource[4].number}</ChartLabel>
      <ChartLabel style={{ marginLeft: '691px' }}>$ {dataSource[5].number}</ChartLabel> */}

      <Chart
        style={{ margin: '20px 20px 0 20px', width: hasData ? '759px' : '95%', height: '230px' }}
        palette={utilityPaletteChartRebates}
        id="chart"
        dataSource={dataSource}
      >
        <CommonSeriesSettings
          barPadding={0.3}
          cornerRadius={4}
          argumentField="label"
          valueField="number"
          type="bar"
          ignoreEmptyPoints={true}
        >
          <Label
            visible={true}
            position={'outside'}
            backgroundColor={'white'}
            font={{ color: 'black', size: '10px' }}
            customizeText={customizeLabel}
          >
            <Connector visible={true} width={1} />
          </Label>
        </CommonSeriesSettings>

        <CommonAxisSettings
          tick={{ visible: false }}
          grid={{ color: mapColors.backgrounds.borderSecondary }}
          color={mapColors.backgrounds.borderSecondary}
          visible={false}
        />

        <ArgumentAxis label={{ visible: false }} />
        <SeriesTemplate nameField="label" />

        <Legend
          margin={{ top: 20, right: 280 }}
          horizontalAlignment="center"
          verticalAlignment="bottom"
          itemTextPosition="right"
          font={{ color: mapColors.fonts.fontTertiary, weight: 600 }}
        />

        <Tooltip
          enabled={true}
          cornerRadius={8}
          color={mapColors.fonts.fontPrimary}
          border={{ visible: false }}
          contentRender={TooltipNumberTemplate}
        />
      </Chart>
    </>
  ) : (
    <NoData />
  );
});
