/** @format */

import { FC, useEffect, useState } from 'react';
import './TypeIdNewLevelPage.scss';
import { loaderSlice } from '../../../services/loader/slice';
import { getGroupLevels } from '../../../services/admin/api';
import { apiRefresh } from '../../../services/auth/api';
import { clearStorage } from '../../../utils/localStorageUtils';
import { useNavigate } from 'react-router-dom';
import { ROUTE_ADMIN_REBATES, ROUTE_HOME } from '../../../constants/general';
import { useDispatch } from 'react-redux';
import { CheckBox } from 'devextreme-react';
import { CustomLevel, InputCheck, SelectionChilds, SelectionElements, TypeIduildingModel } from '../../../models/TypeIdBuildingModel';
import { TextAreaInput } from '../../../shared/TextAreaInput';
// import { BackActionButton } from '../partials/BackActionButton';
// import { SaveButton } from '../partials/SaveButton';
import { RefreshButton } from '../partials/RefreshButton';
import { GenericModal } from '../partials/GenericModal';
import { TypeIdCreateRequestModel, TypeIdFullModel, TypeIdGroup } from '../../../models/TypeIdCreateRequestModel';
import EventEmitter from 'events';

interface TypeIdNewLevelPageProps {
  onChangePage: (selection: number) => void;
  onSetRequest: (request: TypeIdCreateRequestModel) => void;
  eventEmitter: EventEmitter;
}

export const TypeIdNewLevelPage: FC<TypeIdNewLevelPageProps> = ({
  onChangePage,
  onSetRequest,
  eventEmitter,
}): JSX.Element => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalBody, setModalBody] = useState<string>('');

  const [custom1, setCustom1] = useState<CustomLevel>(new CustomLevel('Level 1', false));
  const [custom2, setCustom2] = useState<CustomLevel>(new CustomLevel('Level 2', false));
  const [custom3, setCustom3] = useState<CustomLevel>(new CustomLevel('Level 3', false));

  const [customButtonText1, setCustomButtonText1] = useState<string>(' add new level 1');
  const [customButtonText2, setCustomButtonText2] = useState<string>(' add new level 2');
  const [customButtonText3, setCustomButtonText3] = useState<string>(' add new level 3');

  const [finalSelection, setFinalSelection] = useState<SelectionElements[]>([]);

  const [currentLevel1, setCurrentLevel1] = useState<number>(-1);
  const [currentLevel2, setCurrentLevel2] = useState<number>(-1);

  const [level1, setLevel1] = useState<TypeIduildingModel[]>([]);
  const [level2, setLevel2] = useState<TypeIduildingModel[]>([]);
  const [level3, setLevel3] = useState<TypeIduildingModel[]>([]);

  const [customInput1, setCustomInput1] = useState<InputCheck>(new InputCheck(1, false, 'orange', '', 'typeId is composed by 4 character'));
  const [customInput2, setCustomInput2] = useState<InputCheck>(new InputCheck(2, false, 'orange', '', 'typeId is composed by 4 character'));
  const [customInput3, setCustomInput3] = useState<InputCheck>(new InputCheck(3, false, 'orange', '', 'typeId is composed by 4 character'));

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const openModalState = () => {
    setIsOpenModal(true);
  };

  

  function _checkValidTypeId(level: number, value: string | undefined | null) {

    if (value === undefined || value === null) {
      return false;
    } else {
      if (value.toString().length < 4) {
        return false;
      }
      if (value.toString().length > 4) {
        return false;
      }
    }

    switch (level) {
      case 1: {
        const already = level1.find(i => i.spk_group_id === value.toString());
        if (already !== undefined) {
          return false;
        }
        break;
      }

      case 2: {
        const already = level2.find(i => i.spk_group_id === value.toString());
        if (already !== undefined) {
          return false;
        }
        break;
      }

      case 3: {
        const already = level3.find(i => i.spk_group_id === value.toString());
        if (already !== undefined) {
          return false;
        }
        break;
      }
    }

    return true;
  }

  function _checkCustomInputValue(level: number, value: string) {
    const res = new InputCheck(level, true, 'green', value, '');
    let canGo = true;
    if (value === undefined || value === null || value === '') {
      res.statusColor = 'orange';
      res.status = false;
      res.errorMessage = 'typeId is composed by 4 numbers';
      canGo = false;
    } else {
      if (value.toString().length < 4) {
        res.statusColor = 'orange';
        res.status = false;
        res.errorMessage = 'typeId is composed by 4 numbers';
        canGo = false;
      }
      if (value.toString().length > 4) {
        res.statusColor = 'red';
        res.status = false;
        res.errorMessage = 'typeId is composed by 4 numbers';
        canGo = false;
      }
    }

    if (canGo) {
      switch (level) {
        case 1: {
          const already = level1.find(i => i.spk_group_id === value.toString());
          if (already !== undefined) {
            res.statusColor = 'red';
            res.status = false;
            res.errorMessage = 'typeId already exists';
          }
          break;
        }
  
        case 2: {
          const already = level2.find(i => i.spk_group_id === value.toString());
          if (already !== undefined) {
            res.statusColor = 'red';
            res.status = false;
            res.errorMessage = 'typeId already exists';
          }
          break;
        }
  
        case 3: {
          const already = level3.find(i => i.spk_group_id === value.toString());
          if (already !== undefined) {
            res.statusColor = 'red';
            res.status = false;
            res.errorMessage = 'typeId already exists';
          }
          break;
        }
      }
    }
    

    switch (level) {
      case 1: {
        setCustomInput1(res);
        break;
      }

      case 2: {
        setCustomInput2(res);
        break;
      }

      case 3: {
        setCustomInput3(res);
        break;
      }
    
    }
    
  }

  function _buildInput(title: string, level: number) {
    let control = new InputCheck(level, false, 'orange', '', 'typeId is composed by 4 numbers');
    switch (level) {
      case 1: {
        control = customInput1;
        break;
      }

      case 2: {
        control = customInput2;
        break;
      }

      case 3: {
        control = customInput3;
        break;
      }
    }
    return <div key={0} className='composition_input'>
      <label className='composition_input__title'>{title}</label>
      <div className={'composition_input__field ' + control.statusColor}><TextAreaInput
        id="level"
        name="level"
        placeholder=''
        required={false}
        onlyNumbers={false}
        defaultValue={control.value !== undefined && control.value !== '' ? control.value : undefined}
        onChangedSelection={(value) => _checkCustomInputValue(level, value)} /></div>
      <p className={'composition__content__title ' + ' ' + control.statusColor + ' ' + (control.status === true ? ' hidden' : '')}>{control.errorMessage}</p>
    </div>;
  }

  function compareFn(a: TypeIduildingModel, b: TypeIduildingModel) {
    if (parseInt(a.spk_group_id, 10) > parseInt(b.spk_group_id, 10)) return -1;
    if (parseInt(a.spk_group_id, 10) < parseInt(b.spk_group_id, 10)) return 1;
    return 0;
  }

  function _parseResponse(data: TypeIduildingModel[]) {
    let i = 1;
    data.forEach(element => {
      element.id = i;
      i++;
    });
   
    setLevel1(data.filter(e => e.spk_level === '1').sort((a, b) => compareFn(a, b)));
    setLevel2(data.filter(e => e.spk_level === '2').sort((a, b) => compareFn(a, b)));
    setLevel3(data.filter(e => e.spk_level === '3').sort((a, b) => compareFn(a, b)));
  }

  function _loadData(retry = true) {
    dispatch(loaderSlice.actions.startLoading());
    getGroupLevels().then(response => {
      _parseResponse(response.data.data);
      dispatch(loaderSlice.actions.stopLoading());
    }).catch(error => {
      const refreshToken = localStorage.getItem('refreshToken');
      if (error.response && error.response.status && error.response.status === 401 && refreshToken) {
        apiRefresh({ refreshToken })
          .then(response => {
            if (response.data?.data?.jwt_token && response.data?.data?.refresh_token) {
              localStorage.setItem('jwtToken', response.data.data.jwt_token);
              localStorage.setItem('refreshToken', response.data.data.refresh_token);
              if (retry) {
                _loadData(false);
              }
            }
          })
          .catch(() => {
            clearStorage();
            navigate(`${ROUTE_HOME}`, { replace: true });
          });
      } else if (error.response && error.response.status && error.response.status === 500) {
        navigate(`${ROUTE_ADMIN_REBATES}`, { replace: true });
      } else {
        clearStorage();
        navigate(`${ROUTE_HOME}`, { replace: true });
      }
    });
  }

  function _setCheckOnLevel1(index: number) {
    const element = finalSelection.find(e => e.id === index);
    if (element === undefined) {
      const toBeAdded: SelectionElements = new SelectionElements();
      toBeAdded.id = index;
      toBeAdded.childs = [];
      finalSelection.push(toBeAdded);
      setCurrentLevel1(index);
    } else {
      const index = finalSelection.indexOf(element);
      finalSelection.splice(index, 1);
      setCurrentLevel1(-1);
    }
    setCurrentLevel2(-1);
    const fs = JSON.parse(JSON.stringify(finalSelection));
    setFinalSelection(fs);
  }

  function _setCheckOnLevel2(index: number) {
    const elementAr = finalSelection.find(e => e.id === currentLevel1);
    if (elementAr === undefined) {
      console.log('Unexpected no current level 1 valorized');
      return;
    }
    const element = elementAr.childs.find(e => e.id === index);
    if (element === undefined) {
      const toBeAdded: SelectionChilds = new SelectionChilds();
      toBeAdded.id = index;
      toBeAdded.childs = [];
      elementAr.childs.push(toBeAdded);
      setCurrentLevel2(index);
    } else {
      const index = elementAr.childs.indexOf(element);
      elementAr.childs.splice(index, 1);
      setCurrentLevel2(-1);
    }
    const fs = JSON.parse(JSON.stringify(finalSelection));
    setFinalSelection(fs);
  }

  function _setCheckOnLevel3(index: number) {
    const elementAr = finalSelection.find(e => e.id === currentLevel1);
    if (elementAr === undefined) {
      const title = 'No level 1 selected';
      const body = 'No current level 1 valorized.';
      setModalTitle(title);
      setModalBody(body);
      openModalState();
      console.log('Unexpected no current level 1 valorized');
      return;
    }
    const childsAr = elementAr.childs.find(e => e.id === currentLevel2);
    if (childsAr === undefined) {
      const title = 'No level 2 selected';
      const body = 'No current level 2 valorized.';
      setModalTitle(title);
      setModalBody(body);
      openModalState();
      console.log('Unexpected no current level 2 valorized');
      return;
    }
    const element = childsAr.childs.find(e => e === index);
    if (element === undefined) {
      const toBeAdded: number = index;
      childsAr.childs.push(toBeAdded);

    } else {
      const index = childsAr.childs.indexOf(element);
      childsAr.childs.splice(index, 1);
    }
    const fs = JSON.parse(JSON.stringify(finalSelection));
    setFinalSelection(fs);
  }

  function _checkItem(event: any, index: number, level: string) {
    if (event.event !== undefined) {
      switch (level) {
        case '1': {
          _setCheckOnLevel1(index);
          break;
        }
        case '2': {
          _setCheckOnLevel2(index);
          break;
        }

        case '3': {
          _setCheckOnLevel3(index);
          break;
        }
        default: break;
      }
    }
  }

  function _selectItem(event: any, index: number, level: string) {
    switch (level) {
      case '1': {
        const element = finalSelection.find(e => e.id === index);
        if (element !== undefined) {
          setCurrentLevel1(index);
          setCurrentLevel2(-1);
        }
        break;
      }
      case '2': {
        const elementAr = finalSelection.find(e => e.id === currentLevel1);
        if (elementAr === undefined) {
          console.log('Unexpected no current level 1 valorized');
          break;
        }
        const element = elementAr.childs.find(e => e.id === index);
        if (element !== undefined) {
          setCurrentLevel2(index);
        }
        break;
      }

      default: break;
    }
  }

  function _build(level: number, levels: TypeIduildingModel[], fSelection: SelectionElements[]) {

    let checkedIndexArray: number[] = [];
    let currentLevel = -1;
    switch (level) {
      case 1: {
        if (custom1 !== undefined && custom1.active === true) {
          return _buildInput('Custom level 1', 1);
        }
        checkedIndexArray = fSelection.map(e => e.id);
        currentLevel = currentLevel1;
        break;
      }
      case 2: {
        if (custom2 !== undefined && custom2.active === true) {
          return _buildInput('Custom level 2', 2);
        }
        const el = fSelection.find(e => e.id === currentLevel1);
        if (el !== undefined) {
          checkedIndexArray = el.childs.map(l => l.id);
        }
        currentLevel = currentLevel2;
        break;
      }
      case 3: {
        if (custom3 !== undefined && custom3.active === true) {
          return _buildInput('Custom level 3', 3);
        }
        const el = fSelection.find(e => e.id === currentLevel1);
        if (el !== undefined) {
          const el2 = el.childs.find(e => e.id === currentLevel2);
          if (el2 !== undefined) {
            checkedIndexArray = el2.childs.map(l => l);
          }
        }
        currentLevel = -1;
        break;
      }
      default:
        break;
    }

    switch (level) {
      case 1: {
        return (
          levels.map((element) => <div key={element.id} className="composition__content__icon-selection "> <CheckBox
            onValueChanged={(e) => _checkItem(e, element.id, element.spk_level)}
            className="typeid_list_text"
            value={checkedIndexArray.indexOf(element.id) > -1}
            text={element.spk_group_id + ' - ' + element.in_group } />
            <div onClick={(e) => _selectItem(e, element.id, element.spk_level)}
              className={element.id === currentLevel ? 'col-auto pallino-selezione  justify-content-center pallino-selezionato' : 'col-auto pallino-selezione justify-content-center'} />
          </div>));
        break;
      }
      case 2: {
        return (
          levels.map((element) => <div key={element.id} className="composition__content__icon-selection "> <CheckBox
            onValueChanged={(e) => _checkItem(e, element.id, element.spk_level)}
            className="typeid_list_text"
            value={checkedIndexArray.indexOf(element.id) > -1}
            text={element.spk_group_id + ' - ' + element.in_group } />
            <div onClick={(e) => _selectItem(e, element.id, element.spk_level)}
              className={element.id === currentLevel ? 'col-auto pallino-selezione  justify-content-center pallino-selezionato' : 'col-auto pallino-selezione justify-content-center'} />
          </div>));
        break;
      }
      case 3: {
        return (
          levels.map((element) => <div key={element.id} className="composition__content__icon-selection "> <CheckBox
            onValueChanged={(e) => _checkItem(e, element.id, element.spk_level)}
            className="typeid_list_text"
            value={checkedIndexArray.indexOf(element.id) > -1}
            text={element.spk_group_id + ' - ' + element.in_group } />

          </div>));
        break;
      }
      default:
        break;
    }


  }

  function _manageCustomUi(level: number) {
    switch (level) {
      case 1: {
        const active = custom1 !== undefined ? !custom1.active : false;
        const label = custom1 !== undefined && !custom1.active ? 'Set new level 1 value' : 'Level 1';
        const buttonLabel = custom1 !== undefined && custom1.active ? 'add new level 1' : 'back to standard values on level 1';
        const cust = new CustomLevel(label, active);
        setCustom1(cust);
        setCustomButtonText1(buttonLabel);
        break;
      }
      case 2: {
        const active = custom2 !== undefined ? !custom2.active : false;
        const label = custom2 !== undefined && !custom2.active ? 'Set new level 2' : 'Level 2';
        const buttonLabel = custom2 !== undefined && custom2.active ? 'add new level 2' : 'back to standard values on level 2';
        const cust = new CustomLevel(label, active);
        setCustom2(cust);
        setCustomButtonText2(buttonLabel);
        break;
      }
      case 3: {
        const active = custom3 !== undefined ? !custom3.active : false;
        const label = custom3 !== undefined && !custom3.active ? 'Set new level 3' : 'Level 3';
        const buttonLabel = custom3 !== undefined && custom3.active ? 'add new level 3' : 'back to standard values on level 3';
        const cust = new CustomLevel(label, active);
        setCustom3(cust);
        setCustomButtonText3(buttonLabel);
        break;
      }
    }
  }

  function _cleanLevel(level: number) {
    console.log(finalSelection);
    if (level === 1) {
      finalSelection.forEach(function (element) {
        if (element.id !== 0) {
          const index = finalSelection.indexOf(element);
          finalSelection.splice(index, 1);
        }
        if (custom2.active === true) {
          _manageCustomUi(2);
        }
        if (custom3.active === true) {
          _manageCustomUi(3);
        }
      });
    }

    if (level === 2) {
      finalSelection.forEach(function (element) {
        if (element.id !== 0) {
          element.childs = [];
        }
        if (custom3.active === true) {
          _manageCustomUi(3);
        }
      });
    }

    if (level === 3) {
      finalSelection.forEach(function (element) {
        element.childs.forEach(function (eelement) {
          if (eelement.id !== 0) {
            eelement.childs = [];
          }
        });
      });
    }
    console.log(finalSelection);
  }

  function _customizeLevel(index: number) {
    switch (index) {
      case 1: {
        const title = 'No feature enabled';
        const body = 'Button disabled. To add a new Group at level 1 you';
        setModalTitle(title);
        setModalBody(body);
        openModalState();
        // _cleanLevel(1);
        // _setCheckOnLevel1(0);
        // _manageCustomUi(1);
        break;
      }
      case 2: {
        if (currentLevel1 === -1) {
          const title = 'No level 1 selected';
          const body = 'It is not possible to proceed because has not been set a level 1 group. At least one new group for level 1 must be selected.';
          setModalTitle(title);
          setModalBody(body);
          openModalState();
        } else {
          _cleanLevel(2);
          _setCheckOnLevel2(0);
          _manageCustomUi(2);
        } 
        
        break;
      }
      default: {
        if (currentLevel1 === -1) {
          const title = 'No level 1 selected';
          const body = 'It is not possible to proceed because has not been set a level 1 group. At least one new group for level 1 must be selected.';
          setModalTitle(title);
          setModalBody(body);
          openModalState();
        } else if (currentLevel2 === -1) {
          const title = 'No level 2 selected';
          const body = 'It is not possible to proceed because has not been set a level 2 group. At least one new group for level 2 must be selected.';
          setModalTitle(title);
          setModalBody(body);
          openModalState();
        } else {
          _cleanLevel(3);
          _setCheckOnLevel3(0);
          _manageCustomUi(3);
        }
        break;
      }
    }
  }

  function _getSparkSgroupFromId(id: number, level: number) {
    switch (level) {
      case 1: {
        return level1.find(e => e.id === id);
      }
      case 2: {
        return level2.find(e => e.id === id);
      }
      case 3: {
        return level3.find(e => e.id === id);
      }
    }
  }

  function _getCodeFromId(id: number, level: number) {
    switch (level) {
      case 1: {
        if (id === 0) {
          return customInput1.value;
        } else {
          return level1.find(e => e.id === id)?.spk_group_id;
        }
      }
      case 2: {
        if (id === 0) {
          return customInput2.value;
        } else {
          return level2.find(e => e.id === id)?.spk_group_id;
        }
      }
      case 3: {
        if (id === 0) {
          return customInput3.value;
        } else {
          return level3.find(e => e.id === id)?.spk_group_id;
        }
      }
    }
  }

  function _checkTypeIdGeneration() {

    
    let canGo = true;

    //Check that at least one index 0 level exists
    let level0Id = -1;
    let level0Level = -1;

    //Check at level 1
    finalSelection.forEach(function (value) {
      if (value.id === 0) {
        level0Id = value.id;
        level0Level = 1;
        const res = _checkValidTypeId(1, customInput1.value?.toString());
        canGo = res;
        return;
      }
    });

    //Check at level 2
    if (level0Id === -1) {
      finalSelection.forEach(function (finalSelectionChild) {
        finalSelectionChild.childs.forEach(function (value) {
          if (value.id === 0) {
            level0Id = value.id;
            level0Level = 2;
            const res = _checkValidTypeId(2, customInput2.value?.toString());
            canGo = res;
            return;
          }
        });
      });
    }

    //Check at level 3
    if (level0Id === -1) {
      finalSelection.forEach(function (finalSelectionChild) {
        finalSelectionChild.childs.forEach(function (finalSelectionChildChild) {
          finalSelectionChildChild.childs.forEach(function (value) {
            if (value === 0) {
              level0Id = value;
              level0Level = 3;
              const res = _checkValidTypeId(3, customInput3.value?.toString());
              canGo = res;
              return;
            }
          });
        });
      });
    }

    if (level0Level === -1) {
      const title = 'Missing new Type Id level';
      const body = 'It is not possible to proceed because it is not present a new level. At least one new level must be added to obtain a new type Id.';
      setModalTitle(title);
      setModalBody(body);
      openModalState();
      return;
    }
    
    const req: TypeIdCreateRequestModel = {
      groups: [],
      company_code: '',
      type_ids: [],
    };
    finalSelection.forEach(function (finalSelectionChild) {
      let nti = '1';
      let ntii = '';
      let ntiii = '';
      const in1 = _getSparkSgroupFromId(finalSelectionChild.id, 1);
      nti += _getCodeFromId(finalSelectionChild.id, 1);
      if (finalSelectionChild.id === 0) {
        const newGroup: TypeIdGroup = {
          spk_group: '',
          spk_group_id: customInput1.value !== undefined ? customInput1.value.toString() : '',
          spk_level: 1,
          is_brand: null,
        };
        req.groups.push(newGroup);
      }

      finalSelectionChild.childs.forEach(function (finalSelectionChildChild) {
        ntii = nti + _getCodeFromId(finalSelectionChildChild.id, 2);
        const in2 = _getSparkSgroupFromId(finalSelectionChildChild.id, 2);
        if (finalSelectionChildChild.id === 0) {
          const newGroup: TypeIdGroup = {
            spk_group: '',
            spk_group_id: customInput2.value !== undefined ? customInput2.value.toString() : '',
            spk_level: 2,
            is_brand: null,
          };
          req.groups.push(newGroup);
        }

        finalSelectionChildChild.childs.forEach(function (value) {
          ntiii = ntii + _getCodeFromId(value, 3);
          const in3 = _getSparkSgroupFromId(value, 3);
          if (value === 0) {
            const newGroup: TypeIdGroup = {
              spk_group: '',
              spk_group_id: customInput3.value !== undefined ? customInput3.value.toString() : '',
              spk_level: 3,
              is_brand: null,
            };
            req.groups.push(newGroup);
          }

          const newTypeId: TypeIdFullModel = {
            type_id: ntiii,
            in_group1: in1 !== undefined ? in1.in_group : '',
            in_group2: in2 !== undefined ? in2.in_group : '',
            in_group3: in3 !== undefined ? in3.in_group : '',
            db_gr1: in1 !== undefined ? in1.spk_group : '',
            db_gr2: in2 !== undefined ? in2.spk_group : '',
            db_gr3: in3 !== undefined ? in3.spk_group : '',
          };
          req.type_ids.push(newTypeId);

        });
      });
    });

    if (req.type_ids.length === 0) {
      const title = 'Missing generated Type Ids';
      const body = 'No Type Ids have been generated, verify that at least one element has been selected for each level..';
      setModalTitle(title);
      setModalBody(body);
      openModalState();
    } else if (!canGo) {
      const title = 'Not valid Group Id has been set';
      const body = 'Please check that all type id are valid';
      setModalTitle(title);
      setModalBody(body);
      openModalState();  
    } else {
      const finalreq = JSON.parse(JSON.stringify(req));
      onSetRequest(finalreq);
      onChangePage(2);
    }
  
  }

  eventEmitter.addListener('SuccesfullSaved', event => {
    console.log(event);
    setCustomInput1(new InputCheck(1, false, 'orange', '', 'typeId is composed by 4 character'));
    setCustomInput2(new InputCheck(2, false, 'orange', '', 'typeId is composed by 4 character'));
    setCustomInput3(new InputCheck(3, false, 'orange', '', 'typeId is composed by 4 character'));
    setCurrentLevel1(-1);
    setCurrentLevel2(-1);
    _cleanLevel(1);
    _loadData();
    const title = 'Type Id Succesfully saved';
    const body = 'Type Id, Import Map and Group info has been succesfull saved in DB.';
    setModalTitle(title);
    setModalBody(body);
    openModalState();
  });

  useEffect(() => {
    _loadData();
  }, []);

  return (
    <div className='composition__container'>
      <div className='composition__header'>
        <div className={custom1.active ? 'customize_level_button active' : 'customize_level_button'} >
          <a onClick={() => _customizeLevel(1)}>{customButtonText1}</a>
        </div>
        <div className={custom2.active ? 'customize_level_button active' : 'customize_level_button'} >
          <a onClick={() => _customizeLevel(2)}>{customButtonText2}</a>
        </div>
        <div className={custom3.active ? 'customize_level_button active' : 'customize_level_button'} >
          <a onClick={() => _customizeLevel(3)}>{customButtonText3}</a>
        </div>
      </div>
      <div className='composition__content'>
        <div className='composition__content__level composition__content_border_right'>
          <div className='composition__content__title'>{custom1?.label}</div>
          <div className="typeid_list">
            {_build(1, level1, finalSelection)}
          </div>
        </div>
        <div className='composition__content__level composition__content_border_right'>
          <div className='composition__content__title'>{custom2?.label}</div>
          <div className="typeid_list">
            {_build(2, level2, finalSelection)}
          </div>
        </div>
        <div className='composition__content__level '>
          <div className='composition__content__title'>{custom3?.label}</div>
          <div className="typeid_list">
            {_build(3, level3, finalSelection)}
          </div>
        </div>
      </div>
      <div className='flex-end type_id_container__footer' >
        <RefreshButton enabled={true}
          text='Generate Type Ids'
          onClick={() => _checkTypeIdGeneration()} />


      </div>
      <GenericModal
        isOpenModal={isOpenModal}
        onClick={closeModal}
        title={modalTitle}
        body={modalBody}
      />
    </div>


  );
};




