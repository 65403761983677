/** @format */

import { FC, useState } from 'react';
import { UIIcon } from './Icon';
import { apiRevoke } from '../services/auth/api';
// import { selectorRefreshToken } from '../@ducks/auth/selectors';
// import { /* useAppDispatch, */ useAppSelector } from '../hooks/redux';
// import { sliceAuth } from '../@ducks/auth/slice';
import { jwtToken } from '../services/token';
import userIcon from '../assets/icons/outline/Usercircle.svg';
import { ADMIN_ROLE, ROUTE_ADMIN_REBATES } from '../constants/general';
import { useNavigate } from 'react-router-dom';
// import { sliceResults } from '../@ducks/search/slice';
// import { sliceCustomer } from '../@ducks/customer/slice';
// import { selectorCustomerRoleDescription } from '../@ducks/customer/selectors';

export const AccountInfo: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const [isAccordion, setIsAccordion] = useState<boolean>(false);
  // const dispatch = useAppDispatch();
  const userRole = localStorage.getItem('role');
  const name = localStorage.getItem('firstName');
  const lastName = localStorage.getItem('lastName');
  const refreshToken = localStorage.getItem('refreshToken');
  const isAdmin = (userRole === ADMIN_ROLE);

  const onClickOpen = () => setIsAccordion(!isAccordion);

  const onClickRebate = () => {
    navigate(`${ROUTE_ADMIN_REBATES}`, { replace: true });
  };

  const onClickLogout = () => {
    if (jwtToken !== undefined && refreshToken) {
      apiRevoke({ refreshToken })
        .then(() => {
          // DO NOTHING
        })
        .catch(() => {
          // DO NOTHING
        })
        .finally(function () {
          localStorage.clear();
          /*
          dispatch(sliceAuth.actions.logout());
          dispatch(sliceResults.actions.deleteResults());
          dispatch(sliceCustomer.actions.deleteCustomer());
          */
          location.replace(process.env.REACT_APP_URL_LOGOUT_NAM ? process.env.REACT_APP_URL_LOGOUT_NAM : '');
        });
    }
  };
  return (
    <>
      <div className='account' onClick={onClickOpen}>
        <div className='icon'>
          <UIIcon name={''} srcIcon={userIcon} width={'20'} height={'20'} className='' />
        </div>
        <div className='details'>
          <div className='name'>{name} {lastName}</div>
          <div className='role'>{userRole}</div>
        </div>
      </div>
      {isAccordion && (
        <div className='bubble'>
          {isAdmin &&
            <>
              <button className='rebate' onClick={onClickRebate}>
                <div className='icon' />
                Rebate tool
              </button>
              {/*
              <button className='type_id' onClick={onClickTypeId}>
                <div className='icon' />
                Type id tool
              </button> 
            */}
            </>
          }
          <button className='logout' onClick={onClickLogout}>
            <div className='icon' />
            Logout
          </button>
        </div>
      )
      }
    </>
  );
};
