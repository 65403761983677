/** @format */

import axios from 'axios';
import { jwtToken } from '../token';

export const apiLogin = (authcode: string) => {
  return axios.get(`${process.env.REACT_APP_URL_LOGIN_AUTH}${authcode}`);
};

export const apiRevoke = ({ refreshToken }: { refreshToken: string }) => {
  const bodyParameters = {
    refresh_token: refreshToken,
  };
  const config = {
    headers: { Authorization: `Bearer ${jwtToken}` },
  };
  return axios.post(process.env.REACT_APP_URL_REVOKE ? process.env.REACT_APP_URL_REVOKE : '', bodyParameters, config);
};

export const apiRefresh = ({ refreshToken }: { refreshToken: string }) => {
  const bodyParameters = {
    refresh_token: refreshToken,
  };
  const config = {
    headers: { Authorization: `Bearer ${jwtToken}` },
  };
  return axios.post(process.env.REACT_APP_URL_REFRESH ? process.env.REACT_APP_URL_REFRESH : '', bodyParameters, config);
};
