/** @format */

import { FC } from 'react';

interface Props {
  name: number | string;
  color: string;
  backgroundColor: string;
  borderColor: string;
}

export const UILabel: FC<Props> = ({ name, color, backgroundColor, borderColor }): JSX.Element => {
  return (
    <div className='label' style={{ color: color, backgroundColor: backgroundColor, borderColor: borderColor }}>
      {name}
    </div>
  );
};
