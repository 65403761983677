/** @format */
import { FC } from 'react';
import { UIIcon } from './Icon';
import { SearchInput } from './SearchInput';
import { AccountInfo } from './AccountInfo';
import { ROUTE_HOME } from '../constants/general';

// import essilorLogo from '../assets/images/essilor.png';
import rhLogo from '../assets/images/logo.png';
import { Alert } from '../pages/Dashboard/Alert';

export interface Props {
    isSearch?: boolean;
    customer?: any;
}

export const Header: FC<Props> = ({ isSearch = false, customer = null /* , isNavigate = false  */ }): JSX.Element | null => {

    const isCustomer = localStorage.getItem('customerId') !== null && localStorage.getItem('customerId') !== 'null';
    
    return (
        <header>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-3 p-0 logo'>
                        {/*
                        <a href='https://my.essilorluxottica.com' target="_blank" rel="noreferrer">
                            <UIIcon name={''} srcIcon={essilorLogo} width={''} height={''} className="essilor" />
                        </a>
                        <div className='separator' />
    */}
                        <a href={`${ROUTE_HOME}`}>
                            <UIIcon name={''} srcIcon={rhLogo} width={''} height={''} className="rh" />
                        </a>
                    </div>
                    <div className='col-md-6 p-0 search'>
                        {!isSearch && !isCustomer && 
                            <SearchInput isSerp={true} />}
                    </div>
                    <div className='col-md-3 p-0 user'>
                        <AccountInfo />
                    </div>
                </div>
                {/* 
                    <nav className='navbar navbar-expand-lg'>
                    <a className="navbar-brand" href="#">Navbar</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="#">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Link</a>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Dropdown
                                </a>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link disabled">Disabled</a>
                            </li>
                        </ul>
                        <form className="d-flex" role="search">
                            <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                                <button className="btn btn-outline-success" type="submit">Search</button>
                        </form>
                    </div>
                    </nav>
                    */}
            </div>

            <Alert customer={customer} variant={'Danger'} />

        </header>
    );
};
