/** @format */

import { FC } from 'react';
// import { UIIcon } from '../../../../shared/Icon';
import { ScalingInfo } from '../../../../shared/ScalingInfo';
// import { GenericTooltip } from '../../../../shared/GenericTooltip';
import { InfoBox } from '../../../../shared/InfoBox';

interface Props {
  isScaling: boolean;
  showScaling: boolean;
  showInfo: boolean;
  srcIcon: string;
  value: string | number;
  delta?: string;
  info: string;
  isLast?: boolean;
  iconColor?: string;
  isName?: boolean;
  isIcon?: boolean;
  tooltipMessage: string;
  infoMessage: string;
  neededLabel?: string;
  isNeeded?: boolean;
  valueColor?: string;
}

export const CardPortfolio: FC<Props> = ({
  isScaling,
  showInfo,
  // srcIcon,
  value,
  delta,
  info,
  // iconColor = '',
  // tooltipMessage = '',
  infoMessage = '',
  neededLabel = '',
  isNeeded = false,
  valueColor,
}): JSX.Element => {
  // const [showTooltip, setShowTooltip] = useState(false);
  return (
    <div className='data-card'>
      {/*
      <div className='iconBox'
        style={{ backgroundColor: iconColor }}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}>
        <UIIcon name={'icon'} srcIcon={srcIcon} width={'26'} height={'26'} className="" />
        {tooltipMessage.length > 0 && <GenericTooltip mode={'left'} label={tooltipMessage} isVisible={showTooltip} />}
      </div>
  */}
      <div className='valueBox' style={{ color: valueColor !== null ? valueColor : 'inherit' }}>{value}</div>
      <div className='infoBox'>{info}</div>

      <div className='scalingBox'>
        <ScalingInfo
          isInfo={showInfo}
          isIcon={showInfo}
          isScaling={isScaling}
          value={delta}
          isNeeded={isNeeded}
          neededLabel={neededLabel}
        />
      </div>

      {infoMessage.length > 0 && (
        <div className='infoBlock'>
          <InfoBox message={infoMessage} />
        </div>
      )}
    </div>
  );
};
