import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface State {
    performance?: {
        customer_year:[]
        all_performance:[]
    }
}
type Reducers = {
    createPerformance: (state: State, action: PayloadAction<any>) => State;
    deletePerformance: (state: State) => State;
};
export const slicePerformance = createSlice<State, Reducers>({
    name: 'performance',
    initialState: {},
    reducers:{
        createPerformance(state, action) {
            return { ...state, performance: action.payload };
        },
        deletePerformance() {
            return {};
        },
    },
});
/**
 * {"status":200,"data":{"customer_year":[],"all_performance":[]},"count":1,"message":null,"error":""}
 */