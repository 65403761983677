import { FC } from 'react';
import './CellBoxTable.scss';

interface KeyElement {
    title: string;
    weight: number;
}
interface CellBoxTableProps {
    data: {
        keys: KeyElement[],
        rows: any
    };
}
export const CellBoxTable: FC<CellBoxTableProps> = ({
    data = null,
}): JSX.Element => {

    function buildTableHeader() {
        const elements = data?.keys.map((keyObject: KeyElement, i: number) => (
            <div
                key={'head_' + i}
                style={{ width: keyObject.weight + '%' }}
                className='cellboxcontent--header-item'>
                <div
                    key={'head_value_' + i.toString()}
                    className='cellboxcontent--header-itemtitle'>
                    {keyObject.title}
                </div>
            </div>));
        
        
        return <div className='cellboxcontent--header'>{elements}</div>;
    }

    function buildRows() {
        return data?.rows.map((row: any, index: number) => {
            const rowContent = data?.keys.map((keyObject: KeyElement, i: number) => {
                const value = row[keyObject.title];
                return <div className='cellboxcontent--valuecontent'
                    key={'value_' + i.toString()}
                    style={{ width: keyObject.weight + '%' }}>
                    <div key={'value_value_' + i.toString()}
                        className='cellboxcontent--valuecontent__title'>
                        {value}
                    </div>
                </div>;
            });

            return (<div key={'value_row' + index.toString()}className='cellboxcontent--row'>{rowContent}</div>);
        });
    }
    return (
        <div className='cellboxcontent'>
            {buildTableHeader()}
            {buildRows()}
        </div>
    );
};
