/** @format */

/* eslint-disable no-alert */
import { Route, Routes, useNavigate } from 'react-router-dom';
// import { ROUTE_DASHBOARD, ROUTE_HOME, ROUTE_NO_PERMISSION, ROUTE_SEARCH, ROUTE_SERP, ROUTE_LOGIN, ROUTE_COOKIE_POLICY } from './constants/general';
import { ROUTE_DASHBOARD, ROUTE_HOME, ROUTE_NO_PERMISSION, ROUTE_SEARCH, ROUTE_SERP, ROUTE_COOKIE_POLICY, ROUTE_ADMIN_REBATES, ROUTE_ADMIN_REBATES_EDIT, ROUTE_ADMIN_TYPE_IDS_ADD } from './constants/general';
import { PageDashboard } from './pages/Dashboard';
import { PageHome } from './pages/Home';
import { PageSearch } from './pages/Search';
import { PageSerp } from './pages/Serp';
import { Auth0Provider } from '@auth0/auth0-react';
import { ProtectedAdminRoute, ProtectedRoute } from './shared/ProtectedRoute';
import { Loader } from './shared/Loader';
import { loaderSelector } from './services/loader/selectors';
import { useAppSelector } from './hooks/redux';
import 'devextreme/dist/css/dx.light.css';
import { UserPermission } from './pages/NoPermission';
// import { PagePreLogin } from './pages/PreLogin';
import CookieBanner from './shared/CookieBanner';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './shared/AppInsights';
import { CookiePolicy } from './pages/CookiePolicy';
import { useState } from 'react';
import { AdminRebates } from './pages/Admin/Rebates';
import { AdminRebatesEdit } from './pages/Admin/RebatesEdit';
import { AdminTypeIDs } from './pages/Admin/TypeIDs';

const App = () => {
  //const [isLocalhost] = [true]; //useState(process.env.REACT_APP_LOCALHOST ? !!+process.env.REACT_APP_LOCALHOST : false);
  const [isLocalhost] = useState(process.env.REACT_APP_LOCALHOST ? !!+process.env.REACT_APP_LOCALHOST : false);
  const isCustomer = localStorage.getItem('customerId') !== null && localStorage.getItem('customerId') !== 'null';
  const navigate = useNavigate();
  const isLoading = useAppSelector(loaderSelector);

  if (isLocalhost) {
    return (
      <AppInsightsContext.Provider value={reactPlugin}>
        <Routes>
          {isCustomer ? (
            <>
              {/* <Route path={ROUTE_LOGIN} element={<PagePreLogin />} /> */}
              <Route path={ROUTE_HOME} element={<PageHome />} />
              <Route path={ROUTE_NO_PERMISSION} element={<UserPermission />} />
              <Route element={<ProtectedRoute />}>
                <Route path={ROUTE_SEARCH} element={<PageSearch />} />
                <Route path={ROUTE_COOKIE_POLICY} element={<CookiePolicy />} />
                <Route path={`${ROUTE_DASHBOARD}/:customerId/:typeId`} element={<PageDashboard />} />
              </Route>
              {/*<Route element={<ProtectedAdminRoute />}>*/}
                <Route path={ROUTE_ADMIN_REBATES} element={<AdminRebates />} />
                <Route path={ROUTE_ADMIN_REBATES_EDIT} element={<AdminRebatesEdit />} />
                <Route path={ROUTE_ADMIN_TYPE_IDS_ADD} element={<AdminTypeIDs />} />
              {/*</Route>*/}
            </>
          ) : (
            <>
              {/* <Route path={ROUTE_LOGIN} element={<PagePreLogin />} /> */}
              <Route path={ROUTE_HOME} element={<PageHome />} />
              <Route path={ROUTE_NO_PERMISSION} element={<UserPermission />} />
              <Route element={<ProtectedRoute />}>
                <Route path={ROUTE_COOKIE_POLICY} element={<CookiePolicy />} />
                <Route path={ROUTE_SEARCH} element={<PageSearch />} />
                <Route path={ROUTE_SERP} element={<PageSerp />} />
                <Route path={`${ROUTE_DASHBOARD}/:customerId/:typeId`} element={<PageDashboard />} />
                <Route path="*" element={<PageSearch />} />
              </Route>
              {/*<Route element={<ProtectedAdminRoute />}>*/}
                <Route path={ROUTE_ADMIN_REBATES} element={<AdminRebates />} />
                <Route path={ROUTE_ADMIN_REBATES_EDIT} element={<AdminRebatesEdit />} />
                <Route path={ROUTE_ADMIN_TYPE_IDS_ADD} element={<AdminTypeIDs />} />
              {/*</Route>*/}
            </>
          )}
        </Routes>
        <CookieBanner />
        <Loader show={isLoading} />
      </AppInsightsContext.Provider>
    );
  }
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <Auth0Provider
        clientId={process.env.REACT_APP_URL_CLIENT_ID || ''}
        domain={process.env.REACT_APP_URL_LOGIN_NAM || ''}
        onRedirectCallback={() => {
          navigate(window.location.pathname);
        }}
      >
        <Routes>
          {isCustomer ? (
            <>
              {/* <Route path={ROUTE_LOGIN} element={<PagePreLogin />} /> */}
              <Route path={ROUTE_HOME} element={<PageHome />} />
              <Route path={ROUTE_NO_PERMISSION} element={<UserPermission />} />
              <Route element={<ProtectedRoute />}>
                <Route path={ROUTE_SEARCH} element={<PageSearch />} />
                <Route path={`${ROUTE_DASHBOARD}/:customerId/:typeId`} element={<PageDashboard />} />
              </Route>
              <Route element={<ProtectedAdminRoute />}>
                <Route path={ROUTE_ADMIN_REBATES} element={<AdminRebates />} />
                <Route path={ROUTE_ADMIN_REBATES_EDIT} element={<AdminRebatesEdit />} />
                <Route path={ROUTE_ADMIN_TYPE_IDS_ADD} element={<AdminTypeIDs />} />
              </Route>
            </>
          ) : (
            <>
              {/* <Route path={ROUTE_LOGIN} element={<PagePreLogin />} /> */}
              <Route path={ROUTE_HOME} element={<PageHome />} />
              <Route path={ROUTE_NO_PERMISSION} element={<UserPermission />} />
              <Route element={<ProtectedRoute />}>
                <Route path={ROUTE_SEARCH} element={<PageSearch />} />
                <Route path={ROUTE_SERP} element={<PageSerp />} />
                <Route path={`${ROUTE_DASHBOARD}/:customerId/:typeId`} element={<PageDashboard />} />
                <Route path="*" element={<PageSearch />} />
              </Route>
              <Route element={<ProtectedAdminRoute />}>
                <Route path={ROUTE_ADMIN_REBATES} element={<AdminRebates />} />
                <Route path={ROUTE_ADMIN_REBATES_EDIT} element={<AdminRebatesEdit />} />
                <Route path={ROUTE_ADMIN_TYPE_IDS_ADD} element={<AdminTypeIDs />} />
              </Route>
            </>
          )}
        </Routes>
      </Auth0Provider>
      <CookieBanner />
      <Loader show={isLoading} />
    </AppInsightsContext.Provider>
  );
};

export default App;
