/** @format */

import { FC, useEffect, useState } from 'react';
import { Link } from 'react-scroll';

export interface Props {
  leftMode: boolean;
  isXR: boolean;
  customerCode: string | null;
  level: string;
  onLinkClick: any;
}

export const SectionNavigator: FC<Props> = ({ leftMode, isXR, customerCode, level, onLinkClick }): JSX.Element => {

  const isCustomer = localStorage.getItem('customerId') !== null && localStorage.getItem('customerId') !== 'null';
  const [offset, setOffset] = useState(0);
  
  const breakpoints: { [section: string]: any } = {
    'accountInformation': { min: -1, max: 100 },
    'creditStatus': { min: 100, max: 200 },
    'portfolioRating': { min: 200, max: 300 },
    'savings': { min: 300, max: 400 },
    'totalPortfolioRebates': { min: 400, max: 500 },
    'brandData': { min: 500, max: 600 },
    'el360': { min: 600, max: 700 },
    'el360panel': { min: 700, max: 800 },
    'doors': { min: 800, max: 900 },
    'contacts': { min: 900, max: -1 },
  };

  useEffect(() => {
    const onScroll = () => setOffset(window.scrollY);

    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  function isSelected(section: any) {
    return false;
    if (breakpoints[section] && offset >= breakpoints[section].min && offset < breakpoints[section].max) return true;
    return false;
  }

  return (
    <nav className={leftMode ? '' : 'up'}>
      <ul>
        <li className={isSelected('accountInformation') ? 'selected' : ''}><Link to="account-information" spy={true} smooth={true} duration={500} offset={leftMode ? -100 : -100} onClick={() => onLinkClick()}>Account</Link></li>
        {!isCustomer &&
          <li className={isSelected('creditStatus') ? 'selected' : ''}><Link to="credit-status" spy={true} smooth={true} duration={500} offset={leftMode ? -210 : -210} onClick={() => onLinkClick()}>Credit</Link></li>}
        <li className={isSelected('portfolioRating') ? 'selected' : ''}><Link to="portfolio-rating" spy={true} smooth={true} duration={500} offset={leftMode ? -210 : -210} onClick={() => onLinkClick()}>Summary</Link></li>
        <li className={isSelected('savings') ? 'selected' : ''} ><Link to="savings" spy={true} smooth={true} duration={500} offset={leftMode ? -210 : -210} onClick={() => onLinkClick()}>Savings</Link></li>
        <li className={isSelected('rewards-rebates') ? 'selected' : ''} ><Link to="rewards-rebates" spy={true} smooth={true} duration={500} offset={leftMode ? -210 : -210} onClick={() => onLinkClick()}>Rebates</Link></li>
        
        {!isXR && customerCode !== '0010015790' && customerCode !== '0010023230' &&
          (level.toLowerCase().includes('platinum') || level.toLowerCase().includes('diamond')
            || level.toLowerCase().includes('multi') || level.toLowerCase().includes('plus')) &&
            <li className={isSelected('total-portfolio-rebates') ? 'selected' : ''} ><Link to="total-portfolio-rebates" spy={true} smooth={true} duration={500} offset={leftMode ? -210 : -210} onClick={() => onLinkClick()}>Portfolio</Link></li>}

        {isXR && 
          <li className={isSelected('vsdata') ? 'selected' : ''} ><Link to="vsdata" spy={true} smooth={true} duration={500} offset={leftMode ? -210 : -210} onClick={() => onLinkClick()}>Vision Source Elite Vendor</Link></li>}
        
        {!isXR && customerCode !== '0010015790' && customerCode !== '0010023230' &&
          <li className={isSelected('brand-data') ? 'selected' : ''}><Link to="brand-data" spy={true} smooth={true} duration={500} offset={leftMode ? -210 : -210} onClick={() => onLinkClick()}>Brand Pillar</Link></li>}
        
        <li className={isSelected('el360') ? 'selected' : ''}><Link to="el360" spy={true} smooth={true} duration={500} offset={leftMode ? -210 : -210} onClick={() => onLinkClick()}>Brand Performance</Link></li>
        <li className={isSelected('el360panel') ? 'selected' : ''} ><Link to="el360panel" spy={true} smooth={true} duration={500} offset={leftMode ? -210 : -210} onClick={() => onLinkClick()}>EL360</Link></li>
        <li className={isSelected('doors') ? 'selected' : ''}><Link to="doors" spy={true} smooth={true} duration={500} offset={leftMode ? -210 : -210} onClick={() => onLinkClick()}>Doors</Link></li>
        <li className={isSelected('contacts') ? 'selected' : ''}><Link to="contacts" spy={true} smooth={true} duration={500} offset={leftMode ? -210 : -210} onClick={() => onLinkClick()}>Contact</Link></li>
      </ul>
    </nav>
  );
};