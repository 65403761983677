/**
 * /* eslint-disable import/no-unresolved
 *
 * @format
 */

/** @format */

import { FC } from 'react';
import styled from '@emotion/styled';
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { FreeMode, Navigation } from 'swiper';

import { mapColors } from '../../../../theme/mapColors';
import { TabButton } from '../partials/TabButton';

// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/free-mode';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/navigation';
import { BrandData } from '..';

//#region ::: PARTIALs

type SlideProps = {
  expanded: boolean;
};

const Slider = styled.div<SlideProps>(props => ({
  width: '100%',
  height: props.expanded ? '86px' : '0',
  borderTop: `1px solid${mapColors.backgrounds.borderPrimary}`,
  // borderLeft: `1px solid${mapColors.backgrounds.borderPrimary}`,
  // borderRight: `1px solid${mapColors.backgrounds.borderPrimary}`,
  display: 'flex',
  alignItems: 'left',
  margin: '0 16px',
}));

//#endregion

SwiperCore.use([Navigation]);

interface Props {
  selectedBrand: (index: number) => void;
  selectedBrands: BrandData[];
  selectedBrandsCount: number;
  customer: any;
  expanded: boolean;
}

export const SwiperBrand: FC<Props> = ({
  selectedBrand,
  selectedBrands,
  customer = null,
  selectedBrandsCount = 0,
  expanded,
}): JSX.Element => {
  // Remove COSTA from brand pillars
  const tempBrands = customer?.customer_data.brands || [];
  const brands = Array<any>();
  tempBrands.forEach((brand:any) => { 
    if (brand.brand_name.toLowerCase() !== 'costa')
      brands.push(brand);
  });
  
  function priority(brandName: string) {
    if (brandName.toLowerCase().includes('costa')) return 10;
    if (brandName.toLowerCase().includes('oakley')) return 100;
    if (brandName.toLowerCase().includes('ray ban')) return 1000;
    if (brandName.toLowerCase().includes('rayban')) return 1000;
    if (brandName.toLowerCase().includes('ray-ban')) return 1000;
    return 10000000;
  }

  function dataPriority(hasData: boolean) {
    if (hasData) return 10000;
    return -1;
  }

  // brands.sort((a: any, b: any) => (priority(a.brand_name) <= priority(b.brand_name) ? (a.brand_name < b.brand_name ? 1 : -1) : -1));
  brands.sort((a: any, b: any) => (a.brand_name > b.brand_name ? 1 : -1));
  brands.sort((a: any, b: any) => (priority(a.brand_name) < priority(b.brand_name) ? 1 : -1));
  brands.sort((a: any, b: any) => (dataPriority(a.is_data_available) < dataPriority(b.is_data_available) ? -1 : 1));
  brands.reverse();

  /*
  const brandsFiltered = brands.filter(obj => {
    return obj.is_data_available === true;
  });
  */
  
  const brandsButtons = brands.map((el: any, index: number) => (
    <SwiperSlide key={index}>
      <TabButton
        isTarget
        isDiscount={el.discount}
        hasData={el.is_data_available}
        label={el.brand_name}
        brandId={el.brand_id}
        selectedBrand={selectedBrand}
        selectedBrands={selectedBrands}
        selectedBrandsCount={selectedBrandsCount}
      />
    </SwiperSlide>
  ));

  return (
    <Slider className="swiperWywg" expanded={expanded} style={{ display: expanded ? 'inherit' : 'none' }}>
      <Swiper
        effect="coverflow"
        grabCursor={true}
        navigation
        slidesPerView={brandsButtons.length > 8 ? 8 : brandsButtons.length}
        freeMode
        modules={[FreeMode]}
      >
        {brandsButtons}
      </Swiper>
    </Slider>
  );
};
