/** @format */

import { FC, useEffect, useState } from 'react';
import { UITabTitle } from '../partials/TabTitle';
// import { TableDoors } from './partials/Table';
import { BOX_TITLE_DOORS, EXPORT_DOORS } from '../../../constants/general';
import DataGrid, {
  Column,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  Sorting,
} from 'devextreme-react/data-grid';
import { useParams } from 'react-router-dom';
import { Expandable } from '../../../shared/Expandable';
import { NoData } from '../../../shared/NoData';

interface DoorsProps {
  customer: any;
  opened?: any;
  printMode: boolean;
}

export const Doors: FC<DoorsProps> = ({ customer = null, opened = false, printMode = false }): JSX.Element => {
  const { customerId } = useParams();
  const [isExpanded, setIsExpanded] = useState(opened);
  const data = customer && customer.doors ? customer.doors : [];

  useEffect(() => {
    setIsExpanded(opened);
  }, [opened]);

  return (
    <div id="doors" className="data-panel">
      <UITabTitle
        label={BOX_TITLE_DOORS}
        infoMessage={''}
        url={data.length > 0 ? EXPORT_DOORS : ''}
        customerId={customerId || '0'}
        onClick={() => setIsExpanded(!isExpanded)}
        collapsed={isExpanded}
      />
      <Expandable expanded={isExpanded}>
        {data.length > 0 ? (
          <>
            <DataGrid
              dataSource={data}
              allowColumnReordering={true}
              rowAlternationEnabled={true}
              showBorders={true}
              showColumnLines={false}
              showRowLines={false}
              allowColumnResizing={true}
              columnAutoWidth={true}
              columnMinWidth={200}
            // onContentReady={this.onContentReady}
            >
              <Sorting mode="multiple" />
              <ColumnFixing enabled={false} />
              <HeaderFilter visible={false} />
              <FilterRow visible={false} /* applyFilter={this.state.currentFilter} */ />

              <Column
                dataField="customer_code_child"
                caption="CUSTOMER ID"
                  /* dataType="number" format="currency" */ alignment="left"
              >
                <HeaderFilter allowSearch={true} />
              </Column>

              <Column dataField="name" caption="NAME" /* dataType="number" format="currency" */ alignment="left">
                <HeaderFilter allowSearch={true} />
              </Column>

              <Column
                dataField="address"
                caption="ADDRESS"
                  /* dataType="number" format="currency" */ alignment="left"
              >
                <HeaderFilter allowSearch={true} />
              </Column>

              <Column dataField="city" caption="CITY" /* dataType="number" format="currency" */ alignment="left">
                <HeaderFilter allowSearch={true} />
              </Column>

              <Column
                dataField="zip_code"
                caption="ZIP CODE"
                  /* dataType="number" format="currency" */ alignment="left"
              >
                <HeaderFilter allowSearch={true} />
              </Column>

              <Column dataField="email" caption="EMAIL" /* dataType="number" format="currency" */ alignment="left">
                <HeaderFilter allowSearch={true} />
              </Column>

              <Column dataField="phone" caption="PHONE" /* dataType="number" format="currency" */ alignment="left">
                <HeaderFilter allowSearch={true} />
              </Column>

              <Column
                dataField="country_code"
                caption="COUNTRY CODE"
                  /* dataType="number" format="currency" */ alignment="left"
              >
                <HeaderFilter allowSearch={true} />
              </Column>

              
              {false && <Column
                dataField="sales_org"
                caption="SALES ORG"
                  /* dataType="number" format="currency" */ alignment="left"
              >
                <HeaderFilter allowSearch={true} />
              </Column>}
              {false && <Column
                dataField="brand_code"
                caption="BRAND CODE"
                  /* dataType="number" format="currency" */ alignment="left"
              >
                <HeaderFilter allowSearch={true} />
              </Column>}

              {false && <Column
                dataField="partner_name"
                caption="PARTNER NAME"
                  /* dataType="number" format="currency" */ alignment="left"
              >
                <HeaderFilter allowSearch={true} />
              </Column>}

              {false && <Column
                dataField="partner2"
                caption="PARTNER"
                  /* dataType="number" format="currency" */ alignment="left"
              >
                <HeaderFilter allowSearch={true} />
              </Column>}
              {false && <Column
                dataField="partner_funcation"
                caption="PARTNER FUNCTION"
                  /* dataType="number" format="currency" */ alignment="left"
              >
                <HeaderFilter allowSearch={true} />
              </Column>}
              {false && <Column
                dataField="main_account"
                caption="MAIN ACCOUNT"
                  /* dataType="number" format="currency" */ alignment="left"
              >
                <HeaderFilter allowSearch={true} />
              </Column>}
              {false && <Column
                dataField="buying_group_code"
                caption="BUYING GROUP CODE"
                  /* dataType="number" format="currency" */ alignment="left"
              >
                <HeaderFilter allowSearch={true} />
              </Column>}
              {false && <Column
                dataField="co_number"
                caption="CO NUMBER"
                  /* dataType="number" format="currency" */ alignment="left"
              >
                <HeaderFilter allowSearch={true} />
              </Column>}
              {false && <Column
                dataField="alliance_code"
                caption="ALLIANCE CODE"
                  /* dataType="number" format="currency" */ alignment="left"
              >
                <HeaderFilter allowSearch={true} />
              </Column>}
              {false && <Column
                dataField="alliance7"
                caption="ALLIANCE7"
                  /* dataType="number" format="currency" */ alignment="left"
              >
                <HeaderFilter allowSearch={true} />
              </Column>}
              {false && <Column
                dataField="central_ord_block"
                caption="CENTRAL ORD BLOCK"
                  /* dataType="number" format="currency" */ alignment="left"
              >
                <HeaderFilter allowSearch={true} />
              </Column>}
              {false && <Column dataField="lm_id" caption="LM ID" /* dataType="number" format="currency" */ alignment="left">
                <HeaderFilter allowSearch={true} />
              </Column>}
              {false && <Column
                dataField="vs_member_id"
                caption="VS MEMBER ID"
                  /* dataType="number" format="currency" */ alignment="left"
              >
                <HeaderFilter allowSearch={true} />
              </Column>}
              
              {false &&
                <Column
                  dataField="gmt_rating"
                  caption="GMT RATING"
                  /* dataType="number" format="currency" */ alignment="left"
                >
                  <HeaderFilter allowSearch={true} />
                </Column>}
              
              <Column
                dataField="rating"
                caption="RATING"
                  /* dataType="number" format="currency" */ alignment="left"
              >
                <HeaderFilter allowSearch={true} />
              </Column>

              <Pager
                visible={printMode ? false : true}
                // allowedPageSizes={false}
                displayMode={'compact'}
                showPageSizeSelector={false}
                showInfo={true}
                showNavigationButtons={true}
              />
              <Paging defaultPageSize={printMode ? 100000 : 10} />
            </DataGrid>
            <div className='note'>Only 1st 50 records are displayed, use excel download to see full data</div>
          </>
        ) : (
          <NoData />
        )}
      </Expandable>
    </div>
  );
};
