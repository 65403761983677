import { FC } from 'react';
//import '../../../assets/rh.scss';
import './GenericModal.scss';

interface GenericModalProps {
    isOpenModal: boolean,
    onClick:  () => void,
    title: string,
    body: string
}

export const GenericModal: FC<GenericModalProps> = ({
        isOpenModal = false,
        onClick  = () => { },
        title = '',
        body = '',
    }): JSX.Element => {
    return (
        <div className={isOpenModal ? 'modal fade show modal-xl' : 'modal fade modal-xl' } role="dialog"  aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                        <span className='closeSpan' onClick={onClick} aria-hidden="true">&times;</span>
                    </div>
                    <div className="modal-body">
                        <p>{body}</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={onClick} className="btn btn-sm btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};