/** @format */

import { FC, useState } from 'react';
import { UIIcon } from '../../../shared/Icon';
import { EL360_URL } from '../../../constants/general';

interface Props {
  title: string;
  statusDescription: string;
  srcIconTop: string;
  srcIConBottom: string;
  labelColor: string;
  link?: string;
}

export const CardEL360: FC<Props> = ({
  title,
  statusDescription,
  srcIConBottom,
  srcIconTop,
  labelColor,
  link = EL360_URL,
}): JSX.Element => {
  const [isTooltip, setIsTooltip] = useState(false);
  return (
    <div className="item">
      <div className='box'>
        <div style={{ marginTop: '30px' }}>
          <UIIcon name='' srcIcon={srcIconTop} width={''} height={''} className="" />
        </div>

        <div className='content'>
          <div className='title'>{title}</div>
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            style={{
              cursor: 'pointer',
              textDecoration: 'none',
              pointerEvents: link && link.length > 0 ? 'all' : 'none',
              opacity: link && link.length > 0 ? 1.0 : 0.25,
            }}
          >
            <div className='text' style={{ color: labelColor, borderColor: labelColor }}>Learn More</div>
          </a>
        </div>

        <div className='info' onMouseLeave={() => setIsTooltip(false)} onMouseOver={() => setIsTooltip(true)}>
          <UIIcon name='' srcIcon={srcIConBottom} width={''} height={''} className="" />
          {isTooltip && statusDescription?.length > 0 && <span className='hovertip'>{statusDescription}</span>}
        </div>
      </div>
    </div>
  );
};
