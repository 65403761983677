/** @format */

import { FC } from 'react';
import styled from '@emotion/styled';
import { UIIcon } from '../../../../shared/Icon';
import iconTarget from '../../../../assets/icons/solid/BrandTarget.svg';
import { BrandData } from '..';

const ContainerButton = styled.div({
  height: '50px',
  marginTop: '20px',
});

const ContainerIcon = styled.div({
  zIndex: 5,
  position: 'absolute',
  left: '44%',
  right: '50%',
  top: '36px',
});

interface Props {
  label: string;
  isTarget?: boolean;
  isDiscount?: boolean;
  hasData?: boolean;
  brandId: number;
  selectedBrand: (index: number) => void;
  selectedBrands: BrandData[];
  selectedBrandsCount: number;
}
export const TabButton: FC<Props> = ({
  label,
  isTarget = false,
  isDiscount = false,
  hasData = false,
  selectedBrand,
  selectedBrands,
  brandId,
  selectedBrandsCount,
}): JSX.Element => {
  const isActive = selectedBrandsCount > 0 && selectedBrands.findIndex(elem => elem.id === brandId) >= 0;

  function getButtonClassName() {
    let output = 'tab-button';
    if (isActive) output += ' active';
    if (hasData) output += ' data';
    return output;
  }

  return (
    <ContainerButton>
      <button className={getButtonClassName()}
        onClick={() => {
          if (hasData) selectedBrand(brandId);
        }}
      >
        {label}
        {isTarget && isDiscount && (
          <ContainerIcon>
            <UIIcon name={'target'} srcIcon={iconTarget} width={'16'} height={'16'} className="" />
          </ContainerIcon>
        )}
      </button>
    </ContainerButton>
  );
};
