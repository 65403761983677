/** @format */

import { configureStore } from '@reduxjs/toolkit';
import { sliceAuth } from '../services/auth/slice';
import { sliceBrand } from '../services/brand/slice';
import { sliceCustomer } from '../services/customer/slice';
import { loaderSlice } from '../services/loader/slice';
import { sliceResults } from '../services/search/slice';
import { slicePerformance } from '../services/performance/slice';
import { userSlice } from '../services/user/slice';
import { APP_NAME } from '../constants/general';
import { utilityGetFromSessionStorage } from './cache/getFromSessionStorage';
import { utilitySaveToSessionStorage } from './cache/saveToSessionStorage';

export const createStore = () => {
  const preloadedState = utilityGetFromSessionStorage(APP_NAME);
  const store = configureStore({
    reducer: {
      auth: sliceAuth.reducer,
      customer: sliceCustomer.reducer,
      results: sliceResults.reducer,
      brand: sliceBrand.reducer,
      loader: loaderSlice.reducer,
      user: userSlice.reducer,
      performance: slicePerformance.reducer,
    },
    preloadedState,
  });

  return store;
};

export const store = createStore();

const onStoreChanged = () => {
  const reduxStore = store.getState();
  utilitySaveToSessionStorage(APP_NAME, reduxStore);
};

store.subscribe(onStoreChanged);

export type TStore = ReturnType<typeof store.getState>;

export type TDispatch = typeof store.dispatch;
