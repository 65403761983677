/** @format */

import { FC } from 'react';

interface Props {
  onClick: () => void;
  text: string;
  enabled: boolean;
}

export const RefreshButton: FC<Props> = ({ onClick, text, enabled }): JSX.Element => {
  return (
    <div className={ enabled ? 'refresh' : 'refresh disabled'}>
      <a onClick={() => onClick()}>{text}</a>
    </div>
  );
};
