/** @format */

import styled from '@emotion/styled';
import { FC, useEffect } from 'react';
import Lottie from 'lottie-react';
import error from '../../assets/animation/err.json';
import headerBg from '../../assets/images/navbarbg.png';
import { useAppDispatch } from '../../hooks/redux';
import { loaderSlice } from '../../services/loader/slice';
import { Fade } from 'react-awesome-reveal';
import { MainHeader } from '../../shared/MainHeader';

export const Navbar = styled.div({
  display: 'flex',
  alignItems: 'center',
  position: 'fixed',
  justifyContent: 'start',
  height: '80px',
  width: '100%',
  backgroundColor: 'white',
  backgroundImage: 'url(' + headerBg + ')',
  backgroundAttachment: 'fixed',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  boxShadow: '0px 2px 12px 2px rgba(0,0,0,0.1)',
});

export const Logo = styled.p({
  marginLeft: '30px',
});

export const UserPermission: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const updateInfoElement = document.getElementById('update-info');
    if (updateInfoElement) updateInfoElement.style.display = 'none';
    document.title = 'Permission Denied | Rewards Hub';
  }, []);

  dispatch(loaderSlice.actions.stopLoading());
  return (
    <>
      {/*
      <Navbar>
        <Logo>
          <UIIcon name={'Logo'} srcIcon={srcLogo} width={''} height={'54px'} className="" />
        </Logo>
      </Navbar>
      */}
      <MainHeader
        showUser={false}
        enableLeftMenu={false}
        showNavigationBar={false}
        showPdfExportButton={false}
        showSearchBar={false}
        showSearchButton={false}
        customer={null} />
      <section className="main">
        <div className="container unauthorized">
          <div id="unauthorized">
            <Lottie className="image" animationData={error} loop={false} />
            <Fade>
              <div className="title">Permission Denied</div>
              <div className="content">
                You do not have permission to access this page.
                <br />
                Please refer to your system administrator.
              </div>
            </Fade>
          </div>
        </div>
        </section>
    </>
  );
};
