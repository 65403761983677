/** @format */

import { FC, useEffect, useState } from 'react';
import { InfoCard } from './partials/InfoCard';

type GeneralInfoSmallProps = {
  customer: any;
  doorsAction: () => void;
  // contactsAction: () => void;
};

export const GeneralInfoSmall: FC<GeneralInfoSmallProps> = ({ customer = null, doorsAction /* , contactsAction */ }): JSX.Element => {
  const [offset, setOffset] = useState(0);
  
  // const customers = useAppSelector(selectorCustomer);

  const activeDoorsNumber = customer ? customer?.customer_data.active_doors_number : 0;
  // const activeContactsNumber = customer ? customer?.customer_data.contacts_number : 0;
  // const activeContactsNumber = customer ? customer?.contacts?.length : 0;
  const allianceCode = customer ? customer?.customer_data.alliance_code : '';
  const allianceName = customer ? customer?.customer_data.alliance_name : '';
  const commonOwnerCode = customer ? customer?.customer_data.common_owner_code : '';

  const levelStatusValue = customer ? customer.customer_data?.level_status_value ?? 0 : 0;
  const levelStatusValue2 = customer ? customer.customer_data?.customer_status_level?.level_status_value ?? 0 : 0;
  let levelValue = levelStatusValue2 - levelStatusValue + 1;
  if (levelValue === 1) levelValue = 0;

  const levelString = customer ? customer.customer_data?.customer_status_level?.level_status_description ?? '' : '';
  // const totalPotentialSavings = customer ? customer.customer_data.total_potential_savings : -1;
  // const totalAchievedSavings = customer ? customer.customer_data.total_achieved_savings : -1;

  useEffect(() => {
    document.title = `${customer ? customer?.customer_data.customer_code : ''} Dashboard | Rewards Hub`;
    const onScroll = () => setOffset(window.scrollY);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <div id="account-information-small" className={offset > 200 ? 'data-panel show' : 'data-panel'}>
      <div className='data-container'>
          <InfoCard
            customerCode={customer ? customer?.customer_data.customer_code : ''}
            email={customer ? customer?.customer_data.email : ''}
            name={customer ? customer?.customer_data.customer_name : ''}
            address={customer ? customer?.customer_data.address : ''}
            state={customer ? customer?.customer_data.state : ''}
            commonOwnerCode={commonOwnerCode}
            allianceCode={allianceCode}
            allianceName={allianceName}
            level={levelString}
            levelRating={customer ? customer?.customer_data.level_status_rating : null}
            el360Status={customer ? customer?.customer_data.el360_status.status_code : ''}
            activeDoorsNumber={activeDoorsNumber}
            doorsAction={() => doorsAction()}
        />
        </div>
    </div>
  );
};
