import { CheckBox } from 'devextreme-react';
import { FC, useEffect, useState } from 'react';
import './CheckboxSelection.scss';

interface CheckBoxSelectionProps {
    id?: string | undefined;
    data: any;
    defaultValues: any;
    identifier: string;
    displayExpr: string;
    onChangedSelection: (selection?: any) => void;
}
export const CheckBoxSelection: FC<CheckBoxSelectionProps> = ({
    id,
    data = null,
    defaultValues = null,
    identifier = '',
    displayExpr = '',
    onChangedSelection = () => { },
}): JSX.Element => {
    const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const [preSelectedValues, setPreselectedValues] = useState<number[]>([]);

    useEffect(() => {
    }, [data]);

    useEffect(() => {
        setPreselectedValues(defaultValues ?? []);
    }, [defaultValues]);

    function loadOptions() {
        let tmpList = selectedValues.slice(0);
        return data.map(((object: any) => {
            return <div id={id} className='checkboxselection--item' key={object[identifier]}>
                <CheckBox
                    value={preSelectedValues.includes(object[identifier]) || tmpList.some((item: any) => item[identifier] === object[identifier])}
                    onValueChanged={(value) => {
                        if (value.value) {
                            tmpList?.push(object);
                        } else {
                            tmpList = tmpList?.filter((item: any) => item[identifier] !== object[identifier]);
                        }
                        setPreselectedValues([]);
                        setSelectedValues(tmpList);
                        onChangedSelection(tmpList);
                    }} />
                <div className='checkboxselection--title'>{object[displayExpr]}</div>
            </div>;
        }
        ));
    }
    return (
        <div className='checkboxselection hide-scrollbar'>
            {loadOptions()}
        </div>
    );
};
