/** @format */

import { FC, useState } from 'react';
import { UITabTitle } from '../partials/TabTitle';
import { Expandable } from '../../../shared/Expandable';
import { GenericTableRebate } from '../../../shared/GenericTableRebate';
import { numberFormat, percentageFormat } from '../../../utils/numberUtils';
import { NoData } from '../../../shared/NoData';

type RewardsRebatesProps = {
  customer: any;
  potential: any;
  isXR: boolean;
};

export const RewardsRebates: FC<RewardsRebatesProps> = ({ customer = null, potential = null, isXR }): JSX.Element => {

  const [currentYear, setCurrentYear] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);

  const hasBreakdownTable = (currentYear ?
    (customer?.rewards_rebates_learn_more?.current_year_rebates ?? []).length > 0 :
    (customer?.rewards_rebates_learn_more?.previous_year_rebates ?? []).length > 0);

  const hasItems = (currentYear ?
    (customer?.new_total_rewards_rebates?.current_year.items ?? []).length > 0 :
    (customer?.new_total_rewards_rebates?.previous_year.items ?? []).length > 0);

  const breakdownTable = (currentYear ?
    customer?.rewards_rebates_learn_more?.current_year_rebates ?? [] :
    customer?.rewards_rebates_learn_more?.previous_year_rebates ?? [])?.map((element: any, index: number) => (
      <tr key={`${element.label}-${index}`}>
        <th>{element.label}</th>
        <td>{numberFormat(parseFloat(element.total))}</td>
        <td>{numberFormat(parseFloat(element.h1))}</td>
        <td>{numberFormat(parseFloat(element.h2))}</td>
      </tr>
    ));

  const potentialTable = potential?.map((element: any, index: number) => (
    <tr key={`${element.title}-${index}`}>
      <th>{element.title}</th>
      <td>{numberFormat(parseFloat(element.value_from_target))}</td>
      <td>{numberFormat(parseFloat(element.value_potential))}</td>
    </tr>
  ));

  return (
    <div id="rewards-rebates" className="data-panel savings">
      <UITabTitle
        label={'Rebates'}
        infoMessage={''}
        onClick={() => setIsExpanded(!isExpanded)}
        collapsed={isExpanded}
      >
        <div className="selector" style={isExpanded ? {} : { display: 'none ' }}>
          <div className={!currentYear ? 'previous-year selected' : 'previous-year'} onClick={(event) => { event.stopPropagation(); if (currentYear && isExpanded) setCurrentYear(false); }}>Previous year</div>
          <div className={currentYear ? 'current-year selected' : 'current-year'} onClick={(event) => { event.stopPropagation(); if (!currentYear && isExpanded) setCurrentYear(true); }}>Current year</div>
        </div>
      </UITabTitle>
      <Expandable expanded={isExpanded}>
        <div className='box'>
          <div className='savings'>
            {hasItems &&
              <GenericTableRebate items={
                currentYear ?
                  customer?.new_total_rewards_rebates?.current_year.items ?? [] :
                  customer?.new_total_rewards_rebates?.previous_year.items ?? []} />}
            {!hasItems && <NoData />}
          </div>
          <div className='total'>
            <div className='value'>
              {currentYear ?
                numberFormat(customer?.new_total_rewards_rebates?.current_year.total) :
                numberFormat(customer?.new_total_rewards_rebates?.previous_year.total)}
            </div>
            <div className='label'>Total rebates</div>
            <div className='percentage'>
              {(currentYear ?
                percentageFormat(customer?.new_total_rewards_rebates?.current_year.total_percentage) :
                percentageFormat(customer?.new_total_rewards_rebates?.previous_year.total_percentage)) + ' of total portfolio gross sales'}
            </div>

          </div>

          <div className='learn-more-container'>
            <button className='learn-more-button' data-bs-toggle="modal" data-bs-target="#rewards-rebates-popup" onClick={() => { }}>Learn more</button>
          </div>

          <div className='printable'>
            <h3>Rebates breakdown</h3>
            <div className='rebates-breakdown'>
              {hasBreakdownTable &&
                <table className='breakdown-table'>
                  <tbody>
                    <tr>
                      <td />
                      <td className='gray'>{'Total'}</td>
                      <td className='gray'>{'H1'}</td>
                      <td className='gray'>{'H2'}</td>
                    </tr>
                    {breakdownTable}
                  </tbody>
                </table>}
              {!hasBreakdownTable && <div className='breakdown-table-nodata'><NoData /></div>}
              <div className="total">
                <div className="value">{currentYear ?
                  numberFormat(customer?.rewards_rebates_learn_more?.current_year_total) :
                  numberFormat(customer?.rewards_rebates_learn_more?.previous_year_total)}</div>
                <div className="label">Total rebates</div>
                <div className="percentage">{(currentYear ?
                  percentageFormat(customer?.rewards_rebates_learn_more?.current_year_percentage ?? 0.0) :
                  percentageFormat(customer?.rewards_rebates_learn_more?.previous_year_percentage ?? 0.0)) + ' of total portfolio gross sales'}</div>
              </div>
            </div>
            <div className='separator' />
            {!isXR && currentYear && potential && <>
              <h3>Rebates potential</h3>
              <div className='rebates-pontential'>
                <table className='potential-table'>
                  <tbody>
                    <tr>
                      <td />
                      <td className='gray'>{'Dollar from target'}</td>
                      <td className='gray'>{'Potential rebate'}</td>
                    </tr>
                    {potentialTable}
                  </tbody>
                </table>
              </div>
            </>}
          </div>

          <div className="modal fade modal-xl" id="rewards-rebates-popup"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-xl" style={{ width: '90%' }}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="staticBackdropLabel">Rebates</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div className="modal-body">
                  <h3>Rebates breakdown</h3>
                  <div className='rebates-breakdown'>
                    {hasBreakdownTable &&
                      <table className='breakdown-table'>
                        <tbody>
                          <tr>
                            <td />
                            <td className='gray'>{'Total'}</td>
                            <td className='gray'>{'H1'}</td>
                            <td className='gray'>{'H2'}</td>
                          </tr>
                          {breakdownTable}
                        </tbody>
                      </table>}
                    {!hasBreakdownTable && <div className='breakdown-table-nodata'><NoData /></div>}
                    <div className="total">
                      <div className="value">{currentYear ?
                        numberFormat(customer?.rewards_rebates_learn_more?.current_year_total) :
                        numberFormat(customer?.rewards_rebates_learn_more?.previous_year_total)}</div>
                      <div className="label">Total rebates</div>
                      <div className="percentage">{(currentYear ?
                        percentageFormat(customer?.rewards_rebates_learn_more?.current_year_percentage ?? 0.0) :
                        percentageFormat(customer?.rewards_rebates_learn_more?.previous_year_percentage ?? 0.0)) + ' of total portfolio gross sales'}</div>
                    </div>
                  </div>
                  <div className='separator' />
                  {!isXR && currentYear && potential && <>
                    <h3>Rebates potential</h3>
                    <div className='rebates-pontential'>
                      <table className='potential-table'>
                        <tbody>
                          <tr>
                            <td />
                            <td className='gray'>{'Dollar from target'}</td>
                            <td className='gray'>{'Potential rebate'}</td>
                          </tr>
                          {potentialTable}
                        </tbody>
                      </table>
                    </div>
                  </>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Expandable>
    </div>
  );
};
