/** @format */

import axios from 'axios';

export const apiResults = ({ inputSearch }: { inputSearch: string }) => {
  const bodyParameters = {
    search_text: inputSearch,
  };
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('jwtToken')}` },
  };
  return axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/customers`, bodyParameters, config);
};

export const apiPagedResults = ({ inputSearch, pageNumber }: { inputSearch: string; pageNumber: number }) => {
  const bodyParameters = {
    search_text: inputSearch,
    page_number: pageNumber,
  };
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('jwtToken')}` },
  };
  return axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/paged_customers`, bodyParameters, config);
};
