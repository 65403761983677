import { DropDownBox, TreeView, Validator } from 'devextreme-react';
import { RequiredRule } from 'devextreme-react/validator';
import { useEffect, useState } from 'react';

export enum SelectionMode {
  single,
  multiple,
}
interface SelectionProps<T> {
  componentKey: string,
  id: string;
  name: string;
  map: any;
  placeholder: string;
  valueExpr: string;
  displayExpr: string;
  selectionMode: SelectionMode;
  disabled: boolean | false;
  values?: Array<any> | null,
  closeAfterSelection?: boolean | false;
  required?: boolean | true;
  onChangedSelection: (selection?: T) => void,
  cssClass?: string
}

export const Selection = <ObjectType,
>(props: SelectionProps<ObjectType>): JSX.Element => {

  let _treeView: TreeView;
  const [treeBoxValue, setTreeBoxValue] = useState<Array<any>>([]);

  /*
  function _arrayEquals(a: Array<any>, b: Array<any>) {
    return Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index]);
  }
  */

  useEffect(() => {
    setTreeBoxValue(props.values ?? []);
  }, [props.values]);

  function onValueChanged(e: any) {
    const values = e.component.getSelectedNodeKeys();
    
    if (props.selectionMode === SelectionMode.single) {
      if (values.length > 0) {
        props.onChangedSelection(values[0]);
      } else {
        props.onChangedSelection(undefined);
      }

      if (props.closeAfterSelection) {
        e.element.close();
      }

    } else {
      props.onChangedSelection(values);
    }
  }

  function treeViewItemSelectionChanged(e: any) {
    setTreeBoxValue(e.component.getSelectedNodeKeys());
  }

  function syncTreeViewSelection(e: any) {
    const treeView = (e.component.selectItem && e.component)
      || (_treeView && _treeView.instance);

    if (treeView) {
      if (e.value === null) {
        treeView.unselectAll();
      } else {
        const values = e.value || props.values;
        if (values && values.length > 0) {
          for (let i = 0; i < values.length; i++) {
            treeView.selectItem(values[i]);
          }
        }
      }
    }

    if (e.value !== undefined) {
      setTreeBoxValue(e.value);
    }
  }

  function treeViewRender() {
    return (
      <TreeView
        id={props.componentKey}
        key={'tree_' + props.componentKey}
        dataSource={props.map}
        ref={(ref: any) => { _treeView = ref; }}
        dataStructure="plain"
        keyExpr={props.valueExpr}
        selectionMode={props.selectionMode === SelectionMode.single ? 'single' : 'multiple'}
        showCheckBoxesMode="normal"
        selectNodesRecursive={true}
        displayExpr={props.displayExpr}
        selectByClick={true}
        onContentReady={syncTreeViewSelection}
        onItemSelectionChanged={treeViewItemSelectionChanged}
        onSelectionChanged={onValueChanged}
      />
    );
  }

  function addValidators() {
    return <Validator>
      {props.required ? <RequiredRule message='Field is required' /> : <div />}
    </Validator>;
  }

  return (
    <div className={'selection ' + props.cssClass}>
      <DropDownBox
        id={props.id}
        name={props.name}
        key={props.componentKey}
        disabled={props.disabled}
        value={treeBoxValue}
        valueExpr={props.valueExpr}
        displayExpr={props.displayExpr}
        placeholder={props.placeholder}
        showClearButton={false}
        dataSource={props.map}
        contentRender={treeViewRender}
        onValueChanged={syncTreeViewSelection}>
        {addValidators()}
      </DropDownBox>
    </div>
  );
};
