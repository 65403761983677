export type PerformanceItem = {
  amount: number;
  brand_code: string;
  customer_code: string;
  customer_name: string;
  main_account_code: string;
  main_account_name: string;
  month: number;
  quarter: string;
  unit: number;
  year: string;
};

/*
export function parseStringToNumber(text: string) {
  return parseInt(text.replace(',', '') ?? '0.0', 10);
}
*/

let currentYear: number;

export function setCurrentYear(value: number) {
  currentYear = value;
}

export function getCurrentYear() {
  return currentYear;
}

export function getPreviousYear() {
  return currentYear - 1;
}

export function getMonthName(monthNumber: number) {
  const date = new Date();
  date.setMonth(monthNumber - 1);
  return date.toLocaleString('en-US', {
    month: 'short',
  });
}

export function retrievePerformanceByBrandIds(brandIds: string[], performances: PerformanceItem[]) {
  const performances_list: PerformanceItem[] = [];
  brandIds.forEach(brandId => {
    performances.filter(element => element.brand_code === brandId).forEach(result => performances_list.push(result));
  });
  return performances_list;
}

export function loadGraphDataSum(data: any,
  brandIds: string[],
  getMainList: (data: any) => any[],
  getSubList: (data: any) => any[],
  getDateRange: (item: any) => void,
  getType: (item: any) => number,
  list: any[]) {

  const performances: PerformanceItem[] = [];

  const mainList: any[] = getMainList(data);
  if (mainList.length === 0) {
    return [];
  }
  for (const key in mainList) {
    if (Object.prototype.hasOwnProperty.call(mainList, key)) {
      const element = mainList[key];
      performances.push(element);
    }
  }

  const currentYearsElements: PerformanceItem | undefined = performances.find(object => object.year === getCurrentYear().toString());
  const previousYearsElements: PerformanceItem | undefined = performances.find(object => object.year === getPreviousYear().toString());
  
  let cy_brands: any[] = [];
  if (currentYearsElements !== undefined) {
    cy_brands = retrievePerformanceByBrandIds(brandIds, getSubList(currentYearsElements));
  }
  
  let py_brands: any[] = [];
  if (previousYearsElements !== undefined) {
    py_brands = retrievePerformanceByBrandIds(brandIds, getSubList(previousYearsElements));
  }

  const graphData: any[] = [];
  let sumCY = 0;
  let sumPY = 0;

  for (const key in list) {
    const listCY = cy_brands.filter(element => getDateRange(element) === list[key]);
    for (const item in listCY) {
      if (Object.prototype.hasOwnProperty.call(listCY, item)) {
        sumCY += getType(listCY[item]);
      }
    }
    const listPY = py_brands.filter(element => getDateRange(element) === list[key]);
    for (const item in listPY) {
      if (Object.prototype.hasOwnProperty.call(listPY, item)) {
        sumPY += getType(listPY[item]);
      }
    }
  }

  graphData.push({
    key: '',
    cy: sumCY,
    py: sumPY,
  });
  
  return graphData;
}

export function getSumFor(data: any,
  brandIds: string[],
  getMainList: (data: any) => any[],
  getSubList: (data: any) => any[],
  getDateRange: (item: any) => void,
  getType: (item: any) => number,
  year: number,
  list: any[]) {
  const performances: PerformanceItem[] = [];
  const mainList = getMainList(data);
  for (const key in mainList) {
    if (Object.prototype.hasOwnProperty.call(mainList, key)) {
      const element = mainList[key];
      performances.push(element);
    }
  }
  const elements: PerformanceItem | undefined = performances.find(object => object.year === year.toString());
  let brands: any[] = [];
  if (elements !== undefined) {
    brands = retrievePerformanceByBrandIds(brandIds, getSubList(elements));
  }
  let sum = 0;

  for (const key in list) {
    const list_ = brands.filter(element => getDateRange(element) === list[key]);
    for (const item in list_) {
      if (Object.prototype.hasOwnProperty.call(list_, item)) {
        sum += getType(list_[item]);
      }
    }
  }
  return sum;
}

export function loadGraphData(data: any,
  brandIds: string[],
  getMainList: (data: any) => any[],
  getSubList: (data: any) => any[],
  getDateRange: (item: any) => void,
  getType: (item: any) => number,
  displayedKey: (item: any) => void,
  getPrefix: () => string,
  list: any[]) {
  const performances: PerformanceItem[] = [];

  const mainList = getMainList(data);

  for (const key in mainList) {
    if (Object.prototype.hasOwnProperty.call(mainList, key)) {
      const element = mainList[key];
      if (element.brand_performance !== null && element.brand_performance !== undefined) {
        element.brand_performance.forEach((item: any) => { 
          if (item.amount !== null && item.amount !== undefined) item.amount = parseFloat(item.amount);
          if (item.unit !== null && item.unit !== undefined) item.unit = parseInt(item.unit, 10);
        });
      }
      performances.push(element);
    }
  }

  const currentYearsElements = performances.find(object => object.year === getCurrentYear().toString());
  const previousYearsElements = performances.find(object => object.year === getPreviousYear().toString());

  let cy_brands: any[] = [];
  if (currentYearsElements !== undefined) {
    cy_brands = retrievePerformanceByBrandIds(brandIds, getSubList(currentYearsElements));
  }

  let py_brands: any[] = [];
  if (previousYearsElements !== undefined) {
    py_brands = retrievePerformanceByBrandIds(brandIds, getSubList(previousYearsElements));
  }

  const graphData: any[] = [];
  
  for (const key in list) {
    let sumCY = 0;
    let sumPY = 0;

    const listCY = cy_brands.filter(element => getDateRange(element) === list[key]);
    for (const item in listCY) {
      if (Object.prototype.hasOwnProperty.call(listCY, item)) {
        sumCY += getType(listCY[item]);
      }
    }

    const listPY = py_brands.filter(element => getDateRange(element) === list[key]);
    for (const item in listPY) {
      if (Object.prototype.hasOwnProperty.call(listPY, item)) {
        sumPY += getType(listPY[item]);
      }
    }

    graphData.push({
      key: displayedKey(list[key]),
      cy: sumCY,
      py: sumPY,
      prefix: getPrefix(),
    });
  }
  return graphData;
}

export function retrieveAllBrandsList(data: any) {
  const brandList = data.map((item: any) => item.brand_code);
  const uniqueBrands: string[] = [];
  brandList.map((brand: any) => {
    if (uniqueBrands.find((element) => element === brand) === undefined) {
      uniqueBrands.push(brand);
    }
  });
  return uniqueBrands;
}

export function parseBrandsForSelection(data: any) {

  const brandList = data.map((item: any) => {
    return { code: item.brand_code, category: item.brand_category, group: item.brand_group };
  });

  const uniqueBrands: { code: string, category?: string, group?: string }[] = [];
  brandList.map((brand: any) => {
    if (uniqueBrands.find((element) => element.code === brand.code) === undefined) {
      uniqueBrands.push({ code: brand.code, category: brand.category, group: brand.group });
    }
  });

  const brandGroups = data.map((item: any) => item.brand_group);
  const uniqueBrandGroups: string[] = [];

  brandGroups.map((brand: string) => {
    if (uniqueBrandGroups.indexOf(brand) === -1) {
      uniqueBrandGroups.push(brand);
    }
  });

  const brandMap: { code: string, category?: string, group?: string, expanded: boolean, selected: boolean }[] = [];

  for (const key in uniqueBrandGroups) {
    if (Object.prototype.hasOwnProperty.call(uniqueBrandGroups, key)) {
      const element = uniqueBrandGroups[key];
      brandMap.push({
        code: element, category: element, expanded: true, selected: false,
       });
    }
  }

  for (const key in uniqueBrands) {
    if (Object.prototype.hasOwnProperty.call(uniqueBrands, key)) {
      const element = uniqueBrands[key];
      brandMap.push({ code: element.code, category: element.category, group: element.group, expanded: false, selected: false });
    }
  }

  brandMap.sort((a, b) => {
    // Prima confronta le categorie
    if (a.group && b.group) {
      if (a.group < b.group) return -1;
      if (a.group > b.group) return 1;
    }
    
    // Se le categorie sono uguali, confronta i codici
    if (a.category && b.category) {
      if (a.category < b.category) return -1;
      if (a.category > b.category) return 1;
    }
    
    // Se sia le categorie che i codici sono uguali, restituisci 0
    return 0;
  });

  brandMap.push({
    category: 'All Brands',
    code: 'ALL',
    expanded: false,
    selected: true,
  });

  return brandMap;
}

export function parseCustomersForSelection(data: any) {
  // set customers
  const uniqueCustomers: string[] = [];
  const customerlist = data.map((item: any) => item.customer_code);

  customerlist.map((customer: string) => {
    if (uniqueCustomers.indexOf(customer) === -1) {
      uniqueCustomers.push(customer);
    }
  });
  const list  = [];
  list.push({ code: 'All Customers', isSelected: true });
  uniqueCustomers.forEach((item) => {
    list.push({ code: item, isSelected: false });
  });


  return list;
}

export type BrandStandardResponse = {
  customer_code: string;
  brand_standard_list: BrandStandardList[];
};

export type BrandStandardList = {
  brand_description: string;
  unit_previous_year: number;
  unit_current_year: number;
  brand_standard: number | null;
  brand_image: string | null;
};

export function parsePopUpData(data: any) {
  const previousYearParsed = data.find((object: any) => object.year === getPreviousYear().toString());
  const currentYearParsed = data.find((object: any) => object.year === getCurrentYear().toString());

  const previousYear = (previousYearParsed !== null && previousYearParsed !== undefined) ? previousYearParsed.brand_performance : [];
  const currentYear = (currentYearParsed !== null && currentYearParsed !== undefined) ? currentYearParsed.brand_performance : [];

  const groupBy = function (xs: any, key: any) { return xs.reduce(function (rv: any, x: any) { (rv[x[key]] = rv[x[key]] || []).push(x); return rv; }, {}); };

  const previousYearMapped = groupBy(previousYear, 'brand_code');
  const currentYearMapped = groupBy(currentYear, 'brand_code');

  const toReturn: { category: string, desc: string, previous: number, current: number, standard: number }[] = [];

  for (const key in previousYearMapped) {
    let category = '';
    let previousYearSum = 0;
    for (const value in previousYearMapped[key]) {
      const obj = previousYearMapped[key][value];
      category = obj.brand_category;
      console.log('#############');
      console.log(parseInt(obj.unit ?? '0.0', 10));
      console.log('#############');
      previousYearSum = previousYearSum + parseInt(obj.unit ?? '0.0', 10);
    }
    let currentYearSum = 0;
    let standard = 0;
    if (key in currentYearMapped) {
      for (const value in currentYearMapped[key]) {
        const obj = currentYearMapped[key][value];
        currentYearSum = currentYearSum + parseInt(obj.unit ?? '0.0', 10);
        standard = standard + obj.brand_standard;
      }
    }

    toReturn.push({ category: key, desc: category, previous: previousYearSum, current: currentYearSum, standard: standard });
  }
  return toReturn;
}