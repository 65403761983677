/** @format */

import { FC, useEffect, useState } from 'react';
import { UITabTitle } from '../partials/TabTitle';
// import { UICardCount } from '../partials/CardCount';
import { InfoCard } from './partials/InfoCard';
import { BOX_TITLE_GENERAL_INFO } from '../../../constants/general';
import { Expandable } from '../../../shared/Expandable';
import { TableGeneralInfo } from './partials/Table';
// import { InfoChart } from '../DataInfo/partials/InfoChart';

// import iconHouse from '../../../assets/icons/outline/Home.svg';
// import iconUserInfo from '../../../assets/icons/outline/UserInfo.svg';

/*
import { useAppSelector } from '../../../hooks/redux';
import { selectorCustomer } from '../../../@ducks/customer/selectors';
import { TCustomer } from '../../../@ducks/search/slice';
*/

type GeneralInfoProps = {
  customer: any;
  doorsAction: () => void;
  // contactsAction: () => void;
};

export const GeneralInfo: FC<GeneralInfoProps> = ({ customer = null, doorsAction /* , contactsAction */ }): JSX.Element => {
  // const customers = useAppSelector(selectorCustomer);

  const activeDoorsNumber = customer ? customer?.customer_data.active_doors_number : 0;
  // const activeContactsNumber = customer ? customer?.customer_data.contacts_number : 0;
  // const activeContactsNumber = customer ? customer?.contacts?.length : 0;
  const allianceCode = customer ? customer?.customer_data.alliance_code : '';
  const allianceName = customer ? customer?.customer_data.alliance_name : '';
  const commonOwnerCode = customer ? customer?.customer_data.common_owner_code : '';

  const levelStatusValue = customer ? customer.customer_data?.level_status_value ?? 0 : 0;
  const levelStatusValue2 = customer ? customer.customer_data?.customer_status_level?.level_status_value ?? 0 : 0;
  let levelValue = levelStatusValue2 - levelStatusValue + 1;
  if (levelValue === 1) levelValue = 0;

  const levelString = customer ? customer.customer_data?.customer_status_level?.level_status_description ?? '' : '';
  // const totalPotentialSavings = customer ? customer.customer_data.total_potential_savings : -1;
  // const totalAchievedSavings = customer ? customer.customer_data.total_achieved_savings : -1;

  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    document.title = `${customer ? customer?.customer_data.customer_code : ''} Dashboard | Rewards Hub`;
  }, []);

  return (
    <div id="account-information" className="data-panel">
      <UITabTitle
        label={BOX_TITLE_GENERAL_INFO}
        infoMessage={''}
        onClick={() => setIsExpanded(!isExpanded)}
        collapsed={isExpanded}
      />
      <Expandable expanded={isExpanded} boxed={false}>
        <>
          <div id="account-information-container" className='data-container horizontal'>
            <InfoCard
              customerCode={customer ? customer?.customer_data.customer_code : ''}
              email={customer ? customer?.customer_data.email : ''}
              name={customer ? customer?.customer_data.customer_name : ''}
              address={customer ? customer?.customer_data.address : ''}
              state={customer ? customer?.customer_data.state : ''}
              commonOwnerCode={commonOwnerCode}
              allianceCode={allianceCode}
              allianceName={allianceName}
              level={levelString}
              levelRating={customer ? customer?.customer_data.level_status_rating : null}
              el360Status={customer ? customer?.customer_data.el360_status.status_code : ''}
              activeDoorsNumber={activeDoorsNumber}
              doorsAction={() => doorsAction()}
            />

            {/*
              <div className='right-block'>
                <div className="savings">
                  <InfoChart
                    name={'TOTAL POTENTIAL SAVINGS'}
                    nameCssClass={''}
                    value={totalPotentialSavings}
                    color={'#8ec5e2'}
                  />
                  <InfoChart
                    name={'TOTAL ACHIEVED SAVINGS'}
                    nameCssClass={''}
                    value={totalAchievedSavings}
                    color={'#004992'}
                  />
                </div>
                <UICardCount
                  action={doorsAction}
                  name={'Doors'}
                  counter={activeDoorsNumber}
                  srcIcon={iconHouse}
                />
                <UICardCount
                  action={contactsAction}
                  name={'Contacts'}
                  counter={activeContactsNumber}
                  srcIcon={iconUserInfo}
                />
  </div>*/}
          </div>
          {/*<div className="divider" />*/}
          <TableGeneralInfo customer={customer} />
        </>
      </Expandable>
    </div>
  );
};
