/** @format */

import { FC } from 'react';

import { Card } from './Card';
import { TCustomer } from '../../../services/search/slice';
//import { UIIcon } from '../../../shared/Icon';
import { EmptyState } from '../../../shared/EmptyState';

//import srcIconEmpty from '../../../assets/images/emptyStateSerp.svg';

type CardListProps = {
  results: any;
};

export const CardList: FC<CardListProps> = ({ results = null }): JSX.Element => {
  // const inputResult = useAppSelector(selectorInputSearch);
  // const resultValue = inputResult ? inputResult : '';
  const isNotFound = !results;
  return (
    <>
      <div id="results">
        {isNotFound && (
          <div id="no-results">
            <EmptyState />
          </div>
        )}
        {results?.map((customer: TCustomer) => (
          <Card
            key={customer.customer_id}
            customerId={customer.customer_id}
            customerCode={customer.customer_code}
            email={customer.email}
            name={customer.customer_name}
            // address={customer.address}
            state={customer.state}
            allianceCode={customer.alliance_code}
            // allianceName={customer.alliance_name}
            mainAccountCode={customer.main_account_code}
            ownerCode={customer.common_owner_code}
            type={customer.customer_type_id}
            tooltipLabel={
              customer.customer_type_description === 'Cutomer' ? 'Customer' : customer.customer_type_description
            }
          />
        ))}
      </div>
    </>
  );
};
