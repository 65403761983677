import { FC } from 'react';

interface PrintInfoProps {
    printDate: string;
    lastUpdateDate: string | null;
}

export const PrintInfo: FC<PrintInfoProps> = ({ printDate, lastUpdateDate }): JSX.Element => {
    return (
        <div id="print-info">Date of export: {printDate}  
            {lastUpdateDate !== null && <> - Sales data last update: {lastUpdateDate}</>}
        </div>
    );
};