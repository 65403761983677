/** @format */

import { FC, useState } from 'react';
import { UITabTitle } from '../partials/TabTitle';
import DataGrid, {
  Column,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  Sorting,
} from 'devextreme-react/data-grid';
import { useParams } from 'react-router-dom';
import {
  BOX_TITLE_CREDIT_STATUS,
  EXPORT_CREDIT_STATUS,
  TABLE_CURRENCY_FORMAT,
  TABLE_NUMBER_FORMAT,
} from '../../../constants/general';
// import { mapColors } from '../../../theme/mapColors';
import { Expandable } from '../../../shared/Expandable';
import { NoData } from '../../../shared/NoData';

/*
export const Box = styled.div({
  width: '100%',
  height: 'auto',
  backgroundColor: `${mapColors.backgrounds.borderTertiary}`,
  borderBottomLeftRadius: '10px',
  borderBottomRightRadius: '10px',
  display: 'block',
  border: `1px solid ${mapColors.backgrounds.borderPrimary}`,
  paddingBottom: '4px',
});
*/

type CreditProps = {
  customer: any;
  printMode: boolean;
};

export const Credit: FC<CreditProps> = ({ customer = null, printMode = false }): JSX.Element => {
  const { customerId } = useParams();
  const [isExpanded, setIsExpanded] = useState(true);
  const data = customer && customer ? customer.credit_status : [];

  return (
    <div id="credit-status" className="data-panel">
      <UITabTitle
        infoMessage={''}
        label={BOX_TITLE_CREDIT_STATUS}
        url={data.length > 0 ? EXPORT_CREDIT_STATUS : ''}
        customerId={customerId || '0'}
        onClick={() => setIsExpanded(!isExpanded)}
        collapsed={isExpanded}
      />
      <Expandable expanded={isExpanded}>

        {data.length > 0 ? (
          <DataGrid
            dataSource={data}
            allowColumnReordering={true}
            rowAlternationEnabled={true}
            showBorders={true}
            showColumnLines={false}
            showRowLines={false}
            allowColumnResizing={true}
            columnAutoWidth={true}
            columnMinWidth={200}
          // onContentReady={this.onContentReady}
          >
            <Sorting mode="multiple" />
            <ColumnFixing enabled={false} />
            <HeaderFilter visible={false} />
            <FilterRow visible={false} /* applyFilter={this.state.currentFilter} */ />

            <Column
              dataField="customer_code_child"
              caption="ACCT"
                /* dataType="number" format="currency" */ alignment="left"
            >
              <HeaderFilter allowSearch={true} />
            </Column>
            <Column
              dataField="customer_name"
              caption="CUSTOMER NAME"
              width={230}
                /* dataType="number" format="currency" */ alignment="left"
            >
              <HeaderFilter allowSearch={true} />
            </Column>
            {/*
              <Column
                dataField="group_name"
                caption="GROUP NAME"
              >
                <HeaderFilter allowSearch={true} />
              </Column>
              */}

            {/*
              <Column dataField="co_code" caption="CO CODE" alignment="left">
                <HeaderFilter allowSearch={true} />
              </Column>
               */}

            <Column
              dataField="credit_limit"
              width={230}
              caption="CREDIT LIMIT"
              alignment="left"
              format={TABLE_CURRENCY_FORMAT}
            >
              <HeaderFilter allowSearch={true} />
            </Column>

            <Column
              dataField="net_balance"
              caption="NET BALANCE"
              alignment="left"
              format={TABLE_CURRENCY_FORMAT}
            /* dataType="number" format="currency" */
            >
              <HeaderFilter allowSearch={true} />
            </Column>

            <Column
              dataField="credit_tolerance"
              caption="CREDIT TOLERANCE"
              alignment="left"
              format={TABLE_CURRENCY_FORMAT}
            /* dataType="number" format="currency" */
            >
              <HeaderFilter allowSearch={true} />
            </Column>
            <Column
              dataField="paynow"
              caption="PAYNOW" /* dataType="number" format="currency" */
              format={TABLE_CURRENCY_FORMAT}
              alignment="left"
            >
              <HeaderFilter allowSearch={true} />
            </Column>
            <Column
              dataField="30_days_past_due"
              caption="30 DAYS PAST DUE"
              format={TABLE_CURRENCY_FORMAT}
                /* dataType="number" format="currency" */ alignment="left"
            >
              <HeaderFilter allowSearch={true} />
            </Column>
            <Column
              dataField="60_days_past_due"
              caption="60 DAYS PAST DUE"
              format={TABLE_NUMBER_FORMAT}
                /* dataType="number" format="currency" */ alignment="left"
            >
              <HeaderFilter allowSearch={true} />
            </Column>
            <Column
              dataField="90_days_past_due"
              caption="90 DAYS PAST DUE"
              format={TABLE_NUMBER_FORMAT}
                /* dataType="number" format="currency" */ alignment="left"
            >
              <HeaderFilter allowSearch={true} />
            </Column>
            <Column
              dataField="120_days_past_due"
              caption="120 DAYS PAST DUE"
              format={TABLE_NUMBER_FORMAT}
                /* dataType="number" format="currency" */ alignment="left"
            >
              <HeaderFilter allowSearch={true} />
            </Column>
            <Column
              dataField="return_credit"
              caption="RETURN CREDIT"
              format={TABLE_NUMBER_FORMAT}
                /* dataType="number" format="currency" */ alignment="left"
            >
              <HeaderFilter allowSearch={true} />
            </Column>
            <Column
              dataField="payments"
              caption="PAYMENTS"
              format={TABLE_NUMBER_FORMAT}
                /* dataType="number" format="currency" */ alignment="left"
            >
              <HeaderFilter allowSearch={true} />
            </Column>
            <Column
              dataField="last_payment_date"
              caption="LAST PAYMENT DATE"
              dataType="date"
                /* format="currency" */ alignment="left"
            >
              <HeaderFilter allowSearch={true} />
            </Column>
            <Column
              dataField="sales_value"
              caption="SALES VALUE"
              format={TABLE_NUMBER_FORMAT}
                /* dataType="number" format="currency" */ alignment="left"
            >
              <HeaderFilter allowSearch={true} />
            </Column>

            <Column
              dataField="status_code"
              caption="STATUS CODE"
                /* dataType="number" format="currency" */ alignment="left"
            >
              <HeaderFilter allowSearch={true} />
            </Column>

            {// <Column dataField="risk" caption="RISK" /* dataType="number" format="currency" */ alignment="left">
              //</DataGrid><HeaderFilter allowSearch={true} />
              //<</div> /Column>
            }

            <Pager
              visible={printMode ? false : true}
              // allowedPageSizes={false}
              displayMode={'compact'}
              showPageSizeSelector={false}
              showInfo={true}
              showNavigationButtons={true}
            />
            <Paging defaultPageSize={printMode ? 100000 : 10} />
          </DataGrid>
        ) : (
          <NoData />
        )}

      </Expandable>
    </div>
  );
};
