import { DropDownBox, TreeView } from 'devextreme-react';
import { FC, useEffect, useState } from 'react';
import './Performance_graph.scss';

interface PerformanceBrandSelectionProps {
  map: any;
  onChangedBrands: (list: string[]) => void
}

export const PerformanceBrandSelection: FC<PerformanceBrandSelectionProps> = ({
  map = [],
  onChangedBrands = () => { },
}): JSX.Element => {

  let treeView: TreeView;
  const [treeBoxBrandValue, setTreeBoxBrandValue] = useState<string[]>(['ALL']);

  function treeViewItemSelectionChanged(e: any) {

    let selectedList: string[] = [];
    if (e.node.key !== 'ALL') {
      for (const key in e.component.getSelectedNodes()) {
        if (Object.prototype.hasOwnProperty.call(e.component.getSelectedNodes(), key)) {
          const element = e.component.getSelectedNodes()[key];
          if (element.itemData.category !== undefined && element.itemData.code !== 'ALL') {
            selectedList.push(element.itemData.code);
          }
        }
      }
    } else {
      selectedList = ['ALL'];
    }

    if (selectedList.length <= 0) {
      selectedList = ['ALL'];
    }

    setTreeBoxBrandValue(selectedList);
  }

  function syncTreeViewSelection(e: any) {

    const _treeView = (e.component.selectItem && e.component)
      || (treeView && treeView.instance);

    if (treeView) {
      if (e.value === null) {
        _treeView.unselectAll();
        onChangedBrands([]);
      } else {
        let values = e.value || treeBoxBrandValue;
        const previous = e.previousValue || treeBoxBrandValue;
        const hadAll = previous.indexOf('ALL') >= 0;

        _treeView.unselectAll();
        
        if (values.indexOf('ALL') >= 0) {
          if (values.length === 1 || !hadAll) {
            values = ['ALL'];
          } else {
            values.splice(values.indexOf('ALL'), 1);
          }
        }

        values.forEach((value: any) => {
          _treeView.selectItem(value);
        });
        onChangedBrands(values);
      }
    }

    if (e.value !== undefined) {
      setTreeBoxBrandValue(e.value);
    }
  }

  function treeViewRender() {
    return (
      <TreeView dataSource={map}
        ref={(ref: any) => { treeView = ref; }}
        dataStructure="plain"
        keyExpr="code"
        parentIdExpr="group"
        selectionMode="multiple"
        showCheckBoxesMode="normal"
        selectNodesRecursive={true}
        displayExpr="category"
        selectByClick={true}
        onContentReady={syncTreeViewSelection}
        onItemSelectionChanged={treeViewItemSelectionChanged}
      />
    );
  }

  useEffect(() => {
    if (treeView) treeView.instance.selectItem('ALL');
    setTreeBoxBrandValue(['ALL']);
  }, []);


  return (
    <div className='brand-selection'>
      <DropDownBox
        value={treeBoxBrandValue}
        valueExpr="code"
        displayExpr="category"
        placeholder="Filter by Brand..."
        showClearButton={false}
        dataSource={map}
        onValueChanged={syncTreeViewSelection}
        contentRender={treeViewRender}
      />
    </div>
  );
};
