/** @format */

import { FC } from 'react';
import { UIIcon } from './Icon';
import arrowRed from '../assets/images/v2/arrow-red.svg';
import arrowGreen from '../assets/images/v2/arrow-green.svg';
import { MESSAGE_NOT_AVAILABLE_ABBREV } from '../constants/general';

interface Props {
  isScaling: boolean;
  value?: string;
  isIcon?: boolean;
  isInfo?: boolean;
  isNeeded?: boolean;
  neededLabel?: string;
}
export const ScalingInfo: FC<Props> = ({
  isScaling,
  value,
  isIcon = true,
  isInfo = true,
  isNeeded = false,
  neededLabel = '',
}): JSX.Element => {
  const srcIcon = isScaling ? arrowGreen : arrowRed;

  return value !== MESSAGE_NOT_AVAILABLE_ABBREV ? (
    <div className='scaling'>
      {isIcon && (
        <div className='scalingIconBox' style={{ backgroundColor: isScaling ? '#56a36a' : '#902727' }}>
          <UIIcon name={'arrow'} srcIcon={srcIcon} width={'18'} height={'18'} className="" />
        </div>
      )}

      {isNeeded ? (
        <div className='value' style={{ color: isInfo ? (isScaling ? '#56a36a' : '#902727') : '#8A9DAF' }}>
          {neededLabel}
          <br />
          <span style={{ color: '#000' }}>{value}</span>
        </div>
      ) : (
        <div className='value' style={{ color: isInfo ? (isScaling ? '#56a36a' : '#902727') : '#031434' }}>
          {value}
        </div>
      )}
      {isInfo && <div className='info' style={{ color: isScaling ? '#56a36a' : '#902727' }}>Since last year</div>}
    </div>
  ) : (
    <></>
  );
};
