/** @format */

import { FC } from 'react';

//import srcLoading from '../assets/gif/ripple.gif';

import Lottie from 'lottie-react';
import progress from '../assets/animation/progress.json';

const style = {
  height: 160,
};

interface Props {
  show: boolean;
  message?: string | null;
}

export const Loader: FC<Props> = ({ show = false, message = null }): JSX.Element => {
  return (
    <div id="loader" className={ show ? 'show' : 'hide'}>
      <Lottie animationData={progress} style={style} loop={true} />
      {message !== null && 
        <p>{message}</p>}
    </div>
  );
};
