/** @format */
import { FC, useEffect, useState } from 'react';
import { Selection, SelectionMode } from '../../../shared/Selection';
import { DynamicMultiselection } from '../../../shared/DynamicMultiselection';
import { DateSelection, DateSelectionPickerType, DateSelectionType } from '../../../shared/DateSelection';
import { BackButton } from '../partials/BackButton';
import { CheckBoxSelection } from '../../../shared/CheckboxSelection';
import { CellBoxContainer } from '../../../shared/CellBoxContainer';
import { CellBoxTable } from '../../../shared/CellBoxTable';
import { TextAreaInput } from '../../../shared/TextAreaInput';
import { AddButtonDialog } from '../partials/AddButtonDialog';
import { SaveButton } from '../partials/SaveButton';
import { AllianceGroup, apiAdminRebateGet, apiAdminRebateSave, apiRebateInitialize, BrandCategory, Criteria, CriteriaCondition, CriteriaItem, CriteriaType, CustomerStatus, Period, RebateData, SalesOrg, Section, TierInfo, TierItem, Type, TypeCriteria } from '../../../services/admin/api';
import { loaderSlice } from '../../../services/loader/slice';
import { useNavigate } from 'react-router';
import { ROUTE_ADMIN_REBATES, ROUTE_HOME } from '../../../constants/general';
import { useDispatch } from 'react-redux';

import './rebates-edit-style.scss';
import iconAdd from '../../../assets/icons/admin/add.svg';
import { useSearchParams } from 'react-router-dom';
import { apiRefresh } from '../../../services/auth/api';
import { InfoTooltip } from '../../../shared/InfoTooltip';
import { clearStorage } from '../../../utils/localStorageUtils';
import { MainHeader } from '../../../shared/MainHeader';

interface StopAfterSuccess {
  label: string;
  value: number;
}

interface RebateStatus {
  label: string;
  value: string;
}

export const AdminRebatesEdit: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const refreshToken = localStorage.getItem('refreshToken');
  const rebateId = searchParams.get('rebateId');
  //console.log(rebateId);
  //const { rebateId } = useParams();

  const [brandCategories, setBrandCategories] = useState<BrandCategory[]>([]);

  // Backend properties
  const [customerStatus, setCustomerStatus] = useState<CustomerStatus[]>([]);
  const [periods, setPeriods] = useState<Period[]>([]);
  const [sections, setSections] = useState<Section[]>([]);
  const [typesCriteria, setTypesCriteria] = useState<TypeCriteria[]>([]);
  const [types, setTypes] = useState<Type[]>([]);
  const [salesOrgs, setSalesOrgs] = useState<SalesOrg[]>([]);
  const [allianceGroups, setAllianceGroups] = useState<AllianceGroup[]>([]);

  // Frontend properties
  const [rebateStatus, setRebateStatus] = useState<RebateStatus[]>([]);
  const [stopAfterSuccess, setStopAfterSuccess] = useState<StopAfterSuccess[]>([]);
  const [criteriaTypes, setCriteriaTypes] = useState<CriteriaType[]>([]);
  const [criteriaConditions, setCriteriaConditions] = useState<CriteriaCondition[]>([]);

  // Temporary data
  // const [tempRebateName, setTempRebateName] = useState<string>('');

  // Collected data
  const [collectedData, setCollectedData] = useState<RebateData>({
    id_customer: null,
    name: null,
    id_period: null,
    id_type: null,
    alliance_group: null,
    sales_org: null,
    sequence: null,
    stop: null,
    id_section: null,
    start_date: null,
    end_date: null,
    status: null,
    customer_status: [],
    criterias: [],
    brands: [],
    tiers: [],
  });

  const [collectedCriteria, setCollectedCriteria] = useState<Criteria>({ conditions: [] });
  const [collectedCriteriaItem, setCollectedCriteriaItem] = useState<CriteriaItem>({});
  const [collectedCriteriaItemType, setCollectedCriteriaItemType] = useState<string | null>(null);

  const [collectedTierCriteriaItem, setCollectedTierCriteriaItem] = useState<CriteriaItem>({});
  const [collectedTierInfo, setCollectedTierInfo] = useState<TierInfo>({});

  const [collectedTierItem, setCollectedTierItem] = useState<TierItem>({ tier: {}, criterias: [] });

  const [disableAddNew, setDisableAddNew] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<number>(0);

  const [collectedCriteriaEditMode, setCollectedCriteriaEditMode] = useState<number>(-1);
  const [collectedCriteriaItemEditMode, setCollectedCriteriaItemEditMode] = useState<number>(-1);
  const [collectedCriteriaItemSourceTypeIDValid, setCollectedCriteriaItemSourceTypeIDValid] = useState<boolean | undefined>(undefined);
  const [collectedCriteriaItemTypeValid, setCollectedCriteriaItemTypeValid] = useState<boolean | undefined>(undefined);
  const [collectedCriteriaItemConditionIDValid, setCollectedCriteriaItemConditionIDValid] = useState<boolean | undefined>(undefined);
  const [collectedCriteriaItemValueValid, setCollectedCriteriaItemValueValid] = useState<boolean | undefined>(undefined);
  const [collectedCriteriaItemMinValid, setCollectedCriteriaItemMinValid] = useState<boolean | undefined>(undefined);
  const [collectedCriteriaItemMaxValid, setCollectedCriteriaItemMaxValid] = useState<boolean | undefined>(undefined);
  const [collectedCriteriaItemTargetTypeIDValid, setCollectedCriteriaItemTargetTypeIDValid] = useState<boolean | undefined>(undefined);

  const [collectedTierEditMode, setCollectedTierEditMode] = useState<number>(-1);
  const [collectedTierCriteriaItemType, setCollectedTierCriteriaItemType] = useState<string | null>(null);
  const [collectedTierCriteriaItemEditMode, setCollectedTierCriteriaItemEditMode] = useState<number>(-1);

  const [collectedDataRebateNameValid, setCollectedDataRebateNameValid] = useState<boolean | undefined>(undefined);
  const [collectedDataSalesOrgsValid, setCollectedDataSalesOrgsValid] = useState<boolean | undefined>(undefined);
  const [collectedDataRebatePeriodValid, setCollectedDataRebatePeriodValid] = useState<boolean | undefined>(undefined);
  const [collectedDataRebateTypeValid, setCollectedDataRebateTypeValid] = useState<boolean | undefined>(undefined);
  const [collectedDataAlliaceGroupValid, setCollectedDataAlliaceGroupValid] = useState<boolean | undefined>(undefined);
  const [collectedDataStartDateValid, setCollectedDataStartDateValid] = useState<boolean | undefined>(undefined);
  const [collectedDataEndDateValid, setCollectedDataEndDateValid] = useState<boolean | undefined>(undefined);
  const [collectedDataStopAfterSuccessValid, setCollectedDataStopAfterSuccessValid] = useState<boolean | undefined>(undefined);
  const [collectedDataSectionValid, setCollectedDataSectionValid] = useState<boolean | undefined>(undefined);
  const [collectedDataCustomerVisibilityValid, setCollectedDataCustomerVisibilityValid] = useState<boolean | undefined>(undefined);
  const [collectedDataSequenceValid, setCollectedDataSequenceValid] = useState<boolean | undefined>(undefined);
  const [collectedDataStatusValid, setCollectedDataStatusValid] = useState<boolean | undefined>(undefined);
  const [collectedDataBrandsValid, setCollectedDataBrandsValid] = useState<boolean | undefined>(undefined);
  const [collectedDataEligibilityCriteriaValid, setCollectedDataEligibilityCriteriaValid] = useState<boolean | undefined>(undefined);
  const [collectedDataTierValid, setCollectedDataTierValid] = useState<boolean | undefined>(undefined);

  const [collectedTierInfoTargetPercValid, setCollectedTierInfoTargetPercValid] = useState<boolean | undefined>(undefined);
  const [collectedTierInfoTargetValueValid, setCollectedTierInfoTargetValueValid] = useState<boolean | undefined>(undefined);
  const [collectedTierInfoTargetBaselineValid, setCollectedTierInfoTargetBaselineValid] = useState<boolean | undefined>(undefined);
  const [collectedTierInfoTargetActualValid, setCollectedTierInfoTargetActualValid] = useState<boolean | undefined>(undefined);
  const [collectedTierInfoRebateTargetPercValid, setCollectedTierInfoRebateTargetPercValid] = useState<boolean | undefined>(undefined);
  const [collectedTierCriteriaItemSourceTypeIDValid, setCollectedTierCriteriaItemSourceTypeIDValid] = useState<boolean | undefined>(undefined);
  const [collectedTierCriteriaItemTypeValid, setCollectedTierCriteriaItemTypeValid] = useState<boolean | undefined>(undefined);
  const [collectedTierCriteriaItemConditionIDValid, setCollectedTierCriteriaItemConditionIDValid] = useState<boolean | undefined>(undefined);
  const [collectedTierCriteriaItemValueValid, setCollectedTierCriteriaItemValueValid] = useState<boolean | undefined>(undefined);
  const [collectedTierCriteriaItemMinValid, setCollectedTierCriteriaItemMinValid] = useState<boolean | undefined>(undefined);
  const [collectedTierCriteriaItemMaxValid, setCollectedTierCriteriaItemMaxValid] = useState<boolean | undefined>(undefined);
  const [collectedTierCriteriaItemTargetTypeIDValid, setCollectedTierCriteriaItemTargetTypeIDValid] = useState<boolean | undefined>(undefined);
  const [collectedTierCriteriaItemEnabled, setCollectedTierCriteriaItemEnabled] = useState<boolean>(true);

  const [collectedIDPeriod, setCollectedIDPeriod] = useState<number>(-1);
  const [collectedIDSection, setCollectedIDSection] = useState<number>(-1);

  function checkDisableAddNew() {
    setDisableAddNew(!(collectedData.id_period !== null && collectedData.id_period !== undefined && collectedData.id_section !== null && collectedData.id_section !== undefined));
  }

  function _parseResponse(response: any) {
    if (response === undefined) {
      return;
    }

    setBrandCategories(response.brand_categories);
    setCustomerStatus(response.customer_status);
    setPeriods(response.periods);
    setSections(response.sections);
    setTypes(response.types);
    setAllianceGroups(response.allianz_group);
    setSalesOrgs(response.sales_org);
    setTypesCriteria(response.type_criteria);
    setCriteriaConditions(response.criteria_conditions);

    setStopAfterSuccess([
      {
        label: 'No',
        value: 0,
      },
      {
        label: 'Yes',
        value: 1,
      },
    ]);

    setCriteriaTypes([
      {
        label: 'Single Value',
        value: 'single_value',
      },
      {
        label: 'Range',
        value: 'range',
      },
      {
        label: 'VS other type id',
        value: 'type_id',
      },
    ]);

    setRebateStatus([
      {
        label: 'Approved',
        value: 'Approved',
      },
      {
        label: 'Draft',
        value: 'Draft',
      },
    ]);
  }

  function _loadData(retry = true) {
    dispatch(loaderSlice.actions.startLoading());
    apiRebateInitialize().then(response => {

      _parseResponse(response.data.data);

      if (rebateId !== null && rebateId !== undefined && parseInt(rebateId, 10) > 0) {
        apiAdminRebateGet({ id: rebateId }).then(response => {

          // Fix stop
          response.data.data.stop = response.data.data.stop === 1 ? true : false;

          // Fix criterias type (frontend use only)
          if (response.data.data.criterias !== null && response.data.data.criterias !== undefined) {
            for (let i = 0; i < response.data.data.criterias.length; i++) {
              const conditions = response.data.data.criterias[i].conditions;
              if (conditions !== null && conditions !== undefined) {
                for (let j = 0; j < conditions.length; j++) {
                  if (conditions[j].value !== null && conditions[j].value !== undefined) {
                    conditions[j].type = 'single_value';
                  } else if (conditions[j].target_type_id !== null && conditions[j].target_type_id !== undefined) {
                    conditions[j].type = 'type_id';
                  } else if (conditions[j].min !== null && conditions[j].min !== undefined &&
                    conditions[j].max !== null && conditions[j].max !== undefined) {
                    conditions[j].type = 'range';
                  }
                }
              }
            }
          }

          // Fix tiers criterias type (frontend use only)
          if (response.data.data.tiers !== null && response.data.data.tiers !== undefined) {
            for (let i = 0; i < response.data.data.tiers.length; i++) {
              const tier = response.data.data.tiers[i];
              if (tier.criterias !== null && tier.criterias !== undefined) {
                for (let j = 0; j < tier.criterias.length; j++) {
                  if (tier.criterias[j].value !== null && tier.criterias[j].value !== undefined) {
                    tier.criterias[j].type = 'single_value';
                  } else if (tier.criterias[j].target_type_id !== null && tier.criterias[j].target_type_id !== undefined) {
                    tier.criterias[j].type = 'type_id';
                  } else if (tier.criterias[j].min !== null && tier.criterias[j].min !== undefined &&
                    tier.criterias[j].max !== null && tier.criterias[j].max !== undefined) {
                    tier.criterias[j].type = 'range';
                  }
                }
              }
            }
          }

          setCollectedData(response.data.data);
        });
      }
      dispatch(loaderSlice.actions.stopLoading());
    }).catch(error => {
      if (error.response && error.response.status && error.response.status === 401 && refreshToken) {
        apiRefresh({ refreshToken })
          .then(response => {
            if (response.data?.data?.jwt_token && response.data?.data?.refresh_token) {
              localStorage.setItem('jwtToken', response.data.data.jwt_token);
              localStorage.setItem('refreshToken', response.data.data.refresh_token);
              if (retry) {
                _loadData(false);
              }
            }
          })
          .catch(() => {
            clearStorage();
            navigate(`${ROUTE_HOME}`, { replace: true });
          });
      } else if (error.response && error.response.status && error.response.status === 500) {
        navigate(`${ROUTE_ADMIN_REBATES}`, { replace: true });
      } else {
        clearStorage();
        navigate(`${ROUTE_HOME}`, { replace: true });
      }
    });
  }

  function saveData(retry = true) {
    dispatch(loaderSlice.actions.startLoading());
    apiAdminRebateSave({ data: collectedData }).then(response => {
      dispatch(loaderSlice.actions.stopLoading());
      if (response.data.data !== true) {
        document.getElementById('openSaveWarningDialog')?.click();
      } else {
        navigate(ROUTE_ADMIN_REBATES, { replace: true });
      }
    }).catch(error => {
      if (error.response && error.response.status && error.response.status === 500 ||
        error.response && error.response.status && error.response.status === 400) {
        dispatch(loaderSlice.actions.stopLoading());
        document.getElementById('openSaveWarningDialog')?.click();
      } else if (error.response && error.response.status && error.response.status === 401 && refreshToken) {
        apiRefresh({ refreshToken })
          .then(response => {
            if (response.data?.data?.jwt_token && response.data?.data?.refresh_token) {
              localStorage.setItem('jwtToken', response.data.data.jwt_token);
              localStorage.setItem('refreshToken', response.data.data.refresh_token);
              if (retry) {
                saveData(false);
              }
            }
          })
          .catch(() => {
            clearStorage();
            navigate(`${ROUTE_HOME}`, { replace: true });
          });
      } else {
        clearStorage();
        navigate(`${ROUTE_HOME}`, { replace: true });
      }
    });
  }

  useEffect(() => {
    _loadData();
    // setCollectedData((data) => ({ ...data, name: 'test', sales_org: 'CA80', start_date: new Date('03/22/2023') }));
  }, []);

  function emptyState(collection: any[] | undefined, className: string, title: string, objectString: string) {
    if (collection === undefined || collection?.length > 0) {
      return;
    }
    return <div className='empty_state'>
      <div className={className}> </div>
      <div className='empty_state__title'>{title}</div>
      <div className='empty_state__text'>
        <div className='empty_state__message'>{'Select'} </div>
        <span className='empty_state__message_plus'>+</span>
        <div className='empty_state__message'>{`for add a new ${objectString}`}</div>
      </div>
    </div>;
  }

  function sourceTypeIDs(id_period: number, id_section: number) {
    const filtered = typesCriteria.filter(item => item.id_period === id_period && item.id_section === id_section);
    return filtered;
  }

  function criteriaItemTypeId(item: CriteriaItem) {
    const temp = typesCriteria.find(a => a.type_id === item.type_id ?? -1);
    return item.type_id !== null ? ((temp !== null && temp !== undefined) ? temp.description + ' (' + temp.type_id + ')' : '-') : '-';
  }

  function criteriaItemCondition(item: CriteriaItem) {
    return item.condition_id !== null ? criteriaConditions.find(a => a.id_condition === item.condition_id)?.symbol ?? '-' : '-';
  }

  function typeIdString(id: string) {
    const temp = typesCriteria.find(a => a.type_id === id);
    return ((temp !== null && temp !== undefined) ? temp.description + ' (' + temp.type_id + ')' : '-');
  }

  function criteriaItemValue(item: CriteriaItem) {
    if (item.type === 'single_value') {
      return item.value !== null && item.value !== undefined ? new Intl.NumberFormat('en-US').format(item.value) : '-';
    } else if (item.type === 'range') {
      const min = item.min !== null && item.min !== undefined ? new Intl.NumberFormat('en-US').format(item.min) : '-';
      const max = item.max !== null && item.max !== undefined ? new Intl.NumberFormat('en-US').format(item.max) : '-';
      return `${min} - ${max}`;
    } else if (item.type === 'type_id') {
      return criteriaItemTypeId(item);
    } else {
      return '-';
    }
  }

  function checkValidation(status: boolean | undefined) {
    return (status === undefined || status === true) ? true : false;
  }

  function resetCriteriaItemValid() {
    setCollectedCriteriaItemSourceTypeIDValid(undefined);
    setCollectedCriteriaItemConditionIDValid(undefined);
    setCollectedCriteriaItemTargetTypeIDValid(undefined);
    setCollectedCriteriaItemValueValid(undefined);
    setCollectedCriteriaItemMinValid(undefined);
    setCollectedCriteriaItemMaxValid(undefined);
    setCollectedCriteriaItemTypeValid(undefined);
  }

  function resetTierCriteriaItemValid() {
    setCollectedTierCriteriaItemSourceTypeIDValid(undefined);
    setCollectedTierCriteriaItemConditionIDValid(undefined);
    setCollectedTierCriteriaItemTargetTypeIDValid(undefined);
    setCollectedTierCriteriaItemValueValid(undefined);
    setCollectedTierCriteriaItemMinValid(undefined);
    setCollectedTierCriteriaItemMaxValid(undefined);
    setCollectedTierCriteriaItemTypeValid(undefined);
  }

  function resetTierInfoValid() {
    setCollectedTierInfoTargetPercValid(undefined);
    setCollectedTierInfoTargetActualValid(undefined);
    setCollectedTierInfoTargetBaselineValid(undefined);
    setCollectedTierInfoTargetValueValid(undefined);
    setCollectedTierInfoRebateTargetPercValid(undefined);
  }

  function validateRebate() {
    let check = true;
    check = check && collectedDataRebateNameValid !== undefined && collectedDataRebateNameValid;
    check = check && collectedDataSalesOrgsValid !== undefined && collectedDataSalesOrgsValid;
    check = check && collectedDataRebatePeriodValid !== undefined && collectedDataRebatePeriodValid;
    check = check && collectedDataRebateTypeValid !== undefined && collectedDataRebateTypeValid;
    check = check && collectedDataAlliaceGroupValid !== undefined && collectedDataAlliaceGroupValid;
    check = check && collectedDataStartDateValid !== undefined && collectedDataStartDateValid;
    check = check && collectedDataEndDateValid !== undefined && collectedDataEndDateValid;
    check = check && collectedDataStopAfterSuccessValid !== undefined && collectedDataStopAfterSuccessValid;
    check = check && collectedDataSectionValid !== undefined && collectedDataSectionValid;
    check = check && collectedDataCustomerVisibilityValid !== undefined && collectedDataCustomerVisibilityValid;
    check = check && collectedDataSequenceValid !== undefined && collectedDataSequenceValid;
    check = check && collectedDataStatusValid !== undefined && collectedDataStatusValid;
    check = check && collectedDataBrandsValid !== undefined && collectedDataBrandsValid;
    // check = check && collectedDataEligibilityCriteriaValid !== undefined && collectedDataEligibilityCriteriaValid;
    // check = check && collectedDataTierValid !== undefined && collectedDataTierValid;
    return check;
  }

  useEffect(() => {
    // if (rebateId !== null && rebateId !== undefined && parseInt(rebateId, 10) > 0) {
    if (collectedData.id_rebate !== null && collectedData.id_rebate !== undefined && collectedData.id_rebate >= 0) { // Force to use effect only after data loaded

      if (collectedData.id_period !== null && collectedData.id_period !== undefined)
        setCollectedIDPeriod(collectedData.id_period);

      if (collectedData.id_section !== null && collectedData.id_section !== undefined)
        setCollectedIDSection(collectedData.id_section);

      setCollectedDataRebateNameValid(collectedData.name !== null && collectedData.name !== undefined && collectedData.name.length > 0);
      setCollectedDataSalesOrgsValid(collectedData.sales_org !== null && collectedData.sales_org !== undefined);
      setCollectedDataRebatePeriodValid(collectedData.id_period !== null && collectedData.id_period !== undefined);
      setCollectedDataRebateTypeValid(collectedData.id_type !== null && collectedData.id_type !== undefined);
      setCollectedDataAlliaceGroupValid(collectedData.alliance_group !== null && collectedData.alliance_group !== undefined);
      setCollectedDataStartDateValid(collectedData.start_date !== null && collectedData.start_date !== undefined);
      setCollectedDataEndDateValid(collectedData.end_date !== null && collectedData.end_date !== undefined);
      setCollectedDataStopAfterSuccessValid(collectedData.stop !== null && collectedData.stop !== undefined);
      setCollectedDataSectionValid(collectedData.id_section !== null && collectedData.id_section !== undefined);
      setCollectedDataCustomerVisibilityValid(collectedData.customer_status !== null && collectedData.customer_status !== undefined);
      setCollectedDataSequenceValid(collectedData.sequence !== null && collectedData.sequence !== undefined);
      setCollectedDataStatusValid(collectedData.status !== null && collectedData.status !== undefined);
      setCollectedDataBrandsValid(collectedData.brands !== null && collectedData.brands !== undefined);
      setCollectedDataEligibilityCriteriaValid(collectedData.criterias !== null && collectedData.criterias !== undefined);
      setCollectedDataTierValid(collectedData.tiers !== null && collectedData.tiers !== undefined);
      checkDisableAddNew();
    }
    // }
  }, [collectedData]);

  function validateCriteriaItem() {
    let check = true;
    check = check && collectedCriteriaItemSourceTypeIDValid !== undefined && collectedCriteriaItemSourceTypeIDValid;
    check = check && collectedCriteriaItemTypeValid !== undefined && collectedCriteriaItemTypeValid;
    if (collectedCriteriaItem.type === 'single_value') {
      check = check && collectedCriteriaItemValueValid !== undefined && collectedCriteriaItemValueValid;
    } else if (collectedCriteriaItem.type === 'range') {
      check = check && collectedCriteriaItemMinValid !== undefined && collectedCriteriaItemMinValid;
      check = check && collectedCriteriaItemMaxValid !== undefined && collectedCriteriaItemMaxValid;
    } else if (collectedCriteriaItem.type === 'type_id') {
      check = check && collectedCriteriaItemTargetTypeIDValid !== undefined && collectedCriteriaItemTargetTypeIDValid;
    }
    return check;
  }

  function validateTierInfo() {
    let check = true;
    check = check && collectedTierInfoTargetActualValid !== undefined && collectedTierInfoTargetActualValid;
    // check = check && collectedTierInfoTargetBaselineValid !== undefined && collectedTierInfoTargetBaselineValid;
    check = check && collectedTierInfoTargetPercValid !== undefined && collectedTierInfoTargetPercValid;
    check = check && collectedTierInfoTargetValueValid !== undefined && collectedTierInfoTargetValueValid;
    check = check && collectedTierInfoRebateTargetPercValid !== undefined && collectedTierInfoRebateTargetPercValid;
    return check;
  }

  function validateTierCriteriaItem() {
    let check = true;
    check = check && collectedTierCriteriaItemSourceTypeIDValid !== undefined && collectedTierCriteriaItemSourceTypeIDValid;
    check = check && collectedTierCriteriaItemTypeValid !== undefined && collectedTierCriteriaItemTypeValid;
    if (collectedTierCriteriaItem.type === 'single_value') {
      check = check && collectedTierCriteriaItemValueValid !== undefined && collectedTierCriteriaItemValueValid;
    } else if (collectedTierCriteriaItem.type === 'range') {
      check = check && collectedTierCriteriaItemMinValid !== undefined && collectedTierCriteriaItemMinValid;
      check = check && collectedTierCriteriaItemMaxValid !== undefined && collectedTierCriteriaItemMaxValid;
    } else if (collectedTierCriteriaItem.type === 'type_id') {
      check = check && collectedTierCriteriaItemTargetTypeIDValid !== undefined && collectedTierCriteriaItemTargetTypeIDValid;
    }
    return check;
  }

  function prepareCriteriaDialog(editIndex: number) {
    collectedCriteria.id = null;
    collectedCriteria.conditions = [];
    setCollectedCriteriaItem({
      type: null,
      type_id: null,
      condition_id: null,
      value: null,
      min: null,
      max: null,
    });
    resetCriteriaItemValid();
    setCollectedCriteriaItemType(null);
    setCollectedCriteriaItemEditMode(-1);
    setCollectedCriteriaEditMode(editIndex);
  }

  function prepareTierDialog(editIndex: number) {
    resetTierInfoValid();
    resetTierCriteriaItemValid();
    setCollectedTierCriteriaItemType(null);
    setCollectedTierCriteriaItemEditMode(-1);
    setCollectedTierCriteriaItemEnabled(true);
    setCollectedTierItem({
      tier: {},
      criterias: [],
    });
    setCollectedTierInfo({
      target_actual: null,
      target_baseline: null,
      target_perc: null,
      target_value: null,
      rebate_target_perc: null,
    });
    setCollectedTierCriteriaItem({
      type: null,
      type_id: null,
      condition_id: null,
      value: null,
      min: null,
      max: null,
    });
    setCollectedTierEditMode(editIndex);
  }

  function getToolTipText() {
    return 'lorem ipsum dolor';
  }

  return (
    <>
      {/*<Header isSearch={true} />*/}
      <MainHeader
        showUser={false}
        enableLeftMenu={false}
        showNavigationBar={false}
        showPdfExportButton={false}
        showSearchBar={false}
        showSearchButton={false}
        customer={null} />

      <section className="main admin">

        <div className="container admin admin_rebates_edit">

          <div className="row">
            <BackButton />
          </div>

          <form>
            <div className='col-md-12 head'>
              <h2>{rebateId !== null && rebateId !== undefined ? ('Edit rebate ' + collectedData.rebate_code ?? '') : 'Add a new rebate'}</h2>
            </div>
            <div className="row">
              <div className='col-md-12'><h3>General Info</h3>
              </div>

              <div className={checkValidation(collectedDataRebateNameValid) ? 'col-md-4' : 'col-md-4 not-valid'}>
                <label htmlFor='name'>Name <span className='validationRule'>* </span> <InfoTooltip targetId='_name_tooltip_' text={getToolTipText()} /></label>
                <TextAreaInput
                  id="name"
                  name="name"
                  placeholder=''
                  required={false}
                  onlyNumbers={false}
                  defaultValue={collectedData.name ?? ''}
                  onChangedSelection={(value) => {
                    setCollectedData((data) => ({ ...data, name: (value !== undefined && value.length > 0) ? value : null }));
                    setCollectedDataRebateNameValid(value !== undefined && value.length > 0 ? true : false);
                  }} />
              </div>

              <div className={checkValidation(collectedDataSalesOrgsValid) ? 'col-md-2' : 'col-md-2 not-valid'}>
                <label htmlFor='salesOrgs'>Sales Org <span className='validationRule'>*</span> <InfoTooltip targetId='_salesorg_tooltip_' text={getToolTipText()} /></label>
                <Selection<string[]>
                  id="salesOrgs"
                  name="salesOrgs"
                  componentKey="salesOrgs"
                  disabled={false}
                  map={salesOrgs.map((item) => {
                    return {
                      code: item.name,
                      displayItem: item.label,
                    };
                  })}
                  placeholder=''
                  displayExpr='displayItem'
                  valueExpr='code'
                  selectionMode={SelectionMode.multiple}
                  values={collectedData.sales_org?.split(',')}
                  onChangedSelection={(selection) => {
                    collectedData.sales_org = (selection !== undefined && selection.length > 0) ? selection.join(',') : null;
                    setCollectedDataSalesOrgsValid(selection !== undefined && selection.length > 0 ? true : false);
                  }} />
              </div>

              <div className={checkValidation(collectedDataRebatePeriodValid) ? 'col-md-2' : 'col-md-2 not-valid'}>
                <label htmlFor='periods'>Rebate Period <span className='validationRule'>*</span> <InfoTooltip targetId='_periods_' text={getToolTipText()} /></label>
                <Selection<number> id="periods" name="periods" componentKey="periods" disabled={false} map={periods.map((item) => {
                  return {
                    code: item.id_period,
                    displayItem: item.label,
                  };
                })}
                  placeholder=''
                  displayExpr='displayItem'
                  valueExpr='code'
                  selectionMode={SelectionMode.single}
                  values={collectedData.id_period !== null ? [collectedData.id_period] : undefined}
                  onChangedSelection={(selection) => {
                    collectedData.id_period = (selection !== undefined) ? selection : null;
                    setCollectedIDPeriod((selection !== undefined) ? selection : -1);
                    setCollectedDataRebatePeriodValid(selection !== undefined ? true : false);
                    checkDisableAddNew();
                  }} />
              </div>

              <div className={checkValidation(collectedDataRebateTypeValid) ? 'col-md-2' : 'col-md-2 not-valid'}>
                <label htmlFor='types'>Type of Rebate <span className='validationRule'>*</span><InfoTooltip targetId='_rebate_' text={getToolTipText()} /></label>
                <Selection<number> id="types" name="types" componentKey="types" disabled={false} map={types.map((item) => {
                  return {
                    code: item.id_type,
                    displayItem: item.name,
                  };
                })}
                  placeholder=''
                  displayExpr='displayItem'
                  valueExpr='code'
                  selectionMode={SelectionMode.single}
                  values={collectedData.id_type !== null ? [collectedData.id_type] : undefined}
                  onChangedSelection={(selection) => {
                    collectedData.id_type = (selection !== undefined) ? selection : null;
                    setCollectedDataRebateTypeValid(selection !== undefined ? true : false);
                  }} />
              </div>

              <div className={checkValidation(collectedDataAlliaceGroupValid) ? 'col-md-2' : 'col-md-2 not-valid'}>
                <label htmlFor='allianceGroups'>Alliance Group <span className='validationRule'>*</span><InfoTooltip targetId='_alliance_' text={getToolTipText()} /></label>
                <Selection<string> id="allianceGroups" name="allianceGroups" componentKey="allianceGroups" disabled={false} map={allianceGroups.map((item) => {
                  return {
                    code: item.name,
                    displayItem: item.label,
                  };
                })}
                  placeholder=''
                  displayExpr='displayItem'
                  valueExpr='code'
                  selectionMode={SelectionMode.single}
                  values={collectedData.alliance_group !== null ? [collectedData.alliance_group] : null}
                  onChangedSelection={(selection) => {
                    collectedData.alliance_group = (selection !== undefined) ? selection : null;
                    setCollectedDataAlliaceGroupValid(selection !== undefined ? true : false);
                  }} />
              </div>
            </div>

            <div className='row'>
              <div className={checkValidation(collectedDataStartDateValid) ? 'col-md-3' : 'col-md-3 not-valid'}>
                <label htmlFor='start_date'>Start date <span className='validationRule'>*</span> <InfoTooltip targetId='_start_date_' text={getToolTipText()} /></label>
                <DateSelection
                  id="start_date"
                  name="start_date"
                  required={false}
                  value={collectedData.start_date !== null && collectedData.start_date !== undefined ? Date.parse(collectedData.start_date) : undefined}
                  pickerType={DateSelectionPickerType.calendar}
                  selectionType={DateSelectionType.date}
                  onChangedSelection={(selection) => {
                    setCollectedData((data) => ({ ...data, start_date: new Date(selection.value).toISOString() }));
                    setCollectedDataStartDateValid(selection !== undefined ? true : false);
                  }} />
              </div>

              <div className={checkValidation(collectedDataEndDateValid) ? 'col-md-3' : 'col-md-3 not-valid'}>
                <label htmlFor='end_date'>End date <span className='validationRule'>*</span> <InfoTooltip targetId='_end_date_' text={getToolTipText()} /></label>
                <DateSelection
                  id="end_date"
                  name="end_date"
                  value={collectedData.end_date !== null && collectedData.end_date !== undefined ? Date.parse(collectedData.end_date) : undefined}
                  pickerType={DateSelectionPickerType.calendar}
                  selectionType={DateSelectionType.date}
                  onChangedSelection={(selection) => {
                    setCollectedData((data) => ({ ...data, end_date: new Date(selection.value).toISOString() }));
                    setCollectedDataEndDateValid(selection !== undefined ? true : false);
                  }} />
              </div>

              <div className={checkValidation(collectedDataStopAfterSuccessValid) ? 'col-md-2' : 'col-md-2 not-valid'}>
                <label htmlFor='stop_after_success'>Stop after success <span className='validationRule'>*</span>  <InfoTooltip targetId='_stop_after_' text={getToolTipText()} /></label>
                <Selection<number> id="stop_after_success" name="stop_after_success" componentKey="stop_after_success"
                  disabled={false}
                  map={stopAfterSuccess.map((item) => {
                    return {
                      code: item.value,
                      displayItem: item.label,
                    };
                  })}
                  placeholder=''
                  displayExpr='displayItem'
                  valueExpr='code'
                  selectionMode={SelectionMode.single}
                  values={collectedData.stop !== null ? [collectedData.stop ? 1 : 0] : null}
                  onChangedSelection={(selection) => {
                    collectedData.stop = (selection !== undefined) ? selection === 1 ? true : false : null;
                    setCollectedDataStopAfterSuccessValid(selection !== undefined ? true : false);
                  }} />
              </div>

              <div className={checkValidation(collectedDataSectionValid) ? 'col-md-4' : 'col-md-4 not-valid'}>
                <label htmlFor='sections'>Section where to see this on RewardsHub <span className='validationRule'>*</span>  <InfoTooltip targetId='_section_rewards_' text={getToolTipText()} /></label>
                <Selection<number> id="sections" name="sections" componentKey="sections" disabled={false} map={sections.map((item) => {
                  return {
                    code: item.id_section,
                    displayItem: item.label,
                  };
                })}
                  placeholder=''
                  displayExpr='displayItem'
                  valueExpr='code'
                  selectionMode={SelectionMode.single}
                  values={collectedData.id_section !== null ? [collectedData.id_section] : null}
                  onChangedSelection={(selection) => {
                    collectedData.id_section = (selection !== undefined) ? selection : null;
                    setCollectedIDSection((selection !== undefined) ? selection : -1);
                    setCollectedDataSectionValid(selection !== undefined ? true : false);
                    checkDisableAddNew();
                  }} />
              </div>
            </div>
            <div className='row'>
              <div className={checkValidation(collectedDataCustomerVisibilityValid) ? 'col-md-8' : 'col-md-8 not-valid'}>
                <label htmlFor='categories'>Customer visibility <span className='validationRule'>*</span><InfoTooltip targetId='_customer_' text={getToolTipText()} /></label>
                <CheckBoxSelection
                  id='categories'
                  defaultValues={collectedData.customer_status?.map((item) => {
                    return item.level_status_id;
                  })}
                  data={customerStatus.map((item) => {
                    return {
                      itemdata: item,
                      identifier: item.level_status_id,
                      displayItem: item.level_status_description,
                    };
                  })}
                  displayExpr='displayItem'
                  identifier='identifier'
                  onChangedSelection={(selection) => {
                    collectedData.customer_status = selection !== undefined ? selection.map((item: any) => item.itemdata) : [];
                    setCollectedDataCustomerVisibilityValid((collectedData.customer_status?.length ?? 0) > 0 ? true : false);
                  }} />
              </div>

              <div className={checkValidation(collectedDataSequenceValid) ? 'col-md-2' : 'col-md-2 not-valid'}>
                <label htmlFor='sequence'>Sequence <span className='validationRule'>*</span><InfoTooltip targetId='_sequence_' text={getToolTipText()} /></label>
                <TextAreaInput
                  id="sequence"
                  name="sequence"
                  placeholder=''
                  required={false}
                  onlyNumbers={true}
                  defaultValue={collectedData.sequence !== null && collectedData.sequence !== undefined ? collectedData.sequence : undefined}
                  format='#'
                  onChangedSelection={(value) => {
                    setCollectedData((data) => ({ ...data, sequence: (value !== undefined) ? parseInt(value, 10) : null }));
                    setCollectedDataSequenceValid(value !== undefined ? true : false);
                  }} />
              </div>

              <div className={checkValidation(collectedDataStatusValid) ? 'col-md-2' : 'col-md-2 not-valid'}>
                <label htmlFor='status'>Status <span className='validationRule'>*</span><InfoTooltip targetId='_status_' text={getToolTipText()} /></label>
                <Selection<string>
                  id="status"
                  name="status"
                  componentKey="status"
                  disabled={false}
                  map={rebateStatus.map((item) => {
                    return {
                      code: item.value,
                      displayItem: item.label,
                    };
                  })}
                  placeholder=''
                  displayExpr='displayItem'
                  valueExpr='code'
                  selectionMode={SelectionMode.single}
                  values={collectedData.status !== null ? [collectedData.status] : null}
                  onChangedSelection={(selection) => {
                    collectedData.status = selection !== undefined ? selection : null;
                    setCollectedDataStatusValid(selection !== undefined ? true : false);
                  }} />
              </div>

            </div>

            <div className={checkValidation(collectedDataBrandsValid) ? 'row' : 'row not-valid'}>
              <div>
                <label htmlFor='brands'>Select Brands for &quot;Luxottica Rewards Brand Pillar Rebate Performance&quot; section <span className='validationRule'>*</span><InfoTooltip targetId='_brands_' text={getToolTipText()} /></label>
                <DynamicMultiselection
                  id="brands"
                  defaultValues={collectedData.brands !== null && collectedData.brands !== undefined ? collectedData.brands : []}
                  data={brandCategories.sort((a, b) =>
                    a.category > b.category ? 1 : -1,
                  )}
                  onChangedSelection={(selection) => {
                    collectedData.brands = selection !== undefined ? selection : [];
                    setCollectedDataBrandsValid(selection !== undefined ? true : false);
                  }}
                />
              </div>
            </div>

            <div className="row" style={{ paddingBottom: '0px' }}>
              <div className={checkValidation(collectedDataEligibilityCriteriaValid) ? 'col-md-6 boxed noleft noright box-container' : 'col-md-6 boxed noleft noright box-container not-valid'}>
                <div className='box__header'>
                  <div className='title'>Eligibilty criteria</div>
                  <AddButtonDialog
                    id="openCriteriaDialog"
                    text='Add new criteria'
                    target='#criteriaDialog'
                    disabled={disableAddNew}
                    onClick={() => { }} />
                </div>
                {emptyState(collectedData.criterias ?? [], 'empty_state_criteria', 'No Criteria', 'eligibility criteria')}
                {
                  refresh >= 0 &&
                  collectedData.criterias?.map((item: Criteria, i: number) => {

                    return (
                      <CellBoxContainer
                        key={('criteria_' + i).toString()}
                        title={'Criteria'}
                        content={<CellBoxTable
                          key={('criteria_table_' + i).toString()}
                          data={{
                            keys: [
                              { title: 'Source type id', weight: 40 },
                              { title: 'Condition', weight: 20 },
                              { title: 'Value', weight: 40 },
                            ],
                            rows: item.conditions?.map((condition: CriteriaItem) => {
                              return {
                                'Source type id': criteriaItemTypeId(condition),
                                'Condition': criteriaItemCondition(condition),
                                'Value': criteriaItemValue(condition),
                              };
                            }),
                          }} />}
                        onCancel={() => {
                          if (collectedData.criterias) {
                            collectedData.criterias.splice(i, 1);
                            setCollectedDataEligibilityCriteriaValid(collectedData.criterias.length > 0);
                            setRefresh(refresh + 1);
                          }
                        }}
                        onEdit={() => {
                          if (collectedData.criterias) {
                            prepareCriteriaDialog(i);
                            setCollectedCriteria(JSON.parse(JSON.stringify(collectedData.criterias[i])));
                            document.getElementById('openCriteriaDialog')?.click();
                          }
                        }} />
                    );
                  })}
              </div>

              <div className="modal fade modal-xl" id="criteriaDialog"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-labelledby="criteriaDialog"
                aria-hidden="true">
                <div className="modal-dialog modal-xl" style={{ width: '90%' }}>
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="criteriaDialog">Create new criteria</h5>
                      <button id="criteriaDialogClose" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                        prepareCriteriaDialog(-1);
                      }} />
                    </div>
                    <div className="modal-body">
                      <div className="container">

                        <div className="row criteria_form">

                          <div className={checkValidation(collectedCriteriaItemSourceTypeIDValid) ? 'col' : 'col not-valid'}>
                            <label htmlFor='source_type_id'>Source type id <span className='validationRule'>*</span><InfoTooltip targetId='_type_id_' text={getToolTipText()} /></label>
                            <Selection<string>
                              id="source_type_id"
                              name="source_type_id"
                              componentKey="source_type_id"
                              disabled={false}
                              map={sourceTypeIDs(collectedIDPeriod, collectedIDSection).map((item: any) => {
                                return {
                                  code: item.type_id,
                                  displayItem: item.description + ' (' + item.type_id + ')',
                                };
                              })}
                              placeholder=''
                              displayExpr='displayItem'
                              valueExpr='code'
                              selectionMode={SelectionMode.single}
                              values={collectedCriteriaItem.type_id !== null ? [collectedCriteriaItem.type_id] : null}
                              onChangedSelection={(selection: any) => {
                                collectedCriteriaItem.type_id = selection !== undefined ? selection : null;
                                setCollectedCriteriaItemSourceTypeIDValid(selection !== undefined ? true : false);
                              }} />
                          </div>

                          <div className={checkValidation(collectedCriteriaItemTypeValid) ? 'col' : 'col not-valid'}>
                            <label htmlFor='criteria_types'>Type <span className='validationRule'>*</span></label>
                            <Selection<string>
                              id="criteria_types"
                              name="criteria_types"
                              componentKey="criteria_types"
                              disabled={false}
                              map={criteriaTypes.map((item) => {
                                return {
                                  code: item.value,
                                  displayItem: item.label,
                                };
                              })}
                              placeholder=''
                              displayExpr='displayItem'
                              valueExpr='code'
                              selectionMode={SelectionMode.single}
                              values={collectedCriteriaItem.type !== null ? [collectedCriteriaItem.type] : null}
                              onChangedSelection={(selection) => {
                                collectedCriteriaItem.type = selection !== undefined ? selection : null;
                                setCollectedCriteriaItemType(selection !== undefined ? selection : null);
                                setCollectedCriteriaItemTypeValid(selection !== undefined ? true : false);
                              }} />
                          </div>

                          {
                            (collectedCriteriaItemType === 'single_value' ||
                              collectedCriteriaItemType === 'type_id') &&
                            <div className={checkValidation(collectedCriteriaItemConditionIDValid) ? 'col' : 'col not-valid'}>
                              <label htmlFor='condition'>Condition <span className='validationRule'>*</span></label>
                              <Selection
                                id="criteria_conditions"
                                name="criteria_conditions"
                                componentKey="criteria_conditions"
                                disabled={false}
                                map={criteriaConditions.map((item) => {
                                  return {
                                    code: item.id_condition,
                                    displayItem: item.symbol,
                                  };
                                })}
                                placeholder=''
                                displayExpr='displayItem'
                                valueExpr='code'
                                selectionMode={SelectionMode.single}
                                values={collectedCriteriaItem.condition_id !== null ? [collectedCriteriaItem.condition_id] : null}
                                onChangedSelection={(selection: any) => {
                                  collectedCriteriaItem.condition_id = selection !== undefined ? selection : null;
                                  collectedCriteriaItem.min = null;
                                  collectedCriteriaItem.max = null;
                                  collectedCriteriaItem.target_type_id = null;

                                  setCollectedCriteriaItemConditionIDValid(selection !== undefined ? true : false);
                                  setCollectedCriteriaItemMinValid(undefined);
                                  setCollectedCriteriaItemMaxValid(undefined);
                                  setCollectedCriteriaItemTargetTypeIDValid(undefined);
                                }} />
                            </div>
                          }

                          {
                            collectedCriteriaItemType === 'single_value' &&
                            <div className={checkValidation(collectedCriteriaItemValueValid) ? 'col' : 'col not-valid'}>
                              <label htmlFor='criteria'>Value <span className='validationRule'>*</span></label>
                              <TextAreaInput
                                onlyNumbers={true}
                                format='#,##0'
                                required={false}
                                defaultValue={(collectedCriteriaItem.value !== null && collectedCriteriaItem.value !== undefined) ? collectedCriteriaItem.value : undefined}
                                onChangedSelection={(value) => {
                                  setCollectedCriteriaItem((data) => ({ ...data, value: (value !== undefined) ? parseInt(value, 10) : null }));
                                  collectedCriteriaItem.min = null;
                                  collectedCriteriaItem.max = null;
                                  collectedCriteriaItem.target_type_id = null;

                                  setCollectedCriteriaItemValueValid(value !== undefined ? true : false);
                                  setCollectedCriteriaItemMinValid(undefined);
                                  setCollectedCriteriaItemMaxValid(undefined);
                                  setCollectedCriteriaItemTargetTypeIDValid(undefined);
                                }} />
                            </div>
                          }

                          {
                            collectedCriteriaItemType === 'range' &&
                            <div className={checkValidation(collectedCriteriaItemMinValid) && checkValidation(collectedCriteriaItemMaxValid) ? 'col multiple' : 'col multiple not-valid'}>
                              <label htmlFor='criteria'>Value <span className='validationRule'>*</span></label>
                              <TextAreaInput
                                placeholder='Min'
                                onlyNumbers={true}
                                format='#,##0'
                                required={false}
                                defaultValue={collectedCriteriaItem.min !== null && collectedCriteriaItem.min !== undefined ? collectedCriteriaItem.min : undefined}
                                onChangedSelection={(value) => {
                                  setCollectedCriteriaItem((data) => ({ ...data, min: (value !== undefined) ? parseInt(value, 10) : null }));
                                  collectedCriteriaItem.value = null;
                                  collectedCriteriaItem.target_type_id = null;

                                  setCollectedCriteriaItemMinValid(value !== undefined ? true : false);
                                  setCollectedCriteriaItemValueValid(undefined);
                                  setCollectedCriteriaItemTargetTypeIDValid(undefined);
                                }} />
                              <TextAreaInput
                                placeholder='Max'
                                onlyNumbers={true}
                                format='#,##0'
                                required={false}
                                defaultValue={collectedCriteriaItem.max !== null && collectedCriteriaItem.max !== undefined ? collectedCriteriaItem.max : undefined}
                                onChangedSelection={(value) => {
                                  setCollectedCriteriaItem((data) => ({ ...data, max: (value !== undefined) ? parseInt(value, 10) : null }));
                                  collectedCriteriaItem.value = null;
                                  collectedCriteriaItem.target_type_id = null;

                                  setCollectedCriteriaItemMaxValid(value !== undefined ? true : false);
                                  setCollectedCriteriaItemValueValid(undefined);
                                  setCollectedCriteriaItemTargetTypeIDValid(undefined);
                                }} />
                            </div>
                          }

                          {
                            collectedCriteriaItemType === 'type_id' &&
                            <div className={checkValidation(collectedCriteriaItemTargetTypeIDValid) ? 'col' : 'col not-valid'}>
                              <label htmlFor='target_type_id'>Value <span className='validationRule'>*</span></label>
                              <Selection
                                id="target_type_id"
                                name="target_type_id"
                                componentKey="target_type_id"
                                disabled={false}
                                // isValid={collectedCriteriaItemValidates.target_type_id}
                                map={
                                  sourceTypeIDs(collectedIDPeriod, collectedIDSection).map((item: any) => {
                                    return {
                                      code: item.type_id,
                                      displayItem: item.description + ' (' + item.type_id + ')',
                                    };
                                  })}
                                placeholder=''
                                displayExpr='displayItem'
                                valueExpr='code'
                                selectionMode={SelectionMode.single}
                                values={collectedCriteriaItem.target_type_id !== null ? [collectedCriteriaItem.target_type_id] : null}
                                onChangedSelection={(selection: any) => {
                                  collectedCriteriaItem.target_type_id = selection !== undefined ? selection : null;
                                  collectedCriteriaItem.value = null;
                                  collectedCriteriaItem.min = null;
                                  collectedCriteriaItem.max = null;

                                  setCollectedCriteriaItemTargetTypeIDValid(selection !== undefined ? true : false);
                                  setCollectedCriteriaItemValueValid(undefined);
                                  setCollectedCriteriaItemMinValid(undefined);
                                  setCollectedCriteriaItemMaxValid(undefined);
                                }} />
                            </div>
                          }

                          <div className="col-md-1 addbutton">
                            <img className={validateCriteriaItem() ? 'add' : 'add disabled'} alt="Add" src={iconAdd} width={'16'} height={'16'} onClick={() => {
                              if (collectedCriteria.conditions !== undefined && collectedCriteria.conditions !== null) {
                                const tempCriteriaItem = JSON.parse(JSON.stringify(collectedCriteriaItem));
                                if (collectedCriteriaItemEditMode >= 0) {
                                  collectedCriteria.conditions[collectedCriteriaItemEditMode] = tempCriteriaItem;
                                } else {
                                  collectedCriteria.conditions.push(tempCriteriaItem);
                                }
                                setCollectedCriteriaItem({});
                                setCollectedCriteriaItemType(null);
                                resetCriteriaItemValid();
                                setCollectedCriteriaItemEditMode(-1);
                              }
                            }} />
                          </div>

                        </div>

                        <div className='row'>
                          <div className='col'>
                            {collectedCriteria.conditions &&
                              collectedCriteria.conditions.map((item: CriteriaItem, i: number) => {
                                return (
                                  <CellBoxContainer
                                    key={('criteria_' + i).toString()}
                                    title={'Criteria'}
                                    content={<CellBoxTable
                                      key={('criteria_table_' + i).toString()}
                                      data={{
                                        keys: [
                                          { title: 'Source type id', weight: 40 },
                                          { title: 'Condition', weight: 20 },
                                          { title: 'Value', weight: 40 },
                                        ],
                                        rows: [
                                          {
                                            'Source type id': criteriaItemTypeId(item),
                                            'Condition': criteriaItemCondition(item),
                                            'Value': criteriaItemValue(item),
                                          },
                                        ],
                                      }} />}
                                    onCancel={() => {
                                      if (collectedCriteria.conditions) {
                                        collectedCriteria.conditions.splice(i, 1);
                                        setRefresh(refresh + 1);
                                      }
                                    }}
                                    onEdit={() => {
                                      if (collectedCriteria.conditions) {
                                        const tempItem = JSON.parse(JSON.stringify(collectedCriteria.conditions[i]));
                                        setCollectedCriteriaItemEditMode(i);
                                        setCollectedCriteriaItem(tempItem);
                                        setCollectedCriteriaItemValueValid(true);
                                        setCollectedCriteriaItemMinValid(true);
                                        setCollectedCriteriaItemMaxValid(true);
                                        setCollectedCriteriaItemTypeValid(true);
                                      }
                                    }} />
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="modal-footer">
                      <SaveButton text='Save'
                        enabled={collectedCriteria.conditions && collectedCriteria.conditions.length > 0 ? true : false}
                        onClick={() => {
                          if (collectedData.criterias !== null && collectedData.criterias !== undefined) {
                            const tempCriteria = JSON.parse(JSON.stringify(JSON.parse(JSON.stringify(collectedCriteria))));
                            if (collectedCriteriaEditMode >= 0) {
                              collectedData.criterias[collectedCriteriaEditMode] = tempCriteria;
                            } else {
                              collectedData.criterias.push(tempCriteria);
                            }
                            setCollectedDataEligibilityCriteriaValid(collectedData.criterias.length > 0);
                            prepareCriteriaDialog(-1);
                            setRefresh(refresh + 1);
                          }
                          document.getElementById('criteriaDialogClose')?.click();
                        }} />
                    </div>

                  </div>
                </div>
              </div>

              <div className={checkValidation(collectedDataTierValid) ? 'col-md-6 boxed noright box-container' : 'col-md-6 boxed noright box-container not-valid'}>
                <div className='box__header'>
                  <div className='title'>Tiers</div>
                  <AddButtonDialog
                    id='openTierDialog'
                    text='Add new tier'
                    target='#tierDialog'
                    disabled={disableAddNew}
                    onClick={() => { }} />
                </div>
                {
                  emptyState(collectedData.tiers ?? [], 'empty_state_tier', 'No Tiers', 'tier')}
                {
                  refresh >= 0 &&
                  collectedData.tiers?.map((item: TierItem, i: number) => {
                    return (
                      <CellBoxContainer
                        onCancel={() => {
                          if (collectedData.tiers) {
                            collectedData.tiers.splice(i, 1);
                            setCollectedDataTierValid(collectedData.tiers.length > 0);
                            setRefresh(refresh + 1);
                          }
                        }}
                        onEdit={() => {
                          if (collectedData.tiers) {
                            prepareTierDialog(i);
                            const tempTier = JSON.parse(JSON.stringify(collectedData.tiers[i]));
                            setCollectedTierItem(tempTier);
                            setCollectedTierInfo(tempTier.tier);
                            document.getElementById('openTierDialog')?.click();
                          }
                        }}
                        key={('tier_' + i).toString()}
                        title='Tier'
                        content={
                          <>
                            <div className='row g-0'>
                              <div className='col col-tier-value'>
                                <label>Rebate Target %</label>
                                <span>{item.tier?.target_perc !== null ? item.tier?.target_perc + '%' : '-'}</span>
                              </div>
                              <div className='col col-tier-value'>
                                <label>Rebate Target $</label>
                                <span>{item.tier?.target_value !== null ? '$ ' + item.tier?.target_value : '-'}</span>
                              </div>
                              <div className='col col-tier-value'>
                                <label>Target baseline period</label>
                                <span>{typeIdString(item.tier?.target_baseline ?? '')}</span>
                              </div>
                              <div className='col col-tier-value'>
                                <label>Target actual</label>
                                <span>{typeIdString(item.tier?.target_actual ?? '')}</span>
                              </div>
                              <div className='col col-tier-value'>
                                <label>Rebate %</label>
                                <span>{item.tier?.rebate_target_perc !== null ? item.tier?.rebate_target_perc + '%' : '-'}</span>
                              </div>
                            </div>

                            {item.criterias?.map((criteriaItem: CriteriaItem, j: number) => {
                              return (
                                <CellBoxContainer
                                  key={('tier_criteria_item_' + j).toString()}
                                  title='Criteria'
                                  content={
                                    <CellBoxTable
                                      key={('tier_criteria_item_data_' + j).toString()}
                                      data={{
                                        keys: [
                                          { title: 'Source type id', weight: 40 },
                                          { title: 'Condition', weight: 20 },
                                          { title: 'Value', weight: 40 },
                                        ],
                                        rows: item.criterias?.map((criteriaItem: CriteriaItem) => {
                                          return {
                                            'Source type id': criteriaItemTypeId(criteriaItem),
                                            'Condition': criteriaItemCondition(criteriaItem),
                                            'Value': criteriaItemValue(criteriaItem),
                                          };
                                        }),
                                      }
                                      } />
                                  }
                                  onCancel={() => {
                                    if (collectedData.tiers && collectedData.tiers[i].criterias) {
                                      collectedData.tiers[i].criterias?.splice(j, 1);
                                      setRefresh(refresh + 1);
                                    }
                                  }}
                                  onEdit={() => {
                                    if (collectedData.tiers !== null &&
                                      collectedData.tiers !== undefined) {
                                      prepareTierDialog(i);
                                      const tempTier = JSON.parse(JSON.stringify(collectedData.tiers[i]));
                                      setCollectedTierItem(tempTier);
                                      setCollectedTierInfo(tempTier.tier);

                                      const tempCollectedTierCriteriaItem = collectedData.tiers[i].criterias;

                                      if (tempCollectedTierCriteriaItem !== undefined &&
                                        tempCollectedTierCriteriaItem !== null &&
                                        tempCollectedTierCriteriaItem.length > 0) {
                                        const tempItem = JSON.parse(JSON.stringify(tempCollectedTierCriteriaItem[0]));
                                        setCollectedTierCriteriaItemEditMode(j);
                                        setCollectedTierCriteriaItem(tempItem);
                                        setCollectedTierCriteriaItemValueValid(true);
                                        setCollectedTierCriteriaItemMinValid(true);
                                        setCollectedTierCriteriaItemMaxValid(true);
                                        setCollectedTierCriteriaItemTypeValid(true);
                                      }
                                      document.getElementById('openTierDialog')?.click();
                                    }
                                  }
                                  } />);
                            })}
                          </>} />);
                  })}
              </div>


              <div className="modal fade modal-xl"
                id="tierDialog"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-labelledby="tierDialog"
                aria-hidden="true">
                <div className="modal-dialog modal-xl" style={{ width: '90%' }}>
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="tierDialog">Create new tier</h5>
                      <button id="tierDialogClose" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                        prepareTierDialog(-1);
                      }} />
                    </div>
                    <div className="modal-body">
                      <div className="container">

                        <div className="row">

                          <div className={checkValidation(collectedTierInfoTargetPercValid) ? 'col' : 'col not-valid'}>
                            <label htmlFor='tier_target_percentage'>Rebate Target % <span className='validationRule'>*</span></label>
                            <TextAreaInput
                              id='tier_target_percentage'
                              name='tier_target_percentage'
                              required={false}
                              onlyNumbers={true}
                              format='#,##0'
                              defaultValue={collectedTierInfo.target_perc !== null && collectedTierInfo.target_perc !== undefined ? collectedTierInfo.target_perc : undefined}
                              onChangedSelection={(value) => {
                                setCollectedTierInfo((data) => ({ ...data, target_perc: (value !== undefined) ? parseInt(value, 10) : null }));
                                setCollectedTierInfoTargetPercValid(value !== undefined ? true : false);
                                validateTierInfo();
                              }} />
                          </div>

                          <div className={checkValidation(collectedTierInfoTargetValueValid) ? 'col' : 'col not-valid'}>
                            <label htmlFor='tier_target_value'>Rebate target $ <span className='validationRule'>*</span></label>
                            <TextAreaInput
                              id='tier_target_value'
                              name='tier_target_value'
                              required={false}
                              onlyNumbers={true}
                              format='#,##0'
                              defaultValue={collectedTierInfo.target_value !== null && collectedTierInfo.target_value !== undefined ? collectedTierInfo.target_value : undefined}
                              onChangedSelection={(value) => {
                                setCollectedTierInfo((data) => ({ ...data, target_value: (value !== undefined) ? parseInt(value, 10) : null }));
                                setCollectedTierInfoTargetValueValid(value !== undefined ? true : false);
                              }} />
                          </div>

                          <div className={checkValidation(collectedTierInfoTargetActualValid) ? 'col' : 'col not-valid'}>
                            <label htmlFor='target_actual'>Target actual <span className='validationRule'>*</span></label>
                            <Selection
                              id="target_actual"
                              name="target_actual"
                              componentKey="target_actual"
                              disabled={false}
                              map={sourceTypeIDs(collectedIDPeriod, collectedIDSection).map((item: any) => {
                                return {
                                  code: item.type_id,
                                  displayItem: item.description + ' (' + item.type_id + ')',
                                };
                              })}
                              placeholder=''
                              displayExpr='displayItem'
                              valueExpr='code'
                              selectionMode={SelectionMode.single}
                              values={collectedTierInfo.target_actual !== null ? [collectedTierInfo.target_actual] : null}
                              onChangedSelection={(selection: any) => {
                                collectedTierInfo.target_actual = selection !== undefined ? selection : null;
                                setCollectedTierInfoTargetActualValid(selection !== undefined ? true : false);
                              }} />
                          </div>

                          <div className={checkValidation(collectedTierInfoRebateTargetPercValid) ? 'col' : 'col not-valid'}>
                            <label htmlFor='rebate_target_perc'>Rebate % <span className='validationRule'>*</span></label>
                            <TextAreaInput
                              id='rebate_target_perc'
                              name='rebate_target_perc'
                              required={false}
                              onlyNumbers={true}
                              format='#,##0'
                              defaultValue={collectedTierInfo.rebate_target_perc !== null && collectedTierInfo.rebate_target_perc !== undefined ? collectedTierInfo.rebate_target_perc : undefined}
                              onChangedSelection={(value) => {
                                setCollectedTierInfo((data) => ({ ...data, rebate_target_perc: (value !== undefined) ? parseInt(value, 10) : null }));
                                setCollectedTierInfoRebateTargetPercValid(value !== undefined ? true : false);
                              }} />
                          </div>
                        </div>

                        <div className="row">
                          <div className={checkValidation(collectedTierInfoTargetBaselineValid) ? 'col' : 'col not-valid'}>
                            <label htmlFor='target_baseline'>Target baseline period</label>
                            <Selection
                              id="target_baseline"
                              name="target_baseline"
                              componentKey="target_baseline"
                              disabled={false}
                              map={sourceTypeIDs(collectedIDPeriod, collectedIDSection).map((item: any) => {
                                return {
                                  code: item.type_id,
                                  displayItem: item.description + ' (' + item.type_id + ')',
                                };
                              })}
                              placeholder=''
                              displayExpr='displayItem'
                              valueExpr='code'
                              selectionMode={SelectionMode.single}
                              values={collectedTierInfo.target_baseline !== null ? [collectedTierInfo.target_baseline] : null}
                              onChangedSelection={(selection: any) => {
                                collectedTierInfo.target_baseline = selection !== undefined ? selection : null;
                                setCollectedTierInfoTargetBaselineValid(true); // selection !== undefined ? true : false);
                              }} />
                          </div>
                        </div>
                      </div>

                      <div className="container-fluid separator">
                        <div className='row'>
                          <h6>Create new criteria</h6>
                        </div>
                      </div>

                      <div className="container">
                        <div className="row criteria_form" style={{ display: collectedTierCriteriaItemEnabled ? 'flex' : 'none' }}>
                          <div className={checkValidation(collectedTierCriteriaItemSourceTypeIDValid) ? 'col' : 'col not-valid'}>
                            <label htmlFor='tier_source_type_id'>Source type id <span className='validationRule'>*</span></label>
                            <Selection<string>
                              id="tier_source_type_id"
                              name="tier_source_type_id"
                              componentKey="tier_source_type_id"
                              disabled={false}
                              map={sourceTypeIDs(collectedIDPeriod, collectedIDSection).map((item: any) => {
                                return {
                                  code: item.type_id,
                                  displayItem: item.description + ' (' + item.type_id + ')',
                                };
                              })}
                              placeholder=''
                              displayExpr='displayItem'
                              valueExpr='code'
                              selectionMode={SelectionMode.single}
                              values={collectedTierCriteriaItem.type_id !== null ? [collectedTierCriteriaItem.type_id] : null}
                              onChangedSelection={(selection: any) => {
                                collectedTierCriteriaItem.type_id = selection !== undefined ? selection : null;
                                setCollectedTierCriteriaItemSourceTypeIDValid(selection !== undefined ? true : false);
                              }} />
                          </div>

                          <div className="col">
                            <label htmlFor='tier_criteria_types'>Type <span className='validationRule'>*</span></label>
                            <Selection<string>
                              id="tier_criteria_types"
                              name="tier_criteria_types"
                              componentKey="tier_criteria_types"
                              disabled={false}
                              map={criteriaTypes.map((item) => {
                                return {
                                  code: item.value,
                                  displayItem: item.label,
                                };
                              })}
                              placeholder=''
                              displayExpr='displayItem'
                              valueExpr='code'
                              selectionMode={SelectionMode.single}
                              values={collectedTierCriteriaItem.type !== null ? [collectedTierCriteriaItem.type] : null}
                              onChangedSelection={(selection: any) => {
                                collectedTierCriteriaItem.type = selection !== undefined ? selection : null;
                                setCollectedTierCriteriaItemType(selection !== undefined ? selection : null);
                                setCollectedTierCriteriaItemTypeValid(selection !== undefined ? true : false);
                              }} />
                          </div>

                          {
                            (collectedTierCriteriaItemType === 'single_value' ||
                              collectedTierCriteriaItemType === 'type_id') &&
                            <div className={checkValidation(collectedTierCriteriaItemConditionIDValid) ? 'col' : 'col not-valid'}>
                              <label htmlFor='tier_criteria_conditions'>Condition <span className='validationRule'>*</span></label>
                              <Selection<number>
                                id="tier_criteria_conditions"
                                name="tier_criteria_conditions"
                                componentKey="tier_criteria_conditions"
                                disabled={false}
                                map={criteriaConditions.map((item) => {
                                  return {
                                    code: item.id_condition,
                                    displayItem: item.symbol,
                                  };
                                })}
                                placeholder=''
                                displayExpr='displayItem'
                                valueExpr='code'
                                selectionMode={SelectionMode.single}
                                values={collectedTierCriteriaItem.condition_id !== null ? [collectedTierCriteriaItem.condition_id] : null}
                                onChangedSelection={(selection: any) => {
                                  collectedTierCriteriaItem.condition_id = selection !== undefined ? selection : null;
                                  collectedTierCriteriaItem.min = null;
                                  collectedTierCriteriaItem.max = null;
                                  collectedTierCriteriaItem.target_type_id = null;

                                  setCollectedTierCriteriaItemConditionIDValid(selection !== undefined ? true : false);
                                  setCollectedTierCriteriaItemMinValid(undefined);
                                  setCollectedTierCriteriaItemMaxValid(undefined);
                                  setCollectedTierCriteriaItemTargetTypeIDValid(undefined);
                                }} />
                            </div>
                          }

                          {
                            collectedTierCriteriaItemType === 'single_value' &&
                            <div className="col">
                              <label htmlFor='tier_criteria'>Value <span className='validationRule'>*</span></label>
                              <TextAreaInput
                                onlyNumbers={true}
                                format='#,##0'
                                required={false}
                                defaultValue={(collectedTierCriteriaItem.value !== null && collectedTierCriteriaItem.value !== undefined) ? collectedTierCriteriaItem.value : undefined}
                                onChangedSelection={(value) => {
                                  setCollectedTierCriteriaItem((data) => ({ ...data, value: (value !== undefined) ? parseInt(value, 10) : null }));
                                  collectedTierCriteriaItem.min = null;
                                  collectedTierCriteriaItem.max = null;
                                  collectedTierCriteriaItem.target_type_id = null;

                                  setCollectedTierCriteriaItemValueValid(value !== undefined ? true : false);
                                  setCollectedTierCriteriaItemMinValid(undefined);
                                  setCollectedTierCriteriaItemMaxValid(undefined);
                                  setCollectedTierCriteriaItemTargetTypeIDValid(undefined);
                                }} />
                            </div>
                          }

                          {
                            collectedTierCriteriaItemType === 'range' &&
                            <div className="col multiple">
                              <label htmlFor='criteria'>Value</label>
                              <TextAreaInput
                                placeholder='Min'
                                onlyNumbers={true}
                                format='$ #,##0'
                                required={false}
                                defaultValue={collectedTierCriteriaItem.min !== null && collectedTierCriteriaItem.min !== undefined ? collectedTierCriteriaItem.min : undefined}
                                onChangedSelection={(value) => {
                                  setCollectedTierCriteriaItem((data) => ({ ...data, min: (value !== undefined) ? parseInt(value, 10) : null }));
                                  collectedTierCriteriaItem.value = null;
                                  collectedTierCriteriaItem.target_type_id = null;

                                  setCollectedTierCriteriaItemMinValid(value !== undefined ? true : false);
                                  setCollectedTierCriteriaItemValueValid(undefined);
                                  setCollectedTierCriteriaItemTargetTypeIDValid(undefined);

                                }} />
                              <TextAreaInput
                                placeholder='Max'
                                onlyNumbers={true}
                                format='$ #,##0'
                                required={false}
                                defaultValue={collectedTierCriteriaItem.max !== null && collectedTierCriteriaItem.max !== undefined ? collectedTierCriteriaItem.max : undefined}
                                onChangedSelection={(value) => {
                                  setCollectedTierCriteriaItem((data) => ({ ...data, max: (value !== undefined) ? parseInt(value, 10) : null }));
                                  collectedTierCriteriaItem.value = null;
                                  collectedTierCriteriaItem.target_type_id = null;

                                  setCollectedTierCriteriaItemMaxValid(value !== undefined ? true : false);
                                  setCollectedTierCriteriaItemValueValid(undefined);
                                  setCollectedTierCriteriaItemTargetTypeIDValid(undefined);
                                }} />
                            </div>
                          }

                          {
                            collectedTierCriteriaItemType === 'type_id' &&
                            <div className={checkValidation(collectedTierCriteriaItemTargetTypeIDValid) ? 'col' : 'col not-valid'}>
                              <label htmlFor='tier_target_type_id'>Value</label>
                              <Selection
                                id="tier_target_type_id"
                                name="tier_target_type_id"
                                componentKey="tier_target_type_id"
                                disabled={false}
                                map={
                                  sourceTypeIDs(collectedIDPeriod, collectedIDSection).map((item: any) => {
                                    return {
                                      code: item.type_id,
                                      displayItem: item.description + ' (' + item.type_id + ')',
                                    };
                                  })}
                                placeholder=''
                                displayExpr='displayItem'
                                valueExpr='code'
                                selectionMode={SelectionMode.single}
                                values={collectedTierCriteriaItem.target_type_id !== null ? [collectedTierCriteriaItem.target_type_id] : null}
                                onChangedSelection={(selection: any) => {
                                  collectedTierCriteriaItem.target_type_id = selection !== undefined ? selection : null;
                                  collectedTierCriteriaItem.value = null;
                                  collectedTierCriteriaItem.min = null;
                                  collectedTierCriteriaItem.max = null;

                                  setCollectedTierCriteriaItemTargetTypeIDValid(selection !== undefined ? true : false);
                                  setCollectedTierCriteriaItemValueValid(undefined);
                                  setCollectedTierCriteriaItemMinValid(undefined);
                                  setCollectedTierCriteriaItemMaxValid(undefined);
                                }} />
                            </div>
                          }

                          <div className="col-md-1 addbutton">
                            <img className={validateTierCriteriaItem() ? 'add' : 'add disabled'} alt="Add" src={iconAdd} width={'16'} height={'16'} onClick={() => {
                              if (collectedTierItem.criterias !== undefined && collectedTierItem.criterias !== null) {
                                const tempTierCriteriaItem = JSON.parse(JSON.stringify(collectedTierCriteriaItem));
                                if (collectedTierCriteriaItemEditMode >= 0) {
                                  collectedTierItem.criterias[collectedTierCriteriaItemEditMode] = tempTierCriteriaItem;
                                } else {
                                  collectedTierItem.criterias.push(tempTierCriteriaItem);
                                }
                                setCollectedTierCriteriaItem({});
                                setCollectedTierCriteriaItemType(null);
                                resetTierCriteriaItemValid();
                                setCollectedTierCriteriaItemEditMode(-1);

                                if (collectedTierItem.criterias.length <= 1 || collectedTierCriteriaItemEditMode >= 0) {
                                  setCollectedTierCriteriaItemEnabled(false);
                                }
                              }
                            }} />
                          </div>

                        </div>

                        <div className='row'>
                          <div className='col'>
                            {collectedTierItem.criterias &&
                              collectedTierItem.criterias.map((item: CriteriaItem, i: number) => {
                                return (
                                  <CellBoxContainer
                                    key={('criteria_' + i).toString()}
                                    title={'Criteria'}
                                    content={<CellBoxTable
                                      key={('criteria_table_' + i).toString()}
                                      data={{
                                        keys: [
                                          { title: 'Source type id', weight: 40 },
                                          { title: 'Condition', weight: 20 },
                                          { title: 'Value', weight: 40 },
                                        ],
                                        rows: [
                                          {
                                            'Source type id': criteriaItemTypeId(item),
                                            'Condition': criteriaItemCondition(item),
                                            'Value': criteriaItemValue(item),
                                          },
                                        ],
                                      }} />}
                                    onCancel={() => {
                                      if (collectedTierItem.criterias) {
                                        collectedTierItem.criterias.splice(i, 1);
                                        setCollectedTierCriteriaItemEnabled(true);
                                        setRefresh(refresh + 1);
                                      }
                                    }}
                                    onEdit={() => {
                                      if (collectedTierItem.criterias) {
                                        const tempItem = JSON.parse(JSON.stringify(collectedTierItem.criterias[i]));
                                        setCollectedTierCriteriaItemEditMode(i);
                                        setCollectedTierCriteriaItem(tempItem);
                                        setCollectedTierCriteriaItemValueValid(true);
                                        setCollectedTierCriteriaItemMinValid(true);
                                        setCollectedTierCriteriaItemMaxValid(true);
                                        setCollectedTierCriteriaItemTypeValid(true);
                                        setCollectedTierCriteriaItemEnabled(true);
                                      }
                                    }} />
                                );
                              })}
                          </div>
                        </div>
                      </div>

                      <div className="modal-footer">
                        <SaveButton text='Save'
                          enabled={collectedTierInfo.target_value !== null &&
                            collectedTierInfo.target_actual !== null &&
                            collectedTierInfo.target_perc !== null &&
                            collectedTierInfo.rebate_target_perc !== null // &&
                            // collectedTierInfo.target_baseline !== null
                            ? true : false}
                          onClick={() => {
                            if (collectedData.tiers !== null && collectedData.tiers !== undefined) {
                              collectedTierItem.tier = JSON.parse(JSON.stringify(collectedTierInfo));
                              const tempTierItem = JSON.parse(JSON.stringify(collectedTierItem));
                              if (collectedTierEditMode >= 0) {
                                collectedData.tiers[collectedTierEditMode] = tempTierItem;
                              } else {
                                collectedData.tiers?.push(tempTierItem);
                              }
                              prepareTierDialog(-1);
                              setCollectedDataTierValid(collectedData.tiers.length > 0);
                              setRefresh(refresh + 1);
                            }
                            document.getElementById('tierDialogClose')?.click();
                          }} />
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div className='row saveButton'>
            <SaveButton
              text='Save'
              enabled={validateRebate()}
              onClick={() => {
                saveData();
              }} />
          </div>
        </div>

        <div style={{ display: 'none' }}>
          <AddButtonDialog
            id="openSaveWarningDialog"
            text=''
            target='#saveWarningDialog'
            disabled={false}
            onClick={() => { }} />
        </div>
        <div className="modal fade" id="saveWarningDialog" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="saveWarningDialogLabel">Warning</h5>
                <button id="saveWarningDialogCloseButton" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <div className='container'>
                  <div className='row'>
                    <div className='col'>
                      Something went wrong. Please try again later.
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </section >
    </>
  );
};