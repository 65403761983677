/** @format */

import { FC } from 'react';
import { UIIcon } from './Icon';
import iconTarget from '../assets/images/v2/discount.svg';

export const Target: FC = (): JSX.Element => {
  return (
    <div className='discount'>
      <UIIcon name={''} srcIcon={iconTarget} width={'16'} height={'16'} className="" />
      <label>Discount</label>
    </div>
  );
};
