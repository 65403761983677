/** @format */

import axios from 'axios';
import { TypeIdCreateRequestModel, TypeIdGroup } from '../../models/TypeIdCreateRequestModel';

export interface BrandCategory {
  category: string;
  brands: string[];
}

export interface CustomerStatus {
  level_status_description: string;
  level_status_id: number;
  level_status_value: number;
}

export interface Period {
  id_period: number;
  label: string;
  name: string;
}

export interface Section {
  id_section: number;
  label: string;
  name: string;
}

export interface TypeCriteria {
  description: string;
  id_period: number;
  id_section: number;
  type_id: string;
}

export interface Type {
  id_type: number;
  label: string;
  name: string;
}

export interface CriteriaCondition {
  id_condition: number;
  label: string;
  symbol: string;
}

export interface SalesOrg {
  label: string;
  name: string;
}

export interface AllianceGroup {
  label: string;
  name: string;
}

export interface TierInfo {
  id_tier?: number | null;
  target_perc?: number | null;
  target_value?: number | null;
  target_baseline?: string | null;
  target_actual?: string | null;
  rebate_target_perc?: number | null;
  growth_target?: number | null;
  from_target?: number | null;
  trending_rebate?: number | null;
  rebate_header_value?: number | null;
  rebate_header_label?: string | null;
}

export interface CriteriaType {
  label: string;
  value: string;
}

export interface CriteriaItem {
  id_criteria?: number | null;
  value?: number | null;
  min?: number | null;
  max?: number | null;
  target_type_id?: string | null;
  type_id?: string | null;
  condition_id?: number | null;
  type?: string | null;
  // condition?: any;
}

export interface TierItem {
  tier?: TierInfo;
  criterias?: Array<CriteriaItem>;
}

export interface Criteria {
  id?: number | null;
  conditions?: Array<CriteriaItem>;
}

export interface RebateData {
  id_rebate?: number | null;
  rebate_code?: string | null;
  id_customer?: number | null;  name?: string | null;
  id_period?: number | null;
  id_type?: number | null;
  alliance_group?: string | null;
  sales_org?: string | null;
  sequence?: number | null;
  stop?: boolean | null;
  id_section?: number | null;
  start_date?: string | null;
  end_date?: string | null;
  status?: string | null;
  message?: string | null;
  company_code?: string | null;
  customer_status?: Array<CustomerStatus> | null;
  criterias?: Array<Criteria> | null;
  brands?: Array<string> | null;
  tiers?: Array<TierItem> | null;

}

export interface TypeIdLevelItem {
  identifier: string;
  displayTitle: string;
  value: any;
  isCustom: boolean;
  children?: TypeIdLevelItem[] | null;
}



export interface TypeIdModel {
  type_id: string | null;
  in_group1: string | null;
  in_group2: string | null;
  in_group3: string | null;
  db_gr2: string | null;
  db_gr3: string | null;
  currency_name: string | null;
  currency_type: string | null;
  type_indicator: string | null;
  data_group: string | null;
}

export interface TypeIdFormModel {
  groups: Array<TypeIdGroup>;
  min_level?: number | null;
  company_code?: string | null;
  sales_org?: string | null;
  type_ids: Array<TypeIdModel>;
}

export const apiRebateInitialize = () => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('jwtToken')}` },
  };
  return axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/rebate/initialize`, config);
};

export const apiAdminRebateList = () => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('jwtToken')}` },
  };
  return axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/rebate/list`, config );
};

export const apiAdminRebateSave = ({ data }: { data: RebateData }) => {
  const bodyParameters = {
    data: data,
  };
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('jwtToken')}` },
  };
  return axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/rebate/save`, bodyParameters, config );
};

export const apiAdminRebateGet = ({ id }: { id: string }) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('jwtToken')}` },
  };
  return axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/rebate?id_rebate=${id}`, config);
};

export const apiAdminRebateDelete = ({ id }: { id: number }) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('jwtToken')}` },
  };
  return axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/v1/rebate?id_rebate=${id}`, config);
};

export const apiAdminTypeIDSave = ({ data }: { data: TypeIdCreateRequestModel }) => {
  /*
  const bodyParameters = {
    data: data,
  };
  */
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('jwtToken')}` },
  };
  return axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/typeids`, data, config);
};

export const getGroupLevels = () => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('jwtToken')}` },
  };
  return axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/levels`, config);
};
