/** @format */

import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// import { selectorIsAuthenticated } from '../@ducks/auth/selectors';
import { /*ADMIN_ROLE, */ ROUTE_HOME } from '../constants/general';
// import { useAppSelector } from '../hooks/redux';

export const ProtectedRoute: FC = (): JSX.Element => {
  const isAuthenticated = localStorage.getItem('jwtToken') !== null && localStorage.getItem('jwtToken') !== 'null'; // useAppSelector(selectorIsAuthenticated);
  // const isAuthenticated = useAppSelector(selectorIsAuthenticated);
  return isAuthenticated ? <Outlet /> : <Navigate to={ROUTE_HOME} replace />;
};

export const ProtectedAdminRoute: FC = (): JSX.Element => {
  const isAuthenticated = localStorage.getItem('jwtToken') !== null && localStorage.getItem('jwtToken') !== 'null'; // useAppSelector(selectorIsAuthenticated);
  const isAdmin = true; // localStorage.getItem('role') !== null && localStorage.getItem('role') === ADMIN_ROLE;
  // const isAuthenticated = useAppSelector(selectorIsAuthenticated);
  return (isAuthenticated && isAdmin) ? <Outlet /> : <Navigate to={ROUTE_HOME} replace />;
};
