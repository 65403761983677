/** @format */

import { FC } from 'react';
import styled from '@emotion/styled';
import { mapColors } from '../../../../theme/mapColors';

const Label = styled.p({
  height: '20px',
  width: '62px',
  border: `1px solid ${mapColors.backgrounds.borderPrimary}`,
  borderRadius: '10px',
  color: mapColors.fonts.fontPrimary,
  fontWeight: 'bold',
  fontSize: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '7px 20px',
});

interface Props {
  name: string;
}
export const LabelGPN: FC<Props> = ({ name }): JSX.Element => {
  return <Label>{name}</Label>;
};
