/** @format */

import { CSSProperties, FC, memo } from 'react';

import styled from '@emotion/styled';
import { mapColors } from '../theme/mapColors';
//import { APP_COPYRIGHT, APP_VERSION } from '../constants/general';
//import { BRANDS_STRIPE_LINE_1, BRANDS_STRIPE_LINE_2 } from '../constants/general';
import logosStripe from '../assets/images/footerbrands.png';
import { useLocation } from 'react-router-dom';

//#region ::: PARTIALS
const FooterContainer = styled.div({
  zIndex: 4,
  fontWeight: '600',
  fontSize: '8px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: 0,
  color: mapColors.fonts.fontTertiary,
  alignItems: 'center',
  maxWidth: '920px',
  minWidth: '320px',
  textAlign: 'center',
  textJustify: 'inter-word',
  lineHeight: '130%',
});

// const Strong = styled.b({
//   margin: '8px 4px 8px 8px',
//   color: mapColors.fonts.fontPrimary,
//   fontWeight: 'bold',
//   minWidth: '700px',
// });

const Info = styled.div({
  fontSize: '12px',
  fontWeight: 'bold',
  textAlign: 'center',
  display: 'none',
  justifyContent: 'center',
  color: '#2E2F32',
  padding: '8px 0',
  width: '100%',
});

//#endregion

const Footer = styled.div({
  //position: 'inherit',
  backgroundColor: 'transparent',
  //borderTop: 'solid 1px #eee %',
  bottom: '0px',
  //top:'50vh',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  zIndex: 999999,
  //marginTop: '12px',
  marginTop: 'auto',
  marginBottom: '0px',
  height: '190px',
});

interface Props {
  style?: CSSProperties;
}

export const FooterContent: FC<Props> = memo(({ style }): JSX.Element => {
  const location = useLocation();
  if (
    location.pathname !== '/' &&
    location.pathname !== '/login') {
    return (
      <Footer>
        <FooterContainer style={style}>
          <img src={logosStripe} height="106.5px" />
          {/* <Strong>
            {BRANDS_STRIPE_LINE_1}
            <br />
            {BRANDS_STRIPE_LINE_2}
          </Strong> */}
          <Info id="update-info" />
        </FooterContainer>
      </Footer>
    );
  } else {
    return <></>;
  }
});
