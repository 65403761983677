/** @format */

import { FC } from 'react';
import { UIDetail } from './UIDetail';

import iconPay from '../../../../assets/icons/solid/Pay.svg';
import iconVisa from '../../../../assets/icons/solid/Visa.svg';
//import iconHandshake from '../../../../assets/icons/outline/Handshake.svg';
//import iconStar from '../../../../assets/icons/solid/Star.svg';
import iconReport from '../../../../assets/icons/solid/Report.svg';
import iconTranslate from '../../../../assets/icons/outline/Translate.svg';
import iconDollar from '../../../../assets/icons/outline/Dollar.svg';
import iconMap from '../../../../assets/icons/solid/Map.svg';

//#region ::: PARTIALS

/*
const ColumnBox = styled.div<ColumnBoxProps>({
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
  width: '100%',
  justifyContent: 'start',
});

const Container = styled.div({
  margin: '16px',
  borderRadius: '10px',
  border: `1px solid ${mapColors.backgrounds.borderPrimary}`,
  backgroundColor: ' white',
  width: '97%',
  borderCollapse: 'collapse',
});
*/

//#endregion

type TableGeneralInfoProps = {
  customer: any;
};

export const TableGeneralInfo: FC<TableGeneralInfoProps> = ({ customer = null }): JSX.Element => {
  const currentData = customer && customer ? customer?.customer_data.payers : [];

  // function getCustomerRatingString(value: string) {
  //   if (value.toLowerCase() === 'a') return 'Diamond';
  //   if (value.toLowerCase() === 'b') return 'Platinum';
  //   if (value.toLowerCase() === 'c') return 'Gold';
  //   if (value.toLowerCase() === 'd') return 'Silver';
  //   return '';
  // }

  const table = currentData.map((el: any, index: number) => (
    <tr key={index}>
      <td>{el.payer_code}</td>
      <td>{el.payer_name}</td>
      {/*<td>{el.alliance_name}</td>
       <td>{getCustomerRatingString(el.customer_rating)}</td> */}
      <td>{el.reporting_scope}</td>
      <td>{el.language}</td>
      <td>{el.currency}</td>
      <td>{el.country}</td>
    </tr>
  ));

  return (
    <div className="data-container">
      <table>
        <thead>
          <tr>
            <th>
              <UIDetail label={'Payer'} value={'6545654'} srcIcon={iconPay} />
            </th>
            <th>
              <UIDetail label={'Payer Name'} value={'HMI Buying Group Inc'} srcIcon={iconVisa} />
            </th>
            {/*<th>
              <UIDetail label={'Alliance Name'} value={''} srcIcon={iconHandshake} />
            </th>
             <th>
              <UIDetail label={'Payer Rating'} value={'C'} srcIcon={iconStar} />
            </th> */}
            <th>
              <UIDetail label={'Reporting Scope'} value={''} srcIcon={iconReport} />
            </th>
            <th>
              <UIDetail label={'Language'} value={'English'} srcIcon={iconTranslate} />
            </th>
            <th>
              <UIDetail label={'Currency'} value={'Dollar'} srcIcon={iconDollar} />
            </th>
            <th>
              <UIDetail label={'Country'} value={'USA'} srcIcon={iconMap} />
            </th>
          </tr>
        </thead>
        <tbody>{table}</tbody>
      </table>
    </div>
  );
};
