/** @format */
import { FC } from 'react';
import { UITabTitle } from '../../Dashboard/partials/TabTitle';
import { Expandable } from '../../../shared/Expandable';
import { BackButton } from '../partials/BackButton';

import { TypeIdMainView } from './TypeIdMainView';
import { MainHeader } from '../../../shared/MainHeader';

export const AdminTypeIDs: FC = (): JSX.Element => {


  return (
    <>
      {/*<Header isSearch={true} />*/}
      <MainHeader
        showUser={false}
        enableLeftMenu={false}
        showNavigationBar={false}
        showPdfExportButton={false}
        showSearchBar={false}
        showSearchButton={false}
        customer={null} />

      <section className="main admin admin_typeids">
        <div className="container admin_rebates">
          <div className="row">
            <BackButton />
          </div>

          <div className="data-panel">
            <UITabTitle
              label={'Add new type ID'}
              infoMessage={''}
              collapsed={false}
              expandable={false}
              url={''}
              customerId={'0'}
            />
            <Expandable expanded={true} boxed={false}>
              <TypeIdMainView />

            </Expandable>
          </div>
        </div>
      </section>
    </>
  );
};