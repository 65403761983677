/** @format */

import { FC, useState, useEffect } from 'react';
import { UITabTitle } from '../partials/TabTitle';
import { Expandable } from '../../../shared/Expandable';
import { BOX_TITLE_BRAND_PERFORMANCE, ROUTE_HOME } from '../../../constants/general';
import { PerformanceGraph } from './partials/Performance_graph';
import { PerformanceFilter } from './partials/Performance_filter';
import { PerformanceLegend } from './partials/Performance_Legend';
import { PerformanceSideGraph } from './partials/Performance_side_graph';
import './partials/Performance_graph.scss';
import { loaderSlice } from '../../../services/loader/slice';
import { apiRefresh } from '../../../services/auth/api';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { apiPerformances, apiPerformancesStandard } from '../../../services/performance/api';
import { setCurrentYear, BrandStandardResponse, getMonthName, loadGraphData, loadGraphDataSum, parseBrandsForSelection, parseCustomersForSelection, retrieveAllBrandsList } from './partials/Performance_graph_service';
import { PerformanceBrandSelection } from './partials/Performance_brand_selection';
import { LearnMore } from './partials/LearnMore';
import { clearStorage } from '../../../utils/localStorageUtils';
import { Selection, SelectionMode } from '../../../shared/Selection';

type BrandProps = {
  customer: any;
};

export const BrandPerformance: FC<BrandProps> = ({ customer = null }): JSX.Element => {
  const [currentGraphData, setCurrentGraphData] = useState<any[]>([]);
  const [currentDollarsData, setCurrentDollarsData] = useState<any[]>([]);
  const [currentUnitsData, setCurrentUnitsData] = useState<any[]>([]);
  const [currentResponse, setCurrentResponse] = useState<any>(null);
  const [currentBrandIds, setCurrentBrandIds] = useState<string[]>(['']);
  const [currentCustomer, setCurrentCustomer] = useState<string | undefined>();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const customerCode = customer?.customer_data?.customer_code || null;
  const customerId = customer?.customer_data?.customer_id || null;
  const commonOwnerCode = customer ? customer?.customer_data.common_owner_code : '';
  const isCommonOwner = commonOwnerCode !== null && commonOwnerCode.length > 0;
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];

  const [isExpanded, setIsExpanded] = useState(false);
  const [currentType, setCurrentType] = useState<string>('');
  const [currentDateRange, setCurrentDateRange] = useState<string>('');
  const [allCustomers, setAllCustomers] = useState<{ code: string, isSelected: boolean }[]>([]);
  const [allBrands, setAllBrands] = useState<{ code: string, category?: string, expanded: boolean }[]>([]);
  const [popUpData, setPopUpData] = useState<BrandStandardResponse[]>([]);

  function _loadMainCustomerData(data: any) {
    const list: any[] = data.customer_performance;
    if (list.length === 0) {
      return [];
    }
    if (currentCustomer === undefined || currentCustomer === 'All Customers') {
      return data.all_performance;
    }
    const object = list.find((customerItem: any) => customerItem.customer_code === currentCustomer);

    if (object === undefined) {
      return [];
    }
    return object.year_performance;
  }

  function _parseResponse(data: any, defaultCustomerId: string, defaultType: string, defaultRange: string) {
    if (data === undefined) {
      return;
    }
    if (data.all_performance === undefined) {
      return;
    }
    const performanceList: any[] = data.all_performance;
    if (performanceList.length === 0) {
      return;
    }

    let flatList: any[] = [];
    data.all_performance.forEach((list: any) => flatList = [...flatList, ...list.brand_performance]);


    //Carmine: CURRENT YEAR DINAMIC FIX
    const date = new Date();
    let currentYear = date.getFullYear();
    if (data.all_performance.length > 0) {
      currentYear = 0;
      data.all_performance.forEach(function (value: any) {
        const currentYearTmp = parseInt(value.year, 10);
        if (currentYearTmp > currentYear) {
          currentYear = currentYearTmp;
        }
      });
    }
    setCurrentYear(currentYear);

    const customersData: any[] = data.customer_performance;
    setAllBrands(parseBrandsForSelection(flatList));
    setAllCustomers(parseCustomersForSelection(customersData));

    setCurrentBrandIds(retrieveAllBrandsList(flatList));
    setCurrentCustomer(defaultCustomerId);
    setCurrentType(defaultType);
    setCurrentDateRange(defaultRange);
  }

  function _updateMainGraph(type: string, dateRange: string) {
    if (currentResponse === null) {
      return;
    }

    const _graphData = loadGraphData(currentResponse.data.data, currentBrandIds,
      (data) => _loadMainCustomerData(data),
      (subdata) => { return subdata.brand_performance; },
      (item) => {
        if (dateRange === 'quarter') {
          return item.quarter;
        } else {
          return item.month;
        }
      },
      (item) => {
        if (type === 'dollar') {
          return item.amount ?? 0;
        } else {
          return item.unit ?? 0;
        }
      },
      (item) => {
        if (dateRange === 'quarter') {
          return item;
        } else {
          return getMonthName(item);
        }
      },
      () => {
        if (type === 'dollar') {
          return '$';
        } else {
          return '';
        }
      },
      (dateRange === 'quarter') ? quarters : months);



    setCurrentGraphData(_graphData);
  }

  function _updateSideGraph(currentBrands: string[]) {
    if (currentResponse === undefined || currentResponse === null) {
      return;
    }

    const _dollarsGraph = loadGraphDataSum(currentResponse.data.data, currentBrands,
      (data) => _loadMainCustomerData(data),
      (subdata) => { return subdata.brand_performance; },
      (item) => { return item.month; },
      (item) => { return item?.amount; },
      months);

    setCurrentDollarsData(_dollarsGraph);

    const _unitsdata = loadGraphDataSum(currentResponse.data.data, currentBrands,
      (data) => _loadMainCustomerData(data),
      /*
      (data) => {
        const list = data.customer_performance;
        
        if (list.length === 0) {
          return [];
        }
        return list[0].year_performance;
      },
      */
      (data) => {
        return data.brand_performance;
      },
      (item) => { return item.month; },
      (item) => { return item?.unit; },
      months);

    setCurrentUnitsData(_unitsdata);
  }

  function _loadPopUpData() {
    dispatch(loaderSlice.actions.startLoading());
    apiPerformancesStandard({
      code: parseInt(customerId ?? '0.0', 10),
    }).then(response => {
      if (response) {
        setPopUpData(response.data.data);
      }
      dispatch(loaderSlice.actions.stopLoading());
    }).catch(error => {
      const refreshToken = localStorage.getItem('refreshToken');
      if (error.response && error.response.status && error.response.status === 401 && refreshToken) {
        apiRefresh({ refreshToken })
          .then(response => {
            if (response.data?.data?.jwt_token && response.data?.data?.refresh_token) {
              localStorage.setItem('jwtToken', response.data.data.jwt_token);
              localStorage.setItem('refreshToken', response.data.data.refresh_token);
              _loadPopUpData();
            }
          })
          .catch(() => {
            clearStorage();
            navigate(`${ROUTE_HOME}`, { replace: true });
          });
      } else {
        clearStorage();
        navigate(`${ROUTE_HOME}`, { replace: true });
      }
    });
  }

  function _loadData(isCommonOwner: boolean, type: string, range: string, code?: string, retry = true) {
    if (code) {
      dispatch(loaderSlice.actions.startLoading());
      apiPerformances({
        code: code,
        isCommonOwner: isCommonOwner,
      }).then(response => {
        setCurrentResponse(response);
        if (response) {
          _parseResponse(response.data.data, 'All Customers', type, range);
        }
        dispatch(loaderSlice.actions.stopLoading());
      }).catch(error => {
        const refreshToken = localStorage.getItem('refreshToken');
        if (error.response && error.response.status && error.response.status === 401 && refreshToken) {
          apiRefresh({ refreshToken })
            .then(response => {
              if (response.data?.data?.jwt_token && response.data?.data?.refresh_token) {
                localStorage.setItem('jwtToken', response.data.data.jwt_token);
                localStorage.setItem('refreshToken', response.data.data.refresh_token);
                if (retry) {
                  _loadData(isCommonOwner, type, range, code, true);
                }
              }
            })
            .catch(() => {
              clearStorage();
              navigate(`${ROUTE_HOME}`, { replace: true });
            });
        } else {
          clearStorage();
          navigate(`${ROUTE_HOME}`, { replace: true });
        }
      });
    }
  }

  useEffect(() => {
    _loadData(isCommonOwner, 'dollar', 'quarter', customerCode, true);
  }, [customerCode, isCommonOwner]);

  useEffect(() => {
    _loadPopUpData();
  }, [customerId]);

  useEffect(() => {
    _updateMainGraph(currentType, currentDateRange);
    _updateSideGraph(currentBrandIds);
  }, [currentResponse, currentDateRange, currentType, currentCustomer, currentBrandIds]);

  function setBrands(brands: string[]) {
    if (brands.length === 0) {
      setCurrentBrandIds(retrieveAllBrandsList(currentResponse.data.data.all_performance[0].brand_performance));
      return;
    }
    setCurrentBrandIds(brands);
  }
  //// HTML LAYOUT
  const values = {
    'PY (Previous year)': '#bdcbfc',
    'CY (Current year)': '#091f5a',
  };
  return (
    <div id="el360" className="data-panel">

      <UITabTitle
        label={BOX_TITLE_BRAND_PERFORMANCE}
        infoMessage={''}
        onClick={() => setIsExpanded(!isExpanded)}
        collapsed={isExpanded}
      />

      <Expandable expanded={isExpanded} boxed={true}><>
        <div className='performance'>
          <div className='performance__header'>
            <div className={'performance__title box--title'}>
              Sales
            </div>
            <div className='performance__selection-container'>
              <Selection<string>
                id="_customer_"
                name="_customer_"
                componentKey="_customer_"
                cssClass='customers-selection'
                disabled={false}
                map={allCustomers}
                placeholder='Filter by Customer...'
                displayExpr='code'
                valueExpr='code'
                selectionMode={SelectionMode.single}
                values={[currentCustomer]}
                onChangedSelection={(selection) => setCurrentCustomer(selection)} />

              <PerformanceBrandSelection map={allBrands.sort((a, b) => {
                if (a.category !== undefined && b.category !== undefined) {
                  return a.category > b.category ? 1 : -1;
                }
                return a.code > b.code ? 1 : -1;
              },
              )} onChangedBrands={(list) => {
                if (list.length <= 0) {
                  list = ['$*NOPE*$'];
                } else if (list.indexOf('ALL') >= 0) {
                  if (list.length > 1) {
                    list.splice(list.indexOf('ALL'), 1);
                  } else {
                    list = [];
                  }
                }
                setBrands(list);
              }
              } />
            </div>
          </div>


          <div className={'performance__content'}>
            <div className='performance__content--left'>

              <div className='performance__content--left-bar'>
                <label className='box--title'>Year to date</label>
                <PerformanceLegend elements={values} />
              </div>

              <div className='performance__content--left-top insets30'>
                <label className='side-bar--title'>Dollars</label>
                <PerformanceSideGraph id="side_chart_dollars" data={currentDollarsData} updateLayout={isExpanded} prefix='$' />
              </div>

              <hr className="solid" />

              <div className='performance__content--left-bottom insets30'>
                <label className='side-bar--title'>Units</label>
                <PerformanceSideGraph id="side_chart_units" data={currentUnitsData} updateLayout={isExpanded} />
              </div>

            </div>


            <div className='performance__content--right insets30'>
              <div className='title_bar'>
                <label className='box--title'>Year over year</label>
                <PerformanceLegend elements={values} />
                <div className='performance__content--filters'>
                  <PerformanceFilter values={['Dollar', 'Units']} didPressButtonIndex={(index) => {
                    setCurrentType(index === 0 ? 'dollar' : 'units');
                  }} />
                  <PerformanceFilter values={['Quarter', 'Month']} didPressButtonIndex={(index) => {
                    setCurrentDateRange(index === 0 ? 'quarter' : 'month');
                  }} />
                </div>
              </div>

              <div className='graph-container'>
                <PerformanceGraph data={currentGraphData} updateLayout={isExpanded} prefix={currentType === 'dollar' ? '$' : null} />
              </div>

            </div>
          </div>
        </div>


        <div className='performance__bottom_content'>
          <LearnMore values={popUpData} />
        </div>
      </>
      </Expandable>
    </div>
  );

};