/** @format */

import { mapColors } from '../theme/mapColors';

export type TVariant = 'Success' | 'Warning' | 'Danger' | 'Info';

export const utilityGetColorByVariant = (variant: TVariant) => {
  switch (variant) {
    case 'Success':
      return {
        background: `${mapColors.theme.success[2]}`,
        color: `${mapColors.theme.success[0]}`,
      };
    case 'Danger':
      return {
        background: `${mapColors.theme.danger[3]}`,
        color: `${mapColors.theme.danger[0]}`,
      };

    case 'Warning':
      return {
        background: `${mapColors.theme.warning[3]}`,
        color: `${mapColors.theme.warning[0]}`,
      };

    case 'Info':
      return {
        background: `${mapColors.theme.primary[2]}`,
        color: `${mapColors.theme.primary[0]}`,
      };
  }
};
