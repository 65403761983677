/** @format */

import axios from 'axios';

export const apiEL360Compliance = ({
  code,
  isCommonOwner,
}: {
  code: string
  isCommonOwner: boolean
}) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('jwtToken')}` },
  };
  return axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/customer/el360Compliance?code=${code}&isCommonOwner=${isCommonOwner}`, config );
};
