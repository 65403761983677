/** @format */

import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    id: '',
  },
  reducers: {
    startUser: state => {
      state.id = localStorage.getItem('userId') ?? '';
    },
    stopUser: state => {
      state.id = '';
    },
  },
});

export const { startUser, stopUser } = userSlice.actions;

/*
const loaderStore = configureStore({
  reducer: loaderSlice.reducer,
});

// Can still subscribe to the store
// loaderStore.subscribe(() => console.log(loaderStore.getState()));
*/
