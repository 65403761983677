/** @format */

import { FC } from 'react';
import { ChartBullet } from '../../../../shared/ChartBullet';
// import labelMultiDiamond from '../../../../assets/icons/outline/MultiDiamondLabel.svg';
// import labelDiamond from '../../../../assets/icons/outline/DiamondLabel.svg';
// import labelGold from '../../../../assets/icons/outline/GoldLabel.svg';
// import labelPlatinum from '../../../../assets/icons/outline/PlatinumLabel.svg';
// import labelSilver from '../../../../assets/icons/outline/SilverLabel.svg';
import { numberFormat } from '../../../../utils/numberUtils';
// import { numberFormat } from '../../../../utils/numberUtils';

interface Props {
  value: number;
  needed: number;
  level: string;
  trending_level: string;
  rating: string;
  total: number;
  oldId: number;
}

/*
type Congratulations = {
  oldId: number;
  currentId: number;
  old: string;
  current: string;
  label: string;
};
*/

export const CardBulletChart: FC<Props> = ({
  value,
  level,
  trending_level,
  rating,
  needed,
  total,
  // oldId,
}): JSX.Element => {
  let label = '';
  let showRatingText = false;

  if (rating && rating.length > 0) {
    // if (level.toLowerCase().includes('multi')) label = labelMultiDiamond;
    if (rating.toLowerCase() === 'a') {
      // oldId = 4;
      label = 'Diamond'; // labelDiamond;
    } else if (rating.toLowerCase() === 'c') {
      // oldId = 2;
      label = 'Gold'; // labelGold;
    } else if (rating.toLowerCase() === 'b') {
      // oldId = 3;
      label = 'Platinum'; // labelPlatinum;
    } else if (rating.toLowerCase() === 'd') {
      // oldId = 1;
      label = 'Silver'; // labelSilver;
    } else {
      label = rating;
      showRatingText = true;
    }
  } else {
    
    if (level.toLowerCase().includes('multi') || level.toLowerCase().includes('plus')) {
      label = 'Diamond Plus'; // labelMultiDiamond;
      // oldId = 5;
    } else if (level.toLowerCase().includes('diamond')) label = 'Diamond'; // labelDiamond;
    else if (level.toLowerCase().includes('gold')) label = 'Gold'; // labelGold;
    else if (level.toLowerCase().includes('platinum')) label = 'Platinum'; // labelPlatinum;
    else if (level.toLowerCase().includes('silver')) label = 'Silver';
  }

  let trending_label = null;
  if (trending_level) {
    if (trending_level.toLowerCase().includes('multi') || trending_level.toLowerCase().includes('plus')) trending_label = 'Diamond Plus'; // labelMultiDiamond;
    else if (trending_level.toLowerCase().includes('diamond')) trending_label = 'Diamond'; // labelDiamond;
    else if (trending_level.toLowerCase().includes('gold')) trending_label = 'Gold'; // labelGold;
    else if (trending_level.toLowerCase().includes('platinum')) trending_label = 'Platinum'; // labelPlatinum;
    else if (trending_level.toLowerCase().includes('silver')) trending_label = 'Silver'; // labelSilver;
  }

  /*
  let currentLevelFixed = 0;
  if (total >= 0 && total < 15000) currentLevelFixed = 1;
  if (total >= 15000 && total < 40000) currentLevelFixed = 2;
  if (total >= 40000 && total < 80000) currentLevelFixed = 3;
  if (total >= 80000 && total < 150000) currentLevelFixed = 4;
  if (total >= 150000) currentLevelFixed = 5;
  
  if (showRatingText) {
    oldId = -1;
    currentLevelFixed = -1;
  }

  const congratsTable: Congratulations[] = [
    {
      oldId: 0,
      currentId: 1,
      old: '',
      current: 'silver',
      label: `You need ${numberFormat(15000 - total)} to reach Gold`,
    },
    {
      oldId: 0,
      currentId: 2,
      old: '',
      current: 'gold',
      label: `You need ${numberFormat(40000 - total)} to reach Platinum`,
    },
    {
      oldId: 0,
      currentId: 3,
      old: '',
      current: 'platinum',
      label: `You need ${numberFormat(80000 - total)} to reach Diamond`,
    },
    {
      oldId: 0,
      currentId: 4,
      old: '',
      current: 'diamond',
      label: 'Congratulations for being a Diamond customer',
    },
    {
      oldId: 0,
      currentId: 5,
      old: '',
      current: 'multi',
      label: 'Congratulations for being a Diamond Plus customer',
    },

    {
      oldId: 1,
      currentId: 1,
      old: 'silver',
      current: 'silver',
      label: `You need ${numberFormat(15000 - total)} to reach Gold`,
    },
    {
      oldId: 1,
      currentId: 2,
      old: 'silver',
      current: 'gold',
      label: `Congratulations for reaching Gold. You need ${numberFormat(40000 - total)} to reach Platinum`,
    },
    {
      oldId: 1,
      currentId: 3,
      old: 'silver',
      current: 'platinum',
      label: 'Congratulations for reaching Platinum!',
    },
    {
      oldId: 1,
      currentId: 4,
      old: 'silver',
      current: 'diamond',
      label: 'Congratulations for reaching Diamond!',
    },
    {
      oldId: 1,
      currentId: 5,
      old: '',
      current: 'multi',
      label: 'Congratulations for reaching Diamond Plus!',
    },

    {
      oldId: 2,
      currentId: 1,
      old: 'gold',
      current: 'silver',
      label: `You need ${numberFormat(15000 - total)} to maintain Gold`,
    },
    {
      oldId: 2,
      currentId: 2,
      old: 'gold',
      current: 'gold',
      label: `Congratulations for maintaining Gold. You need ${numberFormat(40000 - total)} to reach Platinum`,
    },
    {
      oldId: 2,
      currentId: 3,
      old: 'gold',
      current: 'platinum',
      label: 'Congratulations for reaching Platinum!',
    },
    {
      oldId: 2,
      currentId: 4,
      old: 'gold',
      current: 'diamond',
      label: 'Congratulations for reaching Diamond!',
    },
    {
      oldId: 2,
      currentId: 5,
      old: '',
      current: 'multi',
      label: 'Congratulations for reaching Diamond Plus!',
    },

    {
      oldId: 3,
      currentId: 1,
      old: 'platinum',
      current: 'silver',
      label: `You need ${numberFormat(15000 - total)} to reach Gold`,
    },
    {
      oldId: 3,
      currentId: 2,
      old: 'platinum',
      current: 'gold',
      label: `You need ${numberFormat(40000 - total)} to maintain Platinum`,
    },
    {
      oldId: 3,
      currentId: 3,
      old: 'platinum',
      current: 'platinum',
      label: `Congratulations for maintaining Platinum. You need ${numberFormat(80000 - total)} to reach Diamond`,
    },
    {
      oldId: 3,
      currentId: 4,
      old: 'platinum',
      current: 'diamond',
      label: 'Congratulations for reaching Diamond!',
    },
    {
      oldId: 3,
      currentId: 5,
      old: '',
      current: 'multi',
      label: 'Congratulations for reaching Diamond Plus!',
    },

    {
      oldId: 4,
      currentId: 1,
      old: 'diamond',
      current: 'silver',
      label: `You need ${numberFormat(15000 - total)} to reach Gold`,
    },
    {
      oldId: 4,
      currentId: 2,
      old: 'diamond',
      current: 'gold',
      label: `You need ${numberFormat(40000 - total)} to reach Platinum`,
    },
    {
      oldId: 4,
      currentId: 3,
      old: 'diamond',
      current: 'platinum',
      label: `You need ${numberFormat(80000 - total)} to maintain your Diamond`,
    },
    {
      oldId: 4,
      currentId: 4,
      old: 'diamond',
      current: 'diamond',
      label: 'Congratulations for maintaining your Diamond',
    },
    {
      oldId: 4,
      currentId: 5,
      old: 'diamond',
      current: 'multi',
      label: 'Congratulations for reaching Diamond Plus!',
    },

    {
      oldId: 5,
      currentId: 1,
      old: 'multi',
      current: 'silver',
      label: `You need ${numberFormat(15000 - total)} to reach Gold`,
    },
    {
      oldId: 5,
      currentId: 2,
      old: 'multi',
      current: 'gold',
      label: `You need ${numberFormat(40000 - total)} to reach Platinum`,
    },
    {
      oldId: 5,
      currentId: 3,
      old: 'multi',
      current: 'platinum',
      label: `You need ${numberFormat(80000 - total)} to reach Diamond`,
    },
    {
      oldId: 5,
      currentId: 4,
      old: 'multi',
      current: 'diamond',
      label: `You need ${numberFormat(150000 - total)} to maintain your Diamond Plus`,
    },
    {
      oldId: 5,
      currentId: 5,
      old: 'multi',
      current: 'multi',
      label: 'Congratulations for maintaining your Diamond Plus',
    },
  ];
  */

  // const congratulations = label === labelDiamond && needed <= 0;
  /*
  const congratulations = congratsTable.filter(obj => {
    return obj.oldId === oldId && obj.currentId === currentLevelFixed;
  });
  */
  return (
    <div className='data-status'>
      <div className='needed'>You need <b>{numberFormat(needed)}</b> to mantain your status</div>
      <div className='separator' />
      <ChartBullet value={value ? value : 0} needed={needed} total={total} />
      {/*congratulations !== null && congratulations.length > 0 && <div className='title'>{congratulations[0].label}</div>*/}
      <div className='title'>Progress for current calendar year</div>
      {label && label.length > 0 && (
        <div className='current'>
          Current Status:&nbsp;<b>{label}</b>
          {false && showRatingText && <span className='rating'>{label}</span>}
          {false && !showRatingText && <img src={label} />}
        </div>
      )}

      {trending_label && trending_label.length > 0 && (
        <div className='trending'>
          Trending 2023 Status: <img src={trending_label} />
        </div>
      )}
    </div>
  );
};
